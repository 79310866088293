import { createFileRoute } from '@tanstack/react-router'

import PlatformEphemeralJobsPage from '../../../components/pages/backoffice/platform/platform-ephemeral-jobs-page/platform-ephemeral-jobs-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/ephemeral-jobs')({
  component: () => (
    <PlatformPage requiredPermissions={['job.runEphemeral']}>
      <PlatformEphemeralJobsPage />
    </PlatformPage>
  ),
})
