import { createTheme, type SxProps } from '@mui/material'

// DO NOT REMOVE THIS LINE
// IntelliJ says it's an unused import, but it's not true !!
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { colors } from './colors.js'
import { borderRadius } from './foundation/border-radius-tokens.js'
import { borderStroke } from './foundation/border-stroke-tokens.js'
import { colorTokens } from './foundation/color-tokens.js'
import { shadow } from './foundation/shadow-tokens.js'
import { spacing } from './foundation/spacing-tokens.js'

/** @deprecated use colors instead */
export const secondaryColor = colors.blue[500]
/** @deprecated use colors instead */
export const primaryColor = colors.gray[900]
/** @deprecated use colors instead */
export const problemColor = colors.red[600]

export const mobileSideSpace = spacing[60]
export const mobileSideSmallSpace = spacing[30]
export const maxDesktopColumnContentWidth = '1303px'

const h1Typography = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '28px',
  lineHeight: '36px',
  fontFamily: 'var(--skin-font-title)',
} as const
const h2Typography = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '24px',
  lineHeight: '32px',
  fontFamily: 'var(--skin-font-title)',
} as const
const h3Typography = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '20px',
  lineHeight: '28px',
  fontFamily: 'var(--skin-font-title)',
} as const
const h4Typography = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '18px',
  lineHeight: '24px',
  fontFamily: 'var(--skin-font-title)',
} as const
const h5Typography = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'var(--skin-font-title)',
} as const
const h6Typography = {
  fontWeight: 'var(--skin-font-weight-subtitle1)',
  fontSize: '18px',
  lineHeight: '24px',
  fontFamily: 'var(--skin-font-subtitle1)',
} as const
const buttonTypography = {
  fontWeight: 'var(--skin-font-weight-button)',
  fontSize: '14px',
  lineHeight: '24px',
  textTransform: 'none',
  fontFamily: 'var(--skin-font-button)',
} as const
const subtitleTypography = {
  fontWeight: 'var(--skin-font-weight-subtitle2)',
  fontSize: '14px',
  lineHeight: '20px',
  fontFamily: 'var(--skin-font)',
} as const
export const captionTypography = {
  fontWeight: 'var(--skin-font-weight-caption)',
  fontSize: '12px',
  lineHeight: '16px',
  textTransform: 'none',
  fontFamily: 'var(--skin-font)',
} as const
const captionMediumTypography = {
  fontWeight: 'var(--skin-font-weight-caption-medium)',
  fontSize: '12px',
  lineHeight: '16px',
  fontFamily: 'var(--skin-font)',
} as const
export const body1Typography = {
  fontWeight: 'var(--skin-font-weight-body1)',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'var(--skin-font)',
} as const
export const body1MediumTypography = {
  fontWeight: 'var(--skin-font-weight-body1-medium)',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'var(--skin-font)',
} as const
const body2Typography = {
  fontWeight: 'var(--skin-font-weight-body2)',
  fontSize: '14px',
  lineHeight: '20px',
  fontFamily: 'var(--skin-font)',
} as const
export const body2MediumTypography = {
  fontWeight: 'var(--skin-font-weight-body2-medium)',
  fontSize: '14px',
  lineHeight: '20px',
  fontFamily: 'var(--skin-font)',
} as const

declare module '@mui/material/Button' {
  // Interface syntax required for theme extension
  // eslint-disable-next-line @orus.eu/no-interfaces
  interface ButtonPropsVariantOverrides {
    rich: true
    warning: true
    primary: true
    secondary: true
    tertiary: true
    danger: true
  }
}

declare module '@mui/material/Paper' {
  // Interface syntax required for theme extension
  // eslint-disable-next-line @orus.eu/no-interfaces
  interface PaperPropsVariantOverrides {
    flat: true
  }
}

declare module '@mui/material/Typography' {
  // Interface syntax required for theme extension
  // eslint-disable-next-line @orus.eu/no-interfaces
  interface TypographyPropsVariantOverrides {
    body1Medium: true
    body2Medium: true
    captionMedium: true
  }
}

export const stripeControlBorder = `${borderStroke[20]} solid #d9d8e1`
export const controlBorder = `${borderStroke[20]} solid #F1F0F5`
export const controlBorderFocus = `${borderStroke[20]} solid #4a37f2`
export const controlShadowFocus = `0 0 ${borderStroke[30]} 2px #dbd6ff`
export const borderDashed = `${borderStroke[20]} dashed #D9D8E1}`

export const spaceBetweenFormFields = spacing[60]

export const removeBorderRadius = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
} as const

export const theme = createTheme({
  cssVariables: true,
  typography: {
    fontFamily: 'var(--skin-font)',
  },
  // Need to add 2 * 24 px to width in figma because there is 24px padding on the sides
  breakpoints: {
    values: {
      xs: 0,
      sm: 800 + 48,
      md: 1180,
      lg: 1200 + 48,
      xl: 1400 + 48,
    },
  },
  shape: {
    borderRadius: 8,
  },
  // MUI theme does not allow coloring with variables, use hexa codes to replace the 'palette' refs below
  palette: {
    primary: {
      main: '#03001A',
    },
    secondary: {
      main: '#4A37F2',
    },
    background: {
      default: colors.linen,
      paper: colors.white,
    },
    action: {
      active: '#03001A',
    },
    text: {
      primary: colorTokens['color-text-base-main'],
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'div',
          subtitle1: 'span',
          subtitle2: 'span',
          body1: 'span',
          body1Medium: 'span',
          body2: 'span',
          body2Medium: 'span',
          caption: 'span',
          captionMedium: 'span',
        },
      },
      styleOverrides: {
        h1: h1Typography,
        h2: h2Typography,
        h3: h3Typography,
        h4: h4Typography,
        h5: h5Typography,
        h6: h6Typography,
        subtitle1: subtitleTypography,
        subtitle2: subtitleTypography,
        body1: body1Typography,
        body2: body2Typography,
        caption: captionTypography,
        button: buttonTypography,
      },
      variants: [
        {
          props: { variant: 'body1Medium' },
          style: body1MediumTypography,
        },
        {
          props: { variant: 'body2Medium' },
          style: body2MediumTypography,
        },
        {
          props: { variant: 'captionMedium' },
          style: captionMediumTypography,
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius[20],
          textTransform: 'none',
          whiteSpace: 'nowrap',
          // necessary for links (text is centered by default on buttons)
          textAlign: 'center',
        },
        contained: {
          color: colors.white,
          border: 'none',
          backgroundColor: colors.blue[500],
          boxShadow: 'none !important',
          '&:hover': {
            border: 'none',
            backgroundColor: colors.blue[600],
          },
          '&:focus': {
            border: 'none',
            backgroundColor: colors.blue[700],
          },
          '&:disabled': {
            color: colors.gray[400],
            backgroundColor: colors.gray[200],
          },
          '&.MuiButton-containedError': {
            backgroundColor: colors.red[600],
          },
          '&.MuiButton-containedError:hover': {
            border: 'none',
            backgroundColor: colors.red[700],
          },
          '&.MuiButton-containedError:focus': {
            border: 'none',
            backgroundColor: colors.red[500],
          },
        },
        outlined: {
          border: `1px solid ${colors.gray[300]}`,
          backgroundColor: colors.white,
          '&:hover': {
            border: `1px solid ${colors.gray[300]}`,
            backgroundColor: colors.gray[100],
          },
          '&:focus': {
            border: `1px solid ${colors.gray[900]}`,
            backgroundColor: colors.white,
          },
          '&:disabled': {
            border: `1px solid ${colors.gray[300]}`,
            backgroundColor: colors.gray[200],
          },
        },
        text: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base']}`,
          },
          '&:hover fieldset': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-hover']} !important`,
          },
          '&.Mui-focused fieldset': {
            border: `${borderStroke[30]} solid ${colorTokens['color-stroke-base-selected']} !important`,
            boxShadow: `0 0 0 ${borderStroke[30]} ${colorTokens['color-stroke-base-highlight']} !important`,
          },
          '&.Mui-disabled fieldset': {
            border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-disable']} !important`,
          },
          '&.Mui-error fieldset': {
            border: `${borderStroke[30]} solid ${colorTokens['color-stroke-danger']}; !important`,
          },
        },
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          height: '4px',
          backgroundColor: colors.blue[200],
        },
        bar: {
          backgroundColor: colors.blue[500],
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius[20],
          border: controlBorder,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid',
          borderBottomColor: colorTokens['color-stroke-base'],
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          paddingLeft: spacing[60],
          paddingRight: spacing[60],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          position: 'relative',
          // can't do the borders using CSS because the design would require padding or margin
          // on table rows, which is not supported in HTML.
          '&:not(:last-of-type):after': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            bottom: '0',
            height: '1px',
            left: 0,
            right: 0,
            backgroundColor: colorTokens['color-stroke-base'],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: body2MediumTypography,
        ...body2Typography,
        root: {
          borderBottom: 'none',
          paddingLeft: spacing[60],
          paddingRight: spacing[60],
          paddingTop: spacing[50],
          paddingBottom: spacing[50],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          boxShadow: shadow.bottom[20],
        },
      },
      variants: [
        {
          props: { variant: 'flat' },
          style: {
            backgroundColor: colors.white,
            boxShadow: 'none',
            border: controlBorder,
            borderRadius: borderRadius[20],
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...body1Typography,
          backgroundColor: 'white',
          '&.Mui-disabled': {
            backgroundColor: colors.gray[100],
            color: colors.gray[500],
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: colors.gray[300],
          '&.Mui-checked': {
            color: colors.blue[500],
          },
          '&.Mui-disabled': {
            color: colors.gray[200],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.gray[300],
          '&.Mui-checked': {
            color: colors.blue[500],
          },
          '&.Mui-disabled': {
            color: colors.gray[200],
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: colors.black,
        },
        tooltip: {
          backgroundColor: colors.black,
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ...body1Typography,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          ...body1Typography,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.MuiSlider-root': {
            height: '20px',
            padding: 0,
            width: '100%',
          },
          '& .MuiSlider-thumb': {
            backgroundColor: colors.white,
          },
          '& .MuiSlider-rail': {
            height: '4px',
            backgroundColor: colorTokens['color-bg-base-tertiary'],
            opacity: 1,
          },
          '& .MuiSlider-track': {
            height: '4px',
            backgroundColor: colorTokens['color-fg-base-active-inverse'],
            border: 0,
          },
          '& .MuiSlider-mark': {
            backgroundColor: colors.white,
            opacity: 1,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.gray[100],
            color: colors.blue[500],
          },
        },
      },
    },
  },
})

export const largeScreenBreakpointName = 'md'
const largeScreenBreakpoint = theme.breakpoints.up(largeScreenBreakpointName)
theme.typography.h1[largeScreenBreakpoint] = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '44px',
  lineHeight: '54px',
}
theme.typography.h2[largeScreenBreakpoint] = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '36px',
  lineHeight: '44px',
}
theme.typography.h3[largeScreenBreakpoint] = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '32px',
  lineHeight: '40px',
}
theme.typography.h4[largeScreenBreakpoint] = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '28px',
  lineHeight: '36px',
}
theme.typography.h5[largeScreenBreakpoint] = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '24px',
  lineHeight: '32px',
}
theme.typography.h6[largeScreenBreakpoint] = {
  fontWeight: 'var(--skin-font-weight-title)',
  fontSize: '22px',
  lineHeight: '18px',
}

export const disabledLabelStyle: SxProps = {
  opacity: 0.5,
}

export const sectionDelimitersBorder = `1px solid ${colorTokens['color-stroke-base']}`
