import { css } from '@emotion/react'
import {
  DialogWithRows,
  PageTitle,
  RowButtonV2,
  RowContainerV2,
  RowWithLink,
  spacing,
  Text,
  useDialogVisibility,
} from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import { useCallback } from 'react'

export default function HealthInsurancePage(): JSX.Element {
  const {
    show: showMutaContactModal,
    hide: hideMutaContactModal,
    visible: visibleMutaContactModal,
  } = useDialogVisibility(`show-muta-contact`)

  const navigate = useNavigate()

  const onHealthInsuranceBeneficiariesClick = useCallback(() => {
    void navigate({ to: '/health-insurance/beneficiaries' })
  }, [navigate])

  const onHealthInsuranceCpmsClick = useCallback(() => {
    void navigate({ to: '/health-insurance/cpms' })
  }, [navigate])

  const onHealthInsuranceSanteclairClick = useCallback(() => {
    void navigate({ to: '/health-insurance/santeclair' })
  }, [navigate])

  return (
    <>
      <PageTitle title="Ma couverture santé" />
      <div
        css={css`
          max-width: 800px;
          margin: auto;
          padding: 0 ${spacing[60]} 0 ${spacing[60]};
        `}
      >
        <Text
          variant="subtitle"
          css={css`
            margin-bottom: ${spacing[50]};
            margin-top: ${spacing[50]};
          `}
        >
          Vos démarches
        </Text>
        {visibleMutaContactModal ? (
          <DialogWithRows onClose={hideMutaContactModal} title="Nous contacter">
            <RowWithLink href='mailto:hello@orus.eu">hello@orus.eu' text="orus@cpms.fr" />
            <RowWithLink href="tel:0186479059" text="01 86 47 90 59" />
          </DialogWithRows>
        ) : (
          <></>
        )}
        <RowContainerV2
          css={css`
            border: none;
          `}
        >
          <RowButtonV2 primaryText="Vos bénéficiaires" onClick={onHealthInsuranceBeneficiariesClick} />
          <RowButtonV2 primaryText="Nous contacter" onClick={showMutaContactModal} />
        </RowContainerV2>
        <Text
          variant="subtitle"
          css={css`
            margin-bottom: ${spacing[50]};
            margin-top: ${spacing[50]};
          `}
        >
          Vos suivis et consultations
        </Text>
        <RowContainerV2
          css={css`
            border: none;
          `}
        >
          <RowButtonV2 primaryText="Votre application de gestion" onClick={onHealthInsuranceCpmsClick} />
          <RowButtonV2 primaryText="Le réseau Santéclair" onClick={onHealthInsuranceSanteclairClick} />
        </RowContainerV2>
      </div>
    </>
  )
}
