import type { SiretSearchSubscriptionUiElement } from '@orus.eu/dimensions'
import { ifStateProxy } from '../../if-state-proxy'
import { SiretBoSearchElement } from './SiretBoSearchElement'
import { SiretSOSearchElement } from './SiretSOSearchElement'

export const SiretSearchSubscriptionUiElementBlock = ifStateProxy<SiretSearchSubscriptionUiElement>(
  function SiretSearchSubscriptionUiElementBlock({ handleSubmit, uiElement, stateProxy, synchronizing, context }) {
    return (
      <>
        {context === 'backoffice' ? (
          <SiretBoSearchElement
            stateProxy={stateProxy}
            uiElement={uiElement}
            handleSubmit={handleSubmit}
            synchronizing={synchronizing}
          />
        ) : (
          <SiretSOSearchElement
            stateProxy={stateProxy}
            uiElement={uiElement}
            handleSubmit={handleSubmit}
            synchronizing={synchronizing}
          />
        )}
      </>
    )
  },
)
