import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { InsuranceHistorySectionState } from '@orus.eu/dimensions'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo } from 'react'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../detailed-sheet.types'
import { CommentArea } from './common/CommentArea'
import CommonSection from './common/CommonSection'
import { DEFAULT_INSURANCE_TIMELINE_TEMPLATE_AS_STRING } from './rich-text-editor/constants/insurance-timeline-table-template'

type InsuranceHistorySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: InsuranceHistorySectionState
  stateBefore: InsuranceHistorySectionState
  setChanges: (changes: InsuranceHistorySectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (label: GroupCategory) => void
  isReadOnly: boolean
}

export const InsuranceHistorySection = memo(function InsuranceHistorySection(props: InsuranceHistorySectionProps) {
  const { state, setChanges, isReadOnly } = props

  const handleHistorySynthesisChange = (value: string) => {
    setChanges({ detailedSheetHistorySynthesis: value })
  }

  const detailedSheetHistorySynthesisValue =
    state.detailedSheetHistorySynthesis && state.detailedSheetHistorySynthesis !== ''
      ? state.detailedSheetHistorySynthesis
      : DEFAULT_INSURANCE_TIMELINE_TEMPLATE_AS_STRING

  const leftContent = (
    <CommentArea
      onChange={handleHistorySynthesisChange}
      value={detailedSheetHistorySynthesisValue}
      tableTemplate="insurance-timeline"
      readonly={isReadOnly}
    />
  )

  const title = <Row variant="descriptive" title="Timeline antécédents d’assurance" />

  return (
    <CommonSection
      title={title}
      leftContent={leftContent}
      value={detailedSheetHistorySynthesisValue}
      onChange={(value) => {
        setChanges({ detailedSheetHistorySynthesis: value })
      }}
    />
  )
})
