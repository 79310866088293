import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from '@mui/material'
import { memo } from 'react'
import { spacing } from '../../foundation/spacing-tokens.js'
import { useForwardUtm } from '../../hooks/use-forward-utm.js'
import { OrusHorizontalLogoImage } from '../../skin/default-skin/orus-horizontal-logo-image.js'
import { useSkin } from '../../skin/hooks.js'

type HorizontalLogoProps = {
  width?: number
  height?: number
  className?: string
  color: string
}

export const HorizontalLogo = memo<HorizontalLogoProps>(function HorizontalLogo(props) {
  const forwardUtm = useForwardUtm()
  const { width, height, className, color } = props
  const skin = useSkin()
  const { aspectRatio, ImageComponent } = skin.horizontalLogo

  let widthToApply
  let heightToApply
  if (width == undefined && height != undefined) {
    widthToApply = height * aspectRatio
    heightToApply = height
  } else if (width != undefined && height == undefined) {
    widthToApply = width
    heightToApply = width / aspectRatio
  } else if (width != undefined && height != undefined) {
    throw new Error('Cannot specify both width and height')
  } else {
    throw new Error('Must specify either width or height')
  }

  return skin.showByOrusInHeader ? (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${spacing[50]};
      `}
    >
      <ImageComponent className={className} width={widthToApply} height={heightToApply} color={color} />
      <ByOrus decoratedLogoHeight={heightToApply} prefix={skin.showByOrusInHeader} />
    </div>
  ) : (
    <LogoLink href={forwardUtm('https://www.orus.eu/')}>
      <ImageComponent className={className} width={widthToApply} height={heightToApply} color={color} />
    </LogoLink>
  )
})

const ByOrus = memo<{ decoratedLogoHeight: number; prefix: 'by' | 'with' }>(function ByOrus({
  decoratedLogoHeight,
  prefix,
}) {
  const scale = decoratedLogoHeight / 21
  return (
    <div
      css={css`
        display: flex;
        padding: ${7 * scale}px ${10 * scale}px;
        align-items: baseline;
        gap: ${spacing[20]};
        background-color: #e2dcfe;
        border-radius: ${spacing[30]};
      `}
    >
      <span
        css={css`
          color: #151318;
          text-align: center;
          font-family: Poppins;
          font-size: ${12 * scale}px;
          font-style: normal;
          font-weight: 400;
          line-height: ${18 * scale}px;
          letter-spacing: ${0.3 * scale}px;
        `}
      >
        {prefix}
      </span>
      <OrusHorizontalLogoImage width={32.5 * scale} height={10 * scale} color="#0C0331" />
    </div>
  )
})

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`
