import { success, type Result } from '@orus.eu/result'
import type { AllParams, RegisteredRouter, RouteIds } from '@tanstack/react-router'

export type RouteId = RouteIds<RegisteredRouter['routeTree']>
export type PathParamName = keyof AllParams<RegisteredRouter['routeTree']>

export function getPageNameAndResourceIdFromRoute({
  routeId,
  params,
}: {
  routeId: RouteId
  params: Partial<Record<PathParamName, string>>
}): Result<{ pageName: string; resourceId: string | null }, 'no-match'> {
  if (routeId === '/') {
    return success({ pageName: 'ROOT', resourceId: null })
  }

  // RegisteredRouter Ids are defined as any while they are string
  const pathElements = (routeId as string).split('/').filter((s) => !!s)

  const pageNameSegments: string[] = []
  let resourceId: string | null = null

  pathElements.forEach((pathElement, pathElementIndex) => {
    if (pathElement.startsWith('$')) {
      const variableName = pathElement.slice(1) as PathParamName
      const variableValue = params[variableName]

      const behavior = pathVariableNamesBehavior[variableName]

      if (behavior === 'discarded') {
        return
      } else if (behavior === 'included-in-name' && variableValue) {
        pageNameSegments.push(toViewEventWord(variableValue))
      } else if (behavior === 'included-in-resource-id' && !resourceId && variableValue) {
        resourceId = variableValue
      }
    } else {
      const followedByVariable = pathElements[pathElementIndex + 1]?.startsWith('$')
      const eventWord = toViewEventWord(pathElement)

      pageNameSegments.push(followedByVariable ? singularize(eventWord) : eventWord)
    }
  })

  return success({ pageName: pageNameSegments.join(' '), resourceId })
}

/**
 * Transforms a path element into a word for the view event, according to the conventions
 * we decided.
 */
function toViewEventWord(str: string): string {
  return decodeURIComponent(str)
    .split('-')
    .filter((s) => !!s)
    .map((s) => s[0].toUpperCase() + s.substring(1))
    .join('')
}

function singularize(str: string): string {
  return str.replace(/s$/, '')
}

/**
 * Defined how a given path variable should be handled when we generate the view event name
 * from the URL
 */
type PathVariableBehavior =
  /**
   * Path element will be included in the event name for page views (e.g. stepId in the subscription funnel)
   */
  | 'included-in-name'
  /**
   * Stored in the resourceId field, as the main resource associated to the page. If multiple fields
   * are configured to be included as the main resource, only the first by order of appearance in the URL
   * will be taken into account, and the others will be discarded.
   */
  | 'included-in-resource-id'
  /**
   * Ignored
   */
  | 'discarded'

const pathVariableNamesBehavior: Record<PathParamName, PathVariableBehavior> = {
  token: 'discarded',
  productName: 'included-in-name',
  subscriptionId: 'included-in-resource-id',
  stepId: 'included-in-name',
  detailType: 'included-in-name',
  detailId: 'included-in-name',
  sessionId: 'included-in-resource-id',
  userId: 'included-in-resource-id',
  organization: 'included-in-resource-id',
  endorsementId: 'included-in-resource-id',
  versionId: 'included-in-resource-id',
}
