import {
  amountToString,
  applyRateToAmount,
  areAmountsEqual,
  parseAmount,
  zeroAmount,
  type Amount,
  type AmountToStringOptions,
  type FinancialRate,
} from '@orus.eu/amount'
import { nbsp } from '@orus.eu/char'
import type { AmountAndRecurrence, PremiumPaymentRecurrence } from '@orus.eu/quote'

import { translate, type Language, type LocalizableMessage } from '@orus.eu/translations'
import type { ProductAttributeDescription } from '../product-attribute-description.js'
import { BaseDimension } from './abstract-dimension.js'
import type { OptionType } from './common-dimensions.js'
import type { EsRcphGuarantee, EsRcphOptionalGuarantee } from './es-rcph/es-rcph-quote-offer-information.js'
import { type MrpGuaranteesType } from './mrph/mrph-guarantees-typenames.js'
import type { PaymentRecurrence } from './payement-recurrence-dimension.js'
import type { RcpaGuarantees, RcpaOptionalGuarantee } from './rcpa/rcpa-quote-offer-information.js'
import type {
  PolicyOptionalGuaranteesChildrenNames,
  RcProCyberOptionalGuaranteeType,
  rcProOptionalGuaranteeNames,
} from './rcph/rcph-quote-dimension.js'

export { mrphGuaranteesTypeNames, type MrpGuaranteesType } from './mrph/mrph-guarantees-typenames.js'

export const offerInformationDimension = new BaseDimension<'offerInformation', QuoteOfferInformation>({
  name: 'offerInformation',
  displayValues: { name: 'Informations sur le devis' },
} as const)

export type QuoteOfferInformation = {
  products: ProductInformation[]
}

/**
 * Represent a product as we describe it to the client on the quote page.
 * If can be either a Product in the sense of the mrp-and-rc-pro policy, or legacy products,
 * or options presented at the top-level as products for the sake of clarity
 */
export type ProductInformationId = ActivableProductInformationId | 'rcph-pj' | 'restaurant' | 'rcda-rc-pro'

export const rcphActivableProductInformationIds = ['rcph-cyber', 'rcph-tdmi', 'mrp-small-office'] as const

export const rcphOptionalProductInformationIds = [...rcphActivableProductInformationIds, 'rcph-pj'] as const

/**
 * The ProductInformationId that can be selected or unselected in the product selection page.
 */
export type ActivableProductInformationId = (typeof allActivableProductInformationIds)[number]
export const allActivableProductInformationIds = [
  ...rcphActivableProductInformationIds,
  'mrph',
  'mrpw',
  'muta',
  'rc-pro',
  'rcda',
  'es-rcph-pro',
  'es-rcph-general',
  'rcpa',
] as const

export type ProductInformation = {
  product: ProductInformationId
  name: string
  attributes: ProductAttributeDescription[]
  offers: PolicyOffer[]
  guarantees: PolicyGuarantee[]
  optionalGuarantees: PolicyOptionalGuarantee[]
}

export type PolicyOffer = {
  type: OfferType | MutaOfferType
  name: string
}

// Offers are basically a marketing view of multiple feature of a policy. A guarantee has an offer / An offer has multiple guarantees.
export const offerTypes = [
  'protectionJuridique',
  'rcp',
  'avantagePlus',
  'assuranceDommagesBiens',
  'exceptionalEvents',
  'assistance',
  'cyber',
  'pj',
  'decennialCivilLiability',
  'nonDecennialCivilLiability',
  'equipmentsMaterials',
  'esRcph',
  'esRcphGeneral',
  'mrpSmallOffice',
] as const

/**
 * Identifier of a guarantee that is exposed to the client as a high level product
 */
export type GlorifiedGuarantee =
  | 'cyber'
  | 'tdmi'
  | 'pj'
  | 'rcda-rc-pro'
  | 'cyberDommagesPe'
  | 'cyberDefaillanceFournisseur'
  | 'cyberDommagesTiers'
  | 'cyberFraudePiratage'
  | 'mrpSmallOffice'

export type OfferType = (typeof offerTypes)[number]

export const mutaGuaranteesGroups = [
  'MedecinGeneraliste',
  'Paramedicaux',
  'AnalysesExamsLaboratoires',
  'Medicaments',
  'Transport',
  'MedecineNaturelle',
  'ActesDePrevention',
  'PetitAppareillage',
  'ProthesesEtAppareillages',
  'SoinsDentaires',
  'Orthodontie',
  'ProthesesDentaires',
  'DentaireNonPrisEnCharge',
  'MontureVerresSimples',
  'MontureVerresComplexes',
  'Lentilles',
  'AppareillageEtProthesesAuditives',
  'AidesAuditives',
  'FraisDeSejour',
  'HonorairesPraticiens',
  'HonorairesEtablissements',
  'ChambreParticuliere',
  'SejourAccompagnant',
  'ConfortHospitalier',
  'HospitalisationDomicile',
  'TeleconsultationMedicale',
  'CoachingBienEtre',
  'AccompagnementPsychologique',
  'AideDomicile',
  'TransportTaxi',
] as const

export type MutaGuaranteeGroupType = (typeof mutaGuaranteesGroups)[number]

export type MrpSmallOfficeGuaranteeGroupType =
  | 'mrpSmallOfficeIncendies'
  | 'mrpSmallOfficeDegatsDesEaux'
  | 'mrpSmallOfficeVol'
  | 'mrpSmallOfficeVandalisme'
  | 'mrpSmallOfficeEvenementsClimatiques'
  | 'mrpSmallOfficeBrisDeGlace'
  | 'mrpSmallOfficeCatastrophesNaturelles'
  | 'mrpSmallOfficeAttentats'
  | 'mrpSmallOfficeOccupant'
  | 'mrpSmallOfficeAssistance'

export const rcdaGuarantees = [
  'rcDecennaleObligatoire',
  'rcDecennaleSousTraitance',
  'rcDecennaleNonObligatoire',
  'rcAvantApresReception',
  'biensSurChantier',
] as const

export type RcdaGuarantee = (typeof rcdaGuarantees)[number]

export type PolicyGuarantee = {
  type: PolicyGuaranteesType | GlorifiedGuarantee | MutaGuaranteeGroupType | MrpSmallOfficeGuaranteeGroupType
  name: string
  priority: PolicyGuaranteeVisualPriority
  shortText: string
  fullText: string
  example: string
  guaranteeLimits: PolicyGuaranteeLimit[]
  deductibles?: PolicyDeductible
  offer: PolicyOffer
}

export type PolicyOptionalGuarantee = {
  type: PolicyOptionalGuaranteesType
  name: string
  shortText: string
  fullText: string
  example: string
  isRecommanded: boolean
  price?: AmountAndRecurrence | undefined
  guaranteeLimit: PolicyOptionalGuaranteeLimit
  deductibles: PolicyDeductible
  isActive: boolean
  children?: PolicyOptionalGuarantee[]
}

/**
 * Apply Orus fees, partner fees and orus discount on the option's price (only for the display)
 */
export function getPriceWithFeesAndDiscounts(
  amount: Amount | undefined,
  feesAndDiscounts: {
    orusInstallmentFeeRate: FinancialRate | undefined
    orusManagementFeeRate: FinancialRate | undefined
    orusDiscountRate: FinancialRate | undefined
    partnerManagementFeeRate: FinancialRate | undefined
  },
): Amount {
  if (!amount) {
    return zeroAmount
  }
  let computedAmount = amount

  if (feesAndDiscounts.orusInstallmentFeeRate) {
    computedAmount = applyRateToAmount(computedAmount, feesAndDiscounts.orusInstallmentFeeRate)
  }

  if (feesAndDiscounts.orusManagementFeeRate) {
    computedAmount = applyRateToAmount(computedAmount, feesAndDiscounts.orusManagementFeeRate)
  }

  if (feesAndDiscounts.orusDiscountRate) {
    computedAmount = applyRateToAmount(computedAmount, feesAndDiscounts.orusDiscountRate)
  }

  if (feesAndDiscounts.partnerManagementFeeRate) {
    computedAmount = applyRateToAmount(computedAmount, feesAndDiscounts.partnerManagementFeeRate)
  }

  return computedAmount
}

/**
 * Formats the price of an option as a price per month for recurrent prices, and as a one-time price for one-time options.
 * We display the price per month for two reason :
 *   - it makes it easier to compare the price of two options with different payment recurrence
 *   - it looks cheaper and it improves conversion
 */
export function formatOptionPrice(
  amount: Amount | undefined,
  paymentRecurrence: PremiumPaymentRecurrence,
): string | undefined {
  if (!amount) return
  if (
    // we don't display a zero price
    areAmountsEqual(amount, zeroAmount)
  ) {
    return 'Gratuit'
  }

  switch (paymentRecurrence) {
    case 'monthly':
      return `${amountToString(amount, optionPriceFormatOptions)}${nbsp}/${nbsp}mois`
    case 'yearly':
      return `${amountToString(amount, optionPriceFormatOptions)}${nbsp}/${nbsp}an`
    case 'oneTime':
      return amountToString(amount, optionPriceFormatOptions)
  }
}
export const optionPriceFormatOptions: AmountToStringOptions = { displayDecimals: true, addCurrency: true }

function getAmountString(amount: Amount, language: Language): string {
  if (areAmountsEqual(amount, zeroAmount)) {
    return translate('free', language)
  }
  return amountToString(amount, optionPriceFormatOptions)
}

export function getAmountFromAmountAndRecurrence(amountAndRecurrence: AmountAndRecurrence): Amount | null {
  if (typeof amountAndRecurrence === 'string') {
    const amountPart = amountAndRecurrence.split('€')[0]
    if (amountPart) {
      return parseAmount(amountPart)
    }
    return null
  }
  return amountAndRecurrence.amount
}

function getRecurrenceString(type: PaymentRecurrence | 'one-time', language: Language): string | undefined {
  switch (type) {
    case 'monthly':
      return translate('month', language)
    case 'yearly':
      return translate('year', language)
    case 'one-time':
      return undefined
  }
}

export function localizeAmountAndRecurrence(amountAndRecurrence: AmountAndRecurrence, language: Language): string {
  if (typeof amountAndRecurrence === 'string') {
    return amountAndRecurrence
  }
  const { amount, type } = amountAndRecurrence
  if (areAmountsEqual(amount, zeroAmount)) {
    return translate('free', language)
  }
  if (type === 'one-time') {
    return amountToString(amount, optionPriceFormatOptions)
  }
  return `${getAmountString(amount, language)}${nbsp}/${nbsp}${getRecurrenceString(type, language)}`
}

export function localizeAmount(amountAndRecurrence: AmountAndRecurrence, language: Language): string {
  if (typeof amountAndRecurrence === 'string') {
    return amountAndRecurrence
  }
  return getAmountString(amountAndRecurrence.amount, language)
}

export function localizeRecurrence(type: PaymentRecurrence | 'one-time', language: Language): string | undefined {
  switch (type) {
    case 'monthly':
      return translate('per_time_unit_monthly', language)
    case 'yearly':
      return translate('per_time_unit_yearly', language)
    case 'one-time':
      return undefined
  }
}

export type PolicyGuaranteesType =
  | RcProGuaranteesTypes
  | MrpGuaranteesType
  | RestaurantVisualGuaranteeType
  | MrpwGuaranteesType
  | MutaGuaranteeGroupType
  | RcdaGuarantee
  | EsRcphGuarantee
  | RcpaGuarantees

export const rcProGuaranteesTypes = [
  'ManquementsContract',
  'FautesProOuNegligence',
  'RemboursementPresta',
  'BienDocumentConfies',
  'AtteinteDroitPropriete',
  'ContestationCreance',
  'CoutsdeProjet',
  'AtteinteReputation',
  'RemplacementHommeCle',
  'RecoursJuridique',
  'PerteDocuments',
  'PiratageSiteInternet',
] as const

export type RcProGuaranteesTypes = (typeof rcProGuaranteesTypes)[number]

/**
 * Guarantee as they are explained visually in the frontend. It's not the same as the technical
 * guarantee list, as in the wakam API.
 */
export const restaurantVisualGuaranteeTypes = [
  'publicLiability',
  'fire',
  'electricalDamage',
  'waterDamage',
  'theft',
  'vandalisme',
  'climaticEvent',
  'breakage',
  'naturalDisaster',
  'terrorism',
  'assistance',
] as const

export type RestaurantVisualGuaranteeType = (typeof restaurantVisualGuaranteeTypes)[number]

export const mrpwGuaranteesTypeNames = [
  'Incendie',
  'DommagesElec',
  'DegatsEaux',
  'Vol',
  'Vandalisme',
  'EvenementsClim',
  'CatastropheNat',
  'Attentat',
  'MarchConservee',
  'BrisMateriel',
  'RespCivOccupant',
  'publicLiability',
] as const

export type MrpwGuaranteesType = (typeof mrpwGuaranteesTypeNames)[number]

export type PolicyOptionalGuaranteesType =
  | RcProOptionalGuaranteesType
  | RcProCyberOptionalGuaranteeType
  | PolicyOptionalGuaranteesChildrenNames
  | RestaurantOptionType
  | OptionType
  | EsRcphOptionalGuarantee
  | RcpaOptionalGuarantee

export type RcProOptionalGuaranteesType = (typeof rcProOptionalGuaranteeNames)[number]

export const restaurantOptionNames = [
  'optionShatteredGlass',
  'optionMarketValueLoss',
  'optionOperationalLoss',
  'optionLegalProtection',
] as const
export type RestaurantOptionType = (typeof restaurantOptionNames)[number]

export type PolicyGuaranteeVisualPriority = 'P1' | 'P2' | 'P3'

export type GuaranteeLimitModel = {
  /**
   * Limit of the guarantee.
   * Can be `undefined` if there's no limit.
   *
   * @example '1 000 €', '10 % du CA'
   */
  limit: string | undefined
  description?: string
  isGuaranteeFixed: boolean
}

export type PolicyGuaranteeLimit = GuaranteeLimitModel & ({ name: string } | { localizableName: LocalizableMessage })

export type PolicyOptionalGuaranteeLimit = (
  | {
      name: string
    }
  | { localizableName: LocalizableMessage }
) & {
  limits: GuaranteeLimitModel[]
}

export type PolicyDeductible = (
  | {
      name: string
    }
  | { localizableName: LocalizableMessage }
) & {
  descriptions: PolicyDeductibleDescription[]
}

export type PolicyDeductibleDescription = {
  /**
   * Deductible of the policy.
   * Can be `undefined` if there's no deductible.
   *
   * @example '1 000 €', '10 % du CA'
   */
  deductible?: string
  description?: string
  localizableDescription?: LocalizableMessage
}

export const mutaOfferTypes = [
  'SoinsCourants',
  'MedecineDouce',
  'Appareillage',
  'Dentaire',
  'Optique',
  'Auditif',
  'Hospitalisation',
  'Assistance',
] as const

export type MutaOfferType = (typeof mutaOfferTypes)[number]

export const mutaOfferTypeTitle: { [key in MutaOfferType]: string } = {
  SoinsCourants: 'Soins courants',
  MedecineDouce: 'Médecine douce',
  Appareillage: 'Appareillage',
  Dentaire: 'Dentaire',
  Optique: 'Optique',
  Auditif: 'Auditif',
  Hospitalisation: 'Hospitalisation',
  Assistance: 'Assistance',
}
