import { createFileRoute } from '@tanstack/react-router'

import PlatformPendingSubscriptionHistoryPage from '../../../../../components/pages/backoffice/platform/platform-pending-subscription-history-page'
import PlatformPage from '../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/pending-subscriptions/$subscriptionId/history')({
  component: () => (
    <PlatformPage requiredPermissions={['subscription.readHistory']}>
      <PlatformPendingSubscriptionHistoryPage />
    </PlatformPage>
  ),
})
