import { createFileRoute } from '@tanstack/react-router'

import HealthInsuranceCpmsPage from '../../../components/pages/health-insurance/cpms-page'
import CustomerPage from '../../../components/templates/customer-page'

export const Route = createFileRoute('/health-insurance/cpms')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <HealthInsuranceCpmsPage />
    </CustomerPage>
  ),
})
