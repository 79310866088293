import type { PaymentMethodType } from '@orus.eu/backend/src/events/payment-method-update'
import type { CustomerInformation } from '@orus.eu/backend/src/views/user-account-view'
import { Button, ButtonLink, FlexRow, Text, spacing, useAsyncCallback, useEnqueueToast } from '@orus.eu/pharaoh'
import type { NavigateOptions } from '@tanstack/react-router'
import { memo, useCallback, type FunctionComponent } from 'react'
import { trpc, trpcReact } from '../../client'
import { copyToClipboard } from '../../lib/clipboard-util'
import { getFrontContactLink } from '../../lib/front-util'
import { getHubspotContactLink } from '../../lib/hubspot-util'
import { getGocardlessCustomerUrl, getStripeCustomerUrl } from '../../lib/payment-provider-util'
import { usePermissions } from '../../lib/use-permissions'
import { validateEmail, validatePhoneNumber, validateRequiredField } from '../../lib/validation'
import { useBuildUrl } from '../../use-build-url'
import { BackofficeDataCard, DataList, DataRow } from '../molecules/backoffice-data-card'
import { CopyToClipboardButton } from '../molecules/copy-to-clipboard-button'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { BackofficeEditableField } from './backoffice-editable-field'
import { BackofficeInvoicingEmailCheckbox } from './backoffice-invoicing-email-checkbox'

export type CustomerCardProps = {
  customer: CustomerInformation
  showUserLink?: boolean
}

export const BackofficeCustomerCard: FunctionComponent<CustomerCardProps> = memo(function UserCard({
  customer,
  showUserLink,
}) {
  const { type: userType, organization, permissions } = usePermissions()
  const { buildUrl } = useBuildUrl()
  const isPlatform = userType === 'platform'
  const navigateUserPageUrl: NavigateOptions =
    userType === 'partner' && organization
      ? {
          to: '/partner/$organization/users/$userId',
          params: { userId: customer.id, organization: organization.technicalName },
        }
      : { to: '/bak/users/$userId', params: { userId: customer.id } }

  const userLink = showUserLink ? (
    <ButtonLink size="small" variant="secondary" icon="arrow-right-regular" {...navigateUserPageUrl}>
      Ouvrir la page client
    </ButtonLink>
  ) : (
    <></>
  )
  const canCreatePaymentMethodUrl = isPlatform && permissions.includes('stripe.create')
  const { id, firstName, lastName, phone, email, customerNumber } = customer
  const paymentTokenData = trpcReact.paymentMethod.createToken.useQuery(
    { userId: customer.id },
    { enabled: canCreatePaymentMethodUrl },
  )
  const paymentProviderIds = trpcReact.payment.getPaymentProviderIds.useQuery(
    { userId: id },
    { enabled: canCreatePaymentMethodUrl },
  )

  const canReadPaymentMethod = isPlatform && permissions.includes('users.read')

  const { enqueueToast } = useEnqueueToast()

  const emailEditable = !!customerNumber // only customer emails can be edited

  const handleEmailUpdate = useCallback(
    async (email: string) => {
      const userAlreadyExists = await trpc.users.doesUserExist.query(email)
      if (userAlreadyExists) {
        alert(`${email} est déjà un utilisateur. Si besoin, utilisez l’outil de migration`)
        return
      }

      await trpc.users.updateUser.mutate({ id, email })
      alert(`L'email a bien été mis à jour. Nouvel email : ${email}.`)
      document.location.reload()
    },
    [id],
  )

  const handlePhoneUpdate = useCallback(
    async (phone: string) => {
      await trpc.users.updateUser.mutate({ id, phone })
      alert(`Le numéro de téléphone a bien été mis à jour. Nouveau numéro : ${phone}.`)
      document.location.reload()
    },
    [id],
  )

  const handleLastNameUpdate = useCallback(
    async (lastName: string) => {
      await trpc.users.updateUser.mutate({ id, lastName })
      alert(`Le nom a bien été mis à jour. Nouveau nom: ${lastName}.`)
      document.location.reload()
    },
    [id],
  )

  const handleFirstNameUpdate = useCallback(
    async (firstName: string) => {
      await trpc.users.updateUser.mutate({ id, firstName })
      alert(`Le prénom a bien été mis à jour. Nouveau prénom: ${firstName}.`)
      document.location.reload()
    },
    [id],
  )

  const impersonateUserId = useAsyncCallback(async () => {
    const token = await trpc.sessions.createImpersonationToken.mutate(id)

    const url = buildUrl({ to: '/impersonate', search: { token } })
    await copyToClipboard(url)
    enqueueToast('Lien copié, ouvrir en navigation privée', { variant: 'success' })
  }, [enqueueToast, buildUrl, id])

  if (canCreatePaymentMethodUrl && !paymentTokenData.data) return <GlobalLoadingState />

  let paymentMethodUpdateUrl = undefined
  if (paymentTokenData.data) {
    const paymentMethodJwt = paymentTokenData.data
    paymentMethodUpdateUrl = buildUrl({ to: '/payment-method-update', search: { token: paymentMethodJwt } })
  }

  return (
    <BackofficeDataCard icon="circle-info-regular" title="Informations client" headerLink={userLink}>
      <DataList>
        <DataRow>
          <Text variant="body2">Nom</Text>
          {isPlatform && permissions.includes('users.update') ? (
            <BackofficeEditableField
              handleUpdate={handleLastNameUpdate}
              validator={validateRequiredField}
              value={lastName}
              fieldName="lastname"
            />
          ) : (
            <Text variant="body2Medium">{lastName}</Text>
          )}
        </DataRow>
        <DataRow>
          <Text variant="body2">Prénom</Text>
          {isPlatform && permissions.includes('users.update') ? (
            <BackofficeEditableField
              handleUpdate={handleFirstNameUpdate}
              validator={validateRequiredField}
              value={firstName}
              fieldName="firstname"
            />
          ) : (
            <Text variant="body2Medium">{firstName}</Text>
          )}
        </DataRow>
        <DataRow>
          <Text variant="body2">Téléphone</Text>
          {isPlatform && permissions.includes('users.update') ? (
            <BackofficeEditableField
              handleUpdate={handlePhoneUpdate}
              validator={validatePhoneNumber}
              value={phone}
              fieldName="phone"
              inputType="tel"
            />
          ) : (
            <Text variant="body2Medium">{phone}</Text>
          )}
        </DataRow>
        <DataRow>
          <Text variant="body2">Email</Text>
          {isPlatform && emailEditable && permissions.includes('users.update') ? (
            <BackofficeEditableField
              handleUpdate={handleEmailUpdate}
              validator={validateEmail}
              value={email}
              fieldName="email"
              inputType="email"
            />
          ) : (
            <Text>{email}</Text>
          )}
          {permissions.includes('session.impersonate') ? (
            <Button size="small" variant="secondary" icon="copy-regular" onClick={impersonateUserId}>
              Impersonifier
            </Button>
          ) : null}
        </DataRow>
        {isPlatform && (
          <DataRow>
            <Text variant="body2">Notifications</Text>
            {permissions.includes('users.update') ? <BackofficeInvoicingEmailCheckbox userId={id} /> : null}
          </DataRow>
        )}
        <DataRow>
          <Text variant="body2">Numéro de client</Text>
          <Text variant="body2">{customerNumber}</Text>
          {isPlatform && permissions.includes('internalTools.read') && <InternalToolsLinks email={email} />}
        </DataRow>
        {canCreatePaymentMethodUrl && (
          <DataRow>
            <Text variant="body2">Url moyen de paiement</Text>
            <CopyToClipboardButton
              size="small"
              stringToCopy={paymentMethodUpdateUrl}
              title="Copier le lien"
              icon="link-regular"
              variant="tertiary"
            />
            {paymentProviderIds.data && (
              <>
                <Button
                  size="small"
                  variant="secondary"
                  icon="arrow-up-right-from-square-regular"
                  href={getGocardlessCustomerUrl(paymentProviderIds.data.gocardless)}
                  target="_blank"
                >
                  GoCardless
                </Button>

                <Button
                  size="small"
                  variant="secondary"
                  icon="arrow-up-right-from-square-regular"
                  href={getStripeCustomerUrl(paymentProviderIds.data.stripe)}
                  target="_blank"
                >
                  Stripe
                </Button>
              </>
            )}
          </DataRow>
        )}
        {canReadPaymentMethod ? (
          <DataRow>
            <Text variant="body2">Moyen de paiement</Text>
            <PaymentMethodText paymentId={id} />
          </DataRow>
        ) : (
          <></>
        )}
      </DataList>
    </BackofficeDataCard>
  )
})

const InternalToolsLinks = memo<{ email: string }>(function InternalToolsLinks({ email }) {
  return (
    <FlexRow gap={spacing[40]}>
      <Button
        size="small"
        variant="secondary"
        icon="arrow-up-right-from-square-regular"
        href={getFrontContactLink(email)}
        target="_blank"
      >
        Front
      </Button>
      <Button
        size="small"
        variant="secondary"
        icon="arrow-up-right-from-square-regular"
        href={getHubspotContactLink(email)}
        target="_blank"
      >
        Hubspot
      </Button>
    </FlexRow>
  )
})

const paymentMethodTypeToLabel: { [key in PaymentMethodType]: string } = {
  card: 'Carte bleue',
  sepa_debit: 'Prélèvement SEPA',
}

const PaymentMethodText = memo(function PaymentMethodText(props: { paymentId: string }): JSX.Element {
  const paymentMethod = trpcReact.paymentMethod.getUserPaymentMethod.useQuery(props.paymentId)

  return (
    <>
      {paymentMethod.data ? (
        <Text variant="body2">
          {paymentMethodTypeToLabel[paymentMethod.data.type]}&nbsp;{`(termine par ${paymentMethod.data.last4})`}
        </Text>
      ) : (
        <Text>Aucun</Text>
      )}
    </>
  )
})
