import styled from '@emotion/styled'
import type { OverviewInsuranceHistoryTimelineSectionState } from '@orus.eu/dimensions'
import { spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { CommentArea } from '../common/CommentArea'
import { DEFAULT_INSURANCE_TIMELINE_TEMPLATE_AS_STRING } from '../rich-text-editor/constants/insurance-timeline-table-template'

type OverviewInsuranceHistoryTimelineSectionProps = {
  state: OverviewInsuranceHistoryTimelineSectionState
}

const OverviewInsuranceHistoryTimelineSection = memo(function OverviewInsuranceHistoryTimelineSection(
  props: OverviewInsuranceHistoryTimelineSectionProps,
) {
  const { state } = props

  const value =
    state.detailedSheetHistorySynthesis && state.detailedSheetHistorySynthesis !== ''
      ? state.detailedSheetHistorySynthesis
      : DEFAULT_INSURANCE_TIMELINE_TEMPLATE_AS_STRING

  return (
    <CommentAreaWrapper>
      <CommentArea readonly value={value} title="Timeline antécédents d'assurance" />
    </CommentAreaWrapper>
  )
})

export default OverviewInsuranceHistoryTimelineSection

const CommentAreaWrapper = styled.div`
  padding-top: ${spacing[40]};
`
