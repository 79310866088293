import type { OperatingZone } from '@orus.eu/operating-zone'
import { failure, success } from '@orus.eu/result'
import { parseCifValue } from '../../../../../company-id-number/src/cif'
import { type CompanyIdNumber, getCompanyIdNumberValue } from '../../../../../company-id-number/src/company-id-number'
import { parseNifValue } from '../../../../../company-id-number/src/nif'
import { parseSirenValue } from '../../../../../company-id-number/src/siren'
import type { ValidatedTypeMapper } from '../validated-text-field'

export const sirenMapper: ValidatedTypeMapper<CompanyIdNumber | undefined> = {
  inputType: 'text',
  format: formatValueForTextInput,
  formatPlaceholder: formatValueForTextInput,
  parse: (text) => {
    text = text.trim()
    if (text === '') return success(undefined)
    const siren = parseSirenValue(text)
    return siren ? success({ type: 'provided', siren }) : failure('Entrez les 9 chiffres de votre SIREN')
  },
}

const cifOrNifMapper: ValidatedTypeMapper<CompanyIdNumber | undefined> = {
  inputType: 'text',
  format: formatValueForTextInput,
  formatPlaceholder: formatValueForTextInput,
  parse: (text) => {
    text = text.trim()
    if (text === '') return success(undefined)
    const cif = parseCifValue(text)
    if (cif) return success({ type: 'cif', cif })
    const nif = parseNifValue(text)
    if (nif) return success({ type: 'nif', nif })
    return failure('Introduce un número NIF o CIF válido')
  },
}

export const companyIdNumberMapper: Record<OperatingZone, ValidatedTypeMapper<CompanyIdNumber | undefined>> = {
  fr: sirenMapper,
  es: cifOrNifMapper,
}

function formatValueForTextInput(value: CompanyIdNumber | undefined): string {
  return getCompanyIdNumberValue(value) ?? ''
}
