import { createFileRoute } from '@tanstack/react-router'

import HealthInsuranceSanteclairPage from '../../../components/pages/health-insurance/santeclair-page'
import CustomerPage from '../../../components/templates/customer-page'

export const Route = createFileRoute('/health-insurance/santeclair')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <HealthInsuranceSanteclairPage />
    </CustomerPage>
  ),
})
