import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { type ReactNode } from 'react'
import type { ToastVariant } from '../../toast.js'
import type { NotificationVariant } from '../util.js'

type EnqueueTemporaryNotificationOpts = {
  variant: NotificationVariant
  extra?: ReactNode | undefined
  autoHideDuration?: number | undefined
}

export function useEnqueueTemporaryNotificationAlert(): {
  enqueueTemporaryNotificationAlert: (message: string, opts: EnqueueTemporaryNotificationOpts) => () => void
} {
  return { enqueueTemporaryNotificationAlert }
}

export function enqueueTemporaryNotificationAlert(
  message: string,
  { variant, extra, autoHideDuration }: EnqueueTemporaryNotificationOpts,
): () => void {
  window?.['isOrusEndToEndTestsContext']?.()
    .then((result) => {
      if (result) {
        // Allow tracking of notifications in end-to-end tests, by leveraging the mechanism
        // already in place for native alerts.
        // Note : native alerts are not displayed in end to end test, but instead the messages are stored for inspection
        // by the test runner.
        alert(message)
      }
    })
    .catch(() => {})

  const snackbarId = enqueueSnackbar(message, {
    orusVariant: variant,
    extra,
    autoHideDuration: autoHideDuration,
  })

  return () => closeSnackbar(snackbarId)
}

type EnqueueToastOpts = {
  variant?: ToastVariant
  extra?: ReactNode | undefined
  autoHideDuration?: number | undefined
}

export function useEnqueueToast(): {
  enqueueToast: (message: string, opts: EnqueueToastOpts) => () => void
} {
  return { enqueueToast }
}

export function enqueueToast(message: string, { variant, extra, autoHideDuration }: EnqueueToastOpts): () => void {
  const snackbarId = enqueueSnackbar(message, {
    orusVariant: variant,
    extra,
    autoHideDuration: autoHideDuration,
  })

  return () => closeSnackbar(snackbarId)
}
