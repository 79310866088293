import { createFileRoute } from '@tanstack/react-router'

import PartnerHomePage from '../../../../components/pages/backoffice/backoffice-home-page'
import PartnerPage from '../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/home')({
  component: () => (
    <PartnerPage requiredPermissions={[]}>
      <PartnerHomePage />
    </PartnerPage>
  ),
})
