import { css } from '@emotion/react'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { calendarDateEqual, getCalendarDateFromTimestamp, PARIS } from '@orus.eu/calendar-date'
import { Badge, borderStroke, Button, colorTokens, useLanguage } from '@orus.eu/pharaoh'
import { Tab, Tabbar } from '@orus.eu/pharaoh/src/components/tabbar'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, useCallback, useState } from 'react'
import { trpcReact } from '../../../../../../client'
import { usePermissions } from '../../../../../../lib/use-permissions'
import { LocalLoadingState } from '../../../../../molecules/local-loading-state'
import { getEndorsementsFromContract } from '../contract-endorsements-block/endorsements-block.utils'
import type { BackofficeUsage } from '../contract-page-content'
import { ContractVersionsTable } from './ContractVersionsTable'
import { EndorsementsTable } from './EndorsementsTable'
import { RenewalsTable } from './RenewalsTable'

export const ContractEndorsementsRenewalBlock = memo<{
  contract: BackofficeContractDescription
  type: BackofficeUsage
  customerEmail: string
}>(function ContractEndorsementsRenewalBlock({ contract, type, customerEmail }) {
  const { subscriptionId } = useParams({ strict: false })
  const { permissions, type: userType } = usePermissions()
  const language = useLanguage()
  const navigate = useNavigate()

  const [selectedTabId, setSelectedTabId] = useState<number>(0)
  const handleTabChange = useCallback((index: number) => {
    setSelectedTabId(index)
  }, [])

  const {
    isPending,
    mutate,
    data: endorsementCreationData,
    isSuccess: mutationSuccess,
  } = trpcReact.endorsement.createEndorsement.useMutation()

  const { data: pendingEndorsementsData, isSuccess: isGetPendingEndorsementsSuccess } =
    trpcReact.endorsement.getPendingEndorsements.useQuery(
      {
        subscriptionId: subscriptionId!,
      },
      { enabled: userType === 'platform' && permissions.includes('endorsement.create') },
    )

  const { data: renewalsData, isSuccess: isGetRenewalsSuccess } = trpcReact.renewal.getRenewalsWithToken.useQuery(
    {
      subscriptionId: subscriptionId!,
    },
    { enabled: userType === 'platform' && permissions.includes('contracts.sign') },
  )

  const { data: pendingLegacyEndorsementInProgressData, isSuccess: isGetPendingLegacyEndorsementInProgressSuccess } =
    trpcReact.subscriptions.hasLegacyEndorsementInProgress.useQuery(
      {
        subscriptionId: subscriptionId!,
      },
      { enabled: userType === 'platform' && permissions.includes('subscription.read') },
    )

  const { data: legacyRenewalsData } = trpcReact.renewal.getLegacyRenewals.useQuery(
    {
      subscriptionId: subscriptionId!,
      periodIndexLimit: renewalsData?.[0]?.renewedPeriodIndex ?? 3, // max period index is 3 as far as Orus exists
    },
    {
      enabled:
        userType === 'platform' &&
        permissions.includes('contracts.sign') &&
        isGetRenewalsSuccess &&
        (renewalsData?.length === 0 || renewalsData[0].renewedPeriodIndex! > 1),
    },
  )

  if (isPending) {
    return <LocalLoadingState />
  }

  if (mutationSuccess) {
    void navigate({ to: `/bak/contracts/${subscriptionId}/endorsement/${endorsementCreationData.endorsementId}` })
  }

  const endorsements = getEndorsementsFromContract(contract, customerEmail, language)
  const allEndorsementsCount = endorsements.length + (pendingEndorsementsData?.length ?? 0)
  const contractVersionsCount = contract.versions.length
  const renewalsCount = (renewalsData?.length ?? 0) + (legacyRenewalsData?.length ?? 0)

  const lastRenewal = renewalsData?.[renewalsData.length - 1]
  const isRenewalEffectDateTomorrow =
    lastRenewal?.effectDate &&
    calendarDateEqual(lastRenewal.effectDate, getCalendarDateFromTimestamp(Date.now() + 24 * 60 * 60 * 1000, PARIS))

  const renewalEndorsementTabs = [
    { index: 0, tabLabel: 'Contrats signés', count: contractVersionsCount },
    { index: 1, tabLabel: 'Avenants', count: allEndorsementsCount },
    { index: 2, tabLabel: 'Renouvellements', count: renewalsCount },
  ]

  const canCreateNewEndorsement =
    type === 'platform' &&
    permissions.includes('endorsement.create') &&
    isGetPendingEndorsementsSuccess &&
    pendingEndorsementsData.length === 0 &&
    isGetPendingLegacyEndorsementInProgressSuccess &&
    pendingLegacyEndorsementInProgressData === false

  const CreateNewEndorsementButton = function CreateNewEndorsementButton() {
    return canCreateNewEndorsement ? (
      <Button
        variant="secondary"
        size="small"
        icon="plus-solid"
        avatarPosition="left"
        disabled={isRenewalEffectDateTomorrow}
        title={
          isRenewalEffectDateTomorrow
            ? "La création d'un avenant est possible dès demain date d'effet du renouvellement"
            : undefined
        }
        onClick={() => mutate({ subscriptionId: subscriptionId! })}
      >
        Nouvel avenant
      </Button>
    ) : undefined
  }

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          border-bottom: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
        `}
      >
        <Tabbar size="large" activeTabIndex={selectedTabId} onClick={handleTabChange}>
          {renewalEndorsementTabs.map(({ index, tabLabel, count }) => (
            <Tab
              key={index}
              badge={
                <Badge dark={!!count} minWidth="20px">
                  {count}
                </Badge>
              }
            >
              {tabLabel}
            </Tab>
          ))}
        </Tabbar>
        <CreateNewEndorsementButton />
      </div>

      {selectedTabId === 0 ? (
        <ContractVersionsTable contract={contract} customerEmail={customerEmail} />
      ) : selectedTabId === 1 ? (
        <EndorsementsTable
          endorsements={endorsements}
          pendingEndorsementsData={pendingEndorsementsData}
          CreateNewEndorsementButton={<CreateNewEndorsementButton />}
        />
      ) : selectedTabId === 2 ? (
        <RenewalsTable legacyRenewals={legacyRenewalsData ?? []} renewals={renewalsData ?? []} />
      ) : undefined}
    </>
  )
})
