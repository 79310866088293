import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { desktopMediaQuery, spacing, useScreenType, useUiContext } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { SubscriptionV2Column } from './subscription-v2-column'

export type SubscriptionStepLayoutProps = {
  left?: ReactNode
  center: ReactNode
  right: ReactNode | undefined
  footer?: ReactNode
  compact?: boolean
  noDesktopMarginTop?: boolean
}

export const SubscriptionStepLayout = memo<SubscriptionStepLayoutProps>(function SubscriptionStepLayout({
  left,
  center,
  right,
  compact,
  noDesktopMarginTop,
  footer,
}) {
  const isDesktop = useUiContext() === 'desktop'
  return (
    <SubscriptionStepLayoutDiv compact={compact}>
      {left && (
        <SubscriptionStepLayoutLeftDiv noDesktopMarginTop={noDesktopMarginTop}>
          <StickySideHelper>{left}</StickySideHelper>
        </SubscriptionStepLayoutLeftDiv>
      )}

      <SubscriptionStepLayoutCenterDiv noDesktopMarginTop={noDesktopMarginTop}>
        {center}
        {isDesktop && footer}
      </SubscriptionStepLayoutCenterDiv>

      {right ? (
        <SubscriptionStepLayoutRightDiv>
          <StickySideHelper>{right}</StickySideHelper>
        </SubscriptionStepLayoutRightDiv>
      ) : (
        <></>
      )}
      {!isDesktop && footer ? (
        <SubscriptionStepLayoutCenterDiv noDesktopMarginTop={noDesktopMarginTop}>
          {footer}
        </SubscriptionStepLayoutCenterDiv>
      ) : (
        <></>
      )}
    </SubscriptionStepLayoutDiv>
  )
})

type StickySideHelperProps = { children: ReactNode }
const StickySideHelper = memo<StickySideHelperProps>(function StickySideHelper({ children }) {
  const screenType = useScreenType()
  return (
    <div
      css={
        screenType === 'desktop'
          ? css`
              position: sticky;
              top: 116px;
            `
          : undefined
      }
    >
      {children}
    </div>
  )
})

const SubscriptionStepLayoutDiv = styled(SubscriptionV2Column)<{ compact?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.compact ? spacing[60] : spacing[100])};
  margin-bottom: ${spacing[50]};

  ${desktopMediaQuery} {
    flex-direction: row;
    margin-bottom: ${spacing[100]};
  }
`

const SubscriptionStepLayoutLeftDiv = styled.div<{ noDesktopMarginTop?: boolean }>`
  display: none;
  flex: 0 0 216px;
  margin-top: ${spacing[60]};

  ${desktopMediaQuery} {
    display: initial;
    margin-top: ${(props) => (props.noDesktopMarginTop ? 0 : spacing[80])};
  }
`

const SubscriptionStepLayoutCenterDiv = styled.div<{ noDesktopMarginTop?: boolean }>`
  flex: 1 1 0%;
  margin-top: ${spacing[60]};

  ${desktopMediaQuery} {
    flex: 1 1 100%;
    margin-top: ${(props) => (props.noDesktopMarginTop ? 0 : spacing[80])};
  }
`
const SubscriptionStepLayoutRightDiv = styled.div`
  flex: 0 0 0%;

  ${desktopMediaQuery} {
    flex: 0 0 326px;
  }
`
