import { DataGrid, type GridColDef } from '@mui/x-data-grid'
import { frFR } from '@mui/x-data-grid/locales/frFR'
import { amountToString, type Amount } from '@orus.eu/amount'
import type { UnpaidNegativeInvoiceGroup } from '@orus.eu/backend/src/invoicing/routers/invoices'
import { Button, ContentContainerBackoffice, spacing } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { memo } from 'react'
import { trpcReact } from '../../../../client'
import { formatDate } from '../../../../lib/format'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { PaymentStatusTag } from '../../../organisms/invoicing/payment-status-tag'

export const PlatformNegativeInvoiceGroupsList = memo(function PlatformNegativeInvoiceGroupsList() {
  const [invoiceGroups] = trpcReact.invoices.listNegativeInvoiceGroups.useSuspenseQuery()

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header title="Factures négatives en attente de paiement" />

      <DataGrid
        getRowId={(row) => row.mainInvoiceId}
        rows={invoiceGroups}
        columns={columns}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        disableColumnMenu
        disableRowSelectionOnClick
        pageSizeOptions={[100]}
      />
    </ContentContainerBackoffice>
  )
})

const columns: GridColDef<UnpaidNegativeInvoiceGroup>[] = [
  { field: 'customerEmail', headerName: 'Client', flex: 1 },
  {
    field: 'dueTimestamp',
    headerName: 'Due le',
    minWidth: 150,
    valueFormatter: formatDate,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
  },
  {
    field: 'total',
    headerName: 'Montant',
    minWidth: 100,
    valueFormatter: formatAmount,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
  },
  {
    field: 'paymentStatus',
    headerName: 'Statut',
    minWidth: 200,
    renderCell: (params) => {
      return <PaymentStatusTag paymentStatus={params.value} />
    },
    align: 'right',
    headerAlign: 'right',
    flex: 1,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: function RenderActionCell(params) {
      const { subscriptionId } = params.row
      const navigateTo = useNavigateTo({ to: '/bak/contracts/$subscriptionId', params: { subscriptionId } })
      return (
        <Button size="small" variant="secondary" icon="arrow-right-regular" onClick={navigateTo}>
          Aller au contrat
        </Button>
      )
    },
    align: 'right',
    headerAlign: 'right',
  },
]

function formatAmount(value: Amount) {
  return amountToString(value, { addCurrency: true })
}
