import { TechnicalError } from '@orus.eu/error'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/payment/authentication')({
  component: () => {
    /**
     * The Stripe library requires a `return_url` when creating a confirmation token, to handle authentication
     * with schemas requiring a redirection.
     *
     * However, with credit cards, 3DS2 is embedded into a popup, and 3DS1 is decommissionned since 2022.
     *
     * So we should never arrive here.
     */
    throw new TechnicalError('User was redirected here by the Stripe library, but this should not happen')
  },
})
