import styled from '@emotion/styled'
import { type Amount } from '@orus.eu/amount'
import type { PaymentMethod } from '@orus.eu/backend/src/views/payment-view'
import { type CalendarDate } from '@orus.eu/calendar-date'
import type { RiskCarrierProductType, StaticFilesGroup } from '@orus.eu/dimensions'
import { TechnicalError, UnreachableCaseError } from '@orus.eu/error'
import { NotFoundError, spacing, TemporaryProblemError, Text, useAsyncCallback, useCrash } from '@orus.eu/pharaoh'

import { GenericPaymentMethodSubmitButton } from '@orus.eu/pharaoh/src/components/features/contract-signing/generic-payment-method-submit-button/GenericPaymentMethodSubmitButton'
import { isFailure } from '@orus.eu/result'
import { memo, useState } from 'react'
import { trpc } from '../../../../client'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { PaymentMethodCard } from '../../../molecules/card-payment-methods'
import { waitForContractToBeReady } from './checkout-util'
import { ContractSignatureSection } from './contract-signature-section'
import { PaymentInformationHeader } from './payment-information-header'

export const SubsequentSubscriptionsPaymentInformationBlock = memo<{
  subscriptionId: string
  token: string
  paymentAmount: Amount
  dueDate: CalendarDate
  generalTermsGroups: StaticFilesGroup[]
  riskCarrierProducts: RiskCarrierProductType[]
  paymentMethod: PaymentMethod
  onFinalizingSubscription: (finalizing: boolean) => void
}>(function SubsequentSubscriptionsPaymentInformationBlock({
  subscriptionId,
  token,
  paymentAmount,
  dueDate,
  generalTermsGroups,
  riskCarrierProducts,
  paymentMethod,
  onFinalizingSubscription,
}) {
  const crash = useCrash()
  const [hasAgreedDocuments, setHasAgreedDocuments] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigateToOnboarding = useNavigateTo({ to: '/home', hash: 'onboarding' })

  const finishSubscription = useAsyncCallback(async () => {
    onFinalizingSubscription(true)
    setIsLoading(true)

    const result = await trpc.subscriptions.finishSubscription.mutate({
      subscriptionId,
      token,
    })

    if (isFailure(result)) {
      const problem = result.problem
      switch (problem.type) {
        case 'session':
          switch (problem.problem) {
            case 'wrong-user':
              throw new TechnicalError('Wrong user when finalizing subscription for registered customer')
            default:
              throw new UnreachableCaseError(problem)
          }
        case 'temporary-api-failure':
          crash(new TemporaryProblemError())
          return
        case 'no-payment-method':
          throw new TechnicalError(
            'Unexpected payment problem when finalizing subscription for registered customer : we are not suppose to attempt a payment during subscription for there users',
            { context: { problem } },
          )
        case 'signature':
          switch (problem.problem) {
            case 'invalid-token':
              throw new TechnicalError('Invalid token when finalizing subscription for registered customer')
            case 'outdated-token':
              alert('La session de signature a expiré, nous allons devoir recommencer.')
              document.location.reload()
              return
            case 'no-subscription-found':
              crash(new NotFoundError())
              return
            default:
              throw new UnreachableCaseError(problem)
          }
        case 'checkout-init': {
          switch (problem.problem) {
            case 'no-payment-init-data':
              throw new TechnicalError(
                'Unexpected payment problem when finalizing subscription for registered customer : we are not suppose to attempt a payment during subscription for there users',
                { context: { problem } },
              )
            case 'not-ready-to-pay':
            case 'no-quote':
              // this can happen if an external change made the quote disappear
              alert("L'état du devis a changé, nous allons devoir recommencer.")
              document.location.reload()
              return
            case 'no-subscription':
              crash(new NotFoundError())
              return
            default:
              throw new UnreachableCaseError(problem)
          }
        }
        default:
          throw new UnreachableCaseError(problem)
      }
    }

    await waitForContractToBeReady(subscriptionId)

    navigateToOnboarding()
  }, [crash, navigateToOnboarding, onFinalizingSubscription, subscriptionId, token])

  return (
    <form onSubmit={finishSubscription}>
      <FlexColumn>
        <PaymentInformationHeader paymentAmount={paymentAmount} dueDate={dueDate} />

        <Text variant="body2">
          Votre actuel moyen de paiement sera utilisé pour les prélèvements de ce contrat. Vous avez la possibilité de
          le modifier à tout moment depuis votre espace personnel.
        </Text>

        <PaymentMethodCard paymentMethod={paymentMethod} />

        <ContractSignatureSection
          subscriptionId={subscriptionId}
          generalTermsGroups={generalTermsGroups}
          riskCarrierProducts={riskCarrierProducts}
          actionButtons={<GenericPaymentMethodSubmitButton isLoading={isLoading} isDisabled={!hasAgreedDocuments} />}
          setHasAgreedDocuments={setHasAgreedDocuments}
          hasAgreedDocuments={hasAgreedDocuments}
        />
      </FlexColumn>
    </form>
  )
})

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
`
