import { createFileRoute } from '@tanstack/react-router'

import PlatformInvoicingPage from '../../../../../components/pages/backoffice/platform/platform-contract-invoicing-page'
import PlatformPage from '../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/invoicing')({
  component: () => (
    <PlatformPage requiredPermissions={['invoices.read']}>
      <PlatformInvoicingPage />
    </PlatformPage>
  ),
})
