import { useCallback, useEffect, useRef, useState } from 'react'

import { TemporaryProblemError, useAsyncCallback, useCrash } from '@orus.eu/pharaoh'
import { isFailure, type Failure, type Result, type Success } from '@orus.eu/result'

import { trpcReact } from '../../../../client'
import { debounceAsync } from '../../../../lib/debounce-async'

import type { TemporaryAPIProblem } from '@orus.eu/backend/src/lib/temporary-api-failure'
import type { EndorsementStateChange } from '@orus.eu/backend/src/routers/endorsement'
import type {
  SubscriptionNonDimensionalData,
  SubscriptionStateChange,
} from '@orus.eu/backend/src/services/subscription/subscription-service'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager.js'
import type { StateChangeNotification } from '@orus.eu/backend/src/views/subscriptions/subscription-state-triggers-applier'
import { ensureError } from '@orus.eu/error'
import { skipToken } from '@tanstack/react-query'
import deepEqual from 'deep-equal'
import { ExclusiveOperationsQueue } from '../../../../lib/exclusive-operations-queue'

export type DetailedSheetStateHook = {
  changes: SubscriptionDimensionnedState
  setChanges(changes: SubscriptionDimensionnedState): void
  before: SubscriptionDimensionnedState | undefined
  after: SubscriptionDimensionnedState | undefined
  nonDimensionalDataAfter: SubscriptionNonDimensionalData | undefined
  stateChangeNotifications: StateChangeNotification[]
  reset: () => void
  commit: () => void
  committing: boolean
  updating: boolean
}

type ComputeCompleteChangesInput = {
  subscriptionId: string
  endorsementId: string
  changes: Record<string, unknown>
}
type ComputeCompleteChangesOutput =
  | Success<EndorsementStateChange>
  | Success<SubscriptionStateChange>
  | Failure<TemporaryAPIProblem>

/**
 * This queue is used to avoid concurrent calls to the backend, which would lead to inconsistent states
 **/
const queue = new ExclusiveOperationsQueue()

/**
 * This debounced function is used to limit the number of calls to the backend, which would lead to performance issues
 **/
const computeStateChangesApiDebounced = debounceAsync(
  (
    params: { subscriptionId: string; changes: SubscriptionDimensionnedState; endorsementId: string },
    computeCompleteChangesMutation: {
      mutateAsync: (params: ComputeCompleteChangesInput) => Promise<ComputeCompleteChangesOutput>
    },
  ) => queue.run(() => computeCompleteChangesMutation.mutateAsync(params)),
  1000,
)

/**
 * Specify how the useQuoteEditorState can compute the complete state change from an input update.
 * Also used to retrieve the initial state, because it's like computing the impact of an empty input update
 */
export type ComputeCompleteChangesApi = (params: {
  subscriptionId: string
  changes: SubscriptionDimensionnedState
}) => Promise<
  Result<
    {
      before: SubscriptionDimensionnedState
      after: SubscriptionDimensionnedState
      nonDimensionnalDataAfter: SubscriptionNonDimensionalData
    },
    TemporaryAPIProblem
  >
>

export function useDetailedSheetState({
  subscriptionId,
  versionId,
  endorsementId,
}: {
  subscriptionId: string
  versionId: string | undefined
  endorsementId: string | undefined
}): DetailedSheetStateHook {
  const crash = useCrash()
  const [committing, setCommitting] = useState(false)
  const [changes, setChanges] = useState<SubscriptionDimensionnedState>({})
  const [before, setBefore] = useState<SubscriptionDimensionnedState | undefined>()
  const [after, setAfter] = useState<SubscriptionDimensionnedState | undefined>()
  const [nonDimensionalDataAfter, setNonDimensionalDataAfter] = useState<SubscriptionNonDimensionalData | undefined>()
  const [stateChangeNotifications, setInvalidStateMessages] = useState<StateChangeNotification[]>([])
  const [updating, setUpdating] = useState(false)

  const commitChangesMutation = trpcReact.pendingSubscriptions.commitChanges.useMutation()
  const computeCompleteChangesMutationRef = useRef(trpcReact.pendingSubscriptions.computeCompleteChanges.useMutation())
  const updateMutation = trpcReact.endorsement.updateEndorsement.useMutation()
  const simulateMutationRef = useRef(trpcReact.endorsement.simulateEndorsementUpdate.useMutation())

  const contract = trpcReact.contracts.getContractVersionById.useQuery(
    versionId ? { subscriptionId, versionId } : skipToken,
  )

  const changesRef = useRef(changes)

  const reset = useCallback(() => setChanges({}), [])

  const commit = useAsyncCallback(async () => {
    setCommitting(true)
    let result: Result<SubscriptionDimensionnedState, TemporaryAPIProblem>
    if (endorsementId) {
      result = await updateMutation.mutateAsync({
        subscriptionId,
        changes,
        endorsementId,
      })
    } else {
      result = await commitChangesMutation.mutateAsync({
        subscriptionId,
        changes,
      })
    }
    if (isFailure(result)) {
      switch (result.problem.type) {
        case 'temporary-api-failure':
          crash(new TemporaryProblemError())
          return
      }
    }
    const stateWithCommittedChanges = result.output
    setBefore(stateWithCommittedChanges)
    setChanges({})
    setCommitting(false)
  }, [updateMutation, subscriptionId, changes, endorsementId, crash, commitChangesMutation])

  const overrideChangesByStateAfter = useCallback(
    (state: SubscriptionDimensionnedState | undefined, stateBefore: SubscriptionDimensionnedState | undefined) => {
      if (!state) return

      const newChanges = Object.fromEntries(
        (Object.keys(changes) as Array<keyof SubscriptionDimensionnedState>).map((dimension) => [
          dimension,
          state[dimension],
        ]),
      )

      changesRef.current = newChanges

      setChanges((prevChanges) => {
        const filteredChanges = Object.fromEntries(
          Object.entries(changesRef.current).filter(
            ([key, value]) => (stateBefore || {})[key as keyof SubscriptionDimensionnedState] !== value,
          ),
        )
        return !deepEqual(filteredChanges, prevChanges) ? filteredChanges : prevChanges
      })
    },
    [changes],
  )

  useEffect(() => {
    let cancelled = false

    setUpdating(true)

    if (endorsementId) {
      computeStateChangesApiDebounced(
        {
          subscriptionId,
          changes,
          endorsementId,
        },
        simulateMutationRef.current,
      ).then(
        (stateChanges) => {
          if (cancelled) return

          if (isFailure(stateChanges)) {
            switch (stateChanges.problem.type) {
              case 'temporary-api-failure':
                crash(new TemporaryProblemError())
                return
            }
          }
          setBefore(stateChanges.output.before)
          setAfter(stateChanges.output.after)
          overrideChangesByStateAfter(stateChanges.output.after, stateChanges.output.before)
          setNonDimensionalDataAfter(stateChanges.output.nonDimensionnalDataAfter)
          setInvalidStateMessages(stateChanges.output.stateChangeNotifications)
          setUpdating(false)
        },
        (err) => {
          if (cancelled) return

          crash(ensureError(err))
        },
      )
    } else {
      computeStateChangesApiDebounced(
        {
          subscriptionId,
          changes,
          endorsementId: '',
        },
        computeCompleteChangesMutationRef.current,
      ).then(
        (stateChanges) => {
          if (cancelled) return

          if (isFailure(stateChanges)) {
            switch (stateChanges.problem.type) {
              case 'temporary-api-failure':
                crash(new TemporaryProblemError())
                return
            }
          }
          setBefore(stateChanges.output.before)
          setAfter(stateChanges.output.after)
          overrideChangesByStateAfter(stateChanges.output.after, stateChanges.output.before)
          setNonDimensionalDataAfter(stateChanges.output.nonDimensionnalDataAfter)
          setInvalidStateMessages(stateChanges.output.stateChangeNotifications)
          setUpdating(false)
        },
        (err) => {
          if (cancelled) return

          crash(ensureError(err))
        },
      )
    }
    return () => {
      cancelled = true
    }
  }, [changes, crash, subscriptionId, overrideChangesByStateAfter, endorsementId])

  const updateChanges = useCallback((newChanges: SubscriptionDimensionnedState) => {
    setChanges((changes) => ({ ...changes, ...newChanges }))
  }, [])

  useEffect(() => {
    if (versionId && contract?.data) {
      setAfter(contract.data.dimensions)
    }
  }, [contract, versionId])

  return {
    changes,
    setChanges: updateChanges,
    before,
    after,
    nonDimensionalDataAfter,
    stateChangeNotifications,
    reset,
    commit,
    committing,
    updating,
  }
}
