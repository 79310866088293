import { css } from '@emotion/react'
import { spacing, Text } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'

export type BackofficeSubectionTitleProps = {
  children: ReactNode
}

export function BackofficeSubsectionTitle({ children }: BackofficeSubectionTitleProps): JSX.Element {
  return (
    <Text
      variant="h5"
      css={css`
        margin-top: ${spacing[80]};
        margin-bottom: ${spacing[70]};
      `}
    >
      {children}
    </Text>
  )
}
