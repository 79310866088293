import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { Text } from '../../components'
import { spacing } from '../../foundation'
import { useUiContext, type UiContext } from '../../hooks'
import { HeaderCustomerLayout } from './header-customer'

export type HeaderProps = {
  // common props
  title?: string
  forceScreenVariant?: UiContext

  // backoffice props
  badge?: ReactNode
  leftButton?: ReactNode
  rightButtonGroups?: ReactNode[]

  // customer props
  /**
   * Compact mode
   * When true, shows the HeaderSeparator
   * When false, shows the a progress bar
   */
  compact?: boolean
  /**
   * Progress bar value between 0 and 1
   */
  progress?: number
  /**
   * Progress bar initial value
   */
  initialProgress?: number
  /**
   * right block to display instead of the default ContactButton
   */
  rightBlock?: ReactNode
}

export const Header = memo<HeaderProps>(function Header(props: HeaderProps) {
  const {
    title,
    badge,
    leftButton,
    rightButtonGroups,
    compact,
    progress,
    initialProgress,
    rightBlock,
    forceScreenVariant,
  } = props

  const screenVariant = useUiContext()
  const actualScreenVariant = forceScreenVariant ?? screenVariant
  const isBackofficeVariant = actualScreenVariant === 'backoffice'

  return isBackofficeVariant ? (
    <HeaderContainer>
      <MainContentContainer>
        {leftButton}
        <TitleText variant="h6">{title}</TitleText>
        {badge}
      </MainContentContainer>
      {rightButtonGroups}
    </HeaderContainer>
  ) : (
    <HeaderCustomerLayout
      title={title}
      compact={compact}
      progress={progress}
      initialProgress={initialProgress}
      rightBlock={rightBlock}
    />
  )
})

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${spacing[50]};
`

const MainContentContainer = styled.div`
  overflow: hidden;

  display: flex;
  flex: 1;

  align-items: center;
  gap: ${spacing[50]};
`
const TitleText = styled(Text)`
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
