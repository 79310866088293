import { TechnicalError } from '@orus.eu/error'
import { useNavigate, type NavigateOptions } from '@tanstack/react-router'
import { useCallback } from 'react'
import { usePermissions } from '../use-permissions'

export function useNavigateToBackofficeSubscriptionPage(): (subscriptionId: string) => void {
  const { type: userType, organization } = usePermissions()
  const isPartner = userType === 'partner'
  const navigate = useNavigate()

  if (isPartner && !organization)
    throw new TechnicalError('The connected user is partner and does not have an organization')

  const getRoute = useCallback(
    (subscriptionId: string): NavigateOptions =>
      isPartner && organization
        ? {
            to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
            params: { subscriptionId, organization: organization.technicalName },
          }
        : {
            to: '/bak/v2-pending-subscriptions/$subscriptionId',
            params: { subscriptionId },
          },
    [isPartner, organization],
  )

  return useCallback((subscriptionId: string) => void navigate(getRoute(subscriptionId)), [navigate, getRoute])
}
