import { createFileRoute } from '@tanstack/react-router'

import InvoicesPage from '../../components/pages/invoices-and-payments/invoices-page'
import CustomerPage from '../../components/templates/customer-page'

export const Route = createFileRoute('/invoices')({
  component: () => (
    <CustomerPage requiredPermissions={['invoices.read']}>
      <InvoicesPage />
    </CustomerPage>
  ),
})
