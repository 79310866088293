import styled from '@emotion/styled'
import { colorTokens, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'

const EmptyDocumentList = memo(function EmptyDocumentList() {
  return (
    <EmptyDocumentListContainer>
      <Text variant="body2Medium">Aucun fichier</Text>
    </EmptyDocumentListContainer>
  )
})

export default EmptyDocumentList

const EmptyDocumentListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing[40]};
  border-radius: ${spacing[30]};
  color: ${colorTokens['color-text-base-basic']};
  font-size: 13px;
  background-color: ${colorTokens['color-bg-neutral-inverse']};
`
