import { css } from '@emotion/react'
import { Typography } from '@mui/material'
import { amountToString, type Amount } from '@orus.eu/amount'
import type { InvoicePaymentStatus } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import { PARIS, calendarDateToString, getCalendarDateFromTimestamp } from '@orus.eu/calendar-date'
import {
  Icon,
  Spinner,
  colors,
  downloadFile,
  spacing,
  useAsyncCallback,
  useLanguage,
  useTranslate,
} from '@orus.eu/pharaoh'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useMemo, useState, type FunctionComponent, type ReactNode } from 'react'
import { dynamicFileNames } from '../../../lib/download-filenames'
import { getMyInvoiceDownloadLink, getMyReceiptDownloadLink } from '../../../lib/download-links'
import { ContainedIcon } from '../../molecules/contained-icon'

type InvoiceVariant = 'receipt' | 'unpaid' | 'pending'

type InvoiceListProps = {
  title: string
  variant: InvoiceVariant
  invoices: InvoicePaymentStatus[]
}

export const InvoiceList = memo(function InvoiceList({ variant, invoices, title }: InvoiceListProps) {
  return (
    <>
      <Typography padding={`${spacing[50]} ${spacing[60]}`} variant="h3">
        {title}
      </Typography>

      <RowContainer size="large" variant="simple">
        {invoices.map((invoice, index) => (
          <InvoiceRow
            key={invoice.invoice.invoiceId}
            variant={variant}
            invoiceAmount={invoice.invoice.totalPremium}
            invoiceDueTimestamp={invoice.invoice.dueTimestamp}
            invoiceId={invoice.invoice.invoiceId}
            invoiceIndex={index}
            invoiceNumber={invoice.invoiceNumber}
          />
        ))}
      </RowContainer>
    </>
  )
})

type InvoiceRowProps = {
  variant: InvoiceVariant
  invoiceId: string
  invoiceDueTimestamp: number
  invoiceAmount: Amount
  invoiceIndex: number
  invoiceNumber: string
}

export const InvoiceRow: FunctionComponent<InvoiceRowProps> = memo(function InvoiceRow({
  variant,
  invoiceId,
  invoiceAmount,
  invoiceDueTimestamp,
  invoiceIndex,
  invoiceNumber,
}) {
  const translate = useTranslate()
  const language = useLanguage()
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)
  const dueDate = useMemo(() => getCalendarDateFromTimestamp(invoiceDueTimestamp, PARIS), [invoiceDueTimestamp])

  const handleDownload = useAsyncCallback(async () => {
    try {
      setDownloadInProgress(true)

      switch (variant) {
        case 'receipt':
          await downloadFile(getMyReceiptDownloadLink(invoiceId), dynamicFileNames.receipts(invoiceNumber, language))
          break
        case 'unpaid':
        case 'pending':
          await downloadFile(getMyInvoiceDownloadLink(invoiceId), dynamicFileNames.invoice(invoiceNumber, language))
          break
      }
    } finally {
      setDownloadInProgress(false)
    }
  }, [invoiceId, invoiceNumber, setDownloadInProgress, variant, language])

  const dueDateString = calendarDateToString(dueDate, PARIS, 'DATE_FULL', language)

  const title =
    variant === 'unpaid'
      ? translate('amount_unpaid', { amount: invoiceAmount })
      : variant === 'pending'
        ? translate('amount_pending', { amount: invoiceAmount })
        : amountToString(invoiceAmount, { addCurrency: true })

  return (
    <Row
      size="large"
      title={title}
      subtitle={dueDateString}
      leftAvatar={getLeftAvatar(variant)}
      rightAvatar={downloadInProgress ? <Spinner size="30" /> : <Icon icon="arrow-down-to-line-solid" />}
      onClick={handleDownload}
      disabled={downloadInProgress}
      css={css`
        padding-left: ${spacing[60]};
        padding-right: ${spacing[60]};
      `}
      ariaLabel={`Facture n°${invoiceIndex + 1}`}
    />
  )
})

function getLeftAvatar(variant: InvoiceVariant): ReactNode {
  switch (variant) {
    case 'receipt':
      return <ContainedIcon icon="check-regular" color={colors.green[600]} backgroundColor={colors.green[100]} />
    case 'unpaid':
      return (
        <ContainedIcon icon="diamond-exclamation-solid" color={colors.red[600]} backgroundColor={colors.red[100]} />
      )
    case 'pending':
      return <ContainedIcon icon="hourglass-solid" color={colors.yellow[700]} backgroundColor={colors.yellow[200]} />
  }
}
