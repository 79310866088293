import { canNavigate, isStepSingleClick } from '@orus.eu/dimensions'
import {
  borderRadius,
  Button,
  colorTokens,
  desktopMediaQuery,
  shadow,
  spacing,
  Text,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { memo, useRef } from 'react'

import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import type { SubscriptionFooterProps } from './types'

export const NavigationButtonsWithSubmitUiElementBlock = memo<
  SubscriptionFooterProps & { nextStepLabel: string; hideInDesktop: boolean }
>(function NavigationButtonsWithSubmitUiElementBlock(props) {
  const {
    step,
    nextEnabled,
    goBackToPreviousStep,
    isLoadingWhileTryCompleteStep,
    isFirstStep,
    className,
    hideInDesktop,
  } = props

  const translate = useTranslate()
  const footerRef = useRef<HTMLDivElement>(null)
  const { ref: triggerRef, inView } = useInView({
    threshold: 1,
    onChange: (inView) => footerRef.current?.classList?.toggle?.('is-pinned', !inView),
  })

  const isDesktop = useUiContext() === 'desktop'

  const showJumpToBottom = !!step.jumpToBottom
  const showNextStepLabel = step.nextStepLabelTip !== undefined
  const showNextStepButton = !isStepSingleClick[step.id] && canNavigate(step.id)
  const showPreviousStepButton = !isFirstStep && !isDesktop && canNavigate(step.id)

  if (
    (!showNextStepLabel && !showNextStepButton && !showPreviousStepButton) ||
    step.hideFooterElement ||
    (hideInDesktop && isDesktop)
  )
    return null

  const shouldPreviousButtonBeLarge = !isDesktop && !showNextStepButton
  return (
    <div ref={footerRef}>
      {showJumpToBottom && !inView && (
        <FloatingButton icon="angle-down-solid" onClick={() => footerRef.current?.scrollIntoView?.()} />
      )}
      <div ref={triggerRef}></div>
      <ButtonRowDiv className={className} inView={inView}>
        {showNextStepLabel && <Text variant="body2">{step.nextStepLabelTip}</Text>}

        {showPreviousStepButton && (
          <PreviousStepStepButton
            variant="secondary"
            icon="arrow-left-regular"
            avatarPosition="left"
            onClick={goBackToPreviousStep}
            className="previous-button"
            size="large"
            isLarge={shouldPreviousButtonBeLarge}
          >
            {isDesktop || shouldPreviousButtonBeLarge ? translate('back') : ''}
          </PreviousStepStepButton>
        )}
        {showNextStepButton && (
          <NextStepButton
            type={'submit'}
            disabled={!nextEnabled}
            size={!isDesktop ? 'large' : 'medium'}
            isLoading={isLoadingWhileTryCompleteStep}
            className={!showJumpToBottom ? 'next-button' : undefined}
          >
            {props.nextStepLabel}
          </NextStepButton>
        )}
      </ButtonRowDiv>
    </div>
  )
})

const FloatingButton = styled(Button)`
  position: fixed;
  bottom: ${spacing[60]};
  right: ${spacing[50]};
  z-index: 1000;
`

const PreviousStepStepButton = styled(Button)<{ isLarge: boolean }>`
  margin-right: ${spacing[30]};
  flex-grow: ${({ isLarge }) => (isLarge ? 1 : 0)};
`

const NextStepButton = styled(Button)`
  flex-grow: 1;

  ${desktopMediaQuery} {
    flex-grow: 0;
  }
`

const ButtonRowDiv = styled.div<{ inView: boolean }>`
  display: flex;
  flex-direction: row;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: ${spacing[50]} ${spacing[60]};
  background-color: ${colorTokens['color-bg-base-normal']};
  z-index: 100;
  border-radius: ${borderRadius[30]} ${borderRadius[30]} 0 0;
  box-shadow: ${({ inView }) => (!inView ? shadow.top[20] : 'none')};

  ${desktopMediaQuery} {
    gap: ${spacing[50]};
    max-width: 760px;
    margin-top: ${spacing[70]};
    margin-bottom: ${spacing[50]};
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    justify-content: flex-end;
  }
`
