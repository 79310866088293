import type { PaymentStatus } from '@orus.eu/backend/src/events/payment-update'
import { Chip } from '@orus.eu/pharaoh'

export type PaymentStatusTagProps = {
  paymentStatus: PaymentStatus
}

/**
 * A tag that displays the status of a undated payment
 * @param paymentStatus
 * @constructor
 */
export function PaymentStatusTag({ paymentStatus }: PaymentStatusTagProps): JSX.Element {
  switch (paymentStatus) {
    case 'new':
      return (
        <Chip size="large" variant="neutral" icon="hourglass-regular">
          Nouveau paiement
        </Chip>
      )
    case 'disputed':
      return (
        <Chip size="large" variant="danger" icon="bolt-solid">
          Paiement contesté
        </Chip>
      )
    case 'failed':
      return (
        <Chip size="large" variant="danger" icon="triangle-exclamation-regular">
          Impayée
        </Chip>
      )
    case 'refunded':
      return (
        <Chip size="large" variant="warning" icon="triangle-exclamation-regular">
          Remboursée
        </Chip>
      )
    case 'cancelled':
      return (
        <Chip size="large" variant="warning" icon="triangle-exclamation-regular">
          Paiement annulé
        </Chip>
      )
    case 'paid':
      return (
        <Chip size="large" variant="success" icon="circle-check-regular">
          Payée
        </Chip>
      )
    case 'pending':
      return (
        <Chip size="large" variant="success" icon="hourglass-regular">
          Paiement en cours
        </Chip>
      )
  }
}
