import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminJobsPage from '../../../../components/pages/backoffice/platform/platform-admin-jobs-page'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/admin/jobs')({
  component: () => (
    <PlatformPage requiredPermissions={['tech.configurations']}>
      <PlatformAdminJobsPage />
    </PlatformPage>
  ),
})
