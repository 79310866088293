import { createFileRoute } from '@tanstack/react-router'

import PartnerUserPage from '../../../../../components/pages/backoffice/partners/partners-user-page'
import PartnerPage from '../../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/users/$userId')({
  component: () => (
    <PartnerPage requiredPermissions={['users.read']}>
      <PartnerUserPage />
    </PartnerPage>
  ),
})
