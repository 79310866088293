import { createFileRoute } from '@tanstack/react-router'

import RolesDocumentationPage from '../../../../components/pages/backoffice/platform/platform-documentation/roles-documentation-page'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/documentation/roles')({
  component: () => (
    <PlatformPage requiredPermissions={['roles.read']}>
      <RolesDocumentationPage />
    </PlatformPage>
  ),
})
