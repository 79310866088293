import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { borderRadius, colorTokens, EmptyState } from '@orus.eu/pharaoh'
import { memo } from 'react'
import PinIconSvg from './pin-icon-svg'

export const EmbeddedGoogleMap = memo<{ address?: string }>(function EmbeddedGoogleMap({ address }) {
  let mapImageUrl: string | undefined = undefined

  if (address) {
    const mapParams = new URLSearchParams()
    mapParams.append('center', address)
    mapParams.append('scale', requestedScale.toFixed(0))
    mapParams.append('size', `${requestedWidth.toFixed(0)}x${requestedHeight.toFixed(0)}`)
    mapParams.append('maptype', 'place')
    mapParams.append('key', googleMapsApiKey)
    mapParams.append('map_id', mapId)
    mapParams.append('zoom', requestedZoom.toFixed(0))

    mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?${mapParams.toString()}`
  }

  return (
    <StyledEmbeddedGoogleMap mapImageUrl={mapImageUrl}>
      {address ? (
        <PinIconSvg
          height={40}
          css={css`
            position: absolute;
            bottom: 50%;
            left: 50%;
            transform: translateX(-50%);
          `}
        />
      ) : (
        <EmptyState
          subtitle="Remplissez les informations concernant l'adresse pour visualiser la carte"
          height={'100%'}
          css={css`
            min-height: ${requestedHeight}px;
          `}
          style="neutral"
        />
      )}
    </StyledEmbeddedGoogleMap>
  )
})

const mapWidth = 736
const mapHeight = 184
const googleMapsApiKey = 'AIzaSyCBIMdTPcmGoC6yjmlgD4k0XvE5lfl_Dv8'
const mapId = '21b9cacdd2b44e25'
const requestedScale = 2
const requestedWidth = 640 // empirically the maximum width the API can produce
const requestedHeight = (mapHeight * requestedWidth) / mapWidth
const requestedZoom = 14

const StyledEmbeddedGoogleMap = styled.div<{ mapImageUrl: string | undefined }>`
  aspect-ratio: 4 / 1;
  background: ${colorTokens['color-stroke-base']};
  border-radius: ${borderRadius[20]};
  border: 1px solid ${colorTokens['color-stroke-base']};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ mapImageUrl }) => (mapImageUrl ? `url('${mapImageUrl}')` : 'none')};
  position: relative;
  min-height: 125px;
  width: 100%;
`
