import { createTRPCProxyClient, httpLink, type CreateTRPCClientOptions, type TRPCLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
import { observable } from '@trpc/server/observable'
import superjson from 'superjson'

import type { AppRouter } from '@orus.eu/backend'
import type { ORUS_TRPC_ERROR_CODE } from '@orus.eu/backend/src/types/orus-custom-error'
import { TechnicalError } from '@orus.eu/error'
import { enqueueTemporaryNotificationAlert } from '@orus.eu/pharaoh'
import { isProductionEnvironment } from './lib/env-utils'
import { logger } from './lib/logger'

type ResponseWithHeaders = {
  headers: {
    get: (name: string) => string | null
  }
}

const errorOrSuggestUpdateLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next: (value) => {
          const response = value.context?.['response'] as ResponseWithHeaders

          if (response && response.headers && response.headers.get('update-webapp') === 'Suggested') {
            window.dispatchEvent(new Event('update-webapp-suggested'))
          }

          observer.next(value)
        },
        error: (err) => {
          const { data } = err

          // If there's no data, it's a network error, and we don't want to do anything other than notify the user
          if (!data) {
            notifyTechnicalIssue()
            observer.error(err)
            return
          }

          const { httpStatus, code, path } = data

          if (!isProductionEnvironment()) {
            logger.info(`Received tRPC ${code}${path ? ` on ${path}` : ''}`)
          }

          // The code below does something similar to what happens in ErrorBoundary when a TRCP error is thrown
          // by a direct trpc call.
          // We can live with this duplication, because directly using trpc calls is deprecated and should be removed in the
          // long run.
          for (const action of trcpCodeFollowupActions[code]) {
            switch (action) {
              case 'report-sentry':
                logger.error(
                  new TechnicalError(`Received tRPC ${code}${path ? ` on ${path}` : ''}`, {
                    context: {
                      trpcPath: path,
                      trpcCode: code,
                      httpStatus,
                      errMessage: err.message,
                      clientAppVersion: __CODE_VERSION_ID__,
                    },
                    cause: err,
                  }),
                )
                break
              case 'redirect-to-login':
                redirectToLogin()
                break
              case 'redirect-to-not-found':
                redirectToNotFound()
                break
              case 'technical-issue-notification':
                notifyTechnicalIssue()
                break
            }
          }
          observer.error(err)
        },
        complete: () => {
          observer.complete()
        },
      })

      return unsubscribe
    })
  }
}

function redirectToLogin() {
  const loginUrl = new URL('/login', window.location.origin)
  loginUrl.searchParams.set('redirect', window.location.toString())
  loginUrl.searchParams.set('fromUnauthorizedCode', 'true')
  window.location.href = loginUrl.toString()
}

function redirectToNotFound() {
  const notFoundUrl = new URL('/not-found', window.location.origin)
  history.replaceState({}, '', notFoundUrl.toString())
}

let networkErrorTimeout: NodeJS.Timeout | null = null
function notifyTechnicalIssue() {
  if (!networkErrorTimeout) {
    enqueueTemporaryNotificationAlert('Une erreur réseau est survenue, êtes-vous bien connecté à Internet ?', {
      variant: 'danger',
    })
    networkErrorTimeout = setTimeout(() => {
      networkErrorTimeout = null
    }, 60 * 1000) // 1 minute before a new notification
  }
}

const clientConfig: CreateTRPCClientOptions<AppRouter> = {
  links: [
    errorOrSuggestUpdateLink,
    httpLink<AppRouter>({
      url: '/trpc',
      transformer: superjson,
      headers: {
        'client-version': __CODE_VERSION_ID__,
      },
    }),
  ],
}

export const trpc = createTRPCProxyClient<AppRouter>(clientConfig)

export const trpcReact = createTRPCReact<AppRouter>()

/** @deprecated This is the client for the tRPC provider, you're probably looking for {@link trpcReact}.  */
export const trpcReactClient = trpcReact.createClient(clientConfig)

logger.addTransport({
  event(event, metadata) {
    trpc.log.sendEvent.mutate({ event, metadata }).catch((err: unknown) => {
      // not much more we can do, and we don't want this to propagate, to avoid an infinite loop of error reporting
      // eslint-disable-next-line no-console
      console.warn('Unable to send event to tRPC', err)
    })
  },
})

type TrcpErrorFollowupAction =
  | 'report-sentry'
  | 'redirect-to-login'
  | 'redirect-to-not-found'
  | 'technical-issue-notification'

const trcpCodeFollowupActions: Record<ORUS_TRPC_ERROR_CODE, TrcpErrorFollowupAction[]> = {
  PARSE_ERROR: ['report-sentry', 'technical-issue-notification'],
  BAD_REQUEST: ['report-sentry', 'technical-issue-notification'],
  INTERNAL_SERVER_ERROR: ['technical-issue-notification'],
  NOT_IMPLEMENTED: ['report-sentry', 'technical-issue-notification'],
  UNAUTHORIZED: ['redirect-to-login'],
  FORBIDDEN: ['redirect-to-login'],
  NOT_FOUND: ['redirect-to-not-found'],
  METHOD_NOT_SUPPORTED: ['report-sentry', 'technical-issue-notification'],
  TIMEOUT: ['technical-issue-notification'],
  CONFLICT: ['report-sentry', 'technical-issue-notification'],
  PRECONDITION_FAILED: ['report-sentry', 'technical-issue-notification'],
  PAYLOAD_TOO_LARGE: ['report-sentry', 'technical-issue-notification'],
  UNPROCESSABLE_CONTENT: ['report-sentry', 'technical-issue-notification'],
  TOO_MANY_REQUESTS: ['report-sentry', 'technical-issue-notification'],
  CLIENT_CLOSED_REQUEST: ['report-sentry', 'technical-issue-notification'],
  BAD_GATEWAY: ['report-sentry', 'technical-issue-notification'],
  SERVICE_UNAVAILABLE: ['report-sentry', 'technical-issue-notification'],
  GATEWAY_TIMEOUT: ['report-sentry', 'technical-issue-notification'],
  UNSUPPORTED_MEDIA_TYPE: ['report-sentry', 'technical-issue-notification'],
  CLIENT_OUTDATED: [], // handled separately by the SessionManager
}
