import { createFileRoute } from '@tanstack/react-router'

import PlatformInvoicingPage from '../../../components/pages/backoffice/platform/platform-invoicing-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/invoicing/')({
  component: () => (
    <PlatformPage requiredPermissions={['invoices.read']}>
      <PlatformInvoicingPage />
    </PlatformPage>
  ),
})
