import styled from '@emotion/styled'
import type { OverviewTimelineSectionState } from '@orus.eu/dimensions'
import { spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { CommentArea } from '../common/CommentArea'
import { DEFAULT_EXPERIENCE_TEMPLATE_AS_STRING } from '../rich-text-editor/constants/experience-table-template'

type OverviewTimelineSectionProps = {
  state: OverviewTimelineSectionState
}

const OverviewTimelineSection = memo(function OverviewTimelineSection(props: OverviewTimelineSectionProps) {
  const { state } = props

  const value =
    state.detailedSheetExperienceSynthesis && state.detailedSheetExperienceSynthesis !== ''
      ? state.detailedSheetExperienceSynthesis
      : DEFAULT_EXPERIENCE_TEMPLATE_AS_STRING

  return (
    <CommentAreaWrapper>
      <CommentArea readonly value={value} title="Timeline" />
    </CommentAreaWrapper>
  )
})

export default OverviewTimelineSection

const CommentAreaWrapper = styled.div`
  padding-top: ${spacing[40]};
`
