import { NotFoundError, useCrash } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useSearch } from '@tanstack/react-router'
import { memo, useEffect } from 'react'
import { getSubscriptionInjectableParamsFromURLSearchParams } from '../../../../lib/subscription-injectable-params'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { useCreateNewSubscription } from './use-create-new-subscription'

const BackofficeNewSubscriptionPage = memo(function BackofficeNewSubscriptionPage() {
  const createNewSubscription = useCreateNewSubscription()

  const crash = useCrash()
  const searchParams = useSearch({ strict: false })

  useEffect(() => {
    const paramsResult = getSubscriptionInjectableParamsFromURLSearchParams(searchParams)
    if (isFailure(paramsResult)) {
      switch (paramsResult.problem.type) {
        case 'invalid-params':
          crash(new NotFoundError())
          return
      }
    }
    const params = paramsResult.output

    createNewSubscription(params)
  }, [crash, searchParams, createNewSubscription])

  return <GlobalLoadingState />
})

export default BackofficeNewSubscriptionPage
