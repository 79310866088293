import { createFileRoute } from '@tanstack/react-router'

import PlatformOrganizationMembershipsPage from '../../../components/pages/backoffice/platform/platform-membership-page/platform-memberships-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/memberships')({
  component: () => (
    <PlatformPage requiredPermissions={['organization.read']}>
      <PlatformOrganizationMembershipsPage />
    </PlatformPage>
  ),
})
