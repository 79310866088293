import { css } from '@emotion/react'
import {
  colors,
  HorizontalLogo,
  mobileSideSpace,
  primaryColor,
  ProgressBar,
  spacing,
  stickyHeaderZIndex,
  Text,
  useUiContext,
} from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { CompactHeaderContactButton } from './compact-header-contact-button'
import { HeaderContactButton } from './header-contact-button'
import { HeaderSeparator } from './header-separator'

export type HeaderCustomerLayoutProps = {
  compact?: boolean
  progress?: number
  initialProgress?: number
  title?: string
  rightBlock?: ReactNode
}

export const HeaderCustomerLayout = memo<HeaderCustomerLayoutProps>(function HeaderCustomerLayout({
  rightBlock,
  progress,
  title,
  compact,
  initialProgress = 0,
}) {
  const screenType = useUiContext()

  return (
    <div
      css={css`
        position: sticky;
        top: 0;
        z-index: ${stickyHeaderZIndex};
      `}
    >
      <div
        css={css`
          background-color: ${colors.white};
        `}
      >
        <HeaderCustomerColumn>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              padding: ${screenType === 'desktop' ? spacing[40] : spacing[50]} 0;
              align-items: center;
            `}
          >
            {title ? (
              <Text variant="subtitle">{title}</Text>
            ) : (
              <HorizontalLogo color={primaryColor} height={screenType === 'desktop' ? 21 : 17} />
            )}
            {rightBlock ? (
              rightBlock
            ) : screenType === 'desktop' ? (
              <HeaderContactButton />
            ) : (
              <CompactHeaderContactButton />
            )}
          </div>
        </HeaderCustomerColumn>
      </div>

      {compact ? <HeaderSeparator /> : <ProgressBar initialProgress={initialProgress} progress={progress} />}
    </div>
  )
})

type HeaderCustomerColumnProps = {
  children: ReactNode
  className?: string
}

export const HeaderCustomerColumn = memo<HeaderCustomerColumnProps>(function HeaderCustomerColumn({
  children,
  className,
}) {
  const screenType = useUiContext()
  return (
    <div
      className={className}
      css={
        screenType === 'desktop'
          ? css`
              width: 100%;
              max-width: 1352px;
              margin: 0 auto;
              padding: 0 ${spacing[50]};
            `
          : css`
              padding: 0 ${mobileSideSpace};
            `
      }
    >
      {children}
    </div>
  )
})
