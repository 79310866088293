import styled from '@emotion/styled'
import { formatDdMmYyyy, parseYyyyMmDd } from '@orus.eu/calendar-date'
import { Divider, spacing, Text, WidgetBase } from '@orus.eu/pharaoh'
import { Fragment } from 'react'
import { AddressDisplay } from './helper'

export type WidgetCompanyEstablishmentProps = {
  title: string
  href: string | undefined
  establishments:
    | {
        siege: boolean | null
        creationDate: string | null
        cessationDate: string | null
        address: {
          streetAddress: string | null
          streetAddress2: string | null
          postalCode: string | null
          city: string | null
          country: string | null
        }
      }[]
    | undefined
  isLoading?: boolean
}

export const WidgetCompanyEstablishment = function (props: WidgetCompanyEstablishmentProps): JSX.Element {
  const { title, href, establishments, isLoading = false } = props

  const isEstablishment = establishments && establishments.length > 0

  if (!isEstablishment && isLoading === false) {
    return <WidgetBase title={title} href={href} />
  }

  return (
    <WidgetBase title={title} href={href} isBodyLoading={isLoading}>
      {isEstablishment &&
        establishments.map((establishment, index) => (
          <Fragment key={index}>
            <StyledEstablishment>
              {establishment.siege ? (
                <Text variant="body2Medium">Siège social</Text>
              ) : establishment.siege === false ? (
                <Text variant="body2Medium">Etablissement secondaire</Text>
              ) : undefined}
              <AddressDisplay address={establishment.address} />

              <Text variant="body2">
                Date de création :{' '}
                {establishment.creationDate ? formatDdMmYyyy(parseYyyyMmDd(establishment.creationDate)) : undefined}
              </Text>

              <Text variant="body2">
                Date de cessation :{' '}
                {establishment.cessationDate ? formatDdMmYyyy(parseYyyyMmDd(establishment.cessationDate)) : undefined}
              </Text>
            </StyledEstablishment>
            {index < establishments.length - 1 && <Divider orientation="horizontal" />}
          </Fragment>
        ))}
    </WidgetBase>
  )
}

const StyledEstablishment = styled.div`
  padding-top: ${spacing[30]};
  padding-bottom: ${spacing[30]};
`
