import { BaseDimension } from '../abstract-dimension'

export const rcpaAciLoiValues = [100_000, 200_000, 300_000, 500_000] as const
export const rcpaAsLoiValues = [100_000, 250_000, 500_000, 1_000_000] as const
export const rcpaFinLoiValues = [
  50_000, 100_000, 150_000, 200_000, 250_000, 300_000, 500_000, 750_000, 800_000, 1_000_000, 1_200_000, 1_500_000,
] as const

export const rcpaLoiValues = [...rcpaAciLoiValues, ...rcpaAsLoiValues, ...rcpaFinLoiValues] as const

export type RcpaLoi = (typeof rcpaLoiValues)[number]

export const rcpaAllowedLoisPerActivityDimension = new BaseDimension<
  'rcpaAllowedLoisPerActivity',
  { [key: string]: RcpaLoi[] }
>({
  name: 'rcpaAllowedLoisPerActivity',
  displayValues: {
    name: 'Limite de garanties (en €) disponibles par activité',
  },
  tags: ['RCPA'],
} as const)
