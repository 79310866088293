import { TextField, type InputBaseComponentProps, type SxProps } from '@mui/material'
import type { InputProps as StandardInputProps } from '@mui/material/Input'
import { useLanguage, type TextFieldSize } from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import {
  forwardRef,
  memo,
  useCallback,
  useRef,
  useState,
  type ChangeEvent,
  type FocusEvent,
  type InputHTMLAttributes,
} from 'react'
import { NumericFormat } from 'react-number-format'
import type { ValidationResult } from '../../lib/validation'

export type ValidatedTextFieldProps<VALUE> = {
  initialValue: string
  validator: (value: string, language: Language) => ValidationResult<VALUE>
  onChange: (value: VALUE | null) => void
  onEditStops?: (value: VALUE | null) => void
  autoFocus?: boolean
  multiline?: boolean
  disabled?: boolean
  placeholder?: string
  formatter?: 'amount'
  InputProps?: Partial<StandardInputProps>
  type?: InputHTMLAttributes<unknown>['type']
  sx?: SxProps
  className?: string
  size?: TextFieldSize
}

type CustomProps<VALUE> = {
  onChange: (event: { target: { value: VALUE | null } }) => void
  allowNegative?: boolean
}

const AmountFormatterBase = forwardRef<unknown, CustomProps<string>>(function NumericFormatCustom(props, ref) {
  const { onChange, allowNegative, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value === undefined ? null : values.value,
          },
        })
      }}
      allowNegative={allowNegative}
      thousandSeparator=" "
      decimalSeparator=","
      thousandsGroupStyle="thousand"
      valueIsNumericString={true}
      decimalScale={2}
      allowLeadingZeros={true}
      prefix="€ "
    />
  )
}) as unknown as React.ElementType<InputBaseComponentProps>

export const AmountFormatter: React.ElementType<InputBaseComponentProps> = (props) => (
  <AmountFormatterBase {...props} allowNegative={true} />
)

export const PositiveAmountFormatter: React.ElementType<InputBaseComponentProps> = (props) => (
  <AmountFormatterBase {...props} allowNegative={false} />
)

export const PercentFormatter = forwardRef<unknown, CustomProps<string>>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value === undefined ? null : values.value,
          },
        })
      }}
      thousandSeparator=" "
      decimalSeparator=","
      thousandsGroupStyle="thousand"
      valueIsNumericString={true}
      prefix="% "
    />
  )
}) as unknown as React.ElementType<InputBaseComponentProps>

function InnerValidatedTextField<VALUE>(props: ValidatedTextFieldProps<VALUE>) {
  const language = useLanguage()
  const {
    initialValue,
    InputProps,
    type,
    formatter,
    sx,
    autoFocus,
    multiline,
    placeholder,
    disabled,
    validator,
    onChange,
    size = 'large',
  } = props

  const [validationActive, setValidationActive] = useState(false)
  const [value, setValue] = useState(initialValue)

  const helperText = getHelperText(validator, value, validationActive, language)

  const lastCommittedValue = useRef<VALUE | null | undefined>()
  const commitChange = useCallback(
    (value: VALUE | null) => {
      if (lastCommittedValue.current === value) return

      lastCommittedValue.current = value
      onChange(value)
    },
    [onChange],
  )

  const validate = useCallback(
    (newValue: string) => {
      const newValueValidationResult = validator(newValue, language)
      const validatedValue = newValueValidationResult.ok ? newValueValidationResult.value : null
      commitChange(validatedValue)
    },
    [validator, commitChange, language],
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setValidationActive(true)
      const newValue = event.target.value
      validate(newValue)
    },
    [validate],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      setValue(newValue)
      if (validationActive) {
        validate(event.target.value)
      } else {
        const validationResult = validator(newValue, language)
        if (validationResult.ok) {
          commitChange(validationResult.value)
          setValidationActive(true)
        }
      }
    },
    [commitChange, validate, validator, validationActive, language],
  )

  let ComputedInputProps = InputProps
  if (formatter === 'amount') {
    ComputedInputProps = {
      ...ComputedInputProps,
      inputComponent: AmountFormatter,
    }
  }

  return (
    <TextField
      autoFocus={autoFocus}
      multiline={multiline}
      disabled={disabled}
      placeholder={placeholder}
      fullWidth={true}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      error={!!helperText}
      helperText={helperText}
      InputProps={ComputedInputProps}
      type={type}
      sx={sx}
      size={size === 'large' ? 'medium' : 'small'}
    />
  )
}

// Had to do this to keep the generics in the component signature
/**
 * @deprecated Use pharao's components
 */
export const ValidatedTextField = memo(InnerValidatedTextField) as typeof InnerValidatedTextField

function getHelperText<VALUE>(
  validator: ValidatedTextFieldProps<VALUE>['validator'],
  value: string,
  validationActive: boolean,
  language: Language,
) {
  if (!validationActive) {
    return null
  }

  const validationResult = validator(value, language)
  return validationResult.ok ? null : validationResult.hint
}
