import { createFileRoute, useParams } from '@tanstack/react-router'
import PlatformRenewalPage from '../../../../../../components/pages/backoffice/platform/PlatformRenewalPage'
import PlatformPage from '../../../../../../components/templates/platform-page'

const BackofficeRenewalPage = () => {
  const { subscriptionId } = useParams({
    strict: false,
  })

  return (
    <PlatformPage requiredPermissions={['contracts.sign']}>
      <PlatformRenewalPage key={subscriptionId} />
    </PlatformPage>
  )
}

export const Route = createFileRoute('/bak/contracts/$subscriptionId/renewal/$endorsementId')({
  component: () => <BackofficeRenewalPage />,
})
