import { WidgetBase, type BadgeVariant } from '@orus.eu/pharaoh'
import { getScoreColors } from './helper/get-score-badge-variant'

export type WidgetCompanyScoreProps = {
  title: string
  score: number | string | null | undefined
  href: string | undefined
  scoreColors: {
    min: number
    max: number
    color: BadgeVariant
  }[]
  isLoading?: boolean
}

export const WidgetCompanyScore = function WidgetCompanyScore(props: WidgetCompanyScoreProps): JSX.Element {
  const { title, score, href, scoreColors, isLoading = false } = props

  let badgeLabel: string | number = 'Aucune donnée'
  let badgeDark = false
  let badgeVariant: BadgeVariant | undefined = undefined

  if (score != null) {
    badgeLabel = score
    badgeDark = true
    const scoreInt = typeof score === 'string' ? parseInt(score) : score

    badgeVariant = getScoreColors({ score: scoreInt, scoreColors })
  }

  return (
    <WidgetBase
      title={title}
      badgeLabel={badgeLabel}
      badgeVariant={badgeVariant}
      badgeDark={badgeDark}
      href={href}
      hasChildren={false}
      isHeaderLoading={isLoading}
    />
  )
}
