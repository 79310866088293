import { frenchPostCodeRegex } from '@orus.eu/address'
import { failure, success } from '@orus.eu/result'
import type { ValidatedTypeMapper } from '../index.js'

export const postcodeMapper: ValidatedTypeMapper<string> = {
  inputType: 'number',
  format: (value) => value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (text) => {
    text = text.trim()

    if (!frenchPostCodeRegex.test(text)) {
      return failure(text.length === 5 ? 'Code postal invalide' : 'Entrez les cinq chiffres du code postal')
    }
    return success(text)
  },
}
