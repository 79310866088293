import { type } from 'arktype'

export const rcpaInsurance = ['rcp'] as const

export const rcpaGuarantees = [
  'rcpDommagesCorporels',
  'rcpConseilJuridique',
  'fraisDefense',
  'fraisDeDefenseJuridiction',
  'rceDommagesCorporels',
  'rceDommagesMateriels',
  'rceDommageImmateriels',
  'rceFauteInexcusable',
  'rceDommagesAuxBiens',
  'rceVolPreposes',
  'rceAtteinteEnvironnement',
  'rceDefensePenale',
  'optionDirigeant',
] as const

export const rcpaInsuranceType = type('===', ...rcpaInsurance)

export type RcpaInsurance = typeof rcpaInsuranceType.infer
