import { css } from '@emotion/react'
import type { StatementCard, StatementSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  Markdown,
  PersistentNotification,
  ResponsiveCard,
  Text,
  borderRadius,
  colorTokens,
  colors,
  spacing,
  useSkin,
  useTranslate,
} from '@orus.eu/pharaoh'
import type { LocalizableMessage } from '@orus.eu/translations'
import { memo } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const StatementSubscriptionUiElementBlock = ifStateProxy<StatementSubscriptionUiElement>(
  function StatementSubscriptionUiElementBlock({ stateProxy, uiElement, context, goBackToPreviousStep, handleSubmit }) {
    return (
      <>
        <StatementBlock
          statementCards={stateProxy.readRequired(uiElement.dimensions.statementCards)}
          statementWarning={stateProxy.readRequired(uiElement.dimensions.statementWarning)}
          hideCheckConditionsText={context === 'backoffice'}
          goBackToPreviousStep={goBackToPreviousStep}
          handleSubmit={handleSubmit}
        />
      </>
    )
  },
)

type StatementBlockProps = {
  statementCards: readonly StatementCard[]
  hideCheckConditionsText: boolean
  statementWarning: LocalizableMessage
  goBackToPreviousStep: () => void
  handleSubmit: () => void
}
export const StatementBlock = memo<StatementBlockProps>(function StatementBlock({
  statementCards,
  hideCheckConditionsText,
  statementWarning,
}) {
  const translate = useTranslate()
  const skin = useSkin()

  const statementWarningText = translate(statementWarning)

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      {statementCards.map((statementCard) =>
        statementCard.banner ? (
          <ResponsiveCard
            key={statementCard.type + statementCard.emoji + statementCard.title}
            css={css`
              margin-bottom: ${spacing[60]};
              background-color: ${statementCard.type === 'info'
                ? skin.subscriptionCalloutColors.backgroundColor
                : colors.red[300]};
              padding: ${spacing[50]};
              border-radius: ${borderRadius[20]};
              box-shadow: none;
            `}
          >
            <Text
              variant="body2Medium"
              css={css`
                margin-bottom: ${spacing[40]};
                color: ${skin.subscriptionCalloutColors.accentColor};
              `}
            >
              {statementCard.title}
            </Text>
            <Text variant="body2" color={skin.subscriptionCalloutColors.accentColor}>
              {statementCard.content}
            </Text>
          </ResponsiveCard>
        ) : (
          <ResponsiveCard
            key={statementCard.title}
            css={css`
              margin-bottom: ${spacing[60]};
              padding: ${spacing[60]};
              border: 1px solid ${colorTokens['color-stroke-base']};
              border-radius: ${borderRadius[30]};
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: ${spacing[50]};
              `}
            >
              <Text>{statementCard.emoji}</Text>
              <Text
                variant="body1Medium"
                css={css`
                  margin-left: ${spacing[50]};
                `}
              >
                {statementCard.title}
              </Text>
            </div>
            {statementCard.content.map((markdown, index) => (
              <Text variant="body2" key={index}>
                <Markdown
                  markdown={markdown}
                  css={css`
                    margin-bottom: ${spacing[50]};

                    ul {
                      padding-left: 18px;
                    }
                  `}
                />
              </Text>
            ))}
            <Text
              variant="caption"
              css={css`
                margin-top: ${spacing[50]};
                color: ${colors.gray[800]};
              `}
            >
              {statementCard.info}
            </Text>
          </ResponsiveCard>
        ),
      )}

      {hideCheckConditionsText || !statementWarningText ? (
        <></>
      ) : (
        <PersistentNotification variant="danger" showAvatar={false}>
          {statementWarningText}
        </PersistentNotification>
      )}
    </div>
  )
})
