import type { ComplyAdvantageCheck } from '../widget-entity'

export function getComplyAdvantageCheckTooltip(amlCheck: ComplyAdvantageCheck): string {
  if (amlCheck.stageType === 'no_case') {
    return 'Aucun risque'
  }
  if (amlCheck.decisionType === 'approved') {
    return 'Risque approuvé'
  }
  if (amlCheck.decisionType === 'rejected') {
    return 'Risque rejeté'
  }

  return "Risque en cours d'analyse"
}
