import { OptionsListDimension } from '../options-list-dimension.js'

export const mrphDisplayableOptionTypesNames = ['PE', 'RCAL', 'RCE', 'PJ', 'ASSISTANCE'] as const
export const mrphOptionTypeNames = [...mrphDisplayableOptionTypesNames, 'FREEDOM_ADVANTAGE'] as const
export type MrphOptionType = (typeof mrphOptionTypeNames)[number]
export type MrpDisplayableOptionalGuaranteeType = Exclude<MrphOptionType, 'FREEDOM_ADVANTAGE'>

export const mrphOptionsDimension = new OptionsListDimension<'mrphOptions', MrphOptionType>({
  name: 'mrphOptions',
  oldNames: ['options'],
  displayValues: { name: 'Active options' },
  tags: ['MRPH'],
} as const)
