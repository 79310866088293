import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class ActivitiesDimension<NAME extends string> extends AbstractDimension<NAME, Activities> {
  override validateData(value: LooselyTypedValue): Result<Activities, DimensionValidationProblem> {
    const parsingResult = SCHEMA(value)

    if (parsingResult instanceof type.errors) {
      return dimensionValidationFailure(
        `Field ${this.name} is not valid. Summary: ${parsingResult.summary}. Value: ${JSON.stringify(value)}`,
      )
    }

    return success(parsingResult)
  }
}

const SCHEMA = type({ activity: 'string', displayName: 'string' }).array()
type Activities = typeof SCHEMA.infer
