import styled from '@emotion/styled'
import {
  Avatar,
  Button,
  Card,
  FlexColumn,
  FlexRow,
  PhoneNumber,
  Text,
  colorTokens,
  colors,
  spacing,
  useAsyncCallback,
  useScreenType,
} from '@orus.eu/pharaoh'
import { useNavigate, useParams, useSearch } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import { useCallback, useState } from 'react'
import { trpc } from '../../../client'
import partnerReferent from '../../../images/françois.webp'
import { assert } from '../../../lib/errors'
import { useSession } from '../../../lib/session'
import { usePermissions } from '../../../lib/use-permissions'
import { openLinkInNewWindow } from '../../../lib/window-util'
import { useBuildUrl } from '../../../use-build-url'
import { BackofficeSectionTitle } from '../../atoms/backoffice-section-title'
import { backofficePartnerReferent, inferFirstName } from './backoffice-home-page.utils'
import { PartnersClientSignedEmailCheckbox } from './partners/partners-client-signed-email-checkbox'

export default function BackofficeHomePage(): JSX.Element {
  const { organization } = useParams({ strict: false })
  const { buildUrl } = useBuildUrl()
  const session = useSession()
  const { type: userType } = usePermissions()
  const isPartner = userType === 'partner'
  const urlSearchParams = useSearch({ strict: false })
  const navigate = useNavigate()
  const [isCreateSubscriptionLoading, setIsCreateSubscriptionLoading] = useState(false)
  const isEmbedInIframe = urlSearchParams.embedInIframe != null || window.self !== window.top

  const createSubscription = useAsyncCallback(async () => {
    setIsCreateSubscriptionLoading(true)
    const subscriptionId = await trpc.subscriptions.createSubscription.mutate({
      organizationTechnicalName: organization,
    })
    if (isPartner && !organization)
      throw new TechnicalError('The connected user is partner and does not have an organization')

    const route =
      isPartner && organization
        ? {
            to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
            params: { subscriptionId, organization },
          }
        : {
            to: '/bak/v2-pending-subscriptions/$subscriptionId',
            params: { subscriptionId },
          }

    void navigate(route)
    setIsCreateSubscriptionLoading(false)
  }, [navigate, organization, isPartner])

  const navigateToClients = useCallback(() => {
    if (isPartner && !organization)
      throw new TechnicalError('The connected user is partner and does not have an organization')

    const route =
      isPartner && organization
        ? {
            to: '/partner/$organization/users',
            params: { organization },
          }
        : {
            to: '/bak/users',
          }

    void navigate(route)
  }, [navigate, organization, isPartner])

  function ContractsCardAvatar() {
    return (
      <Avatar
        variant="contained"
        size="60"
        icon="folder-light"
        color={colorTokens['color-stroke-info-active']}
        containerColor={colorTokens['color-bg-decorative-1']}
      />
    )
  }

  function AcademyCardAvatar() {
    return (
      <Avatar
        variant="contained"
        size="60"
        icon="sparkles-light"
        color={colorTokens['color-stroke-info-active']}
        containerColor={colorTokens['color-bg-decorative-4']}
      />
    )
  }

  assert(
    // eslint-disable-next-line no-unsafe-optional-chaining
    session.user && 'email' in session.user,
    'Backoffice users should have a verified email address',
  )

  if (isEmbedInIframe) {
    return (
      <FlexColumn gap={spacing[70]} margin={`${spacing[90]}`} padding="0">
        <BackofficeSectionTitle>
          Bonjour {session.user.firstName ?? inferFirstName(session.user.email)} !
        </BackofficeSectionTitle>
        <Text>Vous êtes connecté au backoffice Orus, donc la création de devis doit y être effectué.</Text>
        <Text>Afin de créer un nouveau devis, veuillez suivre le lien ci-dessous :</Text>
        <FlexRow gap={spacing[70]}>
          <Button
            variant="secondary"
            onClick={() => {
              if (!window.top) return
              window.top.location.href = !organization
                ? buildUrl({ to: '/bak/pending-subscriptions' })
                : buildUrl({ to: '/partner/$organization/pending-subscriptions', params: { organization } })
            }}
          >
            Accès au backoffice
          </Button>
        </FlexRow>
      </FlexColumn>
    )
  }

  return (
    <HomePageContainer>
      <HomePageTitle>
        <Text variant="h2">Bonjour {session.user.firstName ?? inferFirstName(session.user.email)} 👋</Text>
        <Text variant="body1" color={colorTokens['color-text-base-basic']}>
          Votre espace en ligne pour gérer les contrats de vos clients.
        </Text>
      </HomePageTitle>

      {organization ? (
        <Text variant="body1" color={colorTokens['color-text-base-basic']}>
          Compte: {organization}
        </Text>
      ) : null}

      <CardsContainer>
        <Card
          title="Contrats"
          subtitle="Retrouvez tous vos dossiers en un clic."
          avatar={<ContractsCardAvatar />}
          size="small"
          variant="basic"
          subtitleColor={colorTokens['color-text-base-basic']}
          buttons={[
            {
              props: {
                variant: 'primary',
                onClick: createSubscription,
                icon: 'plus-regular',
                avatarPosition: 'left',
                disabled: isCreateSubscriptionLoading,
                isLoading: isCreateSubscriptionLoading,
              },
              content: 'Nouveau devis',
            },
            {
              props: {
                variant: 'secondary',
                onClick: navigateToClients,
                icon: 'folder-regular',
                avatarPosition: 'left',
              },
              content: 'Voir les clients',
            },
          ]}
        />

        {isPartner ? (
          <>
            <Card
              title="Découvrir Orus"
              subtitle="Bénéficiez de ressources de formation pour utiliser notre plateforme courtier."
              avatar={<AcademyCardAvatar />}
              size="small"
              variant="basic"
              buttons={[
                {
                  props: {
                    variant: 'secondary',
                    onClick: openWelcomeKit,
                    icon: 'arrow-up-right-regular',
                    avatarPosition: 'right',
                  },
                  content: 'Démarrer avec Orus',
                },
                {
                  props: {
                    variant: 'secondary',
                    onClick: openOrusAcademy,
                    icon: 'arrow-up-right-regular',
                    avatarPosition: 'right',
                  },
                  content: 'Orus academy',
                },
              ]}
            />

            <Card
              title="Équipe Orus"
              subtitle="Échangez avec votre référent personnel."
              avatar={
                <Avatar variant="round" size="60" src={partnerReferent} imageBackgroundColor={colors.sky.light} />
              }
              size="small"
              variant="basic"
              buttons={[
                {
                  props: {
                    variant: 'secondary',
                    icon: 'envelope-regular',
                    href: `mailto:${backofficePartnerReferent.email}`,
                    avatarPosition: 'left',
                  },
                  content: backofficePartnerReferent.email,
                },
                {
                  props: {
                    variant: 'secondary',
                    icon: 'phone-regular',
                    href: `tel:${backofficePartnerReferent.phone}`,
                    avatarPosition: 'left',
                  },
                  content: <PhoneNumber>{backofficePartnerReferent.phone}</PhoneNumber>,
                },
              ]}
            />
          </>
        ) : undefined}
      </CardsContainer>

      {isPartner && (
        <NotificationsContainer>
          <Text variant="body1Medium">Notifications </Text>
          <PartnersClientSignedEmailCheckbox />
        </NotificationsContainer>
      )}
    </HomePageContainer>
  )
}

const HomePageContainer = styled.div`
  margin: ${spacing[90]} ${spacing[100]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
`

const CardsContainer = styled.div`
  display: flex;
  flex-flow: row
    ${() => {
      const screenType = useScreenType()
      return screenType === 'mobile' ? 'wrap' : 'nowrap'
    }};
  gap: ${spacing[60]};
`

const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
`

const HomePageTitle = styled.div`
  margin: ${spacing[60]} 0;
  display: flex;
  flex-direction: column;
  gap: ${spacing[20]};
`

function openWelcomeKit() {
  openLinkInNewWindow(
    'https://orus-team.notion.site/Orus-x-Courtiers-Kit-de-Bienvenue-31f0aaf1794d40479999fda020f8fe51',
  )
}

function openOrusAcademy() {
  openLinkInNewWindow('https://orus-team.notion.site/Orus-x-Courtiers-Orus-Academy-1180ab24de3180c39766e9c2ded8746d')
}
