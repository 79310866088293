import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminCpmsPage from '../../../../components/pages/backoffice/platform/platform-admin-cpms-page'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/admin/cpms')({
  component: () => (
    <PlatformPage requiredPermissions={['tech.configurations']}>
      <PlatformAdminCpmsPage />
    </PlatformPage>
  ),
})
