import styled from '@emotion/styled'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { OtherSectionState } from '@orus.eu/dimensions'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { Badge, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import {
  BADGE_LABEL_AND_VARIANT_PER_GROUP_STATUS,
  LABEL_AND_VARIANT_PER_GROUP_STATUS,
} from '../../../subscription-v2/elements/documents-list-element/chip-config'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../detailed-sheet.types'
import DocumentsGridList from './common/DocumentsGridList'

type OtherSectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: OtherSectionState
  stateBefore: OtherSectionState
  setChanges: (changes: OtherSectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (category: GroupCategory) => void
  onFileClick: (selectedFile: File) => void
}

export const OtherSection = memo(function OtherSection(props: OtherSectionProps) {
  const { idsFileGroupStatus, onFileClick } = props

  const title = (
    <Container>
      <Text variant="body1Medium">Autre</Text>
      <Badge
        variant={BADGE_LABEL_AND_VARIANT_PER_GROUP_STATUS[idsFileGroupStatus[0].status ?? 'empty'].variant}
        dark={LABEL_AND_VARIANT_PER_GROUP_STATUS[idsFileGroupStatus[0].status ?? 'empty'].dark}
      >
        {LABEL_AND_VARIANT_PER_GROUP_STATUS[idsFileGroupStatus[0].status ?? 'empty'].label}
      </Badge>
    </Container>
  )

  return <DocumentsGridList title={title} groupStatus={idsFileGroupStatus} onFileClick={(file) => onFileClick(file)} />
})

const Container = styled.div`
  display: inline-flex;
  gap: ${spacing[30]};
  padding: ${spacing[20]} 0;
`
