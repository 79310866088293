import { activityInputType } from '@orus.eu/activity'
import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../abstract-dimension'
import { BooleanDimension } from '../boolean-dimension'
import { oriasType } from '../orias-dimension'

export class RcpaSubsidiariesDimension<NAME extends string> extends AbstractDimension<NAME, RcpaSubsidiary[]> {
  override validateData(value: LooselyTypedValue): Result<RcpaSubsidiary[], DimensionValidationProblem> {
    const result = rcpaSubsidiaryType.array()(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }
    return success(result)
  }
}

export const rcpaSubsidiariesDimension = new RcpaSubsidiariesDimension({
  name: 'rcpaSubsidiaries',
  displayValues: { name: 'Filiales' },
  tags: ['RCPA'],
})

export const isRcpaSubsidiariesAvailableDimension = new BooleanDimension({
  name: 'isRcpaSubsidiariesAvailable',
  displayValues: { name: 'Peut avoir des filiales' },
  tags: ['RCPA'],
})

export const rcpaSubsidiaryType = type({
  name: 'string',
  oriasNumber: oriasType,
  siren: /^[0-9]{9}$/,
  activities: activityInputType.array(),
  address: type({
    street: 'string',
    zipCode: /^[0-9]{5}$/,
    city: 'string',
    country: 'string',
  }),
})

export type RcpaSubsidiary = typeof rcpaSubsidiaryType.infer
