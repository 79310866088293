import { createFileRoute } from '@tanstack/react-router'

import TrackingDocumentationPage from '../../../components/pages/backoffice/platform/platform-documentation/tracking-documentation-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/tracking-doc')({
  component: () => (
    <PlatformPage requiredPermissions={['tracking.read']}>
      <TrackingDocumentationPage />
    </PlatformPage>
  ),
})
