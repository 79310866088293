import { memo } from 'react'
import { Card, colorTokens, useSkin, useTranslate } from '../../../../index.js'
import { PhoneNumber } from '../../../index.js'
import type { HelpContactDetails } from '../../quote-v3/types.js'
import { OrusFaceAvatar } from '../orus-face-avatar/index.js'

export type ContactBannerProps = {
  helpContactDetails: HelpContactDetails
  variant?: 'branded' | 'neutral'
}

export const ContactBanner = memo<ContactBannerProps>(function ContactBanner(props) {
  const translate = useTranslate()
  const { helpContactDetails, variant = 'branded' } = props
  const skin = useSkin()
  return (
    <Card
      title={translate('subscription_need_help_title')}
      subtitle={
        variant === 'branded'
          ? translate('subscription_need_help_explanation')
          : translate('subscription_need_help_explanation_broker')
      }
      avatar={variant === 'branded' ? <OrusFaceAvatar variant="round" size="60" /> : undefined}
      size="small"
      variant="decorative"
      backgroundColorDecorative={skin.quoteCardHelperBackgroundColor ?? colorTokens['color-bg-decorative-2']}
      buttons={[
        helpContactDetails?.phone
          ? {
              props: {
                variant: 'primary',
                icon: 'phone-regular',
                avatarPosition: 'left',
                href: `tel:${helpContactDetails.phone}`,
                trackingId: 'call_button',
              },
              content: <PhoneNumber>{helpContactDetails.phone.replace(/^\+33 ?/, '0')}</PhoneNumber>,
            }
          : undefined,
        variant === 'branded'
          ? helpContactDetails.whatsappLink
            ? {
                props: {
                  variant: 'tertiary',
                  avatarPosition: 'left',
                  icon: 'whatsapp-brands',
                  target: '_blank',
                  href: helpContactDetails.whatsappLink,
                },
                content: translate('chat_on_whatsapp'),
              }
            : undefined
          : helpContactDetails.email
            ? {
                props: {
                  variant: 'tertiary',
                  avatarPosition: 'left',
                  icon: 'envelope-regular',
                  target: '_blank',
                  href: `email:${helpContactDetails.email}`,
                },
                content: translate('send_an_email'),
              }
            : undefined,
      ]}
      fullWidth
    />
  )
})
