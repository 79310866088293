import { colorTokens, spacing } from '@orus.eu/pharaoh'
import isHotkey from 'is-hotkey'
import { memo, useCallback, useEffect, useMemo, useState, type CSSProperties } from 'react'
import { createEditor, Transforms, type Descendant } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, withReact, type RenderElementProps, type RenderLeafProps } from 'slate-react'
import { TableCursor, TableEditor } from 'slate-table'
import { Table, TableBody, TableCell, TableFooter, TableHeader, TableHeaderCell, TableRow } from './components/table'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { validateUrl } from '../../../../../../lib/validation'
import CustomizableLeaf from './components/CustomizableLeaf'
import LinkComponent from './components/link/Link'
import { Toolbar, type TableTemplate } from './components/toolbar/Toolbar'
import { INITIAL_CONTENT_VALUE } from './constants'
import { EMPTY_CONTENT_AS_STRING } from './constants/empty-paragraph'
import withLinks from './plugins/link/withLinks'
import { withTables } from './plugins/table/withTables'
import withKeyCommands from './plugins/withKeyCommands'
import type { CustomElement, Link, Td, Th } from './types'
import { toggleMark } from './utils'
import { isLinkActive } from './utils/is-link-active'

type RichTextEditorProps = {
  onChange?: (value: string) => void
  value: string | null | undefined
  readonly: boolean
  tableTemplate?: TableTemplate
}

type ElementProps = RenderElementProps & { element: CustomElement }

const customStyle = (readonly: boolean): CSSProperties => ({
  width: '100%',
  padding: `${!readonly ? spacing['50'] : '0'}`,
  borderRadius: `${readonly ? `${spacing['30']}` : `0 0 ${spacing['30']} ${spacing['30']}`}`,
  border: `${readonly ? `${spacing['5']} solid ${colorTokens['color-stroke-base']}` : 'none'}`,
  minHeight: !readonly ? '150px' : 'auto',
  overflow: 'hidden',
  boxShadow: `${!readonly ? `0 0 0 1px ${colorTokens['color-stroke-base']}` : 'none'}`,
  background: `${colorTokens['color-bg-base-normal']}`,
})

const getSlateContentFromString = (value: string | null | undefined) => {
  if (!value || value === '' || value === '[]') {
    return INITIAL_CONTENT_VALUE
  }
  return JSON.parse(value)
}

const RichTextEditor = memo(function RichTextEditor({ onChange, value, readonly, tableTemplate }: RichTextEditorProps) {
  const [canMerge, setCanMerge] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [canSplit, setCanSplit] = useState(false)
  const [canManipulateTable, setCanManipulateTable] = useState(false)
  const [canManipulateLink, setCanManipulateLink] = useState(false)
  const [content, setContent] = useState<Descendant[]>(getSlateContentFromString(value))

  const editor = useMemo(() => withHistory(withTables(withLinks(withKeyCommands(withReact(createEditor()))))), [])
  useEffect(() => {
    if (readonly) {
      const valueAsSlateContent = getSlateContentFromString(value)
      editor.children = valueAsSlateContent
    }
  }, [editor, value, readonly])

  const renderElement = useCallback(
    (props: ElementProps) => {
      switch (props.element.type) {
        case 'table':
          return <Table readonly={readonly} {...props} />
        case 'table-head':
          return <TableHeader {...props} />
        case 'table-body':
          return <TableBody {...props} />
        case 'table-footer':
          return <TableFooter {...props} />
        case 'table-row':
          return <TableRow {...props} />
        case 'header-cell':
          return (
            <TableHeaderCell element={props.element as Th} attributes={props.attributes}>
              {props.children}
            </TableHeaderCell>
          )
        case 'table-cell':
          return (
            <TableCell element={props.element as Td} attributes={props.attributes}>
              {props.children}
            </TableCell>
          )
        case 'link':
          return <LinkComponent {...props} />
        case 'paragraph':
        default:
          return <p {...props.attributes}>{props.children}</p>
      }
    },
    [readonly],
  )

  const renderLeaf = useCallback((props: RenderLeafProps) => <CustomizableLeaf {...props} />, [])

  const HOTKEYS = useMemo(
    () => ({
      // Formatting
      BOLD: isHotkey('mod+b'),
      ITALIC: isHotkey('mod+i'),
      UNDERLINE: isHotkey('mod+u'),
      STRIKETHROUGH: isHotkey('mod+s'),

      // Navigation
      ARROW_UP: isHotkey('up'),
      ARROW_DOWN: isHotkey('down'),
      ARROW_LEFT: isHotkey('left'),
      ARROW_RIGHT: isHotkey('right'),
      TAB: isHotkey('tab'),
      SHIFT_TAB: isHotkey('shift+tab'),
    }),
    [],
  )

  const convertContentToString = useCallback((content: Descendant[] | undefined) => {
    return JSON.stringify(content)
  }, [])

  const handleChange = useCallback(
    (newValue: Descendant[]) => {
      setCanUndo(editor.history.undos.length > 0)
      setCanRedo(editor.history.redos.length > 0)
      setCanDelete(TableCursor.isInTable(editor))
      setCanSplit(TableCursor.isInTable(editor))
      setCanManipulateTable(TableCursor.isInTable(editor))
      setCanManipulateLink(isLinkActive(editor))
      setContent(newValue)
    },
    [editor],
  )

  const handleBlur = useCallback(() => {
    const contentString = convertContentToString(content)

    if ((value === '' || value === EMPTY_CONTENT_AS_STRING) && contentString === EMPTY_CONTENT_AS_STRING) {
      return
    }
    if (value === contentString) {
      return
    }

    onChange?.(contentString)
  }, [content, convertContentToString, onChange, value])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (TableCursor.isInTable(editor)) {
        if (HOTKEYS.ARROW_DOWN(event) && TableCursor.isOnEdge(editor, 'bottom')) {
          event.preventDefault()
          return TableCursor.downward(editor)
        }
        if (HOTKEYS.ARROW_UP(event) && TableCursor.isOnEdge(editor, 'top')) {
          event.preventDefault()
          return TableCursor.upward(editor)
        }
        if (HOTKEYS.ARROW_RIGHT(event) && TableCursor.isOnEdge(editor, 'end')) {
          event.preventDefault()
          return TableCursor.forward(editor)
        }
        if (HOTKEYS.ARROW_LEFT(event) && TableCursor.isOnEdge(editor, 'start')) {
          event.preventDefault()
          return TableCursor.backward(editor)
        }
        if (HOTKEYS.TAB(event)) {
          if (TableCursor.isInLastCell(editor)) {
            TableEditor.insertRow(editor)
          }
          event.preventDefault()
          return TableCursor.forward(editor, { mode: 'all' })
        }
        if (HOTKEYS.SHIFT_TAB(event)) {
          event.preventDefault()
          return TableCursor.backward(editor, { mode: 'all' })
        }
      }

      if (HOTKEYS.BOLD(event)) {
        event.preventDefault()
        return toggleMark(editor, 'bold')
      }
      if (HOTKEYS.ITALIC(event)) {
        event.preventDefault()
        return toggleMark(editor, 'italic')
      }
      if (HOTKEYS.UNDERLINE(event)) {
        event.preventDefault()
        return toggleMark(editor, 'underline')
      }
      if (HOTKEYS.STRIKETHROUGH(event)) {
        event.preventDefault()
        return toggleMark(editor, 'bold')
      }
    },
    [editor, HOTKEYS],
  )

  const handleToolbarAction = useCallback(() => {
    const contentString = convertContentToString(editor.children)
    onChange?.(contentString)
  }, [editor, convertContentToString, onChange])

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      event.preventDefault()

      const pastedText = event.clipboardData.getData('text')

      if (pastedText) {
        const validationResult = validateUrl(pastedText, ['http:', 'https:', 'mailto:', 'tel:'], 'fr') // Replace 'en' with the appropriate language code

        if (validationResult.ok) {
          const linkNode: Link = {
            type: 'link',
            url: validationResult.value,
            children: [{ text: pastedText }],
          }

          Transforms.insertNodes(editor, linkNode)
        } else {
          editor.insertText(pastedText)
        }
      }
    },
    [editor],
  )

  return (
    <StyledSlate
      editor={editor}
      initialValue={content}
      onSelectionChange={() => setCanMerge(TableEditor.canMerge(editor))}
      onChange={handleChange}
    >
      {!readonly ? (
        <Toolbar
          editor={editor}
          canMerge={canMerge}
          canUndo={canUndo}
          canRedo={canRedo}
          canDelete={canDelete}
          canSplit={canSplit}
          isLinkActive={canManipulateLink}
          canManipulateTable={canManipulateTable}
          tableTemplate={tableTemplate}
          onToolbarAction={handleToolbarAction}
        />
      ) : null}

      <Editable
        onPaste={handlePaste}
        readOnly={readonly}
        placeholder="Votre commentaire..."
        style={customStyle(readonly)}
        onBlur={handleBlur}
        onDragStart={() => {
          if (TableCursor.isInTable(editor)) {
            return true
          }
          return false
        }}
        onKeyDown={handleKeyDown}
        renderPlaceholder={({ attributes, children }) => (
          <div
            {...attributes}
            css={css`
              margin-top: ${spacing['50']};
              font-size: 13px;
            `}
          >
            {children}
          </div>
        )}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck={false}
      />
    </StyledSlate>
  )
})

export default RichTextEditor

const StyledSlate = styled(Slate)`
  border: ${spacing['5']} solid ${colorTokens['color-stroke-base']};
  min-height: ${spacing['100']};
  word-break: break-all;
`
