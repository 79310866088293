import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension'

export const oriasType = type({
  type: "'provided'",
  oriasId: '/^[0-9]{8}$/',
}).or({
  type: "'later'",
})

export type OriasData = typeof oriasType.infer

export class OriasDimension<NAME extends string> extends AbstractDimension<NAME, OriasData> {
  override validateData(value: LooselyTypedValue): Result<OriasData, DimensionValidationProblem> {
    const result = oriasType(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }
    return success(result)
  }
}
