import {
  activityDimension,
  esRcphAllowedDeductiblesDimension,
  esRcphAllowedLoisDimension,
  esRcphLoiDimension,
  esRcphOptionDPPDimension,
  esRcphOptionGlDimension,
  esRcphOptionRezpCruzDimension,
  esRcphOptionWorldDimension,
  esRcphQuoteDimension,
  esRcphSelectedDeductibleDimension,
  esRcphSelectedDimension,
  generalTermsDimension,
  hiddenQuoteDimension,
  mrphDeductibleDimension,
  mrphOptionAssistanceDimension,
  mrphOptionPeActiveDimension,
  mrphOptionPeAvantageAvailableDimension,
  mrphOptionPeDimension,
  mrphOptionPjDimension,
  mrphOptionRcalDimension,
  mrphOptionRceDimension,
  mrphQuoteV2Dimension,
  mrphSelectedDimension,
  mrpwOptionBdgDimension,
  mrpwOptionBoostDimension,
  mrpwOptionPeDimension,
  mrpwOptionPjDimension,
  mrpwOptionPvvDimension,
  mrpwOptionRcTravauxDimension,
  mrpwQuoteDimension,
  mrpwSelectedDimension,
  mutaClientRecommendedFormulaDimension,
  mutaFormulaDimension,
  mutaFormulasWithPriceDimension,
  mutaPossibleClientFormulasDimension,
  mutaQuoteDimension,
  mutaSelectedDimension,
  offerInformationDimension,
  operatingZoneDimension,
  paymentRecurrenceDimension,
  rcdaCompanyInCreationDimension,
  rcdaDeductibleDimension,
  rcdaOptionHistoryTakeoverDimension,
  rcdaOptionHistoryTakeoverStartDateDimension,
  rcdaOptionHistoryTakeoverYearsDimension,
  rcdaOptionSwimmingPoolDimension,
  rcdaQuoteDimension,
  rcdaSelectedDimension,
  rcpaOptionDirigeantDimension,
  rcpaSelectedDimension,
  rcphLoiDimension,
  rcphMaxLoiDimension,
  rcphMinLoiDimension,
  rcphOptionCyberDefaillanceFournisseurDimension,
  rcphOptionCyberDimension,
  rcphOptionCyberDommagesPeDimension,
  rcphOptionCyberDommagesTiersDimension,
  rcphOptionCyberFraudePiratageDimension,
  rcphOptionMondeDimension,
  rcphOptionMrpSmallOfficeDimension,
  rcphOptionRceDimension,
  rcphOptionTdmiDimension,
  rcphQuoteV2Dimension,
  rcphSelectedDimension,
} from '../dimension'
import type { QuotePageBodySubscriptionUiElement } from './subscription-ui-element'

export const quoteDimensions: QuotePageBodySubscriptionUiElement['dimensions'] = {
  hiddenQuote: hiddenQuoteDimension,
  activity: activityDimension,
  generalTerms: generalTermsDimension,
  offerInformation: offerInformationDimension,
  operatingZone: operatingZoneDimension,
  mrpwOptionPe: mrpwOptionPeDimension,
  mrpwOptionPj: mrpwOptionPjDimension,
  mrpwOptionPvv: mrpwOptionPvvDimension,
  mrpwOptionBdg: mrpwOptionBdgDimension,
  mrpwOptionRcTravaux: mrpwOptionRcTravauxDimension,
  mrpwOptionBoost: mrpwOptionBoostDimension,
  mrpwQuote: mrpwQuoteDimension,
  rcphOptionCyber: rcphOptionCyberDimension,
  rcphOptionCyberDommagesPe: rcphOptionCyberDommagesPeDimension,
  rcphOptionCyberDefaillanceFournisseur: rcphOptionCyberDefaillanceFournisseurDimension,
  rcphOptionCyberDommagesTiers: rcphOptionCyberDommagesTiersDimension,
  rcphOptionCyberFraudePiratage: rcphOptionCyberFraudePiratageDimension,
  rcphOptionRce: rcphOptionRceDimension,
  rcphOptionMonde: rcphOptionMondeDimension,
  rcphOptionMrpSmallOffice: rcphOptionMrpSmallOfficeDimension,
  rcphLoiDimension: rcphLoiDimension,
  rcphMinLoiDimension: rcphMinLoiDimension,
  rcphMaxLoiDimension: rcphMaxLoiDimension,
  mrphOptionPj: mrphOptionPjDimension,
  mrphOptionRcal: mrphOptionRcalDimension,
  mrphOptionRce: mrphOptionRceDimension,
  rcphOptionTdmi: rcphOptionTdmiDimension,
  mrphOptionAssistance: mrphOptionAssistanceDimension,
  mrphOptionPeActive: mrphOptionPeActiveDimension,
  mrphOptionPe: mrphOptionPeDimension,
  mrphOptionPeAvantageAvailable: mrphOptionPeAvantageAvailableDimension,
  mrphDeductible: mrphDeductibleDimension,
  mutaFormula: mutaFormulaDimension,
  mutaClientRecommendedFormula: mutaClientRecommendedFormulaDimension,
  mutaPossibleClientFormulas: mutaPossibleClientFormulasDimension,
  mutaFormulasWithPrice: mutaFormulasWithPriceDimension,
  mutaQuote: mutaQuoteDimension,
  rcdaDeductible: rcdaDeductibleDimension,
  rcdaOptionHistoryTakeover: rcdaOptionHistoryTakeoverDimension,
  rcdaOptionSwimmingPool: rcdaOptionSwimmingPoolDimension,
  rcdaOptionHistoryTakeoverYears: rcdaOptionHistoryTakeoverYearsDimension,
  rcdaOptionHistoryTakeoverStartDate: rcdaOptionHistoryTakeoverStartDateDimension,
  rcdaCompanyInCreation: rcdaCompanyInCreationDimension,
  rcdaQuote: rcdaQuoteDimension,
  esRcphLoi: esRcphLoiDimension,
  esRcphAllowedLois: esRcphAllowedLoisDimension,
  paymentRecurrence: paymentRecurrenceDimension,
  mrphQuoteV2: mrphQuoteV2Dimension,
  mrphSelected: mrphSelectedDimension,
  mrpwSelected: mrpwSelectedDimension,
  mutaSelected: mutaSelectedDimension,
  rcdaSelected: rcdaSelectedDimension,
  rcphQuoteV2: rcphQuoteV2Dimension,
  rcphSelected: rcphSelectedDimension,
  esRcphSelected: esRcphSelectedDimension,
  esRcphQuote: esRcphQuoteDimension,
  esRcphOptionDPP: esRcphOptionDPPDimension,
  esRcphOptionWorld: esRcphOptionWorldDimension,
  esRcphOptionRezpCruz: esRcphOptionRezpCruzDimension,
  esRcphOptionGl: esRcphOptionGlDimension,
  esRcphSelectedDeductible: esRcphSelectedDeductibleDimension,
  esRcphAllowedDeductibles: esRcphAllowedDeductiblesDimension,
  rcpaSelected: rcpaSelectedDimension,
  rcpaOptionDirigeant: rcpaOptionDirigeantDimension,
}
