import { createFileRoute } from '@tanstack/react-router'

import PlatformNewInvoicePageV2 from '../../../../../components/pages/backoffice/platform/platform-new-invoice-page-V2'
import PlatformPage from '../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/new-invoice')({
  component: () => (
    <PlatformPage requiredPermissions={['invoices.create']}>
      <PlatformNewInvoicePageV2 />
    </PlatformPage>
  ),
})
