import { colorTokens, shadow, spacing } from '@orus.eu/pharaoh'
import { memo, useCallback, useRef } from 'react'
import { Editor, Transforms, type BaseEditor } from 'slate'

import usePopup from '../hooks/usePopup.js'

import styled from '@emotion/styled'
import type { ReactEditor } from 'slate-react'
import { CustomIconButton } from '../components/button/CustomIconButton.js'
import { Emoji } from '../components/icons/index.js'
import { emojis } from './emojis.js'

type EmojiPickerProps = {
  onSelectEmoji: () => void
  editor: BaseEditor & ReactEditor
  format: string
}

const EmojiPicker = memo(function EmojiPicker(props: EmojiPickerProps) {
  const { onSelectEmoji, editor } = props
  const emojiPickerRef = useRef(null)
  const [showOptions, setShowOptions] = usePopup(emojiPickerRef)

  const toggleOption = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setShowOptions((prev) => !prev)
  }
  const insertEmoji = useCallback(
    (emoji: string) => {
      Transforms.insertText(editor, ' ')
      Editor.addMark(editor, 'fontSize', 24)
      Transforms.insertText(editor, emoji)
      Editor.addMark(editor, 'fontSize', 14)
      Transforms.insertText(editor, ' ')

      setShowOptions(false)
    },
    [editor, setShowOptions],
  )

  const handleSelectEmoji = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, emoji: string) => {
      event.preventDefault()
      insertEmoji(emoji)
      setShowOptions(false)
      onSelectEmoji()
    },
    [insertEmoji, onSelectEmoji, setShowOptions],
  )

  return (
    <EmojiPickerContainer ref={emojiPickerRef}>
      <CustomIconButton active={true} onMouseDown={(event) => toggleOption(event)}>
        <Emoji />
      </CustomIconButton>
      {showOptions && (
        <EmojiPickerDropdownContainer>
          {emojis.map((emoji, index) => {
            return (
              <EmojiUnitContainer
                key={index}
                style={{ userSelect: 'none' }}
                onMouseDown={(event) => handleSelectEmoji(event, emoji)}
              >
                {emoji}
              </EmojiUnitContainer>
            )
          })}
        </EmojiPickerDropdownContainer>
      )}
    </EmojiPickerContainer>
  )
})

export default EmojiPicker

const EmojiPickerContainer = styled.div`
  position: relative;
`

const EmojiPickerDropdownContainer = styled.div`
  position: absolute;
  background: ${colorTokens['color-bg-base-secondary']};
  left: 0;
  padding: ${spacing['30']};
  border: ${`${spacing['5']} solid ${colorTokens['color-stroke-base']}`};
  border-radius: ${spacing['30']};
  box-shadow: ${shadow.bottom[20]};
  height: fit-content;
  z-index: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  gap: ${spacing['20']};
`

const EmojiUnitContainer = styled.div`
  cursor: pointer;
  border-radius: ${spacing['20']};
  font-size: 24px;
`
