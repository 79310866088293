import { createFileRoute } from '@tanstack/react-router'

import PendingSubscriptionPage from '../../../../../components/pages/backoffice/common/PendingSubscriptionPage'
import PartnerPage from '../../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/v2-pending-subscriptions/$subscriptionId')({
  component: () => (
    <PartnerPage requiredPermissions={['subscription.read']}>
      <PendingSubscriptionPage />
    </PartnerPage>
  ),
})
