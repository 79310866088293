import { css } from '@emotion/react'
import { spacing, Spinner, Text } from '@orus.eu/pharaoh'
import { Center } from './center'

type GlobalLoadingStateProps = {
  text?: string
}

export function GlobalLoadingState({ text }: GlobalLoadingStateProps): JSX.Element {
  return (
    <Center minHeight="80vh">
      <Spinner size="70" />
      {text ? (
        <Text
          variant="h2"
          css={css`
            margin-top: ${spacing[70]};
          `}
        >
          {text}
        </Text>
      ) : null}
    </Center>
  )
}
