import { amountArkType } from '@orus.eu/amount'
import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../../abstract-dimension'

export const rcpaDeductiblePerActivityType = type({
  '[string]': {
    '[string]': {
      loiAmount: amountArkType,
      deductible: amountArkType,
    },
  },
})

export type RcpaDeductiblePerActivity = typeof rcpaDeductiblePerActivityType.infer

export class RcpaDeductiblePerActivityDimension<NAME extends string> extends AbstractDimension<
  NAME,
  RcpaDeductiblePerActivity
> {
  override validateData(value: LooselyTypedValue): Result<RcpaDeductiblePerActivity, DimensionValidationProblem> {
    const result = rcpaDeductiblePerActivityType(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }
    return success(result)
  }
}

export const rcpaDeductiblePerActivityDimension = new RcpaDeductiblePerActivityDimension({
  name: 'rcpaDeductiblePerActivity',
  displayValues: { name: 'Franchise par activité' },
  tags: ['RCPA'],
} as const)
