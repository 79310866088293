import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { ChipVariant } from '@orus.eu/pharaoh'

export const LABEL_PER_DOCUMENT_CATEGORY: Record<GroupCategory, string> = {
  id: 'Pièce d’identité',
  immatriculation: 'Immatriculation',
  infoReport: 'Relevé d’information',
  proformaInvoice: 'Facture vierge',
  invoices: 'Factures justificatives',
  RIB: 'RIB',
  experience: "Justificatif d'expérience",
  experiencePizzeria: "Justificatif d'expérience (pizzeria)",
  yearlyAccount: 'Comptes annuels',
  decennaleCertificate: 'Attestation décennale',
  other: 'Autre',
  acag: 'ACAG',
}

export const FUNNEL_LABEL_AND_VARIANT_PER_GROUP_STATUS: Record<
  GroupStatus,
  { label: string; variant: ChipVariant; dark: boolean }
> = {
  empty: { label: 'À compléter', variant: 'neutral', dark: false },
  to_validate: { label: 'En cours', variant: 'neutral', dark: true },
  incomplete: { label: 'Incomplet', variant: 'warning', dark: true },
  validated: { label: 'Validé', variant: 'success', dark: true },
  rejected: { label: 'Rejeté', variant: 'danger', dark: true },
}
