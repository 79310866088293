import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { TechnicalError } from '@orus.eu/error'
import { Text, colors, cornerRibbonZIndex, shadow, spacing, type ColorScale } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'
import { memo, useEffect, useState } from 'react'
import {
  isE2eEnvironment,
  isLocalDevEnvironment,
  isProductionEnvironment,
  isReviewOrProductionEnvironment,
} from '../../lib/env-utils'
import { useSession } from '../../lib/session'

export type PageProps = {
  children: ReactNode
}

export function Page({ children }: PageProps): JSX.Element {
  return (
    <>
      {children}
      <EnvironmentHelper />
      <ImpersonationHelper />
    </>
  )
}

function EnvironmentHelper(): JSX.Element {
  if (isProductionEnvironment() || isE2eEnvironment()) {
    return <></>
  }

  if (isReviewOrProductionEnvironment()) {
    return <EnvironmentRibbon name="Review Env" colorScale={colors.green} />
  }

  if (isLocalDevEnvironment()) {
    return <EnvironmentRibbon name="Local Dev" colorScale={colors.gray} />
  }

  throw new TechnicalError('Unexpected environment type')
}

type EnvironmentRibbonProps = {
  name: string
  colorScale: ColorScale
}

function EnvironmentRibbon({ name, colorScale }: EnvironmentRibbonProps): JSX.Element {
  // On click the ribbon will disappear for 10 seconds, so we can interact with the content behind
  // We don't hide forever, because we don't want old tabs to be confused with prod
  const [hidden, setHidden] = useState(false)

  // We need to store the timeout id, to be able to clear it when the component is dismounted
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  useEffect(() => {
    if (timeoutId !== null) {
      return () => clearTimeout(timeoutId)
    }

    return undefined
  }, [timeoutId])

  if (hidden) {
    return <></>
  }

  const hide = () => {
    setHidden(true)
    setTimeoutId(window.setTimeout(() => setHidden(false), 10000))
  }

  return (
    <Typography
      variant="h6"
      component="div"
      onClick={hide}
      sx={{
        cursor: 'pointer',
        color: colors.white,
        backgroundColor: colorScale[600],
        border: `1px solid ${colors.white}`,
        boxShadow: shadow.bottom[40],
        position: 'fixed',
        top: 0,
        left: 0,
        padding: `${spacing[30]} 100px`,
        width: '320px',
        transform: 'translate(-50%, -50%) rotate(-45deg) translateY(80px)',
        textAlign: 'center',
        zIndex: cornerRibbonZIndex,
      }}
    >
      {name}
      <Typography variant="body2" component="div">
        Click to hide
      </Typography>
    </Typography>
  )
}

const ImpersonationHelper = memo(function ImpersonationHelper() {
  const session = useSession()

  if (!session.impersonatorId) return <></>

  return (
    <ImpersonationOverlay>
      <Text variant="body2Medium">Impersonification en cours</Text>
    </ImpersonationOverlay>
  )
})

const ImpersonationOverlay = styled.div`
  color: ${colors.white};
  background-color: ${colors.red[600]};
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  transform: translate(-50%, -50%) rotate(-45deg) translateY(120px);
  text-align: center;
  z-index: ${cornerRibbonZIndex};
`
