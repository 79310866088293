import { css } from '@emotion/react'
import { amountToNumber, amountToString, type Amount, type FinancialRate } from '@orus.eu/amount'
import { formatDdMmYyyy, type CalendarDate } from '@orus.eu/calendar-date'
import { type ProductAttributes } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { memo } from 'react'
import { ContractPanel } from '../../../../feature/contract-panel/index.js'
import { useDialogVisibility } from '../../../../hooks/index.js'
import { spacing } from '../../../../index.js'
import { useTranslate } from '../../../../localization/language-context.js'
import { Avatar, Text } from '../../../atoms/index.js'
import { Button } from '../../../button/index.js'
import { PersistentNotification } from '../../../callout-notification/index.js'
import { PricingDetailsDialog } from '../../quote-v3/index.js'
import { PdfDocumentDialog } from '../pdf-document-dialog/index.js'

export type ContractBannerV2Props = {
  monthlyPrice: Amount
  yearlyPrice: Amount
  paymentRecurrence: 'monthly' | 'yearly'
  forbiddenMonthlyPayment: boolean
  commitment: 'monthly' | 'yearly'
  yearlyCommitmentHasDiscount: boolean
  /** The Orus discount */
  discount: FinancialRate
  /** The first period price. E.g., when paying monthly, the first month might include terrorism tax */
  firstPeriodPrice?: Amount
  /** The yearly terrorism tax. If it equals 0, it won't be shown */
  yearlyTerrorismTaxPrice?: Amount
  historyTakeoverPrice: Amount | undefined
  partnerApplicationFee: Amount | undefined
  productsAttributes: ProductAttributes[]
  /**
   * Contract start date
   */
  startDate?: CalendarDate
  pdfBytes?: Uint8Array
  downloadablePdfBytes?: Uint8Array
  isPartner?: boolean
  onEditQuote?: () => void
  operatingZone: OperatingZone
}

export const ContractBannerV2 = memo<ContractBannerV2Props>(function ContractBannerV2(props) {
  const translate = useTranslate()
  const {
    downloadablePdfBytes,
    pdfBytes,
    yearlyTerrorismTaxPrice,
    startDate,
    historyTakeoverPrice,
    partnerApplicationFee,
    isPartner,
  } = props
  const {
    hide: hideContractDialog,
    show: showContractDialog,
    visible: isContractDialogVisible,
  } = useDialogVisibility('contract')
  const {
    hide: hideDetailDialog,
    show: showDetailDialog,
    visible: isDetailDialogVisible,
  } = useDialogVisibility('details')

  const price = props.paymentRecurrence === 'monthly' ? props.monthlyPrice : props.yearlyPrice
  const priceAmountText = amountToString(price, { addCurrency: true, displayDecimals: true })
  const priceText = `${priceAmountText} ${translate(`per_time_unit_${props.paymentRecurrence}`)}`
  const { productsAttributes } = props
  const productNames = productsAttributes.map(({ productName }) => productName)

  const contractGridRows = [
    { type: 'row' as const, title: translate('subscription_funnel_checkout_coverage'), values: productNames },
    {
      type: 'row' as const,
      title: translate('subscription_funnel_checkout_rate'),
      values: [priceText],
    },
    ...(yearlyTerrorismTaxPrice && amountToNumber(yearlyTerrorismTaxPrice) > 0
      ? [
          {
            type: 'row' as const,
            title: 'Taxe attentat',
            values: [
              amountToString(yearlyTerrorismTaxPrice, {
                addCurrency: true,
                displayDecimals: true,
              }),
            ],
            paymentPrecisionLabel: 'Payée le 1er mois',
          },
        ]
      : []),
    ...(historyTakeoverPrice && amountToNumber(historyTakeoverPrice) > 0
      ? [
          {
            type: 'row' as const,
            title: 'Reprise du passé',
            values: [
              amountToString(historyTakeoverPrice, {
                addCurrency: true,
                displayDecimals: true,
              }),
            ],
            paymentPrecisionLabel: 'Payée le 1er mois',
          },
        ]
      : []),
    ...(partnerApplicationFee && amountToNumber(partnerApplicationFee) > 0
      ? [
          {
            type: 'row' as const,
            title: 'Frais de dossier',
            values: [
              amountToString(partnerApplicationFee, {
                addCurrency: true,
                displayDecimals: true,
              }),
            ],
            paymentPrecisionLabel: 'Payée le 1er mois',
          },
        ]
      : []),
    ...(startDate
      ? [
          {
            type: 'row' as const,
            title: translate('subscription_funnel_checkout_start_date'),
            values: [formatDdMmYyyy(startDate)],
          },
        ]
      : []),
  ]

  return (
    <div>
      <ContractPanel
        title={translate(isPartner ? 'the_insurance_contract' : 'your_insurance_contract')}
        rightButton={
          <Button avatar={<Avatar icon="pen-regular" />} size="small" variant="tertiary" onClick={showDetailDialog} />
        }
        contractGridRows={contractGridRows}
        buttomButton={
          <Button
            variant="text"
            avatar={<Avatar icon="file-lines-regular" />}
            avatarPosition="left"
            onClick={showContractDialog}
          >
            <Text variant="button">{translate('subscription_funnel_checkout_view_contract')}</Text>
          </Button>
        }
      />
      {isContractDialogVisible ? (
        <PdfDocumentDialog
          fileName={`Orus - ${translate('filename_agreed_terms_draft')}.pdf`}
          onClose={hideContractDialog}
          downloadablePdfBytes={downloadablePdfBytes}
          pdfBytes={pdfBytes}
        />
      ) : (
        <></>
      )}
      {isDetailDialogVisible ? (
        <PricingDetailsDialog
          monthlyPrice={props.monthlyPrice}
          yearlyPrice={props.yearlyPrice}
          paymentRecurrence={props.paymentRecurrence}
          forbiddenMonthlyPayment={props.forbiddenMonthlyPayment}
          commitment={props.commitment}
          yearlyCommitmentHasDiscount={props.yearlyCommitmentHasDiscount}
          discount={props.discount}
          firstPeriodPrice={props.firstPeriodPrice}
          yearlyTerrorismTaxPrice={props.yearlyTerrorismTaxPrice}
          partnerApplicationPrice={props.partnerApplicationFee}
          productsAttributes={props.productsAttributes}
          onClose={hideDetailDialog}
          isPartner={isPartner}
          operatingZone={props.operatingZone}
          footer={
            props.onEditQuote && (
              <PersistentNotification
                variant="info"
                css={css`
                  margin-bottom: ${spacing[70]};
                `}
              >
                {translate(
                  isPartner
                    ? 'subscription_funnel_edit_quote_explanation_partner'
                    : 'subscription_funnel_edit_quote_explanation',
                )}
              </PersistentNotification>
            )
          }
          onEditQuote={props.onEditQuote}
        />
      ) : (
        <></>
      )}
    </div>
  )
})
