import { css } from '@mui/material'
import type { FieldSpecification, MessageSpecification, ValueSpecification } from '@orus.eu/message'
import { ContentContainerBackoffice, Text, TextContainer, colors, spacing } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import { trpcReact } from '../../../../../client'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'

export default memo(function TrackingDocumentationPage() {
  const getTrackingDocumentation = trpcReact.tracking.getTrackingDocumentation.useQuery()

  if (!getTrackingDocumentation.data) {
    return <GlobalLoadingState />
  }

  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Tracking messages documentation</BackofficeSectionTitle>
      <TextContainer>
        <Text>
          This page documents the events sent to the tracking. Request for more information in{' '}
          <a target="_blank" href="https://orus-insurance.slack.com/archives/C02GDHPF1RR" rel="noreferrer">
            #product
          </a>{' '}
          if anything is ambiguous.
        </Text>
      </TextContainer>
      <div>
        {getTrackingDocumentation.data.map((typeSpec) => (
          <MessageSpecDocumentationBlock key={typeSpec.event} spec={typeSpec} />
        ))}
      </div>
    </ContentContainerBackoffice>
  )
})

type MessageSpecDocumentationBlockProps = {
  spec: MessageSpecification
}

const MessageSpecDocumentationBlock: FunctionComponent<MessageSpecDocumentationBlockProps> = memo(
  function MessageSpecDocumentationBlock({ spec }) {
    return (
      <>
        <Text
          variant="h2"
          css={css`
            margin-top: ${spacing[80]};
          `}
        >
          {spec.event}
        </Text>
        <Text
          variant="body1"
          element="span"
          css={css`
            margin-top: ${spacing[70]};
          `}
        >
          <ReactMarkdown>{spec.descriptionMd}</ReactMarkdown>
        </Text>
        <div
          css={css`
            display: grid;
            grid-template-columns: 20% 80%;
            gap: ${spacing[70]};
            margin-top: ${spacing[70]};
          `}
        >
          {Object.entries(spec.fields).map(([fieldName, fieldSpec]) => (
            <FieldDocumentationRow key={fieldName} fieldName={fieldName} fieldSpecification={fieldSpec} />
          ))}
        </div>
      </>
    )
  },
)

type FieldDocumentationBlockProps = {
  fieldName: string
  fieldSpecification: FieldSpecification
}

type PrimitiveDataType = 'number' | 'string' | 'boolean' | 'enum'

type DataType = PrimitiveDataType | `${PrimitiveDataType}[]`

const dataTypeByValueType: { [key in ValueSpecification['type']]: PrimitiveDataType } = {
  string: 'string',
  boolean: 'boolean',
  number: 'number',
  enum: 'enum',
  'undocumented-enum': 'enum',
}

function getDataTypeFromFieldSpecification(fieldSpecification: FieldSpecification): DataType {
  return fieldSpecification.type === 'array'
    ? `${dataTypeByValueType[fieldSpecification.items.type]}[]`
    : dataTypeByValueType[fieldSpecification.type]
}

function getNullableFromFieldSpecification(fieldSpecification: FieldSpecification): boolean {
  return !!fieldSpecification.nullable
}

function getEnumValuesFromFieldSpec(fieldSpecification: FieldSpecification): { [key: string]: string } | null {
  switch (fieldSpecification.type) {
    case 'array':
      return fieldSpecification.items.type === 'enum' ? fieldSpecification.items.values : null
    case 'enum':
      return fieldSpecification.values
    default:
      return null
  }
}

function getUndocumentedEnumValuesFromFieldSpec(
  fieldSpecification: FieldSpecification,
): { [key: string]: null } | null {
  switch (fieldSpecification.type) {
    case 'array':
      return fieldSpecification.items.type === 'undocumented-enum' ? fieldSpecification.items.values : null
    case 'undocumented-enum':
      return fieldSpecification.values
    default:
      return null
  }
}

const FieldDocumentationRow: FunctionComponent<FieldDocumentationBlockProps> = memo(function FieldDocumentationBlock({
  fieldName,
  fieldSpecification,
}) {
  const type = getDataTypeFromFieldSpecification(fieldSpecification)
  const nullable = getNullableFromFieldSpecification(fieldSpecification)
  const documentation = fieldSpecification.documentation
  const enumValues = getEnumValuesFromFieldSpec(fieldSpecification)
  const undocumentedEnumValues = getUndocumentedEnumValuesFromFieldSpec(fieldSpecification)
  return (
    <>
      <div>
        <Text variant="body1Medium">
          <code>
            {fieldName}
            <span
              css={css`
                color: ${colors.gray[500]};
              `}
            >
              : {type}
              {nullable ? ' | null' : ''}
            </span>
          </code>
        </Text>
      </div>
      <div>
        <Text variant="body1Medium">{documentation.title}</Text>

        {documentation.detailedExplanationMd ? (
          <Text
            variant="body2"
            element="span"
            css={css`
              margin-top: ${spacing[30]};
            `}
          >
            <ReactMarkdown>{documentation.detailedExplanationMd}</ReactMarkdown>
          </Text>
        ) : (
          <></>
        )}

        {enumValues ? (
          <Text
            variant="body2"
            element="span"
            css={css`
              margin-top: ${spacing[30]};
            `}
          >
            Possible values:
            <ul
              css={css`
                margin-top: ${spacing[30]};
              `}
            >
              {Object.entries(enumValues).map(([value, description]) => (
                <li key={value}>
                  <Value value={value} type="enum" />: {description}
                </li>
              ))}
            </ul>
          </Text>
        ) : (
          <></>
        )}

        {undocumentedEnumValues ? (
          <Text
            variant="body2"
            element="span"
            css={css`
              margin-top: ${spacing[30]};
            `}
          >
            Possible values:
            <ul>
              {Object.keys(undocumentedEnumValues).map((value) => (
                <li key={value}>
                  <Value value={value} type="enum" />
                </li>
              ))}
            </ul>
          </Text>
        ) : (
          <></>
        )}

        {documentation.examples ? (
          <div
            css={css`
              margin-top: ${spacing[30]};
            `}
          >
            {documentation.examples.map((example, index, array) => (
              <Text key={example.toString()} variant="body2">
                Example{array.length === 1 ? '' : ` ${index + 1}`}:{' '}
                <Value value={example} type={type} preserveWhiteSpace />
              </Text>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
})

type ValueProps = {
  value: string | number | boolean | (string | number | boolean)[]
  type: DataType
  preserveWhiteSpace?: boolean
}

const Value: FunctionComponent<ValueProps> = memo(function Value({ value, type, preserveWhiteSpace }) {
  return (
    <code
      key={value.toString()}
      css={css`
        color: ${exampleColors[type]};
        white-space: ${preserveWhiteSpace ? 'pre' : 'normal'};
      `}
    >
      <strong>{JSON.stringify(value, null, 2)}</strong>
    </code>
  )
})

const exampleColors: { [key in DataType]: string } = {
  string: colors.orange[500],
  'string[]': colors.orange[500],
  number: colors.green[500],
  'number[]': colors.green[500],
  boolean: colors.red[500],
  'boolean[]': colors.red[500],
  enum: colors.blue[500],
  'enum[]': colors.blue[500],
}
