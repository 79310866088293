import styled from '@emotion/styled'
import React, { Children, cloneElement, Fragment, isValidElement, memo } from 'react'
import { borderStroke, colorTokens, spacing } from '../../foundation'
import { isFragment } from '../../util'
import { Divider } from '../atoms'
import type { RowProps } from './row'

export type RowContainerVariant = 'simple' | 'border' | 'spacing'

export type RowContainerProps = {
  variant: RowContainerVariant
  size: RowProps['size']
  checkbox?: boolean
  radio?: boolean
  children: React.ReactNode
  horizontal?: boolean
  className?: string
}

export const RowContainer = memo<RowContainerProps>(function RowContainer(props) {
  const { variant, size, checkbox, radio, children, horizontal = false, className } = props

  const filteredChildren = Children.toArray(children).filter(
    (child): child is React.ReactElement => isValidElement(child) && !isFragment(child),
  )

  return (
    <RowContainerStyle variant={variant} className={className} horizontal={horizontal}>
      {filteredChildren.map((child, index) => (
        <Fragment key={index}>
          {variant !== 'spacing' && index > 0 ? <Divider orientation="horizontal" className="divider" /> : <></>}
          {cloneElement(child, {
            variant: variant === 'spacing' ? 'standalone' : child.props.variant,
            size,
            checkbox,
            radio,
          })}
        </Fragment>
      ))}
    </RowContainerStyle>
  )
})

const RowContainerStyle = styled.div<{ variant: RowContainerVariant; horizontal: boolean }>`
  display: flex;
  flex-flow: ${({ horizontal }) => (horizontal ? 'row wrap' : 'column wrap')};
  gap: ${({ variant }) => (variant === 'spacing' ? spacing[30] : '0')};

  & > *:not(.divider) {
    padding: ${({ variant }) => (variant === 'border' ? `${spacing[40]} ${spacing[50]}` : 'none')};
  }

  border: ${({ variant }) =>
    variant === 'border' ? `${borderStroke[20]} solid ${colorTokens['color-stroke-base']}` : 'none'};
  border-radius: ${({ variant }) => (variant === 'border' ? `${spacing[30]}` : '0')};

  & > *:first-of-type {
    border-radius: ${({ variant }) => (variant === 'border' ? `${spacing[30]} ${spacing[30]} 0 0` : '')};
  }

  & > *:last-of-type {
    border-radius: ${({ variant }) => (variant === 'border' ? `0 0 ${spacing[30]} ${spacing[30]}` : '')};
    color: 'blue' !important;
  }

  & > *:first-of-type:last-of-type {
    border-radius: ${({ variant }) => (variant === 'border' ? `${spacing[30]}` : '')};
  }
`
