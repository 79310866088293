import { amountArkType } from '@orus.eu/amount'
import { type Result, success } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  type DimensionValidationProblem,
  type LooselyTypedValue,
  dimensionValidationFailure,
} from '../../abstract-dimension'

export const rcpaLoiPerActivityType = type({
  '[string]': amountArkType,
})

export type RcpaLoiPerActivity = typeof rcpaLoiPerActivityType.infer

export class RcpaLoiPerActivityDimension<NAME extends string> extends AbstractDimension<NAME, RcpaLoiPerActivity> {
  override validateData(value: LooselyTypedValue): Result<RcpaLoiPerActivity, DimensionValidationProblem> {
    const result = rcpaLoiPerActivityType(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }
    return success(result)
  }
}

export const rcpaLoiPerActivityDimension = new RcpaLoiPerActivityDimension({
  name: 'rcpaLoiPerActivity',
  displayValues: { name: "Plafond d'indemnité par activité" },
  tags: ['RCPA'],
} as const)
