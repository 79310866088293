import { amountToString, parseAmount, type Amount } from '@orus.eu/amount'
import { nbsp } from '@orus.eu/char'
import type { ValidatedTypeMapper } from '@orus.eu/pharaoh'
import type { Result } from '@orus.eu/result'
import { failure, success } from '@orus.eu/result'
import { translate } from '@orus.eu/translations'
import { AmountFormatter, PositiveAmountFormatter } from '../components/molecules/validated-text-field'

export const amountCustomInputProps = { inputComponent: AmountFormatter }
export const positiveAmountCustomInputProps = { inputComponent: PositiveAmountFormatter }

export const amountMapper: ValidatedTypeMapper<Amount> = {
  inputType: 'amount',
  format: (value) => amountToString(value, amountToStringOptions),
  formatPlaceholder: (value) => `€${nbsp}${amountToString(value, amountToStringOptions)}`,
  parse: (text, language) => {
    const amount = parseAmount(text)
    return amount ? success(amount) : failure(translate('error_enter_amount', language))
  },
}

export const amountMapperWithNone: ValidatedTypeMapper<Amount | 'none'> = {
  inputType: 'amount',
  format: (value) => (value === 'none' ? '' : amountToString(value, amountToStringOptions)),
  formatPlaceholder: (value) =>
    value === 'none' ? `€${nbsp}` : `€${nbsp}${amountToString(value, amountToStringOptions)}`,
  parse: (text, language): Result<Amount | 'none', string> => {
    if (!text) return success<Amount | 'none'>('none')

    const amount = parseAmount(text)
    return amount ? success(amount) : failure(translate('error_enter_amount', language))
  },
}
const amountToStringOptions = { displayDecimals: false, addCurrency: false }

export const amountMapperWithDecimals: ValidatedTypeMapper<Amount> = {
  inputType: 'amount',
  format: (value) => amountToString(value, { displayDecimals: true, addCurrency: false }),
  formatPlaceholder: (value) => `€${nbsp}${amountToString(value, { displayDecimals: true, addCurrency: false })}`,
  parse: (text, language) => {
    const amount = parseAmount(text)
    return amount ? success(amount) : failure(translate('error_enter_amount', language))
  },
}
