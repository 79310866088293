import { css } from '@emotion/react'
import { amountToString, multiplyByNumber, zeroAmount } from '@orus.eu/amount'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { getCompanyIdNumberSirenValue } from '@orus.eu/company-id-number'
import { rcdaOptionHistoryTakeoverYearsLabels, type ActivitySectionState } from '@orus.eu/dimensions'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { FormRow, RowContainerV2, spacing, Text, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useState } from 'react'
import { amountCustomInputProps, amountMapper } from '../../../../../lib/amount'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { ComplianceData, DocumentGroupStatus } from '../detailed-sheet.types'
import { CommentArea } from './common/CommentArea'
import CommonSection from './common/CommonSection'
import DocumentsList from './common/DocumentsList'
import { WidgetCompanyActivity } from './widget'
import { getExternalLink } from './widget/helper/get-external-link'

type ActivitySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: ActivitySectionState
  stateBefore: ActivitySectionState
  setChanges: (changes: ActivitySectionState) => void
  subscriptionId: string
  idsFileGroupStatus?: DocumentGroupStatus[]
  onIdFileGroupStatusClick?: (category: GroupCategory) => void
  complianceData: ComplianceData
  isComplianceDataLoading: boolean
  onFileClick: (selectedFile: File) => void
  showAcagComment: boolean
  isReadOnly: boolean
}

export const ActivitySection = memo(function ActivitySection(props: ActivitySectionProps) {
  const {
    state,
    setChanges,
    onIdFileGroupStatusClick,
    idsFileGroupStatus,
    complianceData,
    isComplianceDataLoading,
    onFileClick,
    showAcagComment,
    isReadOnly,
  } = props

  const {
    estimatedRevenue,
    activities,
    activitiesDistribution,
    detailedSheetActivitySynthesis,
    detailedSheetAcagSynthesis,
    rcdaOptionHistoryTakeoverYears,
    companyIdNumber,
  } = state

  const [estimatedRevenueValue, setEstimatedRevenueValue] = useState(estimatedRevenue)

  const { pappersCompany } = complianceData

  const handleClick = (category: GroupCategory, status: GroupStatus) => {
    if (status !== 'empty' && onIdFileGroupStatusClick) {
      onIdFileGroupStatusClick(category)
    }
  }

  const handleActivitySynthesisChange = (value: string) => {
    setChanges({ detailedSheetActivitySynthesis: value })
  }

  const handleAcagSynthesisChange = (value: string) => {
    setChanges({ detailedSheetAcagSynthesis: value })
  }

  const siren = getCompanyIdNumberSirenValue(companyIdNumber)

  const leftContent = (
    <>
      <FormRow>
        <ValidatedTextFieldFormField
          required
          size="small"
          label="Chiffre d’affaires HT"
          value={estimatedRevenue}
          placeholder="100 000 €"
          onBlur={() => {
            setChanges({ estimatedRevenue: estimatedRevenueValue })
          }}
          onChange={(value) => {
            setEstimatedRevenueValue(value)
          }}
          mapper={amountMapper}
          infoTooltip="Chiffre d’affaires approximatif que vous pensez faire en 1 an"
          InputProps={amountCustomInputProps}
          disabled={isReadOnly}
        />

        <div
          css={css`
            display: grid;
            flex-direction: row;
            gap: ${spacing[40]};
          `}
        >
          <Text variant="body2Medium">Reprise du passé</Text>
          <Text variant="body2">
            {rcdaOptionHistoryTakeoverYears
              ? rcdaOptionHistoryTakeoverYearsLabels[rcdaOptionHistoryTakeoverYears]
              : 'Non'}
          </Text>
        </div>
        <div />
      </FormRow>
      <FormRow>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[40]};
            margin-bottom: ${spacing[50]};
          `}
        >
          <Text variant="body2Medium">Liste des activités</Text>
          {activities?.map(({ activity, displayName }) => {
            const distribution =
              activitiesDistribution?.type === 'multiple'
                ? activitiesDistribution!.distribution[activity as keyof typeof activitiesDistribution.distribution]
                : undefined

            return (
              <RowContainerV2 key={activity}>
                <Row
                  variant="basic"
                  css={css`
                    border: none;
                    padding: ${spacing[50]} ${spacing[50]};
                  `}
                  title={displayName}
                  subtitle={
                    distribution && 'sharePercentage' in distribution
                      ? `${distribution.sharePercentage}% (${amountToString(
                          multiplyByNumber(estimatedRevenue ?? zeroAmount, distribution.sharePercentage / 100),
                          {
                            addCurrency: false,
                            displayDecimals: false,
                          },
                        )} €)`
                      : ''
                  }
                  titleVariant={'body2'}
                />
              </RowContainerV2>
            )
          })}
        </div>
        <WidgetCompanyActivity
          title="Informations"
          href={getExternalLink(siren, 'pappers') ?? undefined}
          classification={pappersCompany ? pappersCompany.classifications[0] : undefined}
          declaredActivity={pappersCompany ? pappersCompany.declaredActivity : undefined}
          isLoading={isComplianceDataLoading}
        />
      </FormRow>
      <CommentArea
        title="Commentaire activité"
        onChange={handleActivitySynthesisChange}
        value={detailedSheetActivitySynthesis}
        readonly={isReadOnly}
      />
      {showAcagComment ? (
        <CommentArea
          title="Commentaire ACAG"
          onChange={handleAcagSynthesisChange}
          value={detailedSheetAcagSynthesis}
          readonly={isReadOnly}
        />
      ) : (
        <></>
      )}
    </>
  )

  const rightContent = (
    <DocumentsList
      title="Factures vierges"
      idsFileGroupStatus={idsFileGroupStatus}
      onIdFileGroupStatusClick={handleClick}
      onFileClick={onFileClick}
    />
  )

  return (
    <CommonSection
      value={detailedSheetActivitySynthesis}
      leftContent={leftContent}
      rightContent={rightContent}
      onChange={(value) => {
        setChanges({ detailedSheetActivitySynthesis: value })
      }}
    />
  )
})
