import { isFailure } from '@orus.eu/result'
import { memo, useEffect, useState, type FunctionComponent, type ReactNode } from 'react'
import { ForceUpdateDialog } from '../../lib/ForceUpdateDialog'
import { SessionContext, sessionManager, type SessionResult } from '../../lib/session'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import TemporaryFailureScreen from '../organisms/temporary-failure-screen'

export type SessionProviderProps = {
  children: ReactNode
}

export const SessionProvider: FunctionComponent<SessionProviderProps> = memo(function SessionProvider({ children }) {
  const [sessionResultPromise, setSessionResultPromise] = useState(sessionManager.getSessionResult())

  useEffect(() => sessionManager.onSessionRefreshing(setSessionResultPromise), [])

  const [sessionResult, setSessionResult] = useState<SessionResult | null>(null)

  useEffect(() => {
    let cancelled = false
    sessionResultPromise.then(
      (result) => {
        if (!cancelled) setSessionResult(result)
      },
      () => {
        if (!cancelled) setSessionResult(null)
      },
    )
    return () => {
      cancelled = true
    }
  }, [sessionResultPromise])

  if (!sessionResult) {
    return <GlobalLoadingState />
  }

  if (isFailure(sessionResult)) {
    if (sessionResult.problem === 'client_outdated') {
      return <ForceUpdateDialog />
    }
    return <TemporaryFailureScreen />
  }

  const session = sessionResult.output

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
})
