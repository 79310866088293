import type { Product } from '@orus.eu/product'
import type { AbstractDimension, TypeOfDimension } from './abstract-dimension'
import { StringsWithDataEnumDimension } from './strings-with-data-enum-dimension'

export const mutaPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'mutaPricingVersion',
  displayValues: { name: 'Version du prix de la mutuelle Acheel' },
  entries: [['muta-1.0-2024-11-19', { label: 'Version 1' }]],
} as const)

export type MutaPricingVersion = TypeOfDimension<typeof mutaPricingVersionDimension>

export const rcdaPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'rcdaPricingVersion',
  displayValues: { name: 'Version du prix de la responsabilité civile décennale Axeria' },
  entries: [
    ['rcda-1.0-2024-11-19', { label: 'Version 1' }],
    ['rcda-1.1-2025-01-08', { label: 'Version 1.1 - Fix history takeover' }],
  ],
} as const)

export type RcdaPricingVersion = TypeOfDimension<typeof rcdaPricingVersionDimension>

export const mrphPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'mrphPricingVersion',
  displayValues: { name: 'Version du prix de la multirisque Hiscox' },
  entries: [
    ['mrph-1.0-2024-11-19', { label: 'Version 1' }],
    ['mrph-1.1-2024-11-22', { label: 'Version 1.1 - MaJ CatNat' }],
  ],
} as const)

export type MrphPricingVersion = TypeOfDimension<typeof mrphPricingVersionDimension>

export const rcphPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'rcphPricingVersion',
  displayValues: { name: 'Version du prix de la responsabilité civile Hiscox' },
  entries: [
    ['rcph-1.0-2024-11-19', { label: 'Version 1' }],
    ['rcph-1.1-2024-11-22', { label: 'Version 1.1 - MaJ CatNat' }],
    ['rcph-1.2-2024-12-12', { label: 'Version 1.2 - Fix premium overcharge' }],
  ],
} as const)

export type RcphPricingVersion = TypeOfDimension<typeof rcphPricingVersionDimension>

export const mrpwPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'mrpwPricingVersion',
  displayValues: { name: 'Version du prix de la multirisque Wakam' },
  entries: [
    ['mrpw-1-2024-11-19', { label: 'MRPW v1 / (Wakam API v2) - MRPW Rest v1' }],
    ['mrpw-1.2-2024-11-19', { label: 'MRPW v1.2 (Wakam API v3) - MRPW Rest v2' }],
    ['mrpw-2-2024-11-19', { label: 'MRPW v2 (Wakam API v6) - Retailers' }],
    /**
     * mrpw-2.1-2025-03-23 History
     *
     * On 01/01/2025 at midnight, Wakam forcefully pushed an update to their V6 API
     * to account for the CAT NAT increase, without coordinating with us.
     *
     * As of 20/01/2025, we have two issues:
     * 1. Renewal
     *    - Since there is no change in the pricing version, nothing special happens
     *      when creating a renewal.
     * 2. 2024 Contract Endorsements
     *    - When we create an endorsement on a contract signed in 2024, it applies
     *      the CAT NAT increase even though it shouldn’t.
     *
     * At our request, Wakam provided an API V7, which implements the pricing prior
     * to the CAT NAT increase.
     *
     * Approach to solving these issues:
     *
     * 1. Solve the renewal issue by creating a new Wakam pricing version
     *    "mrpw-2.1-2025-03-23" that:
     *    - Points to Wakam API V6 (the post-CAT NAT increase API).
     *    - Is effective from 23/03/2025 (must be in the future but, late enough to warn customers in advance).
     *
     * 2. Solve the 2024 endorsements issue by:
     *    - Pointing all old Wakam pricing versions to the V7 API
     *      (the pre-CAT NAT increase API).
     *    - Modifying previous pricing versions so they use the V6 API
     *      (with the CAT NAT increase) for subscriptions whose initial signature price
     *      was calculated after 01/01/2025.
     *    - This last detail is necessary so that an endorsement on a contract signed
     *      between 01/01/2025 and 01/23/2025 does not trigger a price recalculation.
     */
    ['mrpw-2.1-2025-03-23', { label: 'MRPW v2 (Wakam API v6)' }],
  ],
} as const)

export type MrpwPricingVersion = TypeOfDimension<typeof mrpwPricingVersionDimension>

export const esRcphPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'esRcphPricingVersion',
  displayValues: { name: 'Version du prix de la responsabilité civile Hiscox Espagne' },
  entries: [['es-rcph-1.0-2024-11-19', { label: 'Version 1' }]],
} as const)

export type EsRcphPricingVersion = TypeOfDimension<typeof esRcphPricingVersionDimension>

export const rcpaPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'rcpaPricingVersion',
  displayValues: { name: 'Version du prix de la responsabilité civile AIG' },
  entries: [['rcpa-1.0-2025-01-16', { label: 'Version 1' }]],
} as const)

export type RcpaPricingVersion = TypeOfDimension<typeof rcpaPricingVersionDimension>

export type PricingVersion =
  | MutaPricingVersion
  | RcdaPricingVersion
  | MrphPricingVersion
  | RcphPricingVersion
  | MrpwPricingVersion
  | EsRcphPricingVersion
  | RcpaPricingVersion

export const pricingVersionDimensionsByProduct = {
  muta: mutaPricingVersionDimension,
  rcda: rcdaPricingVersionDimension,
  mrph: mrphPricingVersionDimension,
  rcph: rcphPricingVersionDimension,
  mrpw: mrpwPricingVersionDimension,
  'es-rcph': esRcphPricingVersionDimension,
  rcpa: rcpaPricingVersionDimension,
} as const satisfies Record<Product, AbstractDimension>
