import { css } from '@emotion/react'
import type { RcphProduct } from '@orus.eu/dimensions'

import { ensureError } from '@orus.eu/error'
import type { OperatingZone } from '@orus.eu/operating-zone'
import {
  Avatar,
  LegacyDialog,
  RowButtonV2,
  RowContainerV2,
  Text,
  TextField,
  colors,
  spacing,
  useCrash,
  useUiContext,
} from '@orus.eu/pharaoh'
import type { Product } from '@orus.eu/product'
import { memo, useEffect, useState, type ChangeEvent } from 'react'
import { trpc } from '../../../../../client'

export type Activity = { id: string; name: string; description: string }

type ActivityDialogProps = {
  operatingZone: OperatingZone
  onAddActivity: (activity: Activity) => void
  onClose: () => void
  product: Product
  rcphProduct: RcphProduct | undefined
  allSelectedActivityIds: Set<string>
}

const placeholderbyProduct: { [K in Product]?: string } = {
  rcda: 'Maçonnerie',
  rcph: 'Data engineer',
  // TODO-RCPA
  rcpa: 'Agent Immobilier',
}

export const ActivityDialog = memo<ActivityDialogProps>(function ActivityDialog({
  operatingZone,
  allSelectedActivityIds,
  product,
  rcphProduct,
  onClose,
  onAddActivity,
}) {
  const [search, setSearch] = useState('')
  const [activityMatches, setActivityMatches] = useState<Activity[]>([])
  const crash = useCrash()

  useEffect(() => {
    let cancelled = false
    ;(async () => {
      const list = await trpc.selfOnboarding.getGroupsWithSelfOnboardingForProductIncludingSecondaryActivities.query({
        operatingZone,
        search,
        product,
        rcphProduct,
      })
      if (cancelled) return

      const activities: Activity[] = []
      const existingActivitiesIds = new Set<string>()

      for (const group of list) {
        if (allSelectedActivityIds.has(group.mainActivityId)) continue

        if (existingActivitiesIds.has(group.mainActivityId)) continue

        existingActivitiesIds.add(group.mainActivityId)
        activities.push({
          id: group.mainActivityId,
          name: group.name,
          description: group.description ?? '',
        })
      }

      setActivityMatches(activities)
    })().catch((err) => {
      if (cancelled) return
      crash(ensureError(err))
    })

    return () => {
      cancelled = true
    }
  }, [search, crash, allSelectedActivityIds, product, rcphProduct, operatingZone])

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newSearch = event.target.value
    setSearch(newSearch)
  }

  const onSelectedActivity = (activity: Activity): void => {
    onAddActivity(activity)
  }

  const variant = useUiContext()

  return (
    <LegacyDialog fullWidth header={<Text variant="h3">Ajouter une autre activité</Text>} onClose={onClose}>
      <div
        css={css`
          min-height: ${variant === 'desktop' ? '760px' : ''};
        `}
      >
        <TextField
          size="large"
          placeholder={placeholderbyProduct[product] ?? placeholderbyProduct['rcda']}
          value={search}
          onChange={handleSearchChange}
          aria-label="Rechercher une autre activité"
          InputProps={{
            startAdornment: (
              <Avatar
                variant="contained"
                icon="magnifying-glass-regular"
                containerColor="transparent"
                color={colors.blue[900]}
                size="50"
              />
            ),
          }}
          autoFocus
          css={css`
            margin-top: ${spacing[60]};
          `}
        />

        <RowContainerV2
          css={css`
            margin-top: ${spacing[60]};
          `}
        >
          {activityMatches.map((activity) => (
            <RowButtonV2
              key={activity.id}
              primaryText={activity.name}
              secondaryText={activity.description}
              onClick={() => onSelectedActivity(activity)}
            />
          ))}
        </RowContainerV2>
      </div>
    </LegacyDialog>
  )
})
