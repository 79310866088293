import { createFileRoute } from '@tanstack/react-router'

import GuaranteesPage from '../../components/pages/guarantees-page'
import CustomerPage from '../../components/templates/customer-page'

export const Route = createFileRoute('/contract-guarantees')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <GuaranteesPage />
    </CustomerPage>
  ),
})
