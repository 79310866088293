import { Editor, Element, Node, Path, Transforms, type BaseEditor } from 'slate'

import type { ReactEditor } from 'slate-react'
import { createParagraphNode } from '../utils/create-paragraph-node'
const withKeyCommands = (editor: BaseEditor & ReactEditor): BaseEditor & ReactEditor => {
  const { deleteBackward, insertBreak, isVoid } = editor

  editor.deleteBackward = (...args) => {
    if (!editor.selection) return

    const [parentNode, parentPath] = Editor.parent(editor, editor.selection.focus.path)

    if (Element.isElement(parentNode)) {
      if (isVoid(parentNode) || !Node.string(parentNode).length) {
        Transforms.removeNodes(editor, { at: parentPath })

        // Ensure at least one node remains => slate content cannot be empty array
        if (editor.children.length === 0) {
          Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{ text: '' }],
          })
        }
      } else {
        deleteBackward(...args)
      }
    }
  }

  editor.insertBreak = (...args) => {
    if (!editor.selection) return
    const [parentNode, parentPath] = Editor.parent(editor, editor.selection.focus.path)
    if (Element.isElement(parentNode)) {
      if (isVoid(parentNode)) {
        const nextPath = Path.next(parentPath)
        Transforms.insertNodes(editor, createParagraphNode(), {
          at: nextPath,
          select: true,
        })
      } else {
        insertBreak(...args)
      }
    }
  }

  return editor
}

export default withKeyCommands
