import type { ClaimsContactInformation } from '@orus.eu/backend/src/services/claim-contact-service'
import type { OrusSessionUser } from '@orus.eu/backend/src/services/session/session-types'
import { formatDdMmYyyy } from '@orus.eu/calendar-date'
import { TechnicalError } from '@orus.eu/error'
import type { AvatarDecorativeProps } from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import { trpc } from '../../../client'
import { isProductionEnvironment } from '../../../lib/env-utils'
import { openTallyPopup } from '../../../lib/tally-util'

export const avatars: {
  [k in ClaimsContactInformation['type']]: {
    avatar: AvatarDecorativeProps['icon']
    avatarBackgroundColor: AvatarDecorativeProps['backgroundColor']
  }
} = {
  decennialLiability: { avatar: 'shovel-light', avatarBackgroundColor: 'periwinkle' },
  healthCoverage: { avatar: 'stethoscope-light', avatarBackgroundColor: 'peach' },
  publicLiability: { avatar: 'book-open-cover-light', avatarBackgroundColor: 'sky' },
  legalProtection: { avatar: 'scale-balanced-light', avatarBackgroundColor: 'peach' },
  assistance: { avatar: 'bell-concierge-light', avatarBackgroundColor: 'mindaro' },
  multiriskCoverage: { avatar: 'warehouse-light', avatarBackgroundColor: 'jasmine' },
}

export const getReportClaimTallyId = (
  type: ClaimsContactInformation['type'],
  language: Language,
): string | undefined => {
  switch (type) {
    case 'assistance':
    case 'multiriskCoverage':
      if (language === 'fr') return 'mKPzqz'
      throw new Error('Not implemented')
    case 'legalProtection':
    case 'publicLiability':
      if (language === 'es') return 'w41B4r'
      if (language === 'fr') return 'm61eJN'
      throw new Error('Not implemented')
    case 'decennialLiability':
      if (language === 'fr') return 'wMrDK8'
      throw new Error('Not implemented')
    case 'healthCoverage':
      return undefined
  }
}

export const onOpenReportClaimTally = async (
  type: ClaimsContactInformation['type'],
  subscriptionId: string,
  user: OrusSessionUser | null,
): Promise<void> => {
  if (!user) {
    throw new TechnicalError('User is required to open the Tally popup')
  }

  const [contract] = await trpc.contracts.getMyContract.query(subscriptionId)

  const formId = getReportClaimTallyId(type, contract.operatingZone)

  if (!formId) {
    throw new Error('FormId is required to open the Tally popup')
  }

  openTallyPopup(formId, {
    layout: 'modal',
    hiddenFields: {
      isProductionEnvironment: isProductionEnvironment(),
      userId: user.id,
      emailAddress: encodeURIComponent(contract.email),
      phone: encodeURIComponent(contract.phone),
      firstName: user.firstName,
      lastName: user.lastName,
      contractNumber: contract.contractNumber,
      subscriptionId: contract.subscriptionId,
      companyName: encodeURIComponent(contract.companyName),
      companyAddress: encodeURIComponent(contract.companyAddress),
      startDate: formatDdMmYyyy(contract.startDate),
      activityName: encodeURIComponent(contract.activity.displayName),
      options: encodeURIComponent(contract.productsInformation.map((product) => product.product).join('\n')),
      activitiesList: encodeURIComponent(
        contract.activitiesList.map((activity) => `- ${activity.displayName}`).join('\n'),
      ),
      customRcphActivity: encodeURIComponent(contract.customRcphActivity ?? ''),
      hasMrpwAssistance: contract.hasMrpwAssistance,
    },
  })
}
