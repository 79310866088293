import { createFileRoute } from '@tanstack/react-router'

import PaymentMethodNewPage from '../../../components/pages/payment-method-new-page'
import CustomerPage from '../../../components/templates/customer-page'

export const Route = createFileRoute('/payment-methods/new')({
  component: () => (
    <CustomerPage requiredPermissions={['paymentAccount.update']}>
      <PaymentMethodNewPage />
    </CustomerPage>
  ),
})
