import { createFileRoute } from '@tanstack/react-router'

import PlatformDocumentationPage from '../../../components/pages/backoffice/platform/platform-documentation/index'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/documentation/')({
  component: () => (
    <PlatformPage requiredPermissions={[]}>
      <PlatformDocumentationPage />
    </PlatformPage>
  ),
})
