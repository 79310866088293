import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Button, ButtonLink, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { BackofficeSubsectionTitle } from '../../../../../atoms/backoffice-subsection-title'
import { BackofficeSubscriptionInvoicingConfigurationCard } from '../../../../../organisms/backoffice-subscription-invoing-configuration'
import { ContractInvoicesList } from './components/ContractInvoicesList'

export const ContractInvoicesBlock = memo<{
  contract: BackofficeContractDescription
  canCreateNewInvoice: boolean
  canSeeInvoiceDebugInformation: boolean
}>(function InvoicesBlock({ contract, canCreateNewInvoice, canSeeInvoiceDebugInformation }) {
  return (
    <div>
      <TitleSectionRow>
        <BackofficeSubsectionTitle>Factures</BackofficeSubsectionTitle>
        <div
          css={css`
            display: flex;
            gap: ${spacing[40]};
            margin-top: ${spacing[80]};
          `}
        >
          {canSeeInvoiceDebugInformation ? (
            <Button variant="secondary" size="small" href={`/bak/contracts/${contract.subscriptionId}/invoicing`}>
              Debug de la facturation
            </Button>
          ) : (
            <></>
          )}
          {canCreateNewInvoice ? (
            <ButtonLink
              variant="secondary"
              size="small"
              icon="plus-solid"
              to="/bak/contracts/$subscriptionId/new-invoice"
              params={{ subscriptionId: contract.subscriptionId }}
            >
              Nouvelle facture
            </ButtonLink>
          ) : null}
        </div>
      </TitleSectionRow>
      {canCreateNewInvoice ? (
        <BackofficeSubscriptionInvoicingConfigurationCard subscriptionId={contract.subscriptionId} />
      ) : null}
      <Text variant="caption">
        Les nouvelles factures sont générées avec l’algorithme v{contract.invoicingAlgorithmVersion}.
      </Text>
      <ContractInvoicesList subscriptionId={contract.subscriptionId} />
    </div>
  )
})

const TitleSectionRow = styled.div`
  display: flex;
  justify-content: space-between;
`
