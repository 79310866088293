import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { useMemo } from 'react'
import type { CompleteGroupLoader } from '../../../../../lib/group-mapping-util'
import { useApi } from '../../../../../lib/use-api/use-api'
import { ActivitySearchField } from './ActivitySearchField'

type MainActivitySearchFieldProps = {
  operatingZone: OperatingZone
  groupLoader: CompleteGroupLoader
  disabled?: boolean
  onChange: (group: GroupData) => void
}

export function MainActivitySearchField({
  operatingZone,
  groupLoader,
  disabled,
  onChange,
}: MainActivitySearchFieldProps): React.ReactNode {
  const activitiesResponse = useApi(groupLoader.getGroups)
  const activityList = useMemo(
    () =>
      activitiesResponse.ready ? activitiesResponse.data.filter((group) => group.operatingZone === operatingZone) : [],
    [activitiesResponse.data, activitiesResponse.ready, operatingZone],
  )

  return (
    <ActivitySearchField
      searchOptions={activityList}
      onChange={onChange}
      disabled={!activitiesResponse.ready || disabled}
    />
  )
}
