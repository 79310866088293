import {
  evaluateCondition,
  quoteEditorSections,
  type PartialNullable,
  type QuoteEditorSection,
  type QuoteEditorTabsTitle,
} from '@orus.eu/dimensions'

import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager.js'
import { isPermission, type OrganizationType, type Permission } from '@orus.eu/right-access-management'
import { isProductionEnvironment } from '../../../../../../lib/env-utils'

type QuoteEditorUiTabs = Map<QuoteEditorTabsTitle, Map<string, QuoteEditorSection[]>>

type UiSectionsFilterParams = {
  section: (typeof quoteEditorSections)[number]
  state: PartialNullable<SubscriptionDimensionnedState>
  permissions: Permission[]
  isPartner: boolean
  isContractSigned: boolean
  subscriptionOrganizationType: OrganizationType | undefined
  currentOrganizationTechnicalName: string | undefined
  currentOrganizationCanEditPartnerFees: boolean | undefined
}

const uiSectionsFilter = ({
  section,
  state,
  permissions,
  isPartner,
  isContractSigned,
  subscriptionOrganizationType,
  currentOrganizationTechnicalName,
  currentOrganizationCanEditPartnerFees,
}: UiSectionsFilterParams) => {
  if (section.hideOnProductionEnv && isProductionEnvironment()) return false
  if (section.permission && !isPermission(section.permission)) return false
  if (section.permission && isPermission(section.permission) && !permissions.includes(section.permission)) return false
  if (section.hideForPartners && isPartner) return false
  if (section.onlyForPartnersSubscription && subscriptionOrganizationType !== 'partner') return false
  if (section.onlyForBrokersSubscription && subscriptionOrganizationType !== 'broker') return false
  if (section.onlyForNonOrusSubscription && currentOrganizationTechnicalName === 'orus') return false
  if (section.condition) return evaluateCondition(state, section.condition)
  if (section.hideWhenCannotEditFees && isPartner && !currentOrganizationCanEditPartnerFees) return false

  if (
    section.bodyElements
      .map((bodyElement) => {
        const condition = bodyElement.condition
        return condition ? evaluateCondition(state, condition) : true
      })
      .every((condition) => condition === false)
  )
    return false

  if (section.whenContractSigned && !isContractSigned) return false
  if (section.whenContractNotSigned && isContractSigned) return false

  return true
}

export const getQuoteEditorUiTabs = ({
  state,
  permissions,
  isPartner,
  subscriptionOrganizationType,
  isContractSigned,
  currentOrganizationTechnicalName,
  currentOrganizationCanEditPartnerFees,
}: Omit<UiSectionsFilterParams, 'section'>): QuoteEditorUiTabs =>
  quoteEditorSections
    .filter((section) =>
      uiSectionsFilter({
        section,
        state,
        permissions,
        isPartner,
        subscriptionOrganizationType,
        isContractSigned,
        currentOrganizationTechnicalName,
        currentOrganizationCanEditPartnerFees,
      }),
    )
    .reduce((previous, curr) => {
      const tab = curr.backofficeUi.tab
      const retrievedTab = previous.get(tab)
      //If there's no tab, we set the tab, add the breadcrumb and the element
      if (!retrievedTab) {
        previous.set(tab, new Map<string, QuoteEditorSection[]>([[curr.backofficeUi.breadcrumb, [curr]]]))
        return previous
      }

      const retrievedBreadcrumb = retrievedTab.get(curr.backofficeUi.breadcrumb)
      //If there's no breadcrumb, we add the breadcrumb and the element
      if (retrievedBreadcrumb === undefined) {
        retrievedTab.set(curr.backofficeUi.breadcrumb, [curr])
        return previous
      }

      //If there's a breadcrumb, we add the element
      retrievedBreadcrumb.push(curr)

      return previous
    }, new Map<QuoteEditorTabsTitle, Map<string, QuoteEditorSection[]>>() as QuoteEditorUiTabs)
