import { createFileRoute } from '@tanstack/react-router'

import { PendingSubscriptionPaymentPage } from '../../../../../components/pages/backoffice/common/backoffice-pending-subscriptions/pending-subscription-payment-page'
import PlatformPage from '../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/pending-subscriptions/$subscriptionId/payment')({
  component: () => (
    <PlatformPage requiredPermissions={['contracts.sign']}>
      <PendingSubscriptionPaymentPage />
    </PlatformPage>
  ),
})
