import type { SubscriptionUiElement } from '@orus.eu/dimensions'
import { memo, type NamedExoticComponent } from 'react'
import type { CommonSubscriptionProps, StateProxy } from './subscription-v2-props'

/**
 * This HOC takes a component that requires access to state and transforms
 * it into a component that does not by simply displaying nothing when the
 * state is currently loading.
 *
 * This allows us to immediately display static components, such as breadcrumbs
 * and various banners while data is being loaded from the server for dynamic
 * components.
 *
 * @param Component
 * @returns
 */
export function ifStateProxy<UiElement extends SubscriptionUiElement>(
  Component: (
    props: CommonSubscriptionProps & {
      stateProxy: StateProxy
      uiElement: UiElement
    },
  ) => JSX.Element,
): NamedExoticComponent<
  CommonSubscriptionProps & {
    stateProxy: StateProxy | undefined
    uiElement: UiElement
  }
> {
  return memo<
    CommonSubscriptionProps & {
      stateProxy: StateProxy | undefined
      uiElement: UiElement
    }
  >(function IfStateProxy(props): JSX.Element {
    if (!props.stateProxy) return <></>
    return <Component {...props} stateProxy={props.stateProxy} />
  })
}
