import { TechnicalError } from '@orus.eu/error'

export type LogMetadata = Record<string, string | number | boolean | null>

export type LoggingTransport = {
  error?(error: Error): void
  event?(event: string, metadata: LogMetadata): void
}

// Very simple rate limiting mechanism to protect against accidental flooding of messages.
let remainingMessages = 10
setInterval(() => {
  remainingMessages = 10
}, 3600_000)

class Logger {
  private transports: Array<LoggingTransport> = []

  /**
   * Reports a problem. This is always logged to the console, and additionally sent to sentry in production and review environments.
   */
  error(error: Error): void {
    this.transports.forEach((transport) => transport.error?.(error))

    // for dev
    // eslint-disable-next-line no-console
    console.error(error)
    if (error instanceof TechnicalError) {
      // eslint-disable-next-line no-console
      console.error('Context data of the error above :', error.context ?? 'No context attached to the error')
    }
  }

  /**
   * Adds a transport that will be notified of errors and events.
   */
  addTransport(transport: LoggingTransport): void {
    this.transports.push(transport)
  }

  /**
   * Sends an event to the server for logging purpose.
   * @param event
   * @param metadata
   */
  event(event: string, metadata: LogMetadata = {}): void {
    if (remainingMessages-- <= 0) return

    this.transports.forEach((transport) => transport.event?.(event, metadata))
  }

  info(msg: string, metadata: LogMetadata = {}): void {
    // same logic as on the backend ("logger.info is syntactic sugar")
    this.event('info', { msg, ...metadata })

    // for dev
    // eslint-disable-next-line no-console
    console.info(msg, metadata)
  }

  warning(msg: string, metadata: LogMetadata = {}): void {
    this.event('warning', { msg, ...metadata })

    // for dev
    // eslint-disable-next-line no-console
    console.warn(msg, metadata)
  }
}

export const logger = new Logger()
