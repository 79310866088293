import { createFileRoute } from '@tanstack/react-router'

import PaymentMethodUpdate from '../../../components/pages/payment-method-update'
import CustomerPage from '../../../components/templates/customer-page'

export const Route = createFileRoute('/payment-methods/update')({
  component: () => (
    <CustomerPage requiredPermissions={['paymentAccount.update']}>
      <PaymentMethodUpdate />
    </CustomerPage>
  ),
})
