import { createFileRoute } from '@tanstack/react-router'

import PlatformTroubleshootingSessionPage from '../../../../../components/pages/backoffice/platform/platform-troubleshooting-session'
import PlatformPage from '../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/troubleshooting/session/$sessionId')({
  component: () => (
    <PlatformPage requiredPermissions={['tech.configurations']}>
      <PlatformTroubleshootingSessionPage />
    </PlatformPage>
  ),
})
