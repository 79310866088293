import styled from '@emotion/styled'
import type { DetailedSheetUiElement } from '@orus.eu/dimensions'
import { Badge, ButtonLink, colorTokens, spacing, Tooltip } from '@orus.eu/pharaoh'
import { useParams, type NavigateOptions } from '@tanstack/react-router'
import { usePermissions } from '../../../../../lib/use-permissions'
import { detailedSheetStatusEntries } from '../../../backoffice/detailed-sheet-page/detailed-sheet.types'
import {
  detailedSheetCompleteStatusBadgeVariantColor,
  detailedSheetCompleteStatusText,
} from '../../../backoffice/utils/detailed-sheet-common'
import { ifStateProxy } from '../../if-state-proxy'

export const DetailedSheetUiElementBlock = ifStateProxy<DetailedSheetUiElement>(function DetailedSheetUiElementBlock({
  stateProxy,
  uiElement,
}) {
  const { subscriptionId, endorsementId } = useParams({ strict: false })

  const isPlatform = usePermissions().type === 'platform'

  const detailedSheetCompleteStatus = stateProxy.read(uiElement.dimensions.detailedSheetCompleteStatus)
  const detailedSheetExperienceStatus = stateProxy.read(uiElement.dimensions.detailedSheetExperienceStatus)

  const detailedSheetNavigateOptions: NavigateOptions = endorsementId
    ? {
        to: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet',
        params: { subscriptionId, endorsementId },
      }
    : {
        to: '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
        params: { subscriptionId },
      }

  return isPlatform ? (
    <Container>
      <Tooltip title={detailedSheetStatusEntries[detailedSheetExperienceStatus ?? 'pioupiou'].description}>
        <EmojiStatusContainer>
          {detailedSheetStatusEntries[detailedSheetExperienceStatus ?? 'pioupiou'].emoji}
        </EmojiStatusContainer>
      </Tooltip>
      <ButtonLink variant="secondary" size="small" avatarPosition="left" {...detailedSheetNavigateOptions}>
        Ouvrir la fiche détaillée
      </ButtonLink>
      <Badge variant={detailedSheetCompleteStatusBadgeVariantColor[detailedSheetCompleteStatus ?? 'pending']} dark>
        {detailedSheetCompleteStatusText[detailedSheetCompleteStatus ?? 'pending']}
      </Badge>
    </Container>
  ) : (
    <></>
  )
})

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[40]};
`

const EmojiStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorTokens['color-bg-neutral-inverse']};
  border-radius: ${spacing[30]};
  padding: ${spacing[30]};
  height: 32px;
  width: 32px;
  font-size: 13px;
`
