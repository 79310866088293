import { createFileRoute } from '@tanstack/react-router'

import PlatformSearchPage from '../../../components/pages/backoffice/platform/platform-search-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/search')({
  component: () => (
    <PlatformPage requiredPermissions={['search']}>
      <PlatformSearchPage />
    </PlatformPage>
  ),
})
