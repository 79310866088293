import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { type GuaranteeDetailUiElement, type ProductInformation } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull, TechnicalError } from '@orus.eu/error'
import type { OperatingZone } from '@orus.eu/operating-zone'
import {
  borderRadius,
  Button,
  colorTokens,
  shadow,
  spacing,
  Text,
  useOpenChat,
  useScreenType,
  useTranslate,
  type ScreenType,
} from '@orus.eu/pharaoh'
import { GuaranteesTable } from '@orus.eu/pharaoh/src/components/features/quote-v3'
import type { GuaranteesSection } from '@orus.eu/pharaoh/src/components/features/quote-v3/guarantees-table'
import { useNavigate } from '@tanstack/react-router'
import { memo, useEffect, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { helpContactDetails } from '../../../../lib/help-contact-details'
import { useNavigateBack } from '../../../../lib/hooks/use-navigate-back'
import { GuaranteeModal } from '../../../organisms/guarantee/guarantee-list'
import { OptionalGuaranteeModalDisplayer } from '../../../organisms/guarantee/option-list'
import { useProductInformation } from '../../subscription/shared-use-offer'
import { computeGuaranteesSection } from '../../subscription/utils'
import { ifStateProxy } from '../if-state-proxy'

export const GuaranteeDetailSubscriptionElementUiBlock = ifStateProxy<GuaranteeDetailUiElement>(
  function GuaranteeDetailSubscriptionElementUiBlock({ uiElement, stateProxy, detail, subscriptionId }) {
    const screenType = useScreenType()
    const navigateBack = useNavigateBack()
    const navigate = useNavigate()

    if (!detail) {
      throw new TechnicalError(
        'The GuaranteeDetailUiElement can only be used in the detail section of a type "guaratee"',
      )
    }

    const offerInformation = stateProxy.readRequired(uiElement.dimensions.offerInformation)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const productName = checkDefinedAndNotNull(detail).id

    const productInformation = offerInformation.products.find((product) => product.name === productName)

    useEffect(() => {
      if (!productInformation)
        void navigate({ to: '/subscribe/$subscriptionId/$stepId', params: { subscriptionId, stepId: 'quote' } })
    }, [navigate, productInformation, subscriptionId])

    const guaranteesSection: GuaranteesSection[] = useMemo(() => {
      if (!productInformation) return []
      return computeGuaranteesSection(productInformation.guarantees, productInformation.offers)
    }, [productInformation])

    //When productInformation is undefined, the user is redirected to the quote page
    if (!productInformation) return <></>

    return (
      <GuaranteeDetail
        productInformation={productInformation}
        operatingZone={operatingZone}
        navigateBack={navigateBack}
        guaranteesSection={guaranteesSection}
        screenType={screenType}
      />
    )
  },
)

type GuaranteeDetailProps = {
  productInformation: ProductInformation
  navigateBack: () => void
  guaranteesSection: GuaranteesSection[]
  screenType: ScreenType
  operatingZone: OperatingZone
}

const GuaranteeDetail = memo<GuaranteeDetailProps>(function GuaranteeDetail(props) {
  const openChat = useOpenChat()
  const translate = useTranslate()
  const { productInformation, navigateBack, guaranteesSection, screenType, operatingZone } = props

  const {
    selectedGuaranteeState,
    selectedOptionState,
    onGuaranteeClick,
    onInfoOptionClick,
    options,
    perOptionSubOptions,
  } = useProductInformation(productInformation)

  const [selectedGuarantee] = selectedGuaranteeState
  const [selectedOption] = selectedOptionState

  const containerRef = useRef<HTMLDivElement>(null)
  const { ref: triggerRef, inView } = useInView({
    threshold: 1,
    onChange: (inView) => containerRef.current?.classList?.toggle?.('is-pinned', !inView),
  })

  return (
    <Container>
      <Text
        variant="h3"
        css={css`
          margin-top: ${spacing[50]};
          margin-bottom: ${spacing[80]};
        `}
      >
        {translate('guarantees_table')}
      </Text>
      <GuaranteesTable
        guaranteesSection={guaranteesSection}
        options={options}
        perOptionSubOptions={perOptionSubOptions}
        variant={screenType}
        includedGuaranteesCount={productInformation.guarantees.length}
        onContactClick={openChat}
        onGuaranteeClick={onGuaranteeClick}
        onInfoOptionClick={onInfoOptionClick}
        quotePageType="static"
        isMuta={productInformation.product === 'muta'}
        helpContactDetails={helpContactDetails[operatingZone]}
      />
      {selectedGuarantee ? <GuaranteeModal guarantee={selectedGuarantee} onClose={navigateBack} /> : <></>}
      {selectedOption ? (
        <OptionalGuaranteeModalDisplayer
          optionalGuarantee={selectedOption}
          onClose={navigateBack}
          addButton={undefined}
          quotePageType="static"
        />
      ) : (
        <></>
      )}
      <div ref={triggerRef}></div>

      {screenType === 'mobile' ? (
        <ButtonContainer inView={inView}>
          <GoBackButton
            variant="secondary"
            size="large"
            icon="arrow-left-regular"
            avatarPosition="left"
            onClick={navigateBack}
            className="previous-button"
          >
            {translate('back')}
          </GoBackButton>
        </ButtonContainer>
      ) : (
        <></>
      )}
    </Container>
  )
})

const Container = styled.div`
  margin-bottom: ${spacing[100]};
`

const ButtonContainer = styled.div<{ inView: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${spacing[30]};

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${spacing[50]} ${spacing[60]};
  background-color: ${colorTokens['color-bg-base-normal']};
  border-radius: ${borderRadius[30]} ${borderRadius[30]} 0 0;
  box-shadow: ${({ inView }) => (!inView ? shadow.top[20] : 'none')};
  z-index: 100;
`

const GoBackButton = styled(Button)`
  flex-grow: 1;
`
