import styled from '@emotion/styled'
import { Select, useAsyncCallback, useEnqueueTemporaryNotificationAlert, useTranslate } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { memo, useMemo } from 'react'
import { trpcReact } from '../../client'
import { usePermissions } from '../../lib/use-permissions'
import { ChipOrganizations } from './chip-organisations'

export const EditableChipOrganizations = memo<{
  subscriptionId: string
}>(function EditableChipOrganizations({ subscriptionId }) {
  const { permissions } = usePermissions()
  const canChangeOrganization = permissions.includes('contracts.updateOrganization')
  const organization = trpcReact.organizations.getOrganizationInfoBySubscriptionId.useQuery(subscriptionId)

  if (!organization.data) return <></>

  return (
    <>
      {canChangeOrganization ? (
        <SelectOrganizations subscriptionId={subscriptionId} />
      ) : (
        <ChipOrganizations organizations={[organization.data.technicalName]} />
      )}
    </>
  )
})

const SelectOrganizations = memo<{
  subscriptionId: string
}>(function SelectOrganizations({ subscriptionId }) {
  const translate = useTranslate()

  const { enqueueTemporaryNotificationAlert } = useEnqueueTemporaryNotificationAlert()

  const organization = trpcReact.organizations.getOrganizationInfoBySubscriptionId.useQuery(subscriptionId)
  const organizations = trpcReact.organizations.getOrganizations.useQuery()

  const changeOrganizationMutation = trpcReact.organizations.applyOwnershipOnSubscription.useMutation({
    onSuccess: () => organization.refetch(),
  })

  const handleChange = useAsyncCallback(
    async (value: string | null) => {
      if (!value) return

      const result = await changeOrganizationMutation.mutateAsync({
        subscriptionId,
        organizationTechnicalName: value,
      })

      if (isFailure(result)) {
        enqueueTemporaryNotificationAlert(translate('update_contract_organization_error'), {
          variant: 'danger',
        })
      } else {
        enqueueTemporaryNotificationAlert(translate('update_contract_organization_success'), {
          variant: 'success',
        })
      }
    },
    [subscriptionId, enqueueTemporaryNotificationAlert, translate, changeOrganizationMutation],
  )

  const organizationsNamesLabelsAndValues = Object.entries(organizations.data ?? {}).map(([key, organization]) => ({
    value: key,
    label: organization.displayName,
  }))

  const sortedOrganizationsValues = useMemo(
    () => organizationsNamesLabelsAndValues.map((organization) => organization.value).sort(),
    [organizationsNamesLabelsAndValues],
  )

  if (!organizations.data || !organization.data) return <></>

  return (
    <StyledSelect>
      <Select
        value={organization.data.technicalName}
        labels={Object.fromEntries(
          organizationsNamesLabelsAndValues.map((organization) => [organization.value, organization.label]),
        )}
        values={sortedOrganizationsValues}
        onChange={handleChange}
        size="small"
        aria-label="Organisation"
      />
    </StyledSelect>
  )
})

const StyledSelect = styled.div`
  flex: 1;
  min-width: 200px;
`
