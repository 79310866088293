import { BaseDimension } from '../abstract-dimension'
import { StringsEnumDimension } from '../strings-enum-dimension'

export const rcpaOffers = ['NIVEAU_1', 'NIVEAU_2', 'NIVEAU_3', 'NIVEAU_4'] as const
export type RcpaOffer = (typeof rcpaOffers)[number]

export const rcpaOfferDimension = new StringsEnumDimension({
  name: 'rcpaOffer',
  displayValues: { name: "Niveau d'offre RC Pro AIG" },
  tags: ['RCPA'],
  values: rcpaOffers,
} as const)

export class RcpaAvailableOffersDimension<NAME extends string> extends BaseDimension<NAME, RcpaOffer[]> {}

export const rcpaAvailableOffersDimension = new RcpaAvailableOffersDimension({
  name: 'rcpaAvailableOffers',
  displayValues: { name: 'Offres disponibles' },
  tags: ['RCPA'],
} as const)
