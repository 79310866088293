import { css } from '@emotion/react'
import { groupsCategories, isGroupsCategory, type GroupDefinitionsUpdateV2 } from '@orus.eu/activity'
import { TechnicalError } from '@orus.eu/error'
import { Button, sectionDelimitersBorder, spacing, useAsyncCallback, useCrash } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { trpc, trpcReact } from '../../../../../client'
import { copyToClipboard } from '../../../../../lib/clipboard-util'
import { useNavigateToHome } from '../../../../../lib/hooks/use-navigate-to-home'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { ensureMonacoWorkerInitialized } from '../platform-editor/monaco-worker'
import { ChangesReviewDialog } from './changes-review-dialog'
import { computeDiff, isDiffEmpty, type GroupDefinitionsUpdateDiff } from './diff'
import { buildGroupsActivitiesExport, buildGroupsAliasesExport } from './export'
import { formatGroupDefinitions, parseGroupDefinitions } from './formatting'
import { ensureActivityEditorHintsInitialized } from './inlay-hints'

export const BackofficeActivitiesEditor = memo(function BackofficeActivitiesEditor() {
  const crash = useCrash()
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null)
  const monacoEl = useRef(null)
  const groupsApiResult = trpcReact.activities.getGroupDefinitions.useQuery()
  const [changesToReview, setChangesToReview] = useState<GroupDefinitionsUpdateDiff | undefined>(undefined)
  const [submitting, setSubmitting] = useState(false)
  const initialGroups = groupsApiResult.status === 'success' && groupsApiResult.data ? groupsApiResult.data : undefined
  const initialEditorContent =
    groupsApiResult.status === 'success' && groupsApiResult.data
      ? formatGroupDefinitions(groupsApiResult.data)
      : undefined
  const navigateToHome = useNavigateToHome()
  const [groupsCategoryVisible, setGroupsCategoryVisible] = useState(false)

  useEffect(() => {
    if (monacoEl) {
      setEditor((editor) => {
        if (editor) return editor

        const currentMonacoEl = monacoEl.current
        if (!currentMonacoEl) {
          crash(new TechnicalError('expected monacoEl.current to be set'))
          return null
        }

        ensureMonacoWorkerInitialized()
        ensureActivityEditorHintsInitialized()

        return monaco.editor.create(currentMonacoEl, {
          value: '', // content loaded asynchronously from server
          language: 'json',
          automaticLayout: true,
        })
      })
    }

    return () => editor?.dispose()
  }, [crash, editor])

  useEffect(() => {
    if (initialEditorContent && editor) {
      editor.setValue(initialEditorContent)
    }
  }, [editor, initialEditorContent])

  const cancelChanges = useCallback(() => {
    if (initialEditorContent && editor) {
      editor.setValue(initialEditorContent)
    }
  }, [editor, initialEditorContent])

  const format = useCallback(() => {
    if (!editor) return

    const stringValue = editor.getValue()
    const parsingResult = parseGroupDefinitions(stringValue)
    if (isFailure(parsingResult)) {
      alert(parsingResult.problem)
      return
    }
    editor.setValue(formatGroupDefinitions(parsingResult.output))
  }, [editor])

  const openChangesReviewDialog = useCallback(() => {
    if (!editor || !initialGroups) return

    const stringValue = editor.getValue()
    const parsingResult = parseGroupDefinitions(stringValue)
    if (isFailure(parsingResult)) {
      alert(parsingResult.problem)
      return
    }

    const diff = computeDiff(initialGroups, parsingResult.output)
    if (isDiffEmpty(diff)) {
      alert("Il n'y a aucun changement !")
      return
    }

    setChangesToReview(diff)
  }, [editor, initialGroups])

  const submitChanges = useAsyncCallback(
    async (changes: GroupDefinitionsUpdateV2) => {
      setSubmitting(true)
      await trpc.activities.applyGroupsChanges.mutate(changes)
      alert('Changements sauvés et actifs !')
      navigateToHome()
    },
    [navigateToHome],
  )

  const closeChangesReviewDialog = useCallback(() => {
    setChangesToReview(undefined)
  }, [])

  const exportGroups = useAsyncCallback(async () => {
    if (!editor) return

    const stringValue = editor.getValue()
    const parsingResult = parseGroupDefinitions(stringValue)
    if (isFailure(parsingResult)) {
      alert(parsingResult.problem)
      return
    }
    const exportContent = await buildGroupsActivitiesExport(parsingResult.output)
    await copyToClipboard(exportContent)
    alert('Groups activities copied to clipboard in a spreadsheet-friendly format')
  }, [editor])

  const exportAliases = useAsyncCallback(async () => {
    if (!editor) return

    const stringValue = editor.getValue()
    const parsingResult = parseGroupDefinitions(stringValue)
    if (isFailure(parsingResult)) {
      alert(parsingResult.problem)
      return
    }
    const exportContent = await buildGroupsAliasesExport(parsingResult.output)
    await copyToClipboard(exportContent)
    alert('Groups aliases copied to clipboard in a spreadsheet-friendly format')
  }, [editor])

  if (submitting) {
    return <GlobalLoadingState />
  }

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 ${spacing[60]} ${spacing[40]} ${spacing[60]};
            display: flex;
            gap: ${spacing[40]};
          `}
        >
          <Button
            icon="copy-regular"
            avatarPosition="left"
            title="Copier les activités des groupes"
            size="small"
            variant="secondary"
            onClick={exportGroups}
          >
            Groupes
          </Button>
          <Button
            icon="copy-regular"
            avatarPosition="left"
            title="Copier les alias des groupes"
            size="small"
            variant="secondary"
            onClick={exportAliases}
          >
            Alias
          </Button>
          <Button
            icon="book-open-regular"
            avatarPosition="left"
            size="small"
            variant="secondary"
            onClick={() => {
              setGroupsCategoryVisible(!groupsCategoryVisible)
            }}
          >
            {groupsCategoryVisible ? 'Cacher les catégories des groupes' : 'Voir les catégories des groupes'}
          </Button>
          <Button
            icon="arrow-rotate-left-solid"
            size="small"
            avatarPosition="left"
            variant="secondary"
            onClick={cancelChanges}
          >
            Annuler les modifications
          </Button>
          <Button icon="file-lines-regular" size="small" avatarPosition="left" variant="secondary" onClick={format}>
            Formatter
          </Button>
          <Button
            icon="magnifying-glass-solid"
            size="small"
            avatarPosition="left"
            variant="primary"
            onClick={openChangesReviewDialog}
          >
            Revoir et tester les changements
          </Button>
        </div>
        {groupsCategoryVisible ? <GroupsCategoriesList /> : undefined}
        <div
          css={css`
            flex-grow: 1;
            flex-shrink: 1;
            border-top: ${sectionDelimitersBorder};
          `}
          ref={monacoEl}
        />
      </div>
      {changesToReview ? (
        <ChangesReviewDialog onSubmit={submitChanges} diff={changesToReview} onClose={closeChangesReviewDialog} />
      ) : (
        <></>
      )}
    </>
  )
})

const GroupsCategoriesList = memo(function GroupsCategoriesList() {
  return (
    <div
      css={css`
        padding-left: ${spacing[60]};
        padding-bottom: ${spacing[40]};
      `}
    >
      {Object.keys(groupsCategories).map((groupsCategoryKey) => {
        if (isGroupsCategory(groupsCategoryKey)) {
          const groupsCategory = groupsCategories[groupsCategoryKey]
          return (
            <p key={groupsCategoryKey}>
              <code>{groupsCategoryKey}</code> : {groupsCategory.displayName} -{' '}
              {groupsCategory.visible ? 'seen by client' : undefined}
            </p>
          )
        }

        return undefined
      })}
    </div>
  )
})
