import { css } from '@emotion/react'
import { ratesEqual, X0, X1, type FinancialRate } from '@orus.eu/amount'
import type { FinancialRateSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  PersistentNotification,
  RowButtonV2,
  RowContainerV2,
  spacing,
  useLanguage,
  ValidatedTextFieldFormField,
} from '@orus.eu/pharaoh'
import { SwitchContainer } from '@orus.eu/pharaoh/src/components/atoms/switch-container'
import { TagBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { financialRateCustomInputProps, getFinancialRateMapper } from '../../../../lib/financial-rate'
import { ifStateProxy } from '../if-state-proxy'

export const FinancialRateSubscriptionUiElementBlock = ifStateProxy<FinancialRateSubscriptionUiElement>(
  function FinancialRateSubscriptionUiElementBlock({ uiElement, stateProxy, context, handleSubmit }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const value = stateProxy.read(dimension)
    const handleChange = stateProxy.useWrite(dimension)
    const [displayYesNo, setDisplayYesNo] = useState(uiElement.variant === 'yes-no' && value == undefined)
    const [financialRateConfig] = useState(uiElement.dimension.configuration)
    const revealRateField = useCallback(() => setDisplayYesNo(false), [setDisplayYesNo])

    const setRateToZeroAndSubmit = useCallback(() => {
      handleSubmit(undefined, { [uiElement.dimension.name]: X0 })
    }, [handleSubmit, uiElement])

    const mapper = useMemo(() => {
      return getFinancialRateMapper(financialRateConfig)
    }, [financialRateConfig])

    useEffect(() => {
      if (uiElement.variant !== 'boolean') return
      if (value !== undefined) return
      stateProxy.write<FinancialRate>(dimension, X0)
    }, [uiElement.variant, value, stateProxy, dimension])

    if (uiElement.variant === 'boolean') {
      return (
        <SwitchContainer
          checked={value !== undefined && !ratesEqual(value, X0)}
          onChange={(newValue) => stateProxy.write<FinancialRate>(dimension, newValue ? X1 : X0)}
          size="large"
        >
          {uiElement.label}
        </SwitchContainer>
      )
    }

    if (uiElement.variant === 'yes-no') {
      if (displayYesNo && context !== 'backoffice') {
        return (
          <div>
            {uiElement.yesNoHint ? (
              <PersistentNotification
                variant="info"
                css={css`
                  margin-bottom: ${spacing[50]};
                `}
              >
                {uiElement.yesNoHint}
              </PersistentNotification>
            ) : (
              <></>
            )}
            <RowContainerV2>
              <RowButtonV2 onClick={setRateToZeroAndSubmit} primaryText="Non" />
              <RowButtonV2 onClick={revealRateField} primaryText="Oui" />
            </RowContainerV2>
          </div>
        )
      }
    }

    return (
      <ValidatedTextFieldFormField
        size={context === 'backoffice' ? 'small' : 'large'}
        label={dimension.displayNames[language]}
        badge={context === 'backoffice' ? <TagBadge tag={uiElement.dimension.tags?.[0]} /> : <></>}
        value={value}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
        onChange={handleChange}
        mapper={mapper}
        infoTooltip={dimension.hints ? dimension.hints[language] : undefined}
        InputProps={financialRateCustomInputProps}
        required={uiElement.required}
        highlight={uiElement.highlight}
      />
    )
  },
)
