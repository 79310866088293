import { groupDefinitionsSchema, type GroupDefinitions, type ZoneGroupDefinitions } from '@orus.eu/activity'
import { failure, isFailure, success, type Result } from '@orus.eu/result'

export function parseGroupDefinitions(dataString: string): Result<GroupDefinitions, string> {
  const jsonParsingResult = parseJson(dataString)
  if (isFailure(jsonParsingResult)) return jsonParsingResult
  const validationResult = validateGroupDefinitions(jsonParsingResult.output)
  if (isFailure(validationResult)) return validationResult

  return success(normalizeGroupDefinitions(validationResult.output))
}

export function formatGroupDefinitions(value: GroupDefinitions): string {
  return JSON.stringify(normalizeGroupDefinitions(value), null, 2)
}

export function normalizeGroupDefinitions(value: GroupDefinitions): GroupDefinitions {
  return {
    fr: normalizeZoneGroupDefinitions(value.fr),
    es: normalizeZoneGroupDefinitions(value.es),
  }
}

export function normalizeZoneGroupDefinitions(value: ZoneGroupDefinitions): ZoneGroupDefinitions {
  const sortedKeys = Object.keys(value).sort()
  return Object.fromEntries(
    sortedKeys.map((key) => {
      return [key, value[key]]
    }),
  )
}

function parseJson(dataString: string): Result<unknown, string> {
  try {
    return success(JSON.parse(dataString))
  } catch (_err: unknown) {
    return failure("La syntaxe JSON n'est pas respectée. Corrige d'abord les lignes en rouge")
  }
}

function validateGroupDefinitions(data: unknown): Result<GroupDefinitions, string> {
  const zodResult = groupDefinitionsSchema.safeParse(data)
  if (zodResult.success) return success(zodResult.data)
  return failure(`Le format des données n'est pas respecté. Détails : ${zodResult.error.message}`)
}
