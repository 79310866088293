import { useSearch } from '@tanstack/react-router'
import { useCallback } from 'react'

/**
 * This hook helps forwarding utm_* parameters to the urls.
 * Use it when we building a link to an external page that is managed by Orus, so
 * we can analyse the traffic across our pages.
 */
export function useForwardUtm(): (href: string) => string {
  const searchParams = useSearch({ strict: false })
  return useCallback(
    (href: string) => {
      const url = new URL(href)
      const urlSearchParams = new URLSearchParams(url.search)
      for (const [key, value] of Object.entries(searchParams)) {
        if (key.startsWith('utm_')) {
          urlSearchParams.set(key, String(value))
        }
      }
      url.search = urlSearchParams.toString()
      return url.toString()
    },
    [searchParams],
  )
}
