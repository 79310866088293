import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { colors } from '../../../colors.js'
import { borderRadius, colorTokens, spacing } from '../../../index.js'
import { AvatarDecorative, Text, TextContainer, type AvatarDecorativeProps } from '../../atoms/index.js'
import { Button, PhoneNumber } from '../../index.js'

export type ClaimCardProps = {
  avatar: AvatarDecorativeProps['icon']
  avatarBackgroundColor: AvatarDecorativeProps['backgroundColor']
  title: string
  description: string
  phoneNumber?: string
  email?: string
}

export const ClaimCard = memo<ClaimCardProps>(function ClaimCard(props) {
  const { phoneNumber, email, title, description, avatar, avatarBackgroundColor } = props

  return (
    <Container>
      <AvatarDecorative
        css={css`
          flex-shrink: 0;
        `}
        size="60"
        icon={avatar}
        backgroundColor={avatarBackgroundColor}
      />

      <TextWrapper>
        <Text variant="h6" color={colorTokens['color-text-base-main']}>
          {title}
        </Text>
        <Text variant="body2" color={colorTokens['color-text-base-basic']}>
          {description}
        </Text>
      </TextWrapper>

      {phoneNumber || email ? (
        <ButtonsContainer>
          {phoneNumber ? (
            <Button
              variant="secondary"
              size={'large'}
              fullWidth
              icon="phone-regular"
              avatarPosition="left"
              trackingId="call_button"
              href={`tel:${encodeURIComponent(phoneNumber)}`}
            >
              <PhoneNumber>{phoneNumber}</PhoneNumber>
            </Button>
          ) : null}

          {email ? (
            <Button variant="secondary" size={'large'} fullWidth href={`mailto:${encodeURIComponent(email)}`}>
              <Text variant="button">{email}</Text>
            </Button>
          ) : null}
        </ButtonsContainer>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[70]};
  background: ${colors.white};
  border: ${colorTokens['color-stroke-base']} 1px solid;
  border-radius: ${borderRadius[30]};
`

const TextWrapper = styled(TextContainer)`
  margin-top: ${spacing[40]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};
`

const ButtonsContainer = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  margin-top: ${spacing[90]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`
