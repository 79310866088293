import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import PlatformPage from '../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet')({
  component: () => (
    <PlatformPage requiredPermissions={['subscription.read']}>
      <DetailedSheetPage />
    </PlatformPage>
  ),
})
