import { css } from '@emotion/react'
import { amountToString } from '@orus.eu/amount'
import { formatDdMmYyyy, parseYyyyMmDd } from '@orus.eu/calendar-date'
import { getCompanyIdNumberSirenValue } from '@orus.eu/company-id-number'
import { type OverviewSectionState } from '@orus.eu/dimensions'
import { Badge, Divider, RowContainerV2, spacing } from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo } from 'react'
import { LABEL_EXP_DIRIGEANT } from '../../constants'
import type { ComplianceData } from '../../detailed-sheet.types'
import { getScoreColors, LYSTA_SCORE_COLORS } from '../widget/helper/get-score-badge-variant'

type OverviewInfo = [
  'name',
  'be',
  'siren',
  'companyName',
  'companyCommercialName',
  'lysta',
  'workforce',
  'estimationCAHT',
  'statutJuridique',
  'expDirigeant',
  'creationDate',
]

const GRID_TEMPLATE_COLUMNS: { type: OverviewInfo[number]; label: string; width: string }[] = [
  { type: 'name', label: 'Nom et prénom', width: '2fr' },
  { type: 'be', label: 'BE', width: '1fr' },
  { type: 'siren', label: 'SIREN', width: '2fr' },
  { type: 'companyName', label: 'Raison sociale', width: '4.5fr' },
  { type: 'companyCommercialName', label: 'Nom commercial', width: '4.5fr' },
]

const GRID_TEMPLATE_COLUMNS_2: { type: OverviewInfo[number]; label: string; width: string }[] = [
  { type: 'lysta', label: 'Lysta', width: '1fr' },
  { type: 'workforce', label: 'Effectif', width: '1fr' },
  { type: 'estimationCAHT', label: 'CA HT', width: '2fr' },
  { type: 'statutJuridique', label: 'Statut juridique', width: '3fr' },
  { type: 'expDirigeant', label: 'Exp. du dirigeant', width: '3fr' },
  { type: 'creationDate', label: 'Date de création', width: '3fr' },
]

const EMPTY_STRING = ' '

type OverviewGlobalInfoSectionProps = {
  state: OverviewSectionState
  complianceData: ComplianceData
}

const getValue = (state: OverviewSectionState, type: OverviewInfo[number], complianceData: ComplianceData) => {
  switch (type) {
    case 'name':
      return state.firstName || state.lastName
        ? `${[state.firstName ?? '', state.lastName ?? ''].join(' ')}`
        : EMPTY_STRING
    case 'be':
      return complianceData.pappersCompany?.beneficialOwners
        ? complianceData.pappersCompany.beneficialOwners.length
        : EMPTY_STRING
    case 'siren': {
      const sirenValue = getCompanyIdNumberSirenValue(state.companyIdNumber)
      return sirenValue ? sirenValue : EMPTY_STRING
    }
    case 'companyName':
      return state.companyName && state.companyName !== '' ? state.companyName : EMPTY_STRING
    case 'companyCommercialName':
      return complianceData.pappersCompany
        ? complianceData.pappersCompany.commercialName
          ? complianceData.pappersCompany.commercialName
          : '-'
        : EMPTY_STRING
    case 'lysta':
      return complianceData.lystaCompany?.trustworthiness.score != null ? (
        <Badge
          dark
          variant={getScoreColors({
            score: complianceData.lystaCompany?.trustworthiness.score,
            scoreColors: LYSTA_SCORE_COLORS,
          })}
        >
          {complianceData.lystaCompany?.trustworthiness.score}
        </Badge>
      ) : (
        EMPTY_STRING
      )
    case 'workforce':
      return state.workforce ?? EMPTY_STRING
    case 'estimationCAHT':
      return state.estimatedRevenue?.__encodedAmount
        ? amountToString(state.estimatedRevenue, { addCurrency: true, displayDecimals: false })
        : EMPTY_STRING
    case 'statutJuridique':
      return complianceData.pappersCompany?.legalForm ? complianceData.pappersCompany.legalForm : EMPTY_STRING
    case 'expDirigeant':
      return state.rcdaBossExperience ? LABEL_EXP_DIRIGEANT[state.rcdaBossExperience] : EMPTY_STRING
    case 'creationDate':
      if (state.companyCreationDate && complianceData.pappersCompany)
        return [
          `${formatDdMmYyyy(state.companyCreationDate)} (BO)`,
          `${formatDdMmYyyy(parseYyyyMmDd(complianceData.pappersCompany.registrationDate))} (Pappers)`,
        ].join('\n')
      if (state.companyCreationDate) return `${formatDdMmYyyy(state.companyCreationDate)} (BO)`
      if (complianceData.pappersCompany)
        return `${formatDdMmYyyy(parseYyyyMmDd(complianceData.pappersCompany.registrationDate))} (Pappers)`
      return EMPTY_STRING
    default:
      return EMPTY_STRING
  }
}

const OverviewGlobalInfoSection = memo(function OverviewGlobalInfoSection(props: OverviewGlobalInfoSectionProps) {
  const gridTemplateColumns = GRID_TEMPLATE_COLUMNS.map((col) => col.width).join(' ')
  const gridTemplateColumns2 = GRID_TEMPLATE_COLUMNS_2.map((col) => col.width).join(' ')

  const { state, complianceData } = props

  return (
    <RowContainerV2
      css={css`
        display: grid;
      `}
    >
      <RowContainerV2
        css={css`
          border: none;
          display: grid;
          grid-template-columns: ${gridTemplateColumns};
        `}
      >
        {GRID_TEMPLATE_COLUMNS.map((column, index) => (
          <div
            key={index}
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Row
              title={column.label}
              titleVariant="body2Medium"
              subtitle={getValue(state, column.type, complianceData)}
              subtitleVariant="body2"
              variant="descriptive"
              css={css`
                padding: ${spacing[40]};
                align-items: start;
              `}
            />
            {index !== GRID_TEMPLATE_COLUMNS.length - 1 ? (
              <Divider
                orientation="vertical"
                css={css`
                  width: 4px;
                  height: 100%;
                `}
              />
            ) : null}
          </div>
        ))}
      </RowContainerV2>
      <Divider
        orientation="horizontal"
        css={css`
          width: 100%;
        `}
      />
      <RowContainerV2
        css={css`
          border: none;
          display: grid;
          grid-template-columns: ${gridTemplateColumns2};
        `}
      >
        {GRID_TEMPLATE_COLUMNS_2.map((column, index) => (
          <div
            key={index}
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Row
              title={column.label}
              titleVariant="body2Medium"
              subtitle={getValue(state, column.type, complianceData)}
              subtitleVariant="body2"
              variant="descriptive"
              css={css`
                padding: ${spacing[40]};
                align-items: start;
              `}
            />
            {index !== GRID_TEMPLATE_COLUMNS_2.length - 1 ? (
              <Divider
                orientation="vertical"
                css={css`
                  width: 4px;
                  height: 100%;
                `}
              />
            ) : null}
          </div>
        ))}
      </RowContainerV2>
    </RowContainerV2>
  )
})

export default OverviewGlobalInfoSection
