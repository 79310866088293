import {
  mrphGuaranteeOfferFactory,
  mrphGuaranteeTitle,
  mrphGuaranteesTypeNames,
  mrpwGuaranteeDetails,
  mrpwGuaranteesTypeNames,
  mutaGuaranteesGroups,
  mutaProductDetails,
  offersDescriptions,
  rcProCyberOptionalGuaranteeNames,
  rcProCyberOptionalGuaranteeTitle,
  rcProGuaranteeNames,
  rcProGuaranteeOfferFactory,
  rcProOptionalGuaranteeTitle,
  rcdaGuarantees,
  rcdaGuaranteesProductInformation,
  rcphProductVisibleGuarantees,
  type ActivableProductInformationId,
  type ProductInformationId,
} from '@orus.eu/dimensions'
import { type AvatarDecorativeProps } from '@orus.eu/pharaoh'
import { guaranteeImages } from '../../../../organisms/guarantee/guarantee-images'

export const productsAvatarConfigurations: Record<
  ProductInformationId,
  Required<Pick<AvatarDecorativeProps, 'icon' | 'backgroundColor'>>
> = {
  mrph: { icon: 'warehouse-light', backgroundColor: 'jasmine' },
  mrpw: { icon: 'shop-light', backgroundColor: 'jasmine' },
  restaurant: { icon: 'user-chef-light', backgroundColor: 'jasmine' },
  'rcph-pj': { icon: 'scale-balanced-light', backgroundColor: 'peach' },
  'rcph-cyber': { icon: 'shield-halved-light', backgroundColor: 'mindaro' },
  'rcph-tdmi': { icon: 'laptop-mobile-light', backgroundColor: 'periwinkle' },
  'mrp-small-office': { icon: 'house-light', backgroundColor: 'mindaro' },
  'rc-pro': { icon: 'book-open-light', backgroundColor: 'sky' },
  muta: { icon: 'stethoscope-light', backgroundColor: 'peach' },
  rcda: { icon: 'shovel-light', backgroundColor: 'periwinkle' },
  'rcda-rc-pro': { icon: 'book-open-light', backgroundColor: 'sky' },
  'es-rcph-pro': { icon: 'book-open-light', backgroundColor: 'sky' },
  'es-rcph-general': { icon: 'book-open-light', backgroundColor: 'sky' },
  rcpa: { icon: 'book-open-light', backgroundColor: 'sky' },
}

export const productsShortNames: Record<ProductInformationId, string> = {
  mrph: 'multirisque',
  mrpw: 'multirisque',
  restaurant: 'multirisque',
  'rcph-pj': 'protection juridique',
  'rcph-cyber': 'cyber',
  'rcph-tdmi': 'Protection matériel',
  'mrp-small-office': 'Protection petit local',
  'rc-pro': 'RC professionnelle',
  muta: 'mutuelle santé',
  rcda: 'RC décennale',
  'rcda-rc-pro': 'RC professionnelle',
  'es-rcph-pro': 'Responsabilidad civil profesional',
  'es-rcph-general': 'Responsabilidad civil profesional',
  rcpa: 'RC professionnelle reglementée',
}

export const productDetailsData = {
  mrpw: {
    name: 'Multirisque',
    description: `
      Nous couvrons votre établissement ainsi que les biens contenus. Cela signifieque
      que vous bénéficiez d'une protection financière en cas d’événements imprévus qui
      pourraient l’endommager, par exemple en cas d’incendie, de dégât des eaux…
    `,
    coverage: getMrpwGuaranteesAndOptions(),
    included: [
      'Le local commercial, en cas de dommages sur le bâtiment et sur le contenu.',
      'La perte des stocks et des marchandises contenus dans le local.',
      'Le mobilier stocké à l’intérieur et à l’extérieur du local.',
    ],
    excluded: [
      'Les bâtiments de stockage ou de dépôts séparés du local principal.',
      'Les locaux à usage d’habitation, les châteaux, manoirs et les locaux de plus de 500 m².',
      'Les locaux situés dans un centre commercial de plus de 3 000 m².',
    ],
  },

  muta: {
    name: 'Mutuelle',
    description: `
      Nous couvrons vos soins santé, comme les frais d'optiques, dentaires et
      médecines douces, qu’il y ait dépassements d'honoraires ou non. Les taux
      de remboursement seront déterminés selon votre choix de formule pendant
      le devis.
    `,
    coverage: getMutaGuaranteesAndOptions(),
    included: [
      'Les frais médicaux, soins courants, hospitalisation, optique, dentaire, appareillage auditif.',
      'Les séances de médecine douce comme l’ostéopathie, podologie, acupuncture, diététique et nutritionniste et bien d’autres.',
      'L’assistance à tout moment, avec les téléconsultations, l’aide à domicile et accompagnement psychologique.',
    ],
    excluded: [
      'Les frais médicaux majorés sur les dépassements d’honoraires en cas de non-respect du parcours de soins.',
      'La chirurgie dans un but de rajeunissement ou esthétique, les cures d’amaigrissement, de désintoxication et de sommeil.',
      'La couverture prévoyance santé, comme pour la prise en charge des arrêts maladies ou des arrêts de travail.',
    ],
  },

  rcda: {
    name: 'RC décennale',
    description: `
      Nous couvrons sur les dommages touchant une construction, tel les fondations et ossatures destinés à une habitation
      ou non. Également de viabilité (réseaux, assainissement) et de voirie (chemin d'accès) ainsi que l’équipement et le
      matériel stocké sur les chantiers.
    `,
    coverage: getRcdaGuaranteesAndOptions(),
    included: [
      'La solidité de l’ouvrage comme un risque d’effondrement.',
      'L’ouvrage est impropre à sa destination comme un chauffage au sol défaillant.',
      'Les dysfonctionnements d’équipements après réception des travaux, comme une VMC défectueuse survenue au cours des 2 ans.',
    ],
    excluded: [
      'Le dommage a été causé volontairement par le maître d’ouvrage.',
      'Le sinistre ou le désordre est en lien avec l’usure du bâtiment.',
      'Le dommage est survenu en raison d’une négligence du client, d’un mauvais entretien ou d’un mauvais usage de l’ouvrage.',
    ],
  },

  mrph: {
    name: 'Multirisque Local',
    description: `
      Nous couvrons vos bureaux ou ateliers ainsi que les biens contenus. Cela signifie
      que vous bénéficiez d'une protection financière en cas d’événements imprévus qui
      pourraient l’endommager, par exemple en cas d’incendie, de dégât des eaux…
    `,
    coverage: getMrphGuaranteesAndOptions(),
    included: [
      'Le local, en cas de dommages sur le bâtiment et sur le contenu.',
      'La perte des stocks et des marchandises contenus dans le local.',
      'Le matériel informatique, le matériel et le mobilier professionnel du local.',
    ],
    excluded: [
      'Les bâtiments de stockage ou de dépôts séparés du local principal.',
      'Les locaux à usage d’habitation, les châteaux, les manoirs et les locaux de plus de 500 m².',
      'Les locaux situés dans un centre commercial de plus de 3 000 m².',
    ],
  },

  'rc-pro': {
    name: 'RC professionnelle',
    description: `
      Nous couvrons votre activité, les erreurs et négligences commises par vous-même ou l’un de vos salariés.
    `,
    coverage: getRcphGuaranteesAndOptions(),
    included: [
      'Les manquements contractuels et fautes professionnelles, produits non conformes ou défectueux.',
      'La divulgation d’informations confidentielles, atteintes à la vie privée et à la propriété intellectuelle, dénigrement et diffamation.',
      'Les pertes et destructions de biens et documents confiés.',
    ],
    excluded: [
      "Les dommages causés par la négligence délibérée ou la mauvaise foi, à l'assuré lui-même ou à ses employés.",
      'Les pertes financières ou les bénéfices manquants sur vos prestations.',
      'La protection de votre matériel informatique (option supplémentaire).',
    ],
  },

  'rcph-cyber': {
    name: 'Cyber',
    description: `
      Nous vous couvrons contre les conséquences financières des attaques informatiques
      telles que les cyberattaques ou le piratage de votre site web ou de vos données.
    `,
    coverage: getRcphCyberGuaranteesAndOptions(),
    included: [
      'Les honoraires des experts tels les frais d’avocat, de gestion de communication de crise et de récupération des données.',
      'Les honoraires des avocats qui vont vous défendre lorsque que vous faites face à une enquête d’une autorité administrative (CNIL).',
      'Le remboursement des rançons que vous avez dû payer sous la contrainte aux pirates en cas de Cyber extorsions.',
    ],
    excluded: [
      'Les pertes dues à des actes frauduleux commis par l’entrepreneur, des employés ou des membres de l’entreprise.',
      'Les dommages physiques causés à votre matériel informatique et vos serveurs réseaux (option protection matériel).',
      'Les cyberattaques préalablement identifiées avant votre souscription à Orus ou intentionnelles.',
    ],
  },

  'rcph-tdmi': {
    name: 'Protection matériel',
    description: `
      Nous couvrons les dommages et le vol du matériel informatique, photographique
      et plus généralement tous les appareils de bureautique et de télécommunication
      utilisés pour les besoins de vos activités professionnelles.
    `,
    coverage: getRcphTdmiGuaranteesAndOptions(),
    included: [
      'Les dommages causés sur votre matériel informatique et bureautique.',
      'Les dommages causés sur votre matériel photographique (appareils, objectifs, etc.).',
      'Le remplacement ou la réparation du matériel endommagé. Les frais de location de matériel de remplacement pendant les réparations.',
    ],
    excluded: [
      'Les dommages ou vols ayant lieu en Corse ou hors de France métropolitaine.',
      'Les téléphones portables et smartphones.',
      'Les dommages causés hors du cadre professionnel (par exemple si vous êtes photographe et utilisez votre matériel photo pendant vos vacances).',
    ],
  },

  'es-rcph-pro': {
    name: 'Responsabilidad civil profesional',
    description: `
      Cubrimos su actividad, los errores y negligencias cometidos por usted o alguno de sus empleados.
    `,
    coverage: undefined,
    included: [
      'Los incumplimientos contractuales y faltas profesionales, productos no conformes o defectuosos.',
      'La divulgación de información confidencial, violaciones a la privacidad y a la propiedad intelectual, y difamación.',
      'Las pérdidas y destrucción de bienes y documentos confiados.',
    ],
    excluded: [
      'Los daños causados por negligencia deliberada o mala fe, al asegurado mismo o a sus empleados.',
      'Las pérdidas financieras o los beneficios perdidos sobre sus servicios.',
    ],
  },

  'es-rcph-general': {
    name: 'Responsabilidad civil general',
    description:
      'La Responsabilidad civil general te protege si alguien te reclama por daños que hayas causado. Esto puede incluir daños a cosas, lesiones a personas y otros perjuicios que ocurran mientras tú y tus empleados realizáis vuestra actividad.',
    coverage: undefined,
    included: [
      'Daños por Incendio, Explosión y Aguas',
      'Daños a objetos confiados',
      'Responsabilidad civil por contaminación accidental',
      'Responsabilidad civil subsidiaria de contratistas o subcontratistas',
    ],
    excluded: [
      'Los daños causados por negligencia deliberada o mala fe, al asegurado mismo o a sus empleados.',
      'Las pérdidas financieras o los beneficios perdidos sobre sus servicios.',
      'Daños o perjuicios consecuenciales causados por los productos, trabajos terminados o servicios prestados destinados, directa o indirectamente, a la industria aeronáutica o nuclear.',
    ],
  },
  'mrp-small-office': {
    name: 'Protection petit local',
    description: `
      Nous couvrons vos petits bureaux ainsi que les biens qu'ils contiennent.
      Vous êtes couvert contre les imprévus qui pourraient affecter vos locaux, comme un incendie, un dégât des eaux, ou un vol.
    `,
    coverage: undefined,
    included: [
      'Sinistres courants (incendie, dégâts des eaux, vol, bris de glace, etc.) avec une franchise fixe de 500 €.',
      'Matériel professionnel portable couvert dans le monde entier (ordinateurs, smartphones, tablettes, etc.).',
    ],
    excluded: [
      'Locaux professionnels de plus de 75 m².',
      'Locaux situés dans un manoir, un château ou un bâtiment classé monument historique.',
      'Bâtiments non conformes ou ne respectant pas les critères de construction en dur.',
    ],
  },
  rcpa: {
    name: 'RC professionnelle reglementée',
    description: `
      Nous couvrons votre activité, les erreurs et négligences commises par vous-même ou l’un de vos salariés.
    `,
    coverage: undefined,
    included: [],
    excluded: [],
  },
} satisfies Record<
  ActivableProductInformationId,
  {
    name: string
    description: string
    coverage: Array<{ name: string; options: Array<{ icon: string; description: string }> }> | undefined
    included: Array<string>
    excluded: Array<string>
  }
>

function getMrphGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  // poor man's groupby (Object.values + reduce)
  return Object.values(
    mrphGuaranteesTypeNames
      .map((type) => ({
        image: guaranteeImages[type],
        description: mrphGuaranteeTitle[type],
        name: mrphGuaranteeOfferFactory[type].name,
      }))
      .reduce(
        (accumulator, { image: icon, description, name }) => ({
          ...accumulator,
          [name]: {
            name,
            options: [
              ...(accumulator[name]?.options ?? []),
              {
                icon,
                description,
              },
            ],
          },
        }),
        {} as Record<string, ReturnType<typeof getMrphGuaranteesAndOptions>[number]>,
      ),
  )
}

function getMrpwGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  // poor man's groupby (Object.values + reduce)
  return Object.values(
    mrpwGuaranteesTypeNames
      // TODO we should sort by priority, but I don't know how to figure the priority
      .map((type) => ({
        image: guaranteeImages[type],
        description: mrpwGuaranteeDetails[type].shortText,
        name: mrpwGuaranteeDetails[type].offer.name,
      }))
      .reduce(
        (accumulator, { image: icon, description, name }) => ({
          ...accumulator,
          [name]: {
            name,
            options: [
              ...(accumulator[name]?.options ?? []),
              {
                icon,
                description,
              },
            ],
          },
        }),
        {} as Record<string, ReturnType<typeof getMrpwGuaranteesAndOptions>[number]>,
      ),
  )
}

function getRcdaGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  // poor man's groupby (Object.values + reduce)
  return Object.values(
    rcdaGuarantees
      // TODO we should sort by priority, but I don't know how to figure the priority
      .map((type) => ({
        image: guaranteeImages[type],
        description: rcdaGuaranteesProductInformation[type].name,
        name: rcdaGuaranteesProductInformation[type].offer.name,
      }))
      .reduce(
        (accumulator, { image: icon, description, name }) => ({
          ...accumulator,
          [name]: {
            name,
            options: [
              ...(accumulator[name]?.options ?? []),
              {
                icon,
                description,
              },
            ],
          },
        }),
        {} as Record<string, ReturnType<typeof getRcdaGuaranteesAndOptions>[number]>,
      ),
  )
}

function getMutaGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  // poor man's groupby (Object.values + reduce)
  return Object.values(
    mutaGuaranteesGroups
      // TODO we should sort by priority, but I don't know how to figure the priority
      .map((type) => ({
        image: guaranteeImages[type],
        description: mutaProductDetails[type].name,
        name: mutaProductDetails[type].offer.name,
      }))
      .reduce(
        (accumulator, { image: icon, description, name }) => ({
          ...accumulator,
          [name]: {
            name,
            options: [
              ...(accumulator[name]?.options ?? []),
              {
                icon,
                description,
              },
            ],
          },
        }),
        {} as Record<string, ReturnType<typeof getMutaGuaranteesAndOptions>[number]>,
      ),
  )
}

function getRcphGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  return Object.values(
    Object.values(rcphProductVisibleGuarantees)
      .flatMap((guarantee) => {
        return Object.entries(guarantee).map(([guaranteeType, priority]) => {
          const type = guaranteeType as keyof typeof guarantee
          return {
            type,
            name: rcProGuaranteeNames[type],
            priority,
            offer: rcProGuaranteeOfferFactory[type],
          }
        })
      })
      .sort((g1, g2) => g2.priority.localeCompare(g1.priority))
      .reduce(
        (accumulator, { type, name, offer }) => ({
          ...accumulator,
          [offer.name]: {
            name: offer.name,
            options: [
              ...(accumulator[offer.name]?.options ?? []),
              ...((accumulator[offer.name]?.options ?? []).find(({ description }) => name === description)
                ? []
                : [
                    {
                      icon: guaranteeImages[type],
                      description: name,
                    },
                  ]),
            ],
          },
        }),
        {} as Record<string, ReturnType<typeof getRcphGuaranteesAndOptions>[number]>,
      ),
  )
}

function getRcphTdmiGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  return [
    {
      name: rcProOptionalGuaranteeTitle['tdmi'],
      options: [
        {
          icon: guaranteeImages['tdmi'],
          description: rcProOptionalGuaranteeTitle['tdmi'],
        },
      ],
    },
  ]
}

function getRcphCyberGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  return [
    {
      name: offersDescriptions['cyber'].name,
      options: [
        {
          icon: guaranteeImages['cyber'],
          description: offersDescriptions['cyber'].name,
        },
      ],
    },
    {
      name: 'Options supplémentaires',
      options: rcProCyberOptionalGuaranteeNames.map((type) => ({
        icon: guaranteeImages[type],
        description: rcProCyberOptionalGuaranteeTitle[type],
      })),
    },
  ]
}
