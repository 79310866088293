import { createFileRoute } from '@tanstack/react-router'

import PlatformActivitiesPage from '../../../components/pages/backoffice/platform/platform-activities-page/platform-activities-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/activities')({
  component: () => (
    <PlatformPage requiredPermissions={['activities.read']}>
      <PlatformActivitiesPage />
    </PlatformPage>
  ),
})
