export type ErrorOptions<Context extends Jsonable = Jsonable, RequiredContext extends boolean = false> = {
  /**
   * The original cause of the error.
   *
   * It is used when catching and re-throwing an error with a more-specific
   * or useful error message in order to still have access to the original error (and stack).
   */
  cause?: Error
} & (RequiredContext extends true ? { context: Context } : { context?: Context })

export class BaseError<Context extends Jsonable = Jsonable, RequiredContext extends boolean = false> extends Error {
  public readonly context: RequiredContext extends true ? Context : Context | undefined

  constructor(
    ...args: RequiredContext extends true
      ? [message: string, options: ErrorOptions<Context, true>]
      : [message: string, options?: ErrorOptions<Context, false>]
  ) {
    const [message, options] = args
    const { cause, context } = options ?? {}

    super(message, { cause })
    this.name = this.constructor.name

    this.context = context as RequiredContext extends true ? Context : Context | undefined
  }
}

export class TechnicalError<
  Context extends Jsonable = Jsonable,
  RequiredContext extends boolean = false,
> extends BaseError<Context, RequiredContext> {}

export class AbortedError extends BaseError {}

export class AssertionError extends BaseError {}

/**
 * This utility exception can be used to get a compile-time error based on whether a value is
 * never or not.
 * Inspired from workaround solutions of https://github.com/microsoft/TypeScript/issues/38881
 * Remove this when/if the issue is closed some day.
 *
 * /!\ Do not use on the client side when processing data received from the server as it
 * will not play nice with the server-side code.
 */
export class UnreachableCaseError extends TechnicalError {
  constructor(value: never, context: Jsonable = {}) {
    super('Unreachable code reached', { context: { value, context } })
  }
}

export class NotYetImplementedError extends TechnicalError {
  constructor() {
    super('Not yet implemented')
  }
}

export class ClientOutdatedError extends BaseError {
  constructor() {
    super('Client version is outdated')
  }
}

export type Jsonable =
  | string
  | number
  | boolean
  | null
  | undefined
  | readonly Jsonable[]
  | JsonableObject
  | { toJSON(): Jsonable }

export type JsonableObject = { readonly [key: string]: Jsonable }

/**
 * @deprecated Use `assertDefinedAndNotNull` instead. checkDefinedAndNotNull is bad because
 *   - it doesn't enforce providing a clear message
 *   - it doesn't enforce giving context, which is almost always necessary
 *   - all errors are groupped in sentry (same message)
 */
export function checkDefinedAndNotNull<T>(value: T | undefined | null, context: Jsonable = {}): NonNullable<T> {
  if (value == null) {
    throw new TechnicalError('Expected defined and not null value, got null or undefined', { context })
  }
  return value
}

/**
 * Suggar syntax to assert that something is defined and not null, while providing a clear explanation
 * and context for sentry when it's not the case.
 *
 * @param value - The value which should be defined and not null.
 *
 * @param explanation - A clear explanation of what happend in the case it happens
 * from a functional standpoint.
 *   - GOOD : "While senting the D-60 renewal email, we encountered a
 *             contract with mrpwSelected set to true but no mrpwQuote"
 *   - BAD : "mrpwQuote is undefined"
 *
 * @param context - Additional context for the error sent to the sentry error to help with debugging.
 *
 * @returns The value if it is defined and not null.
 */
export function assertDefinedAndNotNull<T>(
  value: T | undefined | null,
  explanation: string,
  context: JsonableObject,
): NonNullable<T> {
  if (value == null) {
    throw new TechnicalError(explanation, { context })
  }
  return value
}
