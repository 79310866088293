import type { CollectedFileStatus } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { BadgeVariant, ChipVariant } from '@orus.eu/pharaoh'

export const LABEL_AND_VARIANT_PER_FILE_STATUS: Record<CollectedFileStatus, { label: string; variant: ChipVariant }> = {
  new: { label: 'À valider', variant: 'neutral' },
  'new-revised': { label: 'Révisé', variant: 'warning' },
  validated: { label: 'Validé', variant: 'success' },
  rejected: { label: 'Rejeté', variant: 'danger' },
  ignored: { label: 'Ignoré', variant: 'informal' },
}

export const LABEL_AND_VARIANT_PER_GROUP_STATUS: Record<
  GroupStatus,
  { label: string; variant: ChipVariant; dark: boolean }
> = {
  empty: { label: 'Vide', variant: 'neutral', dark: false },
  to_validate: { label: 'À valider', variant: 'neutral', dark: true },
  incomplete: { label: 'Incomplet', variant: 'warning', dark: true },
  validated: { label: 'Validé', variant: 'success', dark: true },
  rejected: { label: 'Rejeté', variant: 'danger', dark: true },
}

export const BADGE_LABEL_AND_VARIANT_PER_GROUP_STATUS: Record<
  GroupStatus,
  { label: string; variant: BadgeVariant; dark: boolean }
> = {
  empty: { label: 'Vide', variant: 'neutral', dark: false },
  to_validate: { label: 'À valider', variant: 'neutral', dark: true },
  incomplete: { label: 'Incomplet', variant: 'orange', dark: true },
  validated: { label: 'Validé', variant: 'green', dark: true },
  rejected: { label: 'Rejeté', variant: 'red', dark: true },
}
