import { type Descendant } from 'slate'
import { EMPTY_PARAGRAPH } from './empty-paragraph'

const EMPTY_TABLE_CELL: Descendant = {
  type: 'table-cell',
  children: [EMPTY_PARAGRAPH],
}

const VALIDATION_CELL_TEMPLATE: Descendant = {
  type: 'table-cell',
  children: [
    {
      type: 'paragraph',
      children: [
        {
          text: '',
          fontSize: '24px',
        },
      ],
    },
  ],
}

const INSURANCE_TIMELINE_TEMPLATE_ROW: Descendant = {
  type: 'table-row',
  children: [EMPTY_TABLE_CELL, EMPTY_TABLE_CELL, EMPTY_TABLE_CELL, EMPTY_TABLE_CELL, VALIDATION_CELL_TEMPLATE],
}

export const INSURANCE_TIMELINE_TEMPLATE_TABLE: Descendant[] = [
  {
    type: 'table',
    children: [
      {
        type: 'table-head',
        children: [
          {
            type: 'table-row',
            children: [
              {
                type: 'header-cell',
                colSpan: 2,
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Antécédant total',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'table-cell',
                colSpan: 3,
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'table-head',
        children: [
          {
            type: 'table-row',
            children: [
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Compagnie',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Police',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Doc édité',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: "Date d'effet",
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Résiliation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'table-body',
        children: [INSURANCE_TIMELINE_TEMPLATE_ROW],
      },
    ],
  },
]

export const DEFAULT_INSURANCE_TIMELINE_TEMPLATE_AS_STRING = JSON.stringify(INSURANCE_TIMELINE_TEMPLATE_TABLE)
