import { colorTokens, shadow, spacing } from '@orus.eu/pharaoh'
import { memo, useRef } from 'react'
import { type BaseEditor } from 'slate'
import type { ReactEditor } from 'slate-react'

import styled from '@emotion/styled'
import { CustomIconButton } from '../components/button/CustomIconButton.js'
import { BackgroundTextColor, TextColor } from '../components/icons/index.js'
import usePopup from '../hooks/usePopup.js'
import { addMarkData } from '../utils/add-mark-data.js'

const colors = [
  colorTokens['color-text-base-primary'],
  colorTokens['color-text-base-basic'],
  colorTokens['color-text-success-secondary'],
  colorTokens['color-text-warning-secondary'],
  colorTokens['color-text-danger-secondary'],
]

type ColorPickerProps = {
  format: 'color' | 'backgroundColor'
  editor: BaseEditor & ReactEditor
  onSelectColor: () => void
}

const ColorPicker = memo(function ColorPicker({ format, editor, onSelectColor }: ColorPickerProps) {
  const colorPickerRef = useRef(null)
  const [showOptions, setShowOptions] = usePopup(colorPickerRef)

  const changeColor = (event: React.MouseEvent<HTMLDivElement>, color: string) => {
    event.preventDefault()
    addMarkData(editor, { format, value: color })
    setShowOptions(false)
    onSelectColor()
  }

  const toggleOption = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setShowOptions((prev) => !prev)
  }

  return (
    <ColorPickerContainer ref={colorPickerRef}>
      <CustomIconButton active={true} onMouseDown={toggleOption}>
        {format === 'color' ? <TextColor /> : <BackgroundTextColor />}
      </CustomIconButton>
      {showOptions && (
        <ColorPickerDropdownContainer>
          {colors.map((color, index) => {
            return <ColorUnitContainer color={color} key={index} onMouseDown={(event) => changeColor(event, color)} />
          })}
        </ColorPickerDropdownContainer>
      )}
    </ColorPickerContainer>
  )
})

export default ColorPicker

const ColorPickerContainer = styled.div`
  position: relative;
`

const ColorPickerDropdownContainer = styled.div`
  position: absolute;
  background: ${colorTokens['color-bg-base-secondary']};
  left: 0;
  padding: ${spacing['40']};
  border: ${`${spacing['5']} solid ${colorTokens['color-stroke-base']}`};
  border-radius: ${spacing['30']};
  box-shadow: ${shadow.bottom[20]};
  height: fit-content;
  z-index: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  gap: ${spacing['20']};
`

const ColorUnitContainer = styled.div<{ color: string }>`
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: ${spacing['20']};
  background: ${({ color }) => color};
  border: ${`${spacing['5']} solid ${colorTokens['color-stroke-base']}`};
`
