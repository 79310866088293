import styled from '@emotion/styled'
import type { OperatorUiElement } from '@orus.eu/dimensions'
import { colorTokens, spacing, Text } from '@orus.eu/pharaoh'
import { trpcReact } from '../../../../client'
import { EditableChipOrganizations } from '../../../organisms/editable-chip-organizations'
import { ifStateProxy } from '../if-state-proxy'

export const OperatorUiElementBlock = ifStateProxy<OperatorUiElement>(function OperatorUiElementBlock({
  subscriptionId,
}) {
  const operatorEmail = trpcReact.subscriptions.getOperatorEmailForSubscription.useQuery(subscriptionId)

  if (!operatorEmail.data) return <></>

  return (
    <CreatedByRow>
      <Text variant="body2" color={colorTokens['color-text-base-basic']}>
        {operatorEmail.data}
      </Text>
      <EditableChipOrganizations subscriptionId={subscriptionId} />
    </CreatedByRow>
  )
})

const CreatedByRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-width: fit-content;
  place-content: center flex-start;
  align-items: center;
  padding: ${spacing['50']};
  gap: 16px ${spacing['50']};
  border-radius: ${spacing['30']};
  border: 1px solid ${colorTokens['color-stroke-base']};
  background: ${colorTokens['color-bg-base-normal']};
`
