import styled from '@emotion/styled'
import { nbsp } from '@orus.eu/char'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { colors } from '../../../colors'
import { spacing } from '../../../foundation/spacing-tokens'
import { FlexColumn, FlexRow, Text, TextContainer } from '../../atoms'
import { TextFieldFormField } from '../../molecules/form-field/text-field-form-field'
import { LegacyDialog } from '../../templates/dialog'

type NameAuthenticationDialogProps = {
  numberOfLettersForAuthentication?: number
  nameLength?: number
  guideNames?: JSX.Element[]
  tryAuthentication: (nameNFirstLetters: string) => void
}

export const NameAuthenticationDialog = memo(function NameAuthenticationModal(props: NameAuthenticationDialogProps) {
  const {
    numberOfLettersForAuthentication: numberOfLetters = 3,
    nameLength = numberOfLetters,
    guideNames = AuthentificationGuideNames,
    tryAuthentication,
  } = props

  const [inputValues, setInputValues] = useState<string[]>(new Array(numberOfLetters).fill('') as string[])
  const inputRefs = useRef<(HTMLDivElement | null)[]>(new Array(numberOfLetters))

  const [showInvalidInputState, setShowInvalidInputState] = useState(false)

  const checkForAuthentication = useCallback(() => {
    tryAuthentication(inputValues.join(''))
    if (inputValues.join('').length === numberOfLetters) {
      setShowInvalidInputState(true)
    }
  }, [inputValues, setShowInvalidInputState, tryAuthentication, numberOfLetters])

  useEffect(() => {
    // We try authentication if all input fields are filled or if the name is shorter than the number of input fields but
    // all the corresponding input fields are filled.
    if (
      inputValues.join('').length === numberOfLetters ||
      (nameLength < numberOfLetters && inputValues.slice(0, nameLength).every((letter) => letter !== ''))
    ) {
      checkForAuthentication()
    }
  }, [inputValues, checkForAuthentication, numberOfLetters, nameLength])

  // onkeyDown is not well supported on phones, so we use onChange instead to get the values
  const handleLetterInput = (index: number, value: string) => {
    // Only allow letters and backspace
    if ((!/^[A-Za-z]+$/.test(value) || value.length !== 1) && value !== 'Backspace') return

    setShowInvalidInputState(false)

    setInputValues((pred) => {
      const res = [...pred]
      if (value === 'Backspace') {
        res[index] = ''
      } else {
        res[index] = value.toUpperCase()
      }
      return res
    })

    if (index > 0 && value === 'Backspace') {
      inputRefs.current[index - 1]?.focus()
    }

    if (index < inputValues.length - 1 && value !== 'Backspace') {
      inputRefs.current[index + 1]?.focus()
    }
  }

  // we still use onKeyDown to handle the backspace key
  const handleKeyDown = (key: string, index: number) => {
    if (key === 'Backspace') {
      handleLetterInput(index, 'Backspace')
    }
  }

  return (
    <LegacyDialog onClose={() => {}} hideHeader maxWidth="xs">
      <ColumnContainer>
        <Text variant="subtitle">{`Entrez les ${numberOfLetters} premières lettres de votre nom${nbsp}de famille :`}</Text>
        <FlexRowWithMaxWidth>
          {inputValues.map((inputValue, index) => (
            <TextFieldFormField
              inputRef={(ref) => (inputRefs.current[index] = ref)}
              centerContent
              key={index}
              size="large"
              aria-label={`Lettre ${index + 1} de votre nom de famille`}
              value={inputValue}
              autoFocus={index === 0}
              error={showInvalidInputState}
              onChange={(event) => handleLetterInput(index, event.target.value)}
              onKeyDown={(event) => handleKeyDown(event.key, index)}
            />
          ))}
        </FlexRowWithMaxWidth>
        <ErrorText variant="caption">
          {showInvalidInputState ? 'Les lettres que vous avez renseignées sont invalides.' : ''}
        </ErrorText>
        {guideNames.length ? (
          <TextContainer variant="body2">
            {guideNames.map((name) => (
              <Text key={name.key}>{name}</Text>
            ))}
          </TextContainer>
        ) : undefined}
      </ColumnContainer>
    </LegacyDialog>
  )
})

const ColumnContainer = styled(FlexColumn)`
  margin-top: ${spacing[50]};
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: ${spacing[40]};

  & > * {
    text-align: center;
  }
`

const FlexRowWithMaxWidth = styled(FlexRow)`
  max-width: 220px;
  gap: ${spacing[40]};
  margin-top: ${spacing[50]};
`

const ErrorText = styled(Text)`
  color: ${colors.red[600]};
  height: 1em;
`

const AuthentificationGuideNames: JSX.Element[] = [
  <Text key="guideName1">
    Amélie <strong>Mor</strong>eau : <strong>MOR</strong>
  </Text>,
  <Text key="guideName2">
    Alice <strong>de V</strong>aux : <strong>DEV</strong>
  </Text>,
  <Text key="guideName3">
    Louis <strong>d&apos;Au</strong>bert : <strong>DAU</strong>
  </Text>,
  <Text key="guideName4">
    Claire <strong>XU</strong> : <strong>XU</strong>
  </Text>,
]
