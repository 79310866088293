import styled from '@emotion/styled'
import type { OverviewGlobalNoteSectionState } from '@orus.eu/dimensions'
import { spacing, TextFieldFormField } from '@orus.eu/pharaoh'
import { memo, useState } from 'react'
import { CommentArea } from '../common/CommentArea'

type OverviewGlobalNoteSectionProps = {
  state: OverviewGlobalNoteSectionState
  setChanges: (changes: OverviewGlobalNoteSectionState) => void
  isReadOnly: boolean
}

const OverviewGlobalNoteSection = memo(function OverviewGlobalNoteSection(props: OverviewGlobalNoteSectionProps) {
  const { state, setChanges, isReadOnly } = props

  const {
    detailedSheetGlobalSynthesis,
    detailedSheetFirstLevelResponsible,
    detailedSheetSecondLevelResponsible,
    detailedSheetThirdLevelResponsible,
  } = state

  const [responsibleLevel1, setResponsibleLevel1] = useState(detailedSheetFirstLevelResponsible ?? '')
  const [responsibleLevel2, setResponsibleLevel2] = useState(detailedSheetSecondLevelResponsible ?? '')
  const [responsibleLevel3, setResponsibleLevel3] = useState(detailedSheetThirdLevelResponsible ?? '')

  return (
    <Container>
      <CommentArea
        title="Note globale"
        size="large"
        placeholder="Votre synthèse sur le dossier"
        value={detailedSheetGlobalSynthesis}
        onChange={(value) => {
          setChanges({ detailedSheetGlobalSynthesis: value })
        }}
        showEmptyPlaceholder={!detailedSheetGlobalSynthesis || detailedSheetGlobalSynthesis === ''}
        emptyPlaceholderLabel="Aucun commentaire renseigné"
        readonly={isReadOnly}
      />
      <ResponsiblesContainer>
        <TextFieldFormField
          label="Responsable Niveau 1"
          value={responsibleLevel1}
          size="small"
          infoTooltip="Le responsable commercial gère le dossier de souscription et de la collecte des justificatifs"
          onBlur={(value) => {
            setChanges({
              detailedSheetFirstLevelResponsible: value.target.value,
            })
          }}
          onChange={(value) => {
            setResponsibleLevel1(value.target.value)
          }}
          disabled={isReadOnly}
        />
        <TextFieldFormField
          label="Responsable Niveau 2"
          value={responsibleLevel2}
          size="small"
          infoTooltip="L’analyste assurance vérifie le dossier et les justificatifs pour s’assurer de leur conformité"
          onBlur={(value) => {
            setChanges({
              detailedSheetSecondLevelResponsible: value.target.value,
            })
          }}
          onChange={(value) => {
            setResponsibleLevel2(value.target.value)
          }}
          disabled={isReadOnly}
        />
        <TextFieldFormField
          label="Responsable Niveau 3"
          value={responsibleLevel3}
          size="small"
          infoTooltip="Le superviseur assurance valide l'ensemble du dossier pour garantir sa complétude"
          onBlur={(value) => {
            setChanges({
              detailedSheetThirdLevelResponsible: value.target.value,
            })
          }}
          onChange={(value) => {
            setResponsibleLevel3(value.target.value)
          }}
          disabled={isReadOnly}
        />
      </ResponsiblesContainer>
    </Container>
  )
})

export default OverviewGlobalNoteSection

const Container = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 260px;
  gap: ${spacing[60]};
  padding-top: ${spacing[40]};
`

const ResponsiblesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`
