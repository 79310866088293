import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { RowContainerV2, spacing, type CompoundIconName } from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo, type ReactNode } from 'react'

type DocumentButtonProps = {
  title: ReactNode
  avatar?: ReactNode
  rightIcon?: CompoundIconName
  disabled?: boolean
  onClick: () => void
}

const DocumentButton = memo(function DocumentButton({
  title,
  avatar,
  rightIcon,
  onClick,
  disabled,
}: DocumentButtonProps): ReactNode {
  return (
    <RowContainerV2
      css={css`
        height: 100%;
      `}
    >
      <StyledRow
        variant="basic"
        title={title}
        titleVariant={'body2'}
        rightAvatar={avatar}
        rightIcon={rightIcon}
        onClick={onClick}
        disabled={disabled ?? false}
      />
    </RowContainerV2>
  )
})

export default DocumentButton

const StyledRow = styled(Row)`
  height: 100%;
  padding: ${spacing[40]};
  word-break: break-all;
`
