import { Box, Card, Typography } from '@mui/material'
import type { InvoicePaymentStatus } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import type { PaymentMethod } from '@orus.eu/backend/src/views/payment-view'
import { PARIS, calendarDateToString, getCalendarDateFromTimestamp } from '@orus.eu/calendar-date'
import {
  AmountText,
  Icon,
  Spinner,
  downloadFile,
  primaryColor,
  spacing,
  useAsyncCallback,
  useLanguage,
} from '@orus.eu/pharaoh'
import { memo, useState } from 'react'
import { dynamicFileNames } from '../../../lib/download-filenames'
import { getMyInvoiceDownloadLink } from '../../../lib/download-links'
import { UnstyledButton } from '../../atoms/unstyled-button'
import { LegacyPaymentMethodCardBody } from '../../molecules/card-payment-methods'
import { SmallScreenMargin } from '../../templates/small-screen-margin'

type NextInvoiceProps = {
  invoicePaymentStatus: InvoicePaymentStatus
  paymentMethod: PaymentMethod | null
}

export const NextInvoiceElement = memo<NextInvoiceProps>(function NextInvoiceElement({
  invoicePaymentStatus,
  paymentMethod,
}) {
  const language = useLanguage()
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)
  const invoiceAmount = invoicePaymentStatus.invoice.totalPremium
  const invoiceDueDate = getCalendarDateFromTimestamp(invoicePaymentStatus.invoice.dueTimestamp, PARIS)
  const { invoiceId } = invoicePaymentStatus.invoice

  const handleDownload = useAsyncCallback(async () => {
    try {
      setDownloadInProgress(true)
      await downloadFile(getMyInvoiceDownloadLink(invoiceId), dynamicFileNames.invoice(invoiceId, language))
    } finally {
      setDownloadInProgress(false)
    }
  }, [invoiceId, setDownloadInProgress, language])

  return (
    <>
      <SmallScreenMargin>
        <Card sx={{ marginTop: spacing[60], gap: spacing[30], marginBottom: spacing[80] }}>
          <UnstyledButton onClick={handleDownload} disabled={downloadInProgress} fullWidth>
            <Box
              display="flex"
              flexDirection="column"
              gap={spacing[30]}
              alignItems="stretch"
              textAlign="center"
              padding={spacing[70]}
              width="100%"
            >
              <Typography variant="captionMedium">PROCHAINE ÉCHÉANCE</Typography>
              <Typography variant="h1" sx={{ position: 'relative' }}>
                <AmountText amount={invoiceAmount} displayDecimals />
                <Typography
                  variant="body1"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                    padding: spacing[30],
                  }}
                >
                  {downloadInProgress ? (
                    <Spinner size="30" />
                  ) : (
                    <Icon icon="arrow-down-to-line-regular" color={primaryColor} />
                  )}
                </Typography>
              </Typography>
              <Box>
                <Typography variant="body2Medium">Prélévement le </Typography>
                <Typography variant="body2Medium">
                  {calendarDateToString(invoiceDueDate, PARIS, 'DATE_FULL', language)}
                </Typography>
              </Box>
            </Box>
          </UnstyledButton>
          <LegacyPaymentMethodCardBody paymentMethod={paymentMethod} />
        </Card>
      </SmallScreenMargin>
    </>
  )
})
