import { amountToString, newAmount } from '@orus.eu/amount'
import type {
  MrpSmallOfficeGuaranteeGroupType,
  PolicyOptionalGuarantee,
  RcProOptionalGuaranteesType,
} from '../quote-offer-information-dimension.js'

const fiveHundredEuros = amountToString(newAmount('500'), {
  displayDecimals: false,
  addCurrency: true,
})
const fifteenThousandEuros = amountToString(newAmount('15000'), {
  displayDecimals: false,
  addCurrency: true,
})
const tenThousandEuros = amountToString(newAmount('10000'), {
  displayDecimals: false,
  addCurrency: true,
})
const fiveThousandEuros = amountToString(newAmount('5000'), {
  displayDecimals: false,
  addCurrency: true,
})
const oneThousandFiveHundredEuros = amountToString(newAmount('1500'), {
  displayDecimals: false,
  addCurrency: true,
})
const fourThousandFiveHundredEuros = amountToString(newAmount('4500'), {
  displayDecimals: false,
  addCurrency: true,
})
const twoThousandFiveHundredEuros = amountToString(newAmount('2500'), {
  displayDecimals: false,
  addCurrency: true,
})
const sevenMillionFiveHundredThousandEuros = amountToString(newAmount('7500000'), {
  displayDecimals: false,
  addCurrency: true,
})

export const mrpSmallOfficeOptionalGuaranteeChildrenFactories: (Omit<PolicyOptionalGuarantee, 'type'> & {
  type: MrpSmallOfficeGuaranteeGroupType
})[] = [
  {
    type: 'mrpSmallOfficeIncendies',
    name: 'Incendie',
    isRecommanded: false,
    isActive: true,
    shortText: `Nous couvrons les conséquences matérielles causées aux biens de l’assurés par l’incendie, la combustion, la chute de la foudre, les explosions, implosions, etc.`,
    fullText: `Dommages causés à vos locaux et à votre mobilier et matériel par les incendies et leurs fumées, les explosions et les implosions, ou occasionnés par les secours.
- Cette garantie couvre également les dommages causés par les chocs de véhicules à moteur.
- Cette garantie ne couvre pas les brûlures provoquées par certains appareils, comme les fers à repasser.`,
    example: `Un incendie se déclare dans votre local. Vous tentez de contenir l’incendie avec vos extincteurs. Les pompiers arrivent quelques minutes après et parviennent à contrôler l’incendie.
Les dégâts causés par l’incendie, par les fumées, par l’utilisation des extincteurs et par l’intervention des pompiers sont couverts, de même que la recharge des extincteurs.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: fifteenThousandEuros,
          description: 'Pour les biens mobiliers',
          isGuaranteeFixed: true,
        },
        {
          limit: tenThousandEuros,
          description: 'Pour les aménagements',
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeDegatsDesEaux',
    name: 'Dégâts des eaux',
    isRecommanded: false,
    isActive: true,
    shortText: `Nous couvrons le contenu de vos locaux en cas de fuites et d’infiltrations d’eau dans votre local.`,
    fullText: `Dommages causés par des fuites et infiltrations d’eau à vos locaux et à votre mobilier et matériel.
Pour être couvert, pensez à interrompre la distribution d'eau en cas d'absence, à vidanger vos installations non chauffées pendant l'hiver, et à stocker votre matériel à plus de 10 cm du sol.`,
    example: `Un matin, en arrivant à votre bureau vous découvrez que les toilettes sont inondées. Une canalisation d'eau a éclaté provoquant une fuite continue d'eau, occasionnant un débordement qui endommage votre parquet.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: fiveThousandEuros,
          description: 'Pour les biens mobiliers',
          isGuaranteeFixed: true,
        },
        {
          limit: oneThousandFiveHundredEuros,
          description: 'Pour les aménagements',
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeVol',
    name: 'Vol & tentative de vol',
    isRecommanded: false,
    isActive: true,
    shortText: `Vous êtes couvert en cas de disparition, destruction ou détérioration résultant d’un vol ou d’une tentative de vol.`,
    fullText: `Vol de vos biens mobiliers et détériorations de vos biens et de vos locaux lors d’une tentative de vol. Nous couvrons également le remplacement de vos clés et serrures ainsi que le vol de vos espèces, titres et valeurs et d’effraction sur coffre-fort.
- Pour être couvert, vous devez effectuer une déclaration de vol auprès de la police. Si vos biens sont retrouvés avant 30 jours, vous vous engagez à en reprendre possession et à rembourser vos indemnités perçues.
- Trois sinistres maximum par année d’assurance.`,
    example: `La serrure de votre local est fracturée pendant la nuit et le cambrioleur s'empare d’ordinateurs portables et de disques durs externes.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: fiveThousandEuros,
          description: 'Pour les biens mobiliers',
          isGuaranteeFixed: true,
        },
        {
          limit: fourThousandFiveHundredEuros,
          description: 'Pour les aménagements',
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeVandalisme',
    name: 'Vandalisme',
    isRecommanded: false,
    isActive: true,
    shortText: `Nous couvrons les actes de vandalisme causés volontairement par un tiers sur votre local.`,
    fullText: `Dégradation, destruction ou détérioration de vos vitres, fenêtres ou de la façade par des jets de pierre.`,
    example: `Votre local a subi une détérioration sur la façade extérieure en bardage bois durant la nuit.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: fiveThousandEuros,
          description: 'Pour les biens mobiliers',
          isGuaranteeFixed: true,
        },
        {
          limit: oneThousandFiveHundredEuros,
          description: 'Pour les aménagements',
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeEvenementsClimatiques',
    name: 'Événements climatiques',
    isRecommanded: false,
    isActive: true,
    shortText: `Nous vous protégeons en cas de dommages causés à votre local par un évènement climatique, tempête, grêle, neige, glace et les inondations.`,
    fullText: `Dommages causées par la tempête, grêle, neige, glace et les inondations.
Pour être couvert, pensez à vidanger vos installations non chauffées pendant l'hiver.`,
    example: `Une tempête arrache une partie de la toiture de votre local et la pluie abîme votre mobilier et votre parquet.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: fifteenThousandEuros,
          description: 'Pour les biens mobiliers',
          isGuaranteeFixed: true,
        },
        {
          limit: tenThousandEuros,
          description: 'Pour les aménagements',
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeBrisDeGlace',
    name: 'Bris de glace',
    isRecommanded: false,
    isActive: true,
    shortText: `On parle aussi de bris de vitre. Nous couvrons le verre, les glaces et vitres incorporés aux bâtiments ainsi que les enseignes lumineuses.`,
    fullText: `Nous vous remboursons sur les bris accidentels de vitres : portes, fenêtres, et autres éléments vitrés.`,
    example: `Un matin, vous retrouvez la vitrine de votre local brisée, nous prenons en charge le remplacement de la vitre.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: twoThousandFiveHundredEuros,
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeCatastrophesNaturelles',
    name: 'Catastrophes naturelles',
    isRecommanded: false,
    isActive: true,
    shortText: `Nous vous couvrons en cas de catastrophes naturelles (officiellement déclarées comme telles par un arrêté interministériel), tremblements de terre, éboulement, affaissement de terrain, etc.`,
    fullText: `Vos biens immobiliers ou mobiliers sont endommagés par une catastrophe naturelle : tremblement de terre, raz-de marée, éruption volcanique…`,
    example: `Un raz-de-marée submerge votre local situé sur la côte Atlantique. Le raz-de-marée déclenche un arrêté de catastrophe naturelle sur votre commune.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: fifteenThousandEuros,
          description: 'Pour les biens mobiliers',
          isGuaranteeFixed: true,
        },
        {
          limit: tenThousandEuros,
          description: 'Pour les aménagements',
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeAttentats',
    name: 'Attentats',
    isRecommanded: false,
    isActive: true,
    shortText: `Nous protégeons votre local et son contenu en cas de dommages causés par un attentat ou des actes de terrorisme.`,
    fullText: `Vos biens sont endommagés par un attentat ou acte de terrorisme.`,
    example: `Votre local ainsi que son mobilier ont été endommagé par l’explosion d’une bombe artisanale.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: fifteenThousandEuros,
          description: 'Pour les biens mobiliers',
          isGuaranteeFixed: true,
        },
        {
          limit: tenThousandEuros,
          description: 'Pour les aménagements',
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeOccupant',
    name: 'Responsabilité civile occupant',
    isRecommanded: false,
    isActive: true,
    shortText: `Dommages matériels causés au propriétaire des bâtiments assurés.`,
    fullText: `Dommages matériels causés au propriétaire des bâtiments assurés.

Cette garantie est étendue à la perte de loyers subie par le propriétaire des bâtiments, si la responsabilité du sinistre incombe à l’assuré :
- Dans la limite du temps nécessaire à la remise en état des bâtiments
- Dans la limite d’une durée maximale de deux (2) ans à compter de la survenance du sinistre`,
    example: `Vous effectuez une manoeuvre délicate en déplacant de la marchandise dans votre local, ce qui endommage l’un des mur porteurs.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: sevenMillionFiveHundredThousandEuros,
          isGuaranteeFixed: true,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: fiveHundredEuros }],
    },
  },
  {
    type: 'mrpSmallOfficeAssistance',
    name: 'Assistance',
    isRecommanded: false,
    isActive: true,
    shortText: `Nous prenons en charge les réparations provisoires, le nettoyage, le gardiennage du local ainsi que son remplacement provisoire. Par exemple : votre local subit un dégât des eaux vous empéchant de continuer votre activité en toute sécurité. Nous prenons en charge les frais sur la première intervention du plombier, ainsi que, du nettoyage et des frais de transport vers un local provisoire.`,
    fullText: `Nous couvrons suite à un sinistre sur votre local, la prise en charge des frais de retour anticipé sur le lieu du sinistre, les réparations provisoires pour certains sinistres, la recherche de prestataires pour la remise en état, nettoyage et gardiennage du local, la recherche et les frais de transport vers un local de remplacement provisoires.`,
    example: `Votre local subis un dégât des eaux ce qui ne vous permet pas de continuer votre activité en toute sécurité, nous prenons en charge les frais sur la première intervention du plombier, mais entre autres, du nettoyage et des frais de transport vers un local provisoire.`,
    guaranteeLimit: {
      name: 'Plafond',
      limits: [
        {
          limit: 'Par garantie',
          isGuaranteeFixed: false,
        },
      ],
    },
    deductibles: {
      name: 'Franchise',
      descriptions: [{ deductible: 'Par garantie' }],
    },
  },
]

export const rcProOptionalGuaranteeChildrenFactories: {
  [key in RcProOptionalGuaranteesType]: PolicyOptionalGuarantee[]
} = {
  rce: [],
  world: [],
  cyber: [],
  tdmi: [],
  mrpSmallOffice: mrpSmallOfficeOptionalGuaranteeChildrenFactories,
}
