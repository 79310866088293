import type {
  SubscriptionFooterElementType,
  SubscriptionFooterUiElement,
} from '@orus.eu/dimensions/src/subscription-ui/subscription-footer-ui-element'
import { memo, type NamedExoticComponent } from 'react'
import { FinalizeQuoteFooterUiElementBlock } from '../elements/FinalizeQuoteFooterUiElementBlock'
import { StatementChoiceConfirmationFooterUiElementBlock } from '../elements/StatementChoiceConfirmationFooterUiElementBlock'
import type { SubscriptionFooterElementBlockProps } from '../subscription-v2-props'

const uiElementFooterComponents: {
  [key in SubscriptionFooterElementType]: NamedExoticComponent<
    SubscriptionFooterElementBlockProps<SubscriptionFooterUiElement & { type: key }>
  >
} = {
  'statement-choice-confirmation': StatementChoiceConfirmationFooterUiElementBlock,
  'finalize-quote': FinalizeQuoteFooterUiElementBlock,
}

export const SubscriptionFooterElementGroupBlock = memo<
  SubscriptionFooterElementBlockProps<SubscriptionFooterUiElement>
>(function SubscriptionFooterElementGroupBlock(props) {
  const Component = uiElementFooterComponents[props.uiElement.type]

  if (!Component) return <></>

  // @ts-expect-error: The specific type of uiElement is dynamically managed, TypeScript cannot infer the type of uiElement from the SubscriptionFooterElementType
  return <Component {...props} />
})
