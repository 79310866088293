import { createFileRoute } from '@tanstack/react-router'

import { PendingSubscriptionPaymentPage } from '../../../../../../components/pages/backoffice/common/backoffice-pending-subscriptions/pending-subscription-payment-page'
import PartnerPage from '../../../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/pending-subscriptions/$subscriptionId/payment')({
  component: () => (
    <PartnerPage requiredPermissions={['contracts.sign']}>
      <PendingSubscriptionPaymentPage />
    </PartnerPage>
  ),
})
