import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminImaPage from '../../../../components/pages/backoffice/platform/platform-admin-ima-page'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/admin/ima')({
  component: () => (
    <PlatformPage requiredPermissions={['tech.configurations']}>
      <PlatformAdminImaPage />
    </PlatformPage>
  ),
})
