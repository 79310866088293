import type { ContractRenewalState } from '@orus.eu/backend/src/services/contract-endorsement/contract-endorsement-state'
import type { ContractRenewalStatus } from '@orus.eu/backend/src/services/contract-endorsement/contract-endorsement-store'
import { formatDdMmYyyyhhmm, getStartOfCalendarDate, PARIS } from '@orus.eu/calendar-date'
import { colorTokens } from '@orus.eu/pharaoh'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import type { RenewalEndorsementTableRowData } from './RenewalEndorsementTable'
import { RenewalEndorsementTable } from './RenewalEndorsementTable'

const getStatusUi: (
  datePrecision?: number,
) => Record<
  ContractRenewalStatus | 'approved-auto' | 'open-danger' | 'approved-excluded',
  { label: string; bgColorToken: string }
> = (datePrecision) => {
  return {
    open: { label: 'À renouveler', bgColorToken: colorTokens['color-bg-info-inverse'] },
    approved: { label: 'Validé', bgColorToken: colorTokens['color-bg-success-inverse'] },
    'approved-auto': { label: 'Validé auto', bgColorToken: colorTokens['color-bg-success-inverse'] },
    cancelled: { label: 'Annulé', bgColorToken: colorTokens['color-bg-danger-inverse'] },
    'open-danger': { label: `À renouveler | J-${datePrecision}`, bgColorToken: colorTokens['color-bg-danger-inverse'] },
    'approved-excluded': { label: 'Validé (exclusion)', bgColorToken: colorTokens['color-bg-danger-inverse'] },
  }
}

type LegacyRenewal = ContractRenewalState
type RenewalWithToken = ContractRenewalState & { token: string }

export const RenewalsTable = function RenewalsTable({
  legacyRenewals,
  renewals,
}: {
  legacyRenewals: LegacyRenewal[]
  renewals: RenewalWithToken[]
}): JSX.Element {
  const renewalsData = useMemo<RenewalEndorsementTableRowData[]>(() => {
    const renewalsRows: RenewalEndorsementTableRowData[] = []

    renewals.forEach((renewal) => {
      if (!renewal.effectDate || !renewal.status) {
        return
      }

      const dayToRenewal = Math.round(
        getStartOfCalendarDate(renewal.effectDate, PARIS).diff(DateTime.now(), 'days').days,
      )

      const status =
        renewal.status === 'approved' && renewal.isExcluded //If renewal is approved and excluded, we show the excluded status
          ? 'approved-excluded'
          : renewal.status === 'approved' &&
              !renewal.updateResourceId?.startsWith('user') &&
              !renewal.updateResourceId?.startsWith('signature') //If renewal is approved and not done by a user, we show the auto approved status
            ? 'approved-auto'
            : renewal.status === 'open' && dayToRenewal < 30 //If renewal is open and the renewal is in less than 30 days, we show the danger status
              ? 'open-danger'
              : renewal.status

      const statusUi = getStatusUi(dayToRenewal)

      renewalsRows.push({
        dueDate: formatDdMmYyyyhhmm(renewal.effectDate),
        status: { label: statusUi[status].label, backgroundColor: statusUi[status].bgColorToken },
        paymentAmount:
          renewal.update?.quote?.paymentRecurrence === 'yearly'
            ? renewal.update?.quote?.yearlyTotalPremium
            : renewal.update?.quote?.subsequentMonthsTotalPremium,
        paymentRecurrence: renewal.update?.paymentRecurrence ?? renewal.update?.quote?.paymentRecurrence,
        token: renewal.token,
        routeToNavigateTo: `/bak/contracts/${renewal.subscriptionId}/renewal/${renewal.id}`,
        routeToDetailedSheet: renewal.update?.rcdaSelected
          ? {
              to: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
              params: { subscriptionId: renewal.subscriptionId, versionId: renewal.id },
            }
          : undefined,
      })
    })

    legacyRenewals.forEach((renewal) => {
      if (!renewal.effectDate || !renewal.status) {
        return
      }

      const statusUi = getStatusUi()

      renewalsRows.push({
        dueDate: formatDdMmYyyyhhmm(renewal.effectDate),
        status: { label: statusUi['approved-auto'].label, backgroundColor: statusUi['approved-auto'].bgColorToken },
        paymentAmount:
          renewal.update?.quote?.paymentRecurrence === 'yearly'
            ? renewal.update?.quote?.yearlyTotalPremium
            : renewal.update?.quote?.subsequentMonthsTotalPremium,
        paymentRecurrence: renewal.update?.paymentRecurrence ?? renewal.update?.quote?.paymentRecurrence,
      })
    })
    return renewalsRows
  }, [legacyRenewals, renewals])

  return <RenewalEndorsementTable tableData={renewalsData} emptyStateSubtitle="Aucun renouvellement disponible" />
}
