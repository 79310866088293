import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { OutlinedInput } from '@mui/material'
import { Box } from '@mui/system'
import type { ExactMatchesSearchOutput } from '@orus.eu/backend/src/routers/search'
import type { SearchResultsSectionData } from '@orus.eu/backend/src/services/search-service'
import {
  Button,
  ContentContainerBackoffice,
  Icon,
  PersistentNotification,
  Spinner,
  Text,
  body1MediumTypography,
  colors,
  mobileMediaQuery,
  spacing,
  type CompoundIconName,
} from '@orus.eu/pharaoh'
import { memo, useCallback, type ReactNode } from 'react'
import { UnstyledButton } from '../../../atoms/unstyled-button'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { BackofficeContractsTable } from '../../../organisms/backoffice-contracts-table'
import { BackofficePendingSubscriptionsTable } from '../../../organisms/backoffice-pending-subscriptions-table'
import { BackofficeUsersTable } from '../../../organisms/backoffice-users-table'
import { WithLabel } from '../../../organisms/with-label'

type SearchBlockProps = {
  searchIndexUpToDate: 'up-to-date' | 'out-of-date'
  query: string
  setQuery: (txt: string) => void
  exactMatchesSearchOutput: ExactMatchesSearchOutput | 'no-query' | 'loading'
  partialMatchesRequested: boolean | '' | undefined
  partialMatches: SearchResultsSectionData | undefined
  handleRequestMore: (query: string) => void
}

export const BackofficeSearchPageContent = memo<SearchBlockProps>(function BackofficeSearchPageContent(props) {
  return (
    <Container>
      <SearchStateBanner state={props.searchIndexUpToDate} />
      <SearchBlock {...props} />
    </Container>
  )
})

const Container = styled(ContentContainerBackoffice)`
  padding-top: ${spacing[50]};
  ${mobileMediaQuery} {
    padding: 0;

    & > :not(:first-of-type) {
      padding: ${spacing[50]};
    }
  }
`

const SearchStateBanner = memo(function SearchStateBanner(props: { state: 'up-to-date' | 'out-of-date' }) {
  return (
    <>
      <PersistentNotification
        variant={props.state === 'up-to-date' ? 'success' : 'warning'}
        title={props.state === 'up-to-date' ? 'Index à jour' : 'Index en cours de mise à jour'}
        css={css`
          margin-bottom: ${spacing[70]};
        `}
      >
        {props.state === 'up-to-date'
          ? 'Les données sont à jour. Bonne recherche !'
          : "Les données sont en cours d'indexation. Il est possible que la recherche retourne des résultats incomplets."}
      </PersistentNotification>
    </>
  )
})

const SearchBlock = memo<SearchBlockProps>(function SearchBlock({
  query,
  setQuery,
  exactMatchesSearchOutput,
  partialMatchesRequested,
  partialMatches,
  handleRequestMore,
}) {
  return (
    <>
      <SearchBar value={query} setValue={setQuery} />
      {exactMatchesSearchOutput === 'no-query' ? (
        <SearchEmptyState />
      ) : exactMatchesSearchOutput === 'loading' ? (
        <GlobalLoadingState />
      ) : (
        <SearchResults
          query={query}
          exactMatches={exactMatchesSearchOutput.exactMatches}
          partialMatches={
            exactMatchesSearchOutput.partialMatchesFallback ??
            (partialMatchesRequested ? (partialMatches ?? 'loading') : 'not-requested')
          }
          onRequestMore={handleRequestMore}
        />
      )}
    </>
  )
})

function SearchEmptyState(): JSX.Element {
  return (
    <Box
      sx={{
        marginTop: spacing[80],
        paddingTop: spacing[70],
        display: 'flex',
        flexDirection: 'column',
        gap: spacing[30],
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text variant="body1Medium">Tu cherches quelque chose ?</Text>
      <Text variant="caption" color={colors.gray[500]}>
        Ici tu peux rechercher par contrat, utilisateur et souscriptions en cours, pas la peine de faire plus compliqué.
      </Text>
    </Box>
  )
}

type SearchResultsProps = {
  query: string
  exactMatches: SearchResultsSectionData | 'loading'
  partialMatches: SearchResultsSectionData | 'loading' | 'not-requested'
  onRequestMore: (query: string) => void
}

function SearchResults({ query, exactMatches, partialMatches, onRequestMore }: SearchResultsProps): JSX.Element {
  const nbFoundResults =
    (exactMatches === 'loading'
      ? 0
      : exactMatches.contracts.length + exactMatches.users.length + exactMatches.subscriptions.length) +
    (typeof partialMatches === 'string'
      ? 0
      : partialMatches.contracts.length + partialMatches.users.length + partialMatches.subscriptions.length)

  const handleRequestMore = useCallback(() => {
    onRequestMore(query)
  }, [query, onRequestMore])

  if (exactMatches !== 'loading' && typeof partialMatches !== 'string' && nbFoundResults === 0) {
    return <NoResultsFound query={query} />
  }

  return (
    <Box sx={{ marginTop: spacing[70], display: 'flex', flexDirection: 'column', gap: spacing[50] }}>
      <Box>
        <Text variant="caption" color={colors.gray[500]}>
          {nbFoundResults} résultats de recherche
        </Text>
      </Box>
      {exactMatches === 'loading' ? (
        <Spinner size="50" />
      ) : (
        <>
          <ResultSection title="Résultats exacts" results={exactMatches} />
          {partialMatches === 'not-requested' ? (
            <>
              <Text variant="body1">On n’a pas trouvé ce que tu cherches ?</Text>
              <Button variant="secondary" size="medium" onClick={handleRequestMore}>
                Charger plus de résultats
              </Button>
            </>
          ) : partialMatches === 'loading' ? (
            <Spinner size="50" />
          ) : (
            <>
              <ResultSection title="Résultats partiels" results={partialMatches} />
            </>
          )}
        </>
      )}
    </Box>
  )
}

type SearchDataMatchProps = {
  title: string
  results: SearchResultsSectionData
}

function ResultSection({ title, results: { contracts, users, subscriptions } }: SearchDataMatchProps) {
  if (contracts.length === 0 && users.length === 0 && subscriptions.length === 0) return <></>
  return (
    <div
      css={css`
        margin-top: ${spacing[50]};
      `}
    >
      <Text variant="h6" color={colors.blue[400]}>
        {title}
      </Text>
      {contracts.length > 0 ? (
        <ResultsTable
          title="Contracts"
          icon="pen-clip-solid"
          table={<BackofficeContractsTable contracts={contracts} />}
        />
      ) : (
        <></>
      )}
      {users.length > 0 ? (
        <ResultsTable
          title="Clients"
          icon="folder-user-solid"
          table={<BackofficeUsersTable users={users} type="platform" />}
        />
      ) : (
        <></>
      )}
      {subscriptions.length > 0 ? (
        <ResultsTable
          title="Devis"
          icon="clock-three-solid"
          table={<BackofficePendingSubscriptionsTable subscriptions={subscriptions} />}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

type ResultsTableProps = {
  title: string
  icon: CompoundIconName
  table: ReactNode
}

function ResultsTable({ title, icon, table }: ResultsTableProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          gap: spacing[30],
          paddingTop: spacing[50],
          paddingBottom: spacing[50],
        }}
      >
        <Icon icon={icon} />
        <Text variant="body2Medium">{title}</Text>
      </Box>
      {table}
    </Box>
  )
}

type SearchBarProps = {
  value: string
  setValue: (txt: string) => void
}

function SearchBar({ value, setValue }: SearchBarProps): JSX.Element {
  const closeButton = (
    <UnstyledButton onClick={() => setValue('')}>
      <Icon icon="xmark-solid" />
    </UnstyledButton>
  )

  return (
    <WithLabel label="Recherche">
      <OutlinedInput
        autoFocus={true}
        fullWidth={true}
        placeholder="Exemple : nom ou email d'un utilisateur, id d'un contrat ou d'une souscription en cours ... "
        value={value}
        onChange={(event) => setValue(event.target.value)}
        endAdornment={closeButton}
        inputProps={{ style: { ...body1MediumTypography } }}
      />
    </WithLabel>
  )
}

type NoResultsFoundProps = {
  query: string
}

function NoResultsFound({ query }: NoResultsFoundProps): JSX.Element {
  return (
    <Box
      sx={{
        marginTop: spacing[80],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text variant="caption" color={colors.gray[500]}>
        Aucun résultat pour
      </Text>
      <Text variant="captionMedium" color={colors.gray[500]}>{` "${query}"`}</Text>
    </Box>
  )
}
