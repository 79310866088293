import { usePermissions } from '../use-permissions'
import { useNavigateTo } from './use-navigate-to-route'

export function useNavigateToHome(): () => void {
  const { type: userType } = usePermissions()
  const isPartner = userType === 'partner'

  return useNavigateTo({
    to: isPartner ? '/partner/$organization/home' : '/bak/home',
  })
}
