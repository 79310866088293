import { createFileRoute } from '@tanstack/react-router'

import PartnerUsersPage from '../../../../components/pages/backoffice/partners/partners-users-page'
import PartnerPage from '../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/users/')({
  component: () => (
    <PartnerPage requiredPermissions={['users.read']}>
      <PartnerUsersPage />
    </PartnerPage>
  ),
})
