import { createFileRoute } from '@tanstack/react-router'
import BackofficeNewSubscriptionPage from '../../../components/pages/backoffice/common/backoffice-new-subscription-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/new-subscription')({
  component: () => (
    <PlatformPage requiredPermissions={['subscription.create']}>
      <BackofficeNewSubscriptionPage />
    </PlatformPage>
  ),
})
