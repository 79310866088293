import { css } from '@emotion/react'
import { Badge, Divider, RowContainerV2, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { CommentArea } from '../common/CommentArea'

import styled from '@emotion/styled'
import type { OverviewActivitySectionState } from '@orus.eu/dimensions'
import { EMPTY_CONTENT_AS_STRING } from '../rich-text-editor/constants/empty-paragraph'
import { ACTIVITY_ABBREVIATION_LABELS } from './abbreviation-labels-per-activity'

type OverviewActivitySectionProps = {
  state: OverviewActivitySectionState
  showAcagComment: boolean
}

const OverviewActivitySection = memo(function OverviewActivitySection(props: OverviewActivitySectionProps) {
  const { state, showAcagComment } = props

  const { detailedSheetActivitySynthesis, detailedSheetAcagSynthesis, activities } = state

  return (
    <RowContainerV2
      css={css`
        display: grid;
        grid-template-columns: minmax(150px, 1fr) 1px 6fr;
      `}
    >
      <ActivitiesWrapper>
        <Text variant="body2Medium">Activités</Text>
        <ActivitiesGridContainer>
          {activities?.map(({ activity }) => (
            <Badge dark key={activity}>
              {ACTIVITY_ABBREVIATION_LABELS[activity]?.abbreviation}
            </Badge>
          ))}
        </ActivitiesGridContainer>
      </ActivitiesWrapper>
      <Divider
        orientation="vertical"
        css={css`
          width: 4px;
          height: 100%;
        `}
      />

      <RowContainerV2
        css={css`
          border: none;
          display: grid;
        `}
      >
        <CommentAreaWrapper>
          <CommentArea
            readonly
            showEmptyPlaceholder={!detailedSheetActivitySynthesis || detailedSheetActivitySynthesis === ''}
            emptyPlaceholderLabel="Aucun commentaire renseigné"
            value={
              detailedSheetActivitySynthesis && detailedSheetActivitySynthesis !== ''
                ? detailedSheetActivitySynthesis
                : EMPTY_CONTENT_AS_STRING
            }
            title="Commentaire activité"
          />
        </CommentAreaWrapper>

        {showAcagComment ? (
          <>
            <Divider
              orientation="horizontal"
              css={css`
                width: 100%;
              `}
            />
            <CommentAreaWrapper>
              <CommentArea
                readonly
                showEmptyPlaceholder={!detailedSheetAcagSynthesis || detailedSheetAcagSynthesis === ''}
                emptyPlaceholderLabel="Aucun commentaire renseigné"
                value={
                  detailedSheetAcagSynthesis && detailedSheetAcagSynthesis !== ''
                    ? detailedSheetAcagSynthesis
                    : EMPTY_CONTENT_AS_STRING
                }
                title="Commentaire ACAG"
              />
            </CommentAreaWrapper>
          </>
        ) : (
          <></>
        )}
      </RowContainerV2>
    </RowContainerV2>
  )
})

export default OverviewActivitySection

const ActivitiesWrapper = styled.div`
  padding: ${spacing[40]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[50]};
`

const ActivitiesGridContainer = styled.div`
  display: flex;
  gap: ${spacing[30]};
  flex-wrap: wrap;
`

const CommentAreaWrapper = styled.div`
  padding: ${spacing[40]};
`
