import { InvalidAmount, newAmount, type Amount } from '@orus.eu/amount'
import { isEmailValid } from '@orus.eu/email'
import { translate, type Language } from '@orus.eu/translations'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { validateTime, type Time } from './time'

export type ValidationResult<VALUE> = { ok: true; value: VALUE } | { ok: false; hint: string }

export type ValidationUrlScheme = 'http:' | 'https:' | 'mailto:' | 'tel:'

export function validateInteger(text: string, language: Language): ValidationResult<number> {
  text = text.trim().replace(/ /g, '')
  if (!text) {
    return { ok: false, hint: translate('error_mandatory_field', language) }
  }
  if (/^[0-9]+$/.test(text)) {
    return { ok: true, value: parseInt(text, 10) }
  }
  if (/^[0-9.,]+$/.test(text)) {
    return { ok: false, hint: translate('error_integer_no_decimals', language) }
  }
  return { ok: false, hint: translate('error_integer_generic', language) }
}

export function validatePositiveInteger(value: string, language: Language): ValidationResult<number> {
  const isValidInteger = validateInteger(value, language)

  if (!isValidInteger.ok || isValidInteger.value > 0) {
    return isValidInteger
  }

  return { ok: false, hint: translate('error_positive_integer', language) }
}

export function validateAmount(text: string, language: Language): ValidationResult<Amount> {
  text = text.trim().replace(/ /g, '').replace(/,/g, '.').replace(/€/g, '')
  if (!text) {
    return { ok: false, hint: translate('error_mandatory_field', language) }
  }
  try {
    return { ok: true, value: newAmount(text) }
  } catch (err) {
    if (err instanceof InvalidAmount) {
      return { ok: false, hint: translate('error_amount_generic_legacy', language) }
    } else {
      throw err
    }
  }
}
export function validateRequiredField(text: string, language: Language): ValidationResult<string> {
  text = text.trim()
  if (!text) {
    return { ok: false, hint: translate('error_mandatory_field', language) }
  }
  return { ok: true, value: text }
}

export function validateEmail(text: string, language: Language): ValidationResult<string> {
  text = text.trim().toLowerCase()
  if (!text) {
    return { ok: false, hint: translate('error_mandatory_field', language) }
  }

  if (!isEmailValid(text)) {
    return { ok: false, hint: translate('error_email_generic', language) }
  }

  return { ok: true, value: text }
}

export function validateUrl(
  text: string,
  allowedSchemes: ValidationUrlScheme[],
  language: Language,
): ValidationResult<string> {
  text = text.trim()

  if (!text) {
    return { ok: false, hint: translate('error_mandatory_field', language) }
  }

  const schemePattern = new RegExp(`^(${allowedSchemes.join('|')})`)
  const match = text.match(schemePattern)

  if (!match) {
    return { ok: false, hint: "L'url doit commencer par http:, https:, mailto:, ou tel:" }
  }

  const scheme = match[0]

  if (scheme === 'mailto:') {
    const email = text.slice(scheme.length)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailPattern.test(email)) {
      return { ok: false, hint: "Le format de l'adresse email est invalide" }
    }
  } else if (scheme === 'tel:') {
    const phone = text.slice(scheme.length)
    const phonePattern = /^\+?[0-9\s\-().]+$/
    if (!phonePattern.test(phone)) {
      return { ok: false, hint: 'Le numéro de téléphone est invalide' }
    }
  } else if (scheme === 'http:' || scheme === 'https:') {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/

    if (!urlPattern.test(text)) {
      return { ok: false, hint: "Le format de l'url est invalide" }
    }

    if (!/^https?:\/\//.test(text)) {
      return { ok: false, hint: "L'url doit commencer par http:// ou https://" }
    }
  }

  return { ok: true, value: text }
}

export function validateTimeTextInput(text: string, language: Language): ValidationResult<Time> {
  text = text.trim()
  if (!text) {
    return { ok: false, hint: translate('error_mandatory_field', language) }
  }
  const [hours, minutes] = text.split(':').map(Number)
  if (isNaN(hours) || isNaN(minutes)) {
    return { ok: false, hint: translate('error_hour_generic', language) }
  }
  const validatedTime = validateTime({ hours, minutes })
  if (validatedTime === null) {
    return { ok: false, hint: translate('error_hour_generic', language) }
  }
  return { ok: true, value: validatedTime }
} /**
 * Validate a phone number.
 *
 * @param {string} text
 * @returns {ValidationResult<string>}
 */

export function validatePhoneNumber(text: string, language: Language): ValidationResult<string> {
  text = text.trim()
  if (!text) {
    return { ok: false, hint: translate('error_mandatory_field', language) }
  }

  if (!isValidPhoneNumber(text, 'FR')) {
    return { ok: false, hint: translate('error_phone_generic', language) }
  }

  return { ok: true, value: text }
}
