import { css } from '@emotion/react'
import type { GroupDefinitionsUpdateV2 } from '@orus.eu/activity'
import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import { operatingZoneDimension } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { LegacyDialog, spacing, Text, TextInputLabelWrapper, type LegacyDialogStyle } from '@orus.eu/pharaoh'
import { isFailure, isSuccess } from '@orus.eu/result'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { type CompleteGroupLoader } from '../../../../../lib/group-mapping-util'
import { noop } from '../../../../../lib/noop'
import { LocalLoadingState } from '../../../../molecules/local-loading-state'
import { ActivitySearchField } from '../../../../organisms/activity-search-field'
import { MainActivitySearchField } from '../../../subscription-v2/elements/activities-search-element/MainActivitySearchField'
import { DropdownDimensionField } from '../../common/backoffice-quote-editor-v2/fields/dropdown-dimension-field'
import type { GroupDefinitionsUpdateDiff, GroupDescription } from './diff'

export type ChangesReviewDialogProps = {
  onSubmit: (changes: GroupDefinitionsUpdateV2) => void
  onClose: () => void
  diff: GroupDefinitionsUpdateDiff
}

export const ChangesReviewDialog = memo<ChangesReviewDialogProps>(function ChangesReviewDialog({
  onClose,
  diff,
  onSubmit,
}) {
  const {
    mutate,
    data,
    isSuccess: isCreatePreviewSuccess,
    isPending,
  } = trpcReact.activities.createGroupsChangesPreview.useMutation()

  const handleSubmit = useCallback(() => onSubmit(diff.update), [diff.update, onSubmit])
  const [dialogStyle, setDialogStyle] = useState<LegacyDialogStyle>('normal')
  const [dialogTitle, setDialogTitle] = useState('Revue des changements')
  const [submitActionEnabled, enableSubmitAction] = useState(false)

  useEffect(() => {
    mutate(diff.update)
  }, [diff.update, mutate])

  useEffect(() => {
    if (isPending) return
    if (isCreatePreviewSuccess && isFailure(data)) {
      setDialogStyle('problem')
      setDialogTitle('Problèmes à corriger')
      enableSubmitAction(false)
    } else {
      setDialogStyle('normal')
      setDialogTitle('Revue des changements')
      enableSubmitAction(true)
    }
  }, [data, isCreatePreviewSuccess, isPending])

  return (
    <LegacyDialog
      onClose={onClose}
      title={dialogTitle}
      variant="backoffice"
      style={dialogStyle}
      submitDisabled={!submitActionEnabled}
      submitLabel="Valider les changements"
      onSubmit={handleSubmit}
    >
      <div
        css={css`
          margin-top: ${spacing[70]};
          display: flex;
          flex-direction: column;
          gap: ${spacing[50]};
        `}
      >
        {!isCreatePreviewSuccess ? (
          <LocalLoadingState />
        ) : isSuccess(data) ? (
          <>
            <TestArea backofficeGroups={data.output.backoffice} />
            <GroupDescriptionList groups={diff.removedGroups} title="Activités supprimées" />
            <GroupDescriptionList groups={diff.addedGroups} title="Activités ajoutées" />
            <GroupDescriptionList groups={diff.updatedGroups} title="Activités mises à jours" />
          </>
        ) : (
          <IssuesList issues={data.problem} />
        )}
      </div>
    </LegacyDialog>
  )
})

const IssuesList = memo<{ issues: string[] }>(function IssuesList({ issues }) {
  return (
    <div>
      <Text>Certaines activités regroupées ont des paramètres produits différents :</Text>
      <ul>
        {issues.map((issue) => (
          <li key={issue}>
            <Text>{issue}</Text>
          </li>
        ))}
      </ul>
    </div>
  )
})

type TestAreaProps = {
  backofficeGroups: GroupData[]
}

const TestArea = memo<TestAreaProps>(function TestArea({ backofficeGroups }) {
  const [operatingZone, setOperatingZone] = useState<OperatingZone>('fr')
  const completeGroupPreviewLoader: CompleteGroupLoader = useMemo(() => {
    return {
      async getGroups(): Promise<GroupData[]> {
        return backofficeGroups
      },
    }
  }, [backofficeGroups])

  return (
    <div>
      <DialogSubtitle title="Zone de test" />
      <div
        css={css`
          width: 600px;
          display: flex;
          flex-direction: column;
          gap: ${spacing[30]};
          margin-top: ${spacing[50]};
        `}
      >
        <DropdownDimensionField
          state={{ operatingZone }}
          valueDimension={operatingZoneDimension}
          setChanges={({ operatingZone }) => {
            if (operatingZone) setOperatingZone(operatingZone)
          }}
        />
        <TextInputLabelWrapper label="Recherche (vue client)">
          <ActivitySearchField onActivitySelected={noop} operatingZone={operatingZone} />
        </TextInputLabelWrapper>
        <TextInputLabelWrapper label="Recherche (vue backoffice)">
          <MainActivitySearchField
            groupLoader={completeGroupPreviewLoader}
            onChange={noop}
            operatingZone={operatingZone}
          />
        </TextInputLabelWrapper>
      </div>
    </div>
  )
})

type GroupDescriptionListProps = { title: string; groups: GroupDescription[]; className?: string }

const GroupDescriptionList = memo<GroupDescriptionListProps>(function GroupDescriptionList({
  title,
  groups,
  className,
}) {
  return groups.length > 0 ? (
    <div className={className}>
      <DialogSubtitle title={title} />
      <ul>
        {groups.map((group) => (
          <GroupDescriptionLi key={group.name} group={group} />
        ))}
      </ul>
    </div>
  ) : (
    <></>
  )
})

type GroupDescriptionLiProps = { group: GroupDescription }

const GroupDescriptionLi = memo<GroupDescriptionLiProps>(function GroupDescriptionLi({ group }) {
  const aliasPrecision = group.aliases.length === 0 ? '' : ` (${group.aliases.join(', ')})`
  const title = `${group.name}${aliasPrecision}`
  return <li>{title}</li>
})

const DialogSubtitle = memo<{ title: string }>(function DialogSubtitle({ title }) {
  return <Text variant="h6">{title}</Text>
})
