import { canNavigate, isStepSingleClick, type Exclusion } from '@orus.eu/dimensions'
import type { StatementChoiceConfirmationSubscriptionFooterUiElement } from '@orus.eu/dimensions/src/subscription-ui/subscription-footer-ui-element'
import {
  borderRadius,
  Button,
  colorTokens,
  desktopMediaQuery,
  shadow,
  spacing,
  Text,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { useCallback, useRef } from 'react'

import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import { ifFooterStateProxy } from '../if-footer-state-proxy'
import { useHandleExclusionProblem } from '../use-handle-exclusion-problem'

export const StatementChoiceConfirmationFooterUiElementBlock = ifFooterStateProxy<
  StatementChoiceConfirmationSubscriptionFooterUiElement & {
    shouldDisplaySecondaryNavigationElement?: boolean
  }
>(function StatementChoiceConfirmationFooterUiElementBlock(props) {
  const {
    stateProxy,
    uiElement,
    nextEnabled,
    goBackToPreviousStep,
    isLoadingWhileTryCompleteStep,
    step,
    isFirstStep,
    className,
    handleSubmit,
    subscriptionId,
    stepId,
  } = props

  const translate = useTranslate()
  const footerRef = useRef<HTMLDivElement>(null)
  const { ref: triggerRef, inView: inView } = useInView({
    threshold: 1,
    onChange: (inView) => footerRef.current?.classList?.toggle?.('is-pinned', !inView),
  })

  const isDesktop = useUiContext() === 'desktop'
  const handleExclusionProblem = useHandleExclusionProblem()

  const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
  const riskCarrierProducts = stateProxy.readRequired(uiElement.dimensions.riskCarrierProducts)
  const activity = stateProxy.readRequired(uiElement.dimensions.activity)
  const activityName = activity?.displayName

  const handleRefuseStatement = useCallback(() => {
    const exclusion: Exclusion = { name: 'statementRefusedExclusion', message: 'exclusion_statement_refused' }

    handleExclusionProblem({ operatingZone, subscriptionId, stepId, exclusion, riskCarrierProducts, activityName })
  }, [activityName, handleExclusionProblem, operatingZone, riskCarrierProducts, stepId, subscriptionId])

  const handleConfirmStatement = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const scrollToTheEnd = () => {
    return footerRef.current?.scrollIntoView?.()
  }

  const showJumpToBottom = !!step.jumpToBottom
  const showNextStepLabel = step.nextStepLabelTip !== undefined
  const showNextStepButton = !isStepSingleClick[step.id] && canNavigate(step.id)
  const showPreviousStepButton = !isFirstStep && !isDesktop && canNavigate(step.id)
  const shouldDisplayScrollButtonInMobile = showJumpToBottom && !inView && !isDesktop
  const shouldDisplaySecondaryNavigationElement =
    uiElement.shouldDisplaySecondaryNavigationElement && !shouldDisplayScrollButtonInMobile && operatingZone === 'es'

  if ((!showNextStepLabel && !showNextStepButton && !showPreviousStepButton) || step.hideFooterElement) return <></>

  return (
    <>
      <div ref={footerRef}>
        {showJumpToBottom && !inView && isDesktop && (
          <FloatingButton icon="angle-down-solid" onClick={scrollToTheEnd} />
        )}

        <div ref={triggerRef}></div>

        <ButtonRowDiv className={className} inView={inView}>
          {showNextStepLabel && <Text variant="body2">{step.nextStepLabelTip}</Text>}

          {showPreviousStepButton && (
            <SecondaryButton
              variant="secondary"
              size="large"
              icon="arrow-left-regular"
              avatarPosition="left"
              onClick={goBackToPreviousStep}
              className="previous-button"
            >
              {isDesktop ? translate('back') : ''}
            </SecondaryButton>
          )}

          {shouldDisplayScrollButtonInMobile && (
            <PrimaryButton
              size="large"
              isLoading={isLoadingWhileTryCompleteStep}
              onClick={scrollToTheEnd}
              className="next-button"
              icon="angle-down-solid"
              avatarPosition="right"
            >
              {translate('subscription_funnel_statement_scroll_to_bottom')}
            </PrimaryButton>
          )}

          {shouldDisplaySecondaryNavigationElement && (
            <SecondaryButton
              variant="secondary"
              size={!isDesktop ? 'large' : 'medium'}
              onClick={handleRefuseStatement}
              icon={isDesktop ? undefined : 'xmark-regular'}
            >
              {isDesktop ? translate('subscription_funnel_statement_refuse') : ''}
            </SecondaryButton>
          )}

          {showNextStepButton && !shouldDisplayScrollButtonInMobile && (
            <PrimaryButton
              disabled={!nextEnabled}
              size={!isDesktop ? 'large' : 'medium'}
              isLoading={isLoadingWhileTryCompleteStep}
              onClick={handleConfirmStatement}
              className="next-button"
            >
              {translate('subscription_funnel_statement_confirm')}
            </PrimaryButton>
          )}
        </ButtonRowDiv>
      </div>
    </>
  )
})

const FloatingButton = styled(Button)`
  position: fixed;
  bottom: ${spacing[60]};
  right: ${spacing[50]};
  z-index: 1000;
`

const SecondaryButton = styled(Button)`
  margin-right: ${spacing[30]};

  ${desktopMediaQuery} {
    margin-right: 0;
  }
`

const PrimaryButton = styled(Button)`
  flex-grow: 1;

  ${desktopMediaQuery} {
    flex-grow: 0;
  }
`

const ButtonRowDiv = styled.div<{ inView: boolean }>`
  display: flex;
  flex-direction: row;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: ${spacing[50]} ${spacing[60]};
  background-color: ${colorTokens['color-bg-base-normal']};
  z-index: 100;
  border-radius: ${borderRadius[30]} ${borderRadius[30]} 0 0;
  box-shadow: ${({ inView }) => (!inView ? shadow.top[20] : 'none')};

  ${desktopMediaQuery} {
    gap: ${spacing[50]};
    max-width: 760px;
    margin-top: ${spacing[70]};
    margin-bottom: ${spacing[50]};
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    justify-content: flex-end;
  }
`
