import { AbstractDimension } from '../../abstract-dimension.js'
import type { RcpaQuoteV3 } from './rcpa-quote-types.js'

export class RcpaQuoteDimension<NAME extends string> extends AbstractDimension<NAME, RcpaQuoteV3> {}

export const rcpaQuoteDimension = new RcpaQuoteDimension({
  name: 'rcpaQuote',
  displayValues: { name: 'Devis RC Pro AIG' },
  tags: ['RCPA'],
} as const)
