import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Radio as MuiRadio } from '@mui/material'
import { forwardRef } from 'react'
import { colorTokens } from '../../foundation/index.js'

const RadioCommon = styled.span<Pick<RadioProps, 'size'>>((props) => ({
  borderRadius: '50%',
  width: props.size === 'small' ? 16 : 24,
  height: props.size === 'small' ? 16 : 24,
  '.Mui-focusVisible &': {
    outline: `1px solid ${colorTokens['color-stroke-base-focus']}`,
    boxShadow: 'none',
  },
}))

const RadioUnselected = styled(RadioCommon)(() => ({
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))',
  outline: `1px solid ${colorTokens['color-stroke-base']}`,
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.03) inset',
  backgroundColor: `${colorTokens['color-bg-base-normal']}`,

  'input:enabled:hover ~ &': {
    boxShadow: `0px 0px 0px 4px ${colorTokens['color-stroke-base-highlight']}`,
    backgroundColor: colorTokens['color-bg-base-hover'],
    outline: `1px solid ${colorTokens['color-stroke-base-hover']}`,
  },

  'input:disabled ~ &': {
    backgroundColor: `${colorTokens['color-bg-base-disable']}`,
    boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.03) inset`,
    outline: `1px solid ${colorTokens['color-stroke-base-disable']}`,
  },
}))

const RadioSelected = styled(RadioCommon)((props) => ({
  backgroundColor: `${colorTokens['color-bg-base-active']}`,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: props.size === 'small' ? 16 : 24,
    height: props.size === 'small' ? 16 : 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover:enabled ~ &': {
    boxShadow: `0px 0px 0px 4px ${colorTokens['color-bg-base-tertiary']}`,
  },
  'input:disabled ~ &': {
    backgroundColor: `${colorTokens['color-bg-base-disable']}`,
    boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.03) inset`,
  },
}))

type RadioProps = {
  value: string
  size: 'small' | 'medium'
  className?: string
  disabled?: boolean
  checked?: boolean
  onChange?: () => void
}

export const Radio = forwardRef<HTMLButtonElement, RadioProps>(function Radio(props, ref) {
  return (
    <MuiRadio
      checkedIcon={<RadioSelected size={props.size} />}
      icon={<RadioUnselected size={props.size} />}
      value={props.value}
      css={css`
        padding: 0;
      `}
      className={props.className}
      disabled={props.disabled}
      inputRef={ref}
      checked={props.checked}
      onChange={props.onChange}
    />
  )
})
