import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import type { RcphProduct } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import type { Product } from '@orus.eu/product'
import { trpcReact } from '../../../../../client'
import { ActivitySearchField } from './ActivitySearchField'

export function SecondaryActivitiesSearchField({
  operatingZone,
  disabled,
  product,
  rcphProduct,
  selectedActivityIds,
  helperText,
  onChange,
}: {
  operatingZone: OperatingZone
  product: Product
  rcphProduct?: RcphProduct
  selectedActivityIds?: Set<string>
  disabled?: boolean
  helperText?: string
  onChange: (group: GroupData) => void
}): React.ReactNode {
  const { data: list } =
    trpcReact.selfOnboarding.getGroupsWithSelfOnboardingForProductIncludingSecondaryActivities.useQuery({
      operatingZone,
      product,
      rcphProduct,
    })

  const secondaryActivities = (list ?? []).reduce((acc: GroupData[], group) => {
    if (acc.some((existingGroup) => existingGroup.id === group.mainActivityId)) return acc

    if (selectedActivityIds?.has(group.mainActivityId)) return acc

    acc.push({
      name: group.name,
      operatingZone,
      description: group.description,
      products: group.products,
      id: group.mainActivityId,
    })
    return acc
  }, [])

  return (
    <ActivitySearchField
      searchOptions={secondaryActivities}
      onChange={onChange}
      disabled={disabled}
      helperText={helperText}
    />
  )
}
