import type { BadgeVariant } from '@orus.eu/pharaoh'
import type { ComplyAdvantageCheck } from '../widget-entity'

export function getComplyAdvantageRiskColor(check: ComplyAdvantageCheck): { variant: BadgeVariant; dark: boolean } {
  if (check.stageType === 'no_case') {
    return { variant: 'gray', dark: false }
  }
  if (check.decisionType === 'approved') {
    return { variant: 'green', dark: true }
  }
  if (check.decisionType === 'rejected') {
    return { variant: 'red', dark: true }
  }

  return { variant: 'blue', dark: true }
}
