import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { type AggregatedQuote, type SummarySectionState } from '@orus.eu/dimensions'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { colorTokens, Select, spacing, Text, Tooltip } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import { detailedSheetStatusEntries, type ComplianceData, type DetailedSheetStatus } from '../detailed-sheet.types'
import OverviewActivitySection from './overview/OverviewActivitySection'
import OverviewDocumentsSection from './overview/OverviewDocumentsSection'
import OverviewGlobalInfoSection from './overview/OverviewGlobalInfoSection'
import OverviewGlobalNoteSection from './overview/OverviewGlobalNoteSection'
import OverviewInsuranceHistoryTimelineSection from './overview/OverviewInsuranceHistoryTimelineSection'
import OverviewTimelineSection from './overview/OverviewTimelineSection'

const detailedSheetStatusLabels = Object.fromEntries(
  Object.entries(detailedSheetStatusEntries).map(([key, entry]) => [key, entry.label]),
)

const detailedSheetStatusValues = Object.keys(detailedSheetStatusEntries)

type SummarySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: SummarySectionState
  stateBefore: SummarySectionState
  setChanges: (changes: SummarySectionState) => void
  subscriptionId: string
  quote: AggregatedQuote | undefined | null
  filesPerCategory: Partial<Record<GroupCategory | 'none', File[]>>
  groupStatusByCategory: Partial<Record<GroupCategory | 'none', GroupStatus>>
  onIdFileGroupStatusClick: (label: GroupCategory) => void
  complianceData: ComplianceData
  onFileClick: (file: File) => void
  showAcag: boolean
  isReadOnly: boolean
}

export const SummarySection = memo(function SummarySection(props: SummarySectionProps) {
  const {
    state,
    setChanges,
    filesPerCategory,
    groupStatusByCategory,
    complianceData,
    onIdFileGroupStatusClick,
    onFileClick,
    showAcag,
    isReadOnly,
  } = props

  const { detailedSheetExperienceStatus } = state

  const experienceFiles = []

  if (filesPerCategory.invoices) {
    experienceFiles.push(...filesPerCategory.invoices)
  }
  if (filesPerCategory.experience) {
    experienceFiles.push(...filesPerCategory.experience)
  }

  return (
    <SummarySectionWrapper>
      <SummarySectionHeaderContainer>
        <Text variant="body1Medium">Récapitulatif du dossier</Text>
        <SummarySectionHeader>
          <Tooltip title={detailedSheetStatusEntries[detailedSheetExperienceStatus ?? 'pioupiou'].description}>
            <SummarySectionHeaderStatus>
              {detailedSheetStatusEntries[detailedSheetExperienceStatus ?? 'pioupiou'].emoji}
            </SummarySectionHeaderStatus>
          </Tooltip>
          <Select
            css={css`
              align-self: center;
              width: 150px;
            `}
            aria-label="Statut de la fiche détaillée"
            value={detailedSheetExperienceStatus ?? 'pioupiou'}
            values={detailedSheetStatusValues}
            onChange={(value) => {
              setChanges({
                detailedSheetExperienceStatus: value as DetailedSheetStatus,
              })
            }}
            size="small"
            placeholder="Statut"
            labels={detailedSheetStatusLabels}
            disabled={isReadOnly}
          />
        </SummarySectionHeader>
      </SummarySectionHeaderContainer>

      <SummarySectionContainer>
        <OverviewGlobalInfoSection state={state} complianceData={complianceData} />
        <OverviewActivitySection state={state} showAcagComment={showAcag} />
        <OverviewDocumentsSection
          profile={detailedSheetExperienceStatus ?? 'other'}
          groupStatusByCategory={groupStatusByCategory}
          files={experienceFiles}
          onIdFileGroupStatusClick={onIdFileGroupStatusClick}
          onFileClick={onFileClick}
          showAcag={showAcag}
        />
        {detailedSheetExperienceStatus === 'coq' || detailedSheetExperienceStatus === 'aigle' ? (
          <OverviewInsuranceHistoryTimelineSection state={state} />
        ) : null}
        <OverviewTimelineSection state={state} />

        <OverviewGlobalNoteSection state={state} setChanges={setChanges} isReadOnly={isReadOnly} />
      </SummarySectionContainer>
    </SummarySectionWrapper>
  )
})

const SummarySectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
`

const SummarySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};
`

const SummarySectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SummarySectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[30]};
`
const SummarySectionHeaderStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorTokens['color-bg-neutral-inverse']};
  border-radius: ${spacing[30]};
  padding: ${spacing[30]};
  height: 32px;
  width: 32px;
  font-size: 13px;
`
