import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { DetailedSheetStatus } from '../../detailed-sheet.types'

export const GROUP_CATEGORIES_BY_PROFILE: Record<DetailedSheetStatus, GroupCategory[]> = {
  pioupiou: ['id', 'immatriculation', 'proformaInvoice', 'RIB', 'experience', 'other', 'acag'],
  poussin: ['id', 'immatriculation', 'proformaInvoice', 'RIB', 'experience', 'other', 'acag'],
  coq: [
    'id',
    'immatriculation',
    'proformaInvoice',
    'RIB',
    'experience',
    'infoReport',
    'decennaleCertificate',
    'other',
    'acag',
  ],
  aigle: [
    'id',
    'immatriculation',
    'proformaInvoice',
    'RIB',
    'experience',
    'infoReport',
    'decennaleCertificate',
    'other',
    'acag',
  ],
  other: [
    'id',
    'immatriculation',
    'proformaInvoice',
    'RIB',
    'experience',
    'infoReport',
    'decennaleCertificate',
    'other',
    'acag',
  ],
}
