import { createContext, useContext, type ContextType } from 'react'

const skinNames = [
  'light',
  'dark',
  'sidecare',
  'superindep',
  'propulse',
  'jump',
  'shine',
  'legalstart',
  'legalplace',
  'dougs',
  'previi',
] as const

export type SkinName = (typeof skinNames)[number]

const skinContext = createContext<{ setSkin: (skin: SkinName) => void; skin: SkinName | null }>({
  skin: null,
  setSkin: () => {},
})
export const SkinContextProvider = skinContext.Provider

export function usePharaohSkin(): ContextType<typeof skinContext> {
  return useContext(skinContext)
}

export const isPharaohSkin = (skin: string): skin is SkinName => skinNames.includes(skin as SkinName)
