import { createFileRoute } from '@tanstack/react-router'

import PlatformUserPage from '../../../../components/pages/backoffice/platform/platform-user-page'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/users/$userId')({
  component: () => (
    <PlatformPage requiredPermissions={['users.read']}>
      <PlatformUserPage />
    </PlatformPage>
  ),
})
