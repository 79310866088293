import { activityInputType } from '@orus.eu/activity'
import { amountArkType } from '@orus.eu/amount'
import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from '../abstract-dimension'

export class RcpaAgentDimension<NAME extends string> extends AbstractDimension<NAME, RcpaAgentData> {
  override validateData(value: LooselyTypedValue): Result<RcpaAgentData, DimensionValidationProblem> {
    const result = rcpaAgentType(value)
    if (result instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }
    return success(result)
  }
}

export const rcpaAgentType = type({
  lastName: 'string',
  firstName: 'string',
  siren: /^[0-9]{9}$/,
  activities: activityInputType.array(),
  loiAmount: amountArkType,
})

export type RcpaAgentData = typeof rcpaAgentType.infer
