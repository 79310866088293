import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import PlatformPage from '../../../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId')({
  component: () => (
    <PlatformPage requiredPermissions={['contracts.read']}>
      <DetailedSheetPage />
    </PlatformPage>
  ),
})
