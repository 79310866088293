import styled from '@emotion/styled'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { getCompanyIdNumberSirenValue, type CompanyIdNumber } from '@orus.eu/company-id-number'
import type { CompanySectionState } from '@orus.eu/dimensions'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import {
  DatepickerFormField,
  FormRow,
  sirenMapper,
  spacing,
  TextFieldFormField,
  ValidatedTextFieldFormField,
  WidgetBase,
} from '@orus.eu/pharaoh'
import { memo, useState } from 'react'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { ComplianceData, DocumentGroupStatus } from '../detailed-sheet.types'
import CommonSection from './common/CommonSection'
import DocumentsList from './common/DocumentsList'
import { WidgetCompanyEstablishment, WidgetCompanyInformation, WidgetCompanyScore } from './widget'
import { getExternalLink } from './widget/helper/get-external-link'
import { LYSTA_SCORE_COLORS } from './widget/helper/get-score-badge-variant'

type CompanySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: CompanySectionState
  stateBefore: CompanySectionState
  setChanges: (changes: CompanySectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (id: GroupCategory) => void
  complianceData: ComplianceData
  isComplianceDataLoading: boolean
  onFileClick: (selectedFile: File) => void
  isReadOnly: boolean
}

export const CompanySection = memo(function CompanySection(props: CompanySectionProps) {
  const {
    state,
    setChanges,
    onIdFileGroupStatusClick,
    idsFileGroupStatus,
    complianceData,
    isComplianceDataLoading,
    onFileClick,
    isReadOnly,
  } = props

  const { companyName, companyCreationDate, companyIdNumber, detailedSheetCompanySynthesis } = state

  const { pappersCompany, lystaCompany } = complianceData

  const [companyNameValue, setCompanyNameValue] = useState(companyName ?? '')
  const [companyCreationDateValue, setCompanyCreationDateValue] = useState(companyCreationDate)
  const [companyIdNumberValue, setCompanyIdNumberValue] = useState(companyIdNumber)

  const handleClick = (category: GroupCategory, status: GroupStatus) => {
    if (status !== 'empty') {
      onIdFileGroupStatusClick(category)
    }
  }

  const siren = getCompanyIdNumberSirenValue(companyIdNumberValue)

  const leftContent = (
    <>
      <FormRow>
        <ValidatedTextFieldFormField<CompanyIdNumber | undefined>
          size={'small'}
          label={'SIREN'}
          value={companyIdNumberValue}
          placeholder={'552178639'}
          onBlur={() => setChanges({ companyIdNumber: companyIdNumberValue })}
          onChange={(value) => setCompanyIdNumberValue(value)}
          mapper={sirenMapper}
          infoTooltip={''}
          required={true}
          disabled={isReadOnly}
        />
        <TextFieldFormField
          value={companyNameValue}
          label="Raison sociale"
          size="small"
          placeholder="CRM France"
          onBlur={() => setChanges({ companyName: companyNameValue })}
          onChange={(value) => setCompanyNameValue(value.target.value)}
          disabled={isReadOnly}
        />
        <DatepickerFormField
          required
          label="Date de création ou reprise"
          value={companyCreationDateValue}
          size="small"
          fullWidth={true}
          infoTooltip="Date d'immatriculation qui figure sur votre extrait Kbis"
          onChange={(value) => {
            setCompanyCreationDateValue(value)
            setChanges({ companyCreationDate: value })
          }}
          disabled={isReadOnly}
        />
      </FormRow>
      <FormRow>
        <CompanySectionComplianceContainer>
          <FormRow>
            <WidgetCompanyScore
              title="Lysta"
              score={lystaCompany?.trustworthiness.score ?? undefined}
              scoreColors={LYSTA_SCORE_COLORS}
              href={getExternalLink(siren, 'lysta') ?? undefined}
              isLoading={isComplianceDataLoading}
            />
            <WidgetBase
              title="Creditsafe"
              badgeDark={true}
              href={getExternalLink(siren, 'creditsafe') ?? undefined}
              hasChildren={false}
            />
          </FormRow>
          <WidgetCompanyInformation
            title="Informations"
            href={getExternalLink(siren, 'pappers') ?? undefined}
            name={pappersCompany ? pappersCompany.name : undefined}
            commercialName={pappersCompany ? pappersCompany.commercialName : undefined}
            legalForm={pappersCompany ? pappersCompany.legalForm : undefined}
            registrationDate={pappersCompany ? pappersCompany.registrationDate : undefined}
            isLoading={isComplianceDataLoading}
          />
        </CompanySectionComplianceContainer>
        <WidgetCompanyEstablishment
          title="Etablissements"
          href={getExternalLink(siren, 'pappers') ?? undefined}
          establishments={pappersCompany ? pappersCompany.establishments : undefined}
          isLoading={isComplianceDataLoading}
        />
      </FormRow>
    </>
  )

  const rightContent = (
    <DocumentsList
      idsFileGroupStatus={idsFileGroupStatus}
      onIdFileGroupStatusClick={handleClick}
      onFileClick={onFileClick}
    />
  )

  return (
    <CommonSection
      value={detailedSheetCompanySynthesis}
      leftContent={leftContent}
      rightContent={rightContent}
      defaultTable="company"
      onChange={(value) => {
        setChanges({ detailedSheetCompanySynthesis: value })
      }}
    />
  )
})

const CompanySectionComplianceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[50]};
`
