import { css } from '@emotion/react'
import { Button, Dialog, FlexSpacedColumn, TextFieldFormField, useCrash } from '@orus.eu/pharaoh'
import { memo, useCallback, useEffect, useState } from 'react'

export const ApiTokenGeneratorDialog = memo<{
  onClose: () => void
}>(function ApiTokenGeneratorDialog({ onClose }) {
  const crash = useCrash()
  const [token, setToken] = useState(generateSecureRandomString())
  const [sha256Hash, setSha256Hash] = useState('')

  useEffect(() => {
    let cancelled = false
    generateSha256Hash(token).then(
      (sha256Hash) => {
        if (!cancelled) {
          setSha256Hash(sha256Hash)
        }
      },
      (err) => {
        if (!cancelled) {
          crash(err)
        }
      },
    )
    return () => {
      cancelled = true
    }
  }, [token, crash])

  const regenerateToken = useCallback(() => {
    setToken(generateSecureRandomString())
    setSha256Hash('')
  }, [])

  return (
    <Dialog title="Générer un token API" onClose={onClose} size="medium">
      <FlexSpacedColumn
        margin="0"
        padding="0"
        css={css`
          width: 100%;
        `}
      >
        <TextFieldFormField
          fullWidth
          label="Token"
          caption="Token à envoyer au partenaire"
          value={token}
          size="small"
          disabled
        />
        <TextFieldFormField
          caption="SHA256 à stocker dans le champ apiTokenHashes"
          label="SHA256"
          value={sha256Hash}
          size="small"
          disabled
        />
        <Button onClick={regenerateToken}>Générer un nouveau token</Button>
      </FlexSpacedColumn>
    </Dialog>
  )
})

function generateSecureRandomString() {
  // length here is the number of bytes you want, not the length of the final string
  const byteArray = new Uint8Array(32)

  // Fill the array with cryptographically secure random values
  crypto.getRandomValues(byteArray)

  // Convert each byte to a 2-digit hexadecimal string
  const hexString = Array.from(byteArray, (byte) => byte.toString(16).padStart(2, '0')).join('')

  return hexString
}

async function generateSha256Hash(message: string) {
  // Convert the message string into a Uint8Array for hashing
  const msgUint8 = new TextEncoder().encode(message)

  // Compute the SHA-256 digest of the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)

  // Convert the resulting ArrayBuffer to an array of bytes
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // Convert bytes to a hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}
