import { amountToString, areAmountsEqual, type AmountToStringOptions } from '@orus.eu/amount'
import { memo, type FunctionComponent } from 'react'

import type {
  BackofficeContractDescription,
  BackofficeContractVersionDescription,
} from '@orus.eu/backend/src/views/backoffice-contract-view'
import { PARIS, formatDdMmYyyy, getCalendarDateFromTimestamp } from '@orus.eu/calendar-date'
import { TechnicalError } from '@orus.eu/error'
import { Text } from '@orus.eu/pharaoh'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardPriceProps = {
  contract: BackofficeContractDescription
}

export const ContractCardPrice: FunctionComponent<ContractCardPriceProps> = memo(function ContractCardPrice({
  contract,
}) {
  const latestVersion = contract.versions.at(-1)
  if (!latestVersion) {
    throw new TechnicalError('Expected contract to have at least one version', {
      context: { subscriptionId: contract.subscriptionId },
    })
  }

  return (
    <DataRow>
      <Text variant="body2">Yearly price</Text>
      <ContractVersionYearlyPremiumText version={latestVersion} />
    </DataRow>
  )
})

type ContractYearlyPremiumTextProps = {
  version: BackofficeContractVersionDescription
}
export const ContractVersionYearlyPremiumText = memo<ContractYearlyPremiumTextProps>(
  function ContractYearlyPremiumText({ version }) {
    const { expectedYearlyPremiums } = version

    if (areAmountsEqual(expectedYearlyPremiums.firstYear, expectedYearlyPremiums.subsequentYears)) {
      return <Text variant="body2">{amountToString(expectedYearlyPremiums.firstYear, amountToStringOptions)}</Text>
    }

    const { firstRenewalTimestamp, isInFirstYear } = expectedYearlyPremiums

    return isInFirstYear ? (
      <span>
        <Text element="span" variant="body2Medium">
          {amountToString(expectedYearlyPremiums.firstYear, amountToStringOptions)} (première année)
        </Text>
        <Text element="span" variant="body2">
          , puis {amountToString(expectedYearlyPremiums.subsequentYears, amountToStringOptions)} à partir du{' '}
          {formatDdMmYyyy(getCalendarDateFromTimestamp(firstRenewalTimestamp, PARIS))}
        </Text>
      </span>
    ) : (
      <span>
        <Text element="span" variant="body2">
          {amountToString(expectedYearlyPremiums.firstYear, amountToStringOptions)} (première année), puis
        </Text>
        <Text element="span" variant="body2Medium">
          {' '}
          {amountToString(expectedYearlyPremiums.subsequentYears, amountToStringOptions)} depuis le{' '}
          {formatDdMmYyyy(getCalendarDateFromTimestamp(firstRenewalTimestamp, PARIS))}
        </Text>
      </span>
    )
  },
)

const amountToStringOptions: AmountToStringOptions = {
  addCurrency: true,
  displayDecimals: true,
}
