import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ContentContainerBackoffice, ResponsiveCard, spacing, Text } from '@orus.eu/pharaoh'
import type { Permission, Role } from '@orus.eu/right-access-management'
import { memo } from 'react'
import { trpcReact } from '../../../../../client'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'

export default memo(function RolesDocumentationPage() {
  const [{ rolePermissions, permissionDocumentation, roleDocumentation }] = trpcReact.roles.getRoles.useSuspenseQuery()
  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Rôles & permissions</BackofficeSectionTitle>
      {Object.keys(roleDocumentation).map((roleString) => {
        const role = roleString as Role
        return (
          <RoleDocumentation
            key={role}
            role={role}
            roleDescription={roleDocumentation[role]}
            permissions={rolePermissions[role]}
            permissionDocumentation={permissionDocumentation}
          />
        )
      })}
    </ContentContainerBackoffice>
  )
})

const RoleDocumentation = memo<{
  role: Role
  roleDescription: string
  permissions: Permission[]
  permissionDocumentation: Record<Permission, string>
}>(function StaticFileDownloadButton({ role, permissions, permissionDocumentation, roleDescription }) {
  return (
    <ResponsiveCard>
      <Text variant="h6">{role}</Text>
      <Text
        css={css`
          margin-top: ${spacing[30]};
        `}
        variant="body1"
      >
        {roleDescription}
      </Text>
      <PermissionGrid>
        {permissions.map((permission) => (
          <PermissionDocumentation
            key={permission}
            permission={permission}
            description={permissionDocumentation[permission]}
          />
        ))}
      </PermissionGrid>
    </ResponsiveCard>
  )
})

const PermissionDocumentation = memo<{ permission: Permission; description: string }>(function PermissionDocumentation({
  permission,
  description,
}) {
  return (
    <>
      <Text variant="body1Medium" element="span">
        {permission}
      </Text>
      <Text>{description}</Text>
    </>
  )
})

const PermissionGrid = styled.div`
  display: grid;
  gap: ${spacing[40]};
  grid-template-columns: 1fr 3fr;
  margin-top: ${spacing[50]};
`
