import styled from '@emotion/styled'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { Chip, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import {
  LABEL_AND_VARIANT_PER_FILE_STATUS,
  LABEL_AND_VARIANT_PER_GROUP_STATUS,
} from '../../../../subscription-v2/elements/documents-list-element/chip-config'
import type { DocumentGroupStatus } from '../../detailed-sheet.types'
import DocumentButton from './DocumentButton'
import EmptyDocumentList from './EmptyDocumentList'

type DocumentsListProps = {
  title?: string
  idsFileGroupStatus?: DocumentGroupStatus[]
  onIdFileGroupStatusClick?: (category: GroupCategory, status: GroupStatus) => void
  onFileClick?: (selectedFile: File) => void
}

const DocumentsList = memo(function DocumentsList(props: DocumentsListProps) {
  const { idsFileGroupStatus, onFileClick } = props

  return (
    <DocumentListWrapper>
      {idsFileGroupStatus?.map(({ status, label, category, files }) => (
        <DocumentListContainer key={category}>
          <DocumentListTitleContainer>
            <Text variant="body2Medium">{label}</Text>
            <Chip
              key={label}
              variant={LABEL_AND_VARIANT_PER_GROUP_STATUS[status ?? 'empty'].variant}
              dark={LABEL_AND_VARIANT_PER_GROUP_STATUS[status ?? 'empty'].dark}
              size="large"
            >
              {LABEL_AND_VARIANT_PER_GROUP_STATUS[status ?? 'empty'].label}
            </Chip>
          </DocumentListTitleContainer>

          {files?.length > 0 ? (
            files.map((file) => (
              <DocumentButton
                key={file.id}
                title={file.title}
                disabled={status === 'empty'}
                avatar={
                  <Chip variant={LABEL_AND_VARIANT_PER_FILE_STATUS[file.status ?? 'empty'].variant} dark size="large">
                    {LABEL_AND_VARIANT_PER_FILE_STATUS[file.status ?? 'empty'].label}
                  </Chip>
                }
                onClick={() => onFileClick?.(file)}
              />
            ))
          ) : (
            <EmptyDocumentList />
          )}
        </DocumentListContainer>
      ))}
    </DocumentListWrapper>
  )
})

export default DocumentsList

const DocumentListWrapper = styled.div`
  display: grid;
  flex-direction: column;
  gap: ${spacing[60]};
`

const DocumentListContainer = styled.div`
  display: grid;
  flex-direction: column;
  gap: ${spacing[30]};
`

const DocumentListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[40]};
  padding: ${spacing[20]} 0;
`
