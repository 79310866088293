import { createFileRoute, useParams } from '@tanstack/react-router'
import PlatformPendingEndorsementPage from '../../../../../../components/pages/backoffice/platform/platform-endorsement-page/PlatformPendingEndorsementPage'
import PlatformPage from '../../../../../../components/templates/platform-page'

const PendingEndorsementRoute = () => {
  const { subscriptionId } = useParams({
    strict: false,
  })

  return (
    <PlatformPage requiredPermissions={['endorsement.create']}>
      <PlatformPendingEndorsementPage key={subscriptionId} />
    </PlatformPage>
  )
}

export const Route = createFileRoute('/bak/contracts/$subscriptionId/endorsement/$endorsementId/')({
  component: () => <PendingEndorsementRoute />,
})
