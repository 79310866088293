import { type CompanyIdNumber } from '@orus.eu/company-id-number'
import { operatingZoneDimension, type SirenSubscriptionUiElement } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import {
  CheckboxContainer,
  companyIdNumberMapper,
  useLanguage,
  useTranslate,
  ValidatedTextFieldFormField,
} from '@orus.eu/pharaoh'
import { useCallback, useEffect } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const SirenSubscriptionUiElementBlock = ifStateProxy<SirenSubscriptionUiElement>(
  function SirenSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const translate = useTranslate()
    const language = useLanguage()
    const { companyIdNumber: companyIdNumberDimension, companyInCreationAllowed: companyInCreationAllowedDimension } =
      uiElement.dimensions
    const value = stateProxy.read(companyIdNumberDimension)
    const operatingZone = stateProxy.readRequired(operatingZoneDimension)
    const companyInCreationAllowed = stateProxy.read(companyInCreationAllowedDimension)
    const handleChange = useCallback(
      (value: CompanyIdNumber | undefined) => {
        stateProxy.write(companyIdNumberDimension, value ?? null)
      },
      [companyIdNumberDimension, stateProxy],
    )
    const handleCheckboxChange = useCallback(
      (checked: boolean) => {
        stateProxy.write(companyIdNumberDimension, checked ? { type: 'later' } : null)
      },
      [companyIdNumberDimension, stateProxy],
    )

    useEffect(() => {
      if (!companyInCreationAllowed && value?.type === 'later') {
        handleChange(undefined)
      }
    }, [companyInCreationAllowed, handleChange, value])

    const sirenLater = value?.type === 'later'
    return (
      <div>
        <ValidatedTextFieldFormField<CompanyIdNumber | undefined>
          disabled={sirenLater}
          size={context === 'backoffice' ? 'small' : 'large'}
          label={companyIdNumberLabel[operatingZone]}
          value={value}
          placeholder={
            sirenLater
              ? undefined
              : companyIdNumberDimension.placeholders
                ? companyIdNumberDimension.placeholders[language]
                : undefined
          }
          onChange={handleChange}
          mapper={companyIdNumberMapper[operatingZone]}
          infoTooltip={companyIdNumberDimension.hints ? companyIdNumberDimension.hints[language] : undefined}
          required={uiElement.required}
          highlight={uiElement.highlight}
        />
        {companyInCreationAllowed ? (
          <CheckboxContainer
            checked={sirenLater}
            onChange={handleCheckboxChange}
            size={context === 'backoffice' ? 'small' : 'medium'}
          >
            {translate('waiting_for_pending_registration_process')}
          </CheckboxContainer>
        ) : (
          <></>
        )}
      </div>
    )
  },
)

const companyIdNumberLabel: Record<OperatingZone, string> = {
  fr: 'SIREN',
  es: 'NIF o CIF',
}
