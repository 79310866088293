import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'

import { spacing } from '../../../foundation/spacing-tokens.js'
import { useScreenType } from '../../../hooks/index.js'
import { Avatar, Text, type CompoundIconName } from '../../atoms/index.js'
import { Button, type ButtonVariant } from '../../button/index.js'
import { LegacyDialog } from '../../templates/index.js'

type CustomModalProps = {
  title: string

  firstBlockAvatar: string
  firstBlockTitle: string
  firstBlockText: string
  firstBlockButtonText: string
  firstBlockIsButton: boolean

  secondBlockAvatar: string
  secondBlockTitle: string
  secondBlockText: string

  imageSrcDesktop: string
  imageSrcMobile: string

  isPrimaryButton: boolean
  primaryButtonText: string
  primaryButtonVariant: ButtonVariant | undefined
  primaryButtonIcon?: CompoundIconName
  primaryButtonAction: () => void

  isSecondaryButton?: boolean
  secondaryButtonText?: string
  secondaryButtonVariant?: ButtonVariant | undefined
  secondaryButtonIcon?: CompoundIconName
  secondaryButtonAction?: () => void

  children: ReactNode
  onClose: () => void
}

/**
 * @deprecated use `Dialog` instead
 */
export const CustomModal = memo<CustomModalProps>(function CustomModal(props) {
  const {
    title,
    firstBlockAvatar,
    firstBlockTitle,
    firstBlockText,
    secondBlockAvatar,
    secondBlockTitle,
    secondBlockText,
    imageSrcDesktop,
    imageSrcMobile,
    isPrimaryButton,
    primaryButtonText,
    primaryButtonVariant,
    primaryButtonAction,
    primaryButtonIcon,
    isSecondaryButton,
    secondaryButtonText,
    secondaryButtonVariant,
    secondaryButtonIcon,
    secondaryButtonAction,
    children,
    onClose,
  } = props

  const screenType = useScreenType()

  return (
    <LegacyDialog onClose={onClose}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[70]};
        `}
      >
        {screenType === 'mobile' ? <img src={imageSrcMobile} /> : <></>}
        <div
          css={css`
            display: flex;
          `}
        >
          <Text
            variant="h1"
            css={css`
              flex: 1;
            `}
          >
            {title}
          </Text>
          <div
            css={css`
              flex: ${screenType === 'mobile' ? 1 : 2};
            `}
          />
        </div>

        <div
          css={css`
            display: flex;
            gap: ${spacing[70]};
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[60]};
            `}
          >
            <CustomModalBlock src={firstBlockAvatar} title={firstBlockTitle} text={firstBlockText}>
              {children}
            </CustomModalBlock>

            <CustomModalBlock src={secondBlockAvatar} title={secondBlockTitle} text={secondBlockText} />
          </div>
          {screenType === 'desktop' ? <img src={imageSrcDesktop} width="327" height="360" /> : <></>}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: ${screenType === 'desktop' ? 'row-reverse' : 'column'};
            gap: ${spacing[50]};
          `}
        >
          {isPrimaryButton ? (
            <Button
              variant={primaryButtonVariant}
              icon={primaryButtonIcon}
              onClick={primaryButtonAction}
              size={screenType === 'mobile' ? 'large' : undefined}
            >
              {primaryButtonText}
            </Button>
          ) : (
            <></>
          )}
          {isSecondaryButton ? (
            <Button
              onClick={secondaryButtonAction}
              variant={secondaryButtonVariant}
              icon={secondaryButtonIcon}
              size={screenType === 'mobile' ? 'large' : undefined}
            >
              {secondaryButtonText}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </LegacyDialog>
  )
})

type CustomModalBlockProps = {
  src: string
  title: string
  text: string
  children?: ReactNode
}

export const CustomModalBlock = memo<CustomModalBlockProps>(function CustomModal(props) {
  const { src, title, text, children } = props
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[50]};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[30]};
        `}
      >
        <Avatar src={src} size="50" />
        <Text variant="body1Medium">{title}</Text>
        <Text variant="body2">{text}</Text>
      </div>
      {children}
    </div>
  )
})
