import { css } from '@emotion/react'
import { assertActivity } from '@orus.eu/activity'
import {
  normalizeActivitySpecificAnswers,
  type ActivitySpecificAnswersV2,
  type ActivitySpecificQuestion,
  type ActivitySpecificQuestionsSubscriptionUiElement,
} from '@orus.eu/dimensions'
import { spacing } from '@orus.eu/pharaoh'
import { SwitchContainer } from '@orus.eu/pharaoh/src/components/atoms/switch-container'
import { memo, useCallback } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const ActivitySpecificQuestionsSubscriptionUiElementBlock =
  ifStateProxy<ActivitySpecificQuestionsSubscriptionUiElement>(
    function ActivitySpecificQuestionsSubscriptionUiElementBlock({ stateProxy, uiElement }) {
      const activityRaw = stateProxy.read(uiElement.dimensions.activity)
      const activity = activityRaw ? assertActivity(activityRaw.activity) : undefined
      const activitySpecificQuestions = stateProxy.read(uiElement.dimensions.activitySpecificQuestions)
      const answers = stateProxy.read(uiElement.dimensions.answers)
      const normalizedAnswers = answers && activity ? normalizeActivitySpecificAnswers(answers, [activity]) : undefined

      const handleAnswerChange = useCallback(
        (question: ActivitySpecificQuestion, newAnswer: boolean) => {
          stateProxy.write(uiElement.dimensions.answers, {
            _version: 2,
            answers: {
              ...normalizedAnswers?.answers,
              [question.id]: newAnswer,
            },
          })
        },
        [normalizedAnswers, stateProxy, uiElement],
      )

      return (
        <>
          {activitySpecificQuestions ? (
            <ActivitySpecificAnswersBlock
              activitySpecificQuestions={activitySpecificQuestions}
              answers={normalizedAnswers}
              handleAnswerChange={handleAnswerChange}
            />
          ) : undefined}
        </>
      )
    },
  )

export type ActivitySpecificAnswersBlockProps = {
  activitySpecificQuestions: readonly ActivitySpecificQuestion[]
  answers: ActivitySpecificAnswersV2 | undefined
  handleAnswerChange: (question: ActivitySpecificQuestion, newAnswer: boolean) => void
  className?: string
}
export const ActivitySpecificAnswersBlock = memo<ActivitySpecificAnswersBlockProps>(
  function ActivitySpecificAnswersBlock({ activitySpecificQuestions, answers, handleAnswerChange, className }) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[30]};
        `}
        className={className}
      >
        {activitySpecificQuestions.map((question) => (
          <ActivitySpecificQuestion
            key={question.id}
            question={question}
            answer={!!answers?.answers[question.id]}
            onAnswerChange={handleAnswerChange}
          />
        ))}
      </div>
    )
  },
)

type ActivitySpecificQuestionProps = {
  question: ActivitySpecificQuestion
  answer: boolean
  onAnswerChange: (question: ActivitySpecificQuestion, newAnswer: boolean) => void
}
const ActivitySpecificQuestion = memo<ActivitySpecificQuestionProps>(function ActivitySpecificQuestion({
  question,
  answer,
  onAnswerChange,
}) {
  const handleChange = useCallback(
    (newValue: boolean) => {
      onAnswerChange(question, newValue)
    },
    [onAnswerChange, question],
  )
  return (
    <SwitchContainer checked={answer} onChange={handleChange} size="large">
      {question.affirmation}
    </SwitchContainer>
  )
})
