import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, type MouseEvent, type ReactNode } from 'react'
import { colorTokens } from '../../foundation/color-tokens.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { Tooltip } from '../tooltip/tooltip.js'
import { Avatar, Text } from './index.js'
import { Switch, type OrusSwitchSize } from './switch.js'

type SwitchContainerProps = {
  checked: boolean
  onChange: (newValue: boolean) => void
  border?: boolean
  children: ReactNode
  disabled?: boolean
  size?: OrusSwitchSize
  className?: string
  infoTooltip?: string
}

export const SwitchContainer = memo<SwitchContainerProps>(function SwitchContainer(props) {
  const {
    checked,
    onChange,
    children,
    disabled = false,
    border = false,
    size = 'small',
    className,
    infoTooltip,
  } = props

  const handleCardClick = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onChange(!checked)
    }
  }

  return (
    <Wrapper
      onClick={disabled ? undefined : handleCardClick}
      className={className}
      border={border}
      size={size}
      checked={checked}
      disabled={disabled}
    >
      <Label disabled={disabled} size={size}>
        <Switch checked={checked} onChange={() => onChange(!checked)} size={size} disabled={disabled} />
        <Text variant="body2">{children}</Text>
        {infoTooltip ? (
          <Tooltip title={infoTooltip}>
            <Avatar
              icon="circle-info-regular"
              size="10"
              css={css`
                flex-shrink: 0;
              `}
            />
          </Tooltip>
        ) : null}
      </Label>
    </Wrapper>
  )
})

export const Wrapper = styled.div<{ border: boolean; size: OrusSwitchSize; checked: boolean; disabled: boolean }>`
  padding: ${({ border }) => (border ? spacing[50] : 0)};
  border-radius: ${spacing[30]};
  outline: ${({ border, checked }) => (border && !checked ? `1px solid ${colorTokens['color-stroke-base']}` : 'none')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ border, checked }) => (border && checked ? colorTokens['color-bg-base-tertiary'] : 'none')};
`
const Label = styled.label<{ disabled: boolean; size: OrusSwitchSize }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ size }) => (size === 'small' ? spacing[40] : spacing[50])};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`
