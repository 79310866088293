import { AmountDimension } from '../amount-dimension'
import { BooleanDimension } from '../boolean-dimension'
import { ExclusionDimension } from '../exclusion-dimension'
import { IntegerDimension } from '../integer-dimension'
import { StringsEnumDimension } from '../strings-enum-dimension'

export * from './rcpa-activities-model/index'
export * from './rcpa-agent-dimension.js'
export * from './rcpa-department-dimension.js'
export * from './rcpa-loi-dimension.js'
export * from './rcpa-options-dimension.js'
export * from './rcpa-quote-offer-information.js'
export * from './rcpa-quote/index.js'
export * from './rcpa-subsidiaries-dimension.js'

export const rcpaSelectedDimension = new BooleanDimension({
  name: 'rcpaSelected',
  displayValues: { name: 'RC Pro AIG' },
  tags: ['RCPA'],
} as const)

export const rcpaAvailableDimension = new BooleanDimension({
  name: 'rcpaAvailable',
  displayValues: { name: 'RC Pro AIG disponible' },
  tags: ['RCPA'],
} as const)

export const rcpaHasOptionDirigeantAvailableDimension = new BooleanDimension({
  name: 'rcpaHasOptionDirigeantAvailable',
  displayValues: { name: 'Option Dirigeant disponible' },
  tags: ['RCPA'],
} as const)

export const rcpaOptionDirigeantDimension = new BooleanDimension({
  name: 'rcpaOptionDirigeant',
  displayValues: { name: 'Option Dirigeant' },
  tags: ['RCPA'],
} as const)

export const rcpaHasSubsidiariesDimension = new BooleanDimension({
  name: 'rcpaHasSubsidiaries',
  displayValues: { name: 'Le prospect a des filiales' },
  tags: ['RCPA'],
} as const)

export const rcpaHasAgentsDimension = new BooleanDimension({
  name: 'rcpaHasAgents',
  displayValues: { name: 'Le prospect a des mandataires' },
  tags: ['RCPA'],
} as const)

export const isRcpaCifWorkforceNeededDimension = new BooleanDimension({
  name: 'isRcpaCifWorkforceNeeded',
  displayValues: { name: 'Le nombre de conseillers en Investissement Financier est nécessaire' },
  tags: ['RCPA'],
} as const)

export const rcpaCifWorkforceDimension = new IntegerDimension({
  name: 'rcpaCifWorkforce',
  displayValues: { name: 'Nombre de conseillers en Investissement Financier' },
  tags: ['RCPA'],
} as const)

export const isRcpaPersonaNeededDimension = new BooleanDimension({
  name: 'isRcpaPersonaNeeded',
  displayValues: { name: "Le type d'entreprise est nécessaire" },
  tags: ['RCPA'],
} as const)

export const rcpaPersonaDimension = new StringsEnumDimension({
  name: 'rcpaPersona',
  displayValues: { name: "Type d'entreprise" },
  tags: ['RCPA'],
  values: ['moral', 'physique'],
} as const)

export const rcpaExclusionsDimension = new ExclusionDimension({
  name: 'rcpaExclusions',
  displayValues: { name: 'Exclusions spécifiques RC Pro AIG' },
  tags: ['RCPA'],
})

export const rcpaYearlyBasePremiumDimension = new AmountDimension({
  name: 'rcpaYearlyBasePremium',
  displayValues: { name: 'Cotisation annuelle HT' },
  tags: ['RCPA'],
} as const)

export const rcpaYearlyTaxesDimension = new AmountDimension({
  name: 'rcpaYearlyTaxes',
  displayValues: { name: 'Taxes annuelles' },
  tags: ['RCPA'],
} as const)

export const rcpaYearlyTotalPremiumDimension = new AmountDimension({
  name: 'rcpaYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC' },
  tags: ['RCPA'],
} as const)
