import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import { parseSirenValue } from '@orus.eu/company-id-number'
import type { LooselyTypedData, PlaceData, SiretSearchSubscriptionUiElement } from '@orus.eu/dimensions'
import { parseSiret, type JsonableSiretData } from '@orus.eu/siret'
import type { StateProxy, SubscriptionElementBlockProps } from '../../subscription-v2-props'

export function buildPlaceSelectionUpdate(
  place: PlaceData,
  uiElement: SiretSearchSubscriptionUiElement,
): LooselyTypedData {
  const siret = parseSiret(place.siret)
  const siretData: JsonableSiretData | undefined = siret ? { type: 'provided', siret } : undefined
  const siren = parseSirenValue(place.siret.substring(0, 9))
  const companyIdNumber: CompanyIdNumber | undefined = siren ? { type: 'inferred', siren } : undefined
  const placeName = place.name || undefined
  const companyName = place.companyName || undefined
  const activityAddressStreet = place.street || undefined
  const activityAddressPostCode = place.postCode || undefined
  const activityAddressCity = place.city || undefined
  const companyCreateDate = place.registrationDate
  return {
    [uiElement.dimensions.siret.name]: siretData,
    [uiElement.dimensions.companyIdNumber.name]: companyIdNumber,
    [uiElement.dimensions.placeName.name]: placeName,
    [uiElement.dimensions.companyName.name]: companyName,
    [uiElement.dimensions.activityAddressStreet.name]: activityAddressStreet,
    [uiElement.dimensions.activityAddressPostCode.name]: activityAddressPostCode,
    [uiElement.dimensions.activityAddressCity.name]: activityAddressCity,
    [uiElement.dimensions.companyCreationDate.name]: companyCreateDate,
  }
}

export type SiretSearchElementProps = {
  stateProxy: StateProxy
  uiElement: SiretSearchSubscriptionUiElement
  synchronizing: boolean
  handleSubmit: SubscriptionElementBlockProps['handleSubmit']
}
