import type { BadgeVariant } from '@orus.eu/pharaoh'

export function getScoreColors({
  score,
  scoreColors,
}: {
  score: number | string
  scoreColors: {
    min: number
    max: number
    color: BadgeVariant
  }[]
}): BadgeVariant {
  const scoreInt = typeof score === 'string' ? parseInt(score, 10) : score

  const matchedColor = scoreColors.find(({ min, max }) => scoreInt >= min && scoreInt <= max)

  return matchedColor?.color ?? 'neutral'
}

export const LYSTA_SCORE_COLORS: { min: number; max: number; color: BadgeVariant }[] = [
  { min: 0, max: 1, color: 'green' },
  { min: 2, max: 3, color: 'orange' },
  { min: 4, max: 5, color: 'red' },
]
