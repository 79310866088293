import { css } from '@emotion/react'
import { colorTokens, spacing, Text } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'

import styled from '@emotion/styled'
import RichTextEditor from '../rich-text-editor'
import type { TableTemplate } from '../rich-text-editor/components/toolbar/Toolbar'

type CommentAreaProps = {
  title?: string
  size?: 'small' | 'large'
  readonly?: boolean
  placeholder?: string
  onChange?: (value: string) => void
  value: string | null | undefined
  tableTemplate?: TableTemplate
  showEmptyPlaceholder?: boolean
  emptyPlaceholderLabel?: string
}

export const CommentArea = memo(function CommentArea({
  title,
  onChange,
  value,
  readonly,
  tableTemplate,
  showEmptyPlaceholder,
  emptyPlaceholderLabel,
}: CommentAreaProps): ReactNode {
  return (
    <div>
      {title ? (
        <Text
          variant="body2Medium"
          css={css`
            margin-bottom: ${spacing[20]};
          `}
        >
          {title}
        </Text>
      ) : null}
      {showEmptyPlaceholder && readonly ? (
        <EmptyCommentArea>
          <Text variant="body2">{emptyPlaceholderLabel}</Text>
        </EmptyCommentArea>
      ) : (
        <RichTextEditor onChange={onChange} value={value} readonly={readonly ?? false} tableTemplate={tableTemplate} />
      )}
    </div>
  )
})

const EmptyCommentArea = styled.div`
  display: flex;
  align-items: center;
  color: ${colorTokens['color-text-base-disable']};
`
