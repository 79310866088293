import { OptionsListDimension } from '../options-list-dimension.js'

export const rcpaOptionTypeNames = ['OPTION_DIRIGEANT'] as const
export type RcpaOptionType = (typeof rcpaOptionTypeNames)[number]

export const rcpaOptionsDimension = new OptionsListDimension<'rcpaOptions', RcpaOptionType>({
  name: 'rcpaOptions',
  displayValues: { name: 'Active RCPA options' },
  tags: ['RCPA'],
} as const)
