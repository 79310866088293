import { createFileRoute } from '@tanstack/react-router'

import ContractsPage from '../../components/pages/contracts/ContractsPage'
import CustomerPage from '../../components/templates/customer-page'

export const Route = createFileRoute('/contracts')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <ContractsPage />
    </CustomerPage>
  ),
})
