import { isActivity, type ActivityInput, type GroupsCategory } from '@orus.eu/activity'
import { productSelectionDimensions, type ActivitySearchSubscriptionUiElement } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { useAsyncCallback } from '@orus.eu/pharaoh'
import { useSearch } from '@tanstack/react-router'
import { selfOnboardingGroupMappingLoader } from '../../../../lib/group-mapping-util'
import { ActivitySearchField } from '../../../organisms/activity-search-field'
import { ifStateProxy } from '../if-state-proxy'
import { useHandleExclusionProblem } from '../use-handle-exclusion-problem'

export const ActivitySearchSubscriptionUiElementBlock = ifStateProxy<ActivitySearchSubscriptionUiElement>(
  function ActivitySearchSubscriptionUiElementBlock(props) {
    const handleExclusionProblem = useHandleExclusionProblem()
    const { uiElement, handleSubmit, stateProxy, subscriptionId, stepId } = props

    const activity = stateProxy.read<ActivityInput>(uiElement.dimensions.activity)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)

    const urlSearchParams = useSearch({ strict: false })

    const handleActivitySelected = useAsyncCallback(
      async (group: string) => {
        if (!group) return
        const groupDescription = await selfOnboardingGroupMappingLoader.getGroupMapping({ group, operatingZone })
        if (!groupDescription) {
          handleExclusionProblem({
            operatingZone,
            subscriptionId,
            stepId,
            exclusion: {
              name: 'genericActivityExclusion',
              message: {
                key: 'exclusion_activity',
                parameters: { description: group },
              },
            },
          })
          return
        }
        const { mainActivityId } = groupDescription
        if (!isActivity(mainActivityId)) {
          throw new TechnicalError('Invalid activity selected in category search', {
            context: { message: mainActivityId },
          })
        }

        handleSubmit(undefined, {
          [uiElement.dimensions.activity.name]: { activity: mainActivityId, displayName: group },
          [uiElement.dimensions.searchCategory.name]: 'bypassed',
          ...productSelectionDimensions.map((dimension) => ({
            [dimension.name]: urlSearchParams[dimension.name] ?? false,
          })),
        })
      },
      [
        operatingZone,
        handleSubmit,
        uiElement.dimensions.activity.name,
        uiElement.dimensions.searchCategory.name,
        urlSearchParams,
        handleExclusionProblem,
        subscriptionId,
        stepId,
      ],
    )

    const handleCategorySelected = useAsyncCallback(
      async (category: GroupsCategory) => {
        handleSubmit(undefined, {
          [uiElement.dimensions.activity.name]: null,
          [uiElement.dimensions.complementaryActivities.name]: [],
          [uiElement.dimensions.searchCategory.name]: category,
        })
      },
      [handleSubmit, uiElement.dimensions],
    )

    return (
      <ActivitySearchField
        operatingZone={operatingZone}
        defaultValue={activity?.displayName}
        onActivitySelected={handleActivitySelected}
        onCategorySelected={handleCategorySelected}
        autoFocus
      />
    )
  },
)
