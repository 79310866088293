import { Global, css } from '@emotion/react'
import { TechnicalError } from '@orus.eu/error'
import { useState, type FC, type ReactNode } from 'react'

import palette from './color-palette.json'
import { colorTokens } from './color-tokens.json'
import { SkinContextProvider, type SkinName } from './pharaoh-theme-context'
import { typescaleTokens } from './typescale-tokens.json'

const buildStyles = (skin: SkinName) => {
  if (!(skin in colorTokens)) {
    throw new TechnicalError(`Unknown Pharaoh Theme skin: ${skin}`)
  }

  if (!(skin in typescaleTokens)) {
    throw new TechnicalError(`Unknown Pharaoh Theme skin: ${skin}`)
  }

  const styles = {
    ...Object.entries(palette).reduce((styles, [key, value]) => ({ ...styles, [`--${key}`]: value }), {}),
    ...Object.entries(colorTokens[skin]).reduce((styles, [key, value]) => ({ ...styles, [`--${key}`]: value }), {}),
    ...Object.entries(typescaleTokens[skin]).reduce(
      (styles, [skinName, value]) => ({
        ...styles,
        ...Object.entries(value).reduce(
          (innerStyles, [variant, innerValue]) => ({
            ...innerStyles,
            [`--${skinName}-${variant}`]: innerValue,
          }),
          {},
        ),
      }),
      {},
    ),
  }

  return css({
    ':root': styles,
  })
}

export const PharaohThemeProvider: FC<{
  children: ReactNode
  skin?: SkinName
}> = ({ skin = 'light', children }) => {
  const [currentSkin, setSkin] = useState<SkinName | null>(null)
  const styles = buildStyles(currentSkin ?? skin)

  return (
    <SkinContextProvider value={{ skin: currentSkin, setSkin }}>
      <Global styles={styles} />
      {children}
    </SkinContextProvider>
  )
}
