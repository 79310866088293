import { createFileRoute } from '@tanstack/react-router'

import AccountPage from '../../components/pages/account/AccountPage'
import CustomerPage from '../../components/templates/customer-page'

export const Route = createFileRoute('/account')({
  component: () => (
    <CustomerPage requiredPermissions={['users.read']}>
      <AccountPage />
    </CustomerPage>
  ),
})
