import type { ExperienceSectionState, RcdaBossExperience } from '@orus.eu/dimensions'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { DropdownFormField, FormRow } from '@orus.eu/pharaoh'
import { memo, useState } from 'react'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import { LABEL_EXP_DIRIGEANT } from '../constants'
import type { DocumentGroupStatus } from '../detailed-sheet.types'
import { CommentArea } from './common/CommentArea'
import CommonSection from './common/CommonSection'
import DocumentsGridList from './common/DocumentsGridList'
import { DEFAULT_EXPERIENCE_TEMPLATE_AS_STRING } from './rich-text-editor/constants/experience-table-template'

type ExperienceSectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: ExperienceSectionState
  stateBefore: ExperienceSectionState
  setChanges: (changes: ExperienceSectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onFileClick: (selectedFile: File) => void
  isReadOnly: boolean
}

export const ExperienceSection = memo(function ExperienceSection(props: ExperienceSectionProps) {
  const { state, setChanges, idsFileGroupStatus, onFileClick, isReadOnly } = props

  const { rcdaBossExperience, detailedSheetExperienceSynthesis } = state

  const [rcdaBossExperienceValue, setRcdaBossExperienceValue] = useState(rcdaBossExperience ?? '')

  const detailedSheetExperienceSynthesisValue =
    detailedSheetExperienceSynthesis && detailedSheetExperienceSynthesis !== ''
      ? detailedSheetExperienceSynthesis
      : DEFAULT_EXPERIENCE_TEMPLATE_AS_STRING

  const handleTimelineChange = (value: string) => {
    setChanges({ detailedSheetExperienceSynthesis: value })
  }

  const leftContent = (
    <>
      <FormRow>
        <DropdownFormField
          required
          label="Expérience du dirigeant"
          infoTooltip="Les années complètes d’expérience, en incluant les apprentissages, les alternances (50 % en entreprise) et les stages de plus de 3 mois"
          value={rcdaBossExperienceValue}
          values={['0-1', '1-3', '4-5', '6-10', '10+']}
          onChange={(value) => {
            setRcdaBossExperienceValue(value as RcdaBossExperience)
            setChanges({ rcdaBossExperience: value as RcdaBossExperience })
          }}
          size="small"
          labels={LABEL_EXP_DIRIGEANT}
          disabled={isReadOnly}
        />
        <div />
        <div />
      </FormRow>
      <DocumentsGridList
        title="Justificatifs d'expérience"
        groupStatus={idsFileGroupStatus}
        onFileClick={onFileClick}
      />
      <CommentArea
        title="Timeline"
        onChange={handleTimelineChange}
        value={detailedSheetExperienceSynthesisValue}
        tableTemplate="experience"
        readonly={isReadOnly}
      />
    </>
  )

  const rightContent = null

  return (
    <CommonSection
      value={detailedSheetExperienceSynthesis}
      leftContent={leftContent}
      rightContent={rightContent}
      onChange={handleTimelineChange}
    />
  )
})
