import { zeroAmount, type Amount } from '@orus.eu/amount'
import type { InvoiceDataV2 } from './invoice-types'

export function getRcdaRepriseDuPasseTaxAmountFromInvoice(
  invoiceDataV2: Pick<InvoiceDataV2<unknown>, 'quotes' | 'isFirstOfYear' | 'isInFirstYear'>,
): Amount {
  return getRcdaRepriseDuPassePremiumFromInvoice(invoiceDataV2).tax
}

export function getRcdaRepriseDuPasseExTaxAmountFromInvoice(
  invoiceDataV2: Pick<InvoiceDataV2<unknown>, 'quotes' | 'isFirstOfYear' | 'isInFirstYear'>,
): Amount {
  return getRcdaRepriseDuPassePremiumFromInvoice(invoiceDataV2).exTax
}

function getRcdaRepriseDuPassePremiumFromInvoice(
  invoiceDataV2: Pick<InvoiceDataV2<unknown>, 'quotes' | 'isFirstOfYear' | 'isInFirstYear'>,
): {
  exTax: Amount
  tax: Amount
} {
  const isFirstInvoiceEver = invoiceDataV2.isInFirstYear && invoiceDataV2.isFirstOfYear
  if (!isFirstInvoiceEver) {
    return zeroHistoryTakeover
  }
  const historyTakeover = invoiceDataV2.quotes.rcda?.oneTime?.final.options.historyTakeover
  return historyTakeover?.active ? historyTakeover.premium : zeroHistoryTakeover
}

const zeroHistoryTakeover = { exTax: zeroAmount, tax: zeroAmount }
