import { css } from '@emotion/react'
import { Button, ButtonLink, spacing, Text, useAsyncCallback, useTranslate, useUiContext } from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import { memo } from 'react'
import { trpc, trpcReact } from '../../client'
import { sessionManager } from '../../lib/session'
import { ActionPageLayout } from '../templates/action-page-layout'
import { LoadingPage } from '../templates/loading-page'
import LoginPage from './login/login-page'

export default memo(function HomeUnconnectedPage() {
  const pendingSubscriptionId = trpcReact.subscriptions.getPendingSubscriptionId.useQuery()

  if (pendingSubscriptionId.isLoading) {
    // display "loading" while we fetch the result
    return <LoadingPage />
  }

  if (pendingSubscriptionId.data) {
    return <PendingSubscriptionSelectionPage subscriptionId={pendingSubscriptionId.data} />
  }

  // no subscription in progress
  return <LoginPage />
})

const PendingSubscriptionSelectionPage = memo<{ subscriptionId: string }>(function PendingSubscriptionSelectionPage({
  subscriptionId,
}) {
  const translate = useTranslate()
  const navigate = useNavigate()
  const screenType = useUiContext()

  const restartFromScratch = useAsyncCallback(async () => {
    // reinitialize session to be able to get a new inputSetId
    await trpc.sessions.logout.mutate(undefined)
    await sessionManager.refreshSession()

    void navigate({ to: '/login' })
  }, [navigate])

  return (
    <ActionPageLayout
      maxWidth="sm"
      body={
        <>
          <Text variant="h2">{translate('continue_subscription_page_title')}</Text>
          <Text
            variant="body2"
            css={css`
              margin-top: ${spacing[50]};
            `}
          >
            {translate('continue_subscription_page_subtitle')}
          </Text>
          <Text
            variant="body2"
            css={css`
              margin-top: ${spacing[50]};
            `}
          >
            {translate('continue_subscription_page_question')}
          </Text>
        </>
      }
      actions={
        <>
          <Button variant="secondary" onClick={restartFromScratch} size={screenType === 'mobile' ? 'large' : 'medium'}>
            {translate('continue_subscription_page_question_start_over')}
          </Button>
          <ButtonLink
            variant="primary"
            to="/subscribe/$subscriptionId/$stepId"
            params={{ subscriptionId, stepId: 'quote' }}
            size={screenType === 'mobile' ? 'large' : 'medium'}
          >
            {translate('continue_subscription_page_question_continue')}
          </ButtonLink>
        </>
      }
    />
  )
})
