import { createFileRoute } from '@tanstack/react-router'

import PlatformHomePage from '../../../components/pages/backoffice/backoffice-home-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/home')({
  component: () => (
    <PlatformPage requiredPermissions={[]}>
      <PlatformHomePage />
    </PlatformPage>
  ),
})
