import { createFileRoute } from '@tanstack/react-router'

import HealthInsurancePage from '../../components/pages/health-insurance/health-insurance-page'
import CustomerPage from '../../components/templates/customer-page'

export const Route = createFileRoute('/health-insurance/')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <HealthInsurancePage />
    </CustomerPage>
  ),
})
