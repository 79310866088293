import { createFileRoute } from '@tanstack/react-router'

import PartnerContractPage from '../../../../../components/pages/backoffice/partners/partners-contract-page/partners-contract-page'
import PartnerPage from '../../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/contracts/$subscriptionId')({
  component: () => (
    <PartnerPage requiredPermissions={['contracts.read']}>
      <PartnerContractPage />
    </PartnerPage>
  ),
})
