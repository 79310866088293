import { createFileRoute } from '@tanstack/react-router'

import PlatformPendingSubscriptionsPage from '../../../components/pages/backoffice/platform/platform-pending-subscriptions-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/pending-subscriptions/')({
  component: () => (
    <PlatformPage requiredPermissions={['subscription.read']}>
      <PlatformPendingSubscriptionsPage />
    </PlatformPage>
  ),
})
