import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Dialog } from '@orus.eu/pharaoh'
import { organizationDefinitionFieldsDetails } from '@orus.eu/right-access-management'
import { memo } from 'react'

export const FieldsDocumentationDialog = memo<{
  onClose: () => void
}>(function FieldsDocumentationDialog({ onClose }) {
  return (
    <Dialog title="Documentation des champs de l'organisation" onClose={onClose} size="xlarge">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Optionnel ou non</TableCell>
              <TableCell>Exemple</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(organizationDefinitionFieldsDetails).map(([key, value]) => {
              return (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value.description}</TableCell>
                  <TableCell>{value.type}</TableCell>
                  <TableCell>{value.optional ? 'Oui' : 'Non'}</TableCell>
                  <TableCell>{value.example}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  )
})
