export const ACTIVITY_ABBREVIATION_LABELS: Record<string, { label: string; abbreviation: string }> = {
  '8125d785-ec58-4879-9bef-059fc9b2944e': {
    label: 'Terrassement',
    abbreviation: 'TERR',
  },
  'a5632cce-0f44-474d-826a-3962f0f6f347': {
    label: 'V.R.D. : Canalisations – Assainissement – Chaussées – Trottoirs – Pavage – Arrosage – Espaces verts',
    abbreviation: 'VRD',
  },
  'b26dbb8b-5614-4ed0-aac0-c02405bf82e6': {
    label: 'Maçonnerie et béton armé sauf précontraint in situ',
    abbreviation: 'MAC',
  },
  '352e23cd-eda7-4767-9553-5965548c54d2': {
    label: 'Charpente et structure en bois',
    abbreviation: 'CHARP SB',
  },
  '5ddc9f0c-15b2-43f4-9429-abc7278a003b': {
    label: 'Charpente et structure métallique',
    abbreviation: 'CHARP SM',
  },
  '270819de-d629-444a-b52e-3e73c27d6e45': {
    label: 'Couverture',
    abbreviation: 'COUV',
  },
  '57a9bc56-362d-4012-afee-b4fb412cf2c6': {
    label: 'Menuiseries extérieures',
    abbreviation: 'ME',
  },
  'b2a9bd86-f5fd-49de-b719-2b386b3ca577': {
    label: 'Calfeutrement protection, imperméabilité et étanchéité des façades',
    abbreviation: 'CALF',
  },
  'f259e466-d661-4cfd-8804-d17535ba255f': {
    label: 'Bardages de façade',
    abbreviation: 'BARD',
  },
  '5f239cd8-1bb1-4466-b2b9-bc63584162c7': {
    label: 'Menuiseries intérieures',
    abbreviation: 'MI',
  },
  '5e4b42cb-c817-464c-b68b-67f629e1f462': {
    label: 'Plâtrerie – Staff – Stuc – Gypserie',
    abbreviation: 'PLAT',
  },
  '7cfb9bd7-c5a9-4a64-8e71-290edc4c048e': {
    label: 'Serrurerie – Métallerie',
    abbreviation: 'SER/MET',
  },
  'cbf5ebc1-2c56-4e6e-9c0b-470be9f5d9ae': {
    label: 'Vitrerie – Miroiterie',
    abbreviation: 'VIT/MIR',
  },
  'a782046f-0412-4f5f-90ad-bbbb514e9ccd': {
    label: 'Peinture',
    abbreviation: 'PEINT',
  },
  'f925bcff-29df-4f82-8c8a-2beee439d53f': {
    label: 'Revêtement de surfaces en matériaux souples et parquets flottants',
    abbreviation: 'RVT S',
  },
  'f8acf0bc-d0c8-4017-82a4-d22fb14763b0': {
    label: 'Revêtement de surfaces en matériaux durs – Chapes et sols coulés',
    abbreviation: 'RVT D',
  },
  '7dc9b3e8-94ba-48e0-b4e4-0a0f1e948592': {
    label: 'Isolation thermique, acoustique ou frigorifique',
    abbreviation: 'ITAF',
  },
  '56e727b6-b0b4-485d-9f13-c21036eb9249': {
    label: 'Plomberie – Installations sanitaires',
    abbreviation: 'PLOMB',
  },
  '5335bd82-6d9a-47c0-8cb3-7a759dffd1bc': {
    label: 'Installations thermiques de génie climatique',
    abbreviation: 'ITGC',
  },
  '26e00401-a082-45ad-9070-cf31173d91c7': {
    label: 'Installations d’aéraulique et de conditionnement d’air',
    abbreviation: 'IACA',
  },
  '3d74bb69-00ca-4d14-b487-4827a379b852': {
    label: 'Electricité',
    abbreviation: 'ELEC',
  },
  '43221a8d-e15b-4e7c-ad70-237b2d7ac3b5': {
    label: 'Aménagement de cuisines et/ou salles de bains',
    abbreviation: 'ACS',
  },
  '1139a9b7-20b0-4c17-b73a-135cbf6e2dc6': {
    label:
      "Réalisation de jardins, d'espaces verts et d'aménagements paysagers y compris les travaux complémentaires de maçonnerie",
    abbreviation: 'PAYS',
  },
}
