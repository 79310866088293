import { createFileRoute } from '@tanstack/react-router'

import PartnerSearchPage from '../../../../components/pages/backoffice/partners/partners-search-page'
import PartnerPage from '../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/search')({
  component: () => (
    <PartnerPage requiredPermissions={['search']}>
      <PartnerSearchPage />
    </PartnerPage>
  ),
})
