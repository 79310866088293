import { amountToString, newAmount } from '@orus.eu/amount'
import type { TypeOfDimension } from '../abstract-dimension.js'
import { AmountDimension } from '../amount-dimension.js'
import { BooleanDimension } from '../boolean-dimension.js'
import { CalendarDateDimension } from '../calendar-date-dimension.js'
import { ExclusionDimension } from '../exclusion-dimension.js'
import { FinancialRateDimension } from '../financial-rate-dimension.js'
import { IntegerDimension } from '../integer-dimension.js'
import { StringsEnumDimension } from '../strings-enum-dimension.js'
import { StringsWithDataEnumDimension } from '../strings-with-data-enum-dimension.js'
import { SurfaceDimension } from '../surface-dimension.js'
import { MrpwAddressValidationResultDimension } from './mrpw-address-validation-result-dimension.js'
import { MrpwQuoteDimension, mrpwProtectionEntries } from './mrpw-quote-dimension.js'
import { MrpwWakamActivityDimension } from './mrpw-wakam-activity.js'
import { MrpwWakamAllowedCityCodesDimension } from './mrpw-wakam-allowed-city-codes-dimension.js'

export const mrpwVersionDimension = new StringsEnumDimension({
  name: 'mrpwVersion',
  displayValues: { name: 'Version du produit MRP Wakam' },
  values: [
    // the very first restaurant product, corresponding to the legacy policy "restaurants-2022-05-13", mapped to mrpw-rest-v1
    '1.1',

    // a minor iteration first restaurant product, corresponding to the legacy policy "restaurants-v2", mapped to mrpw-rest-v1
    '1.2',

    // the restaurant product as a part of the multi-product policy, with many upgrades, mapped to mrpw-rest-v2
    '2',
  ],
} as const)
export type MrpwVersion = TypeOfDimension<typeof mrpwVersionDimension>

export const mrpwSelectedDimension = new BooleanDimension({
  name: 'mrpwSelected',
  displayValues: { name: 'MRP commerçants' },
  tags: ['MRPW'],
} as const)

export const mrpwActivityAddressAllowedWakamCitiesDimension = new MrpwWakamAllowedCityCodesDimension({
  name: 'mrpwActivityAddressAllowedWakamCities',
  displayValues: { name: 'Codes commune wakam autorisés pour ce code postal' },
  tags: ['MRPW'],
} as const)

export const mrpwActivityAddressValidationResultDimension = new MrpwAddressValidationResultDimension({
  name: 'mrpwActivityAddressValidationResult',
  displayValues: { name: 'Resultat de la validation' },
  tags: ['MRPW'],
} as const)

export const mrpwWakamMainActivityDimension = new MrpwWakamActivityDimension({
  name: 'mrpwWakamMainActivity',
  displayValues: { name: 'Activité principale (now Wakam)' },
  tags: ['MRPW'],
} as const)

export const mrpwWakamSecondaryActivityDimension = new MrpwWakamActivityDimension({
  name: 'mrpwWakamSecondaryActivity',
  displayValues: { name: 'Activité secondaire (nom Wakam)' },
  tags: ['MRPW'],
} as const)

export const mrpwWakamTertiaryActivityDimension = new MrpwWakamActivityDimension({
  name: 'mrpwWakamTertiaryActivity',
  displayValues: { name: 'Activité tertiaire (nom Wakam)' },
  tags: ['MRPW'],
} as const)

export const mrpwSurfaceDimension = new SurfaceDimension({
  name: 'mrpwSurface',
  displayValues: {
    name: 'Surface (m²)',
    placeholder: '240',
    hint: 'Surface totale (y compris terrasses, sous-sols et espaces de stockage).',
  },
  tags: ['MRPW'],
} as const)

export const mrpwHeadCountDimension = new IntegerDimension({
  name: 'mrpwHeadCount',
  displayValues: {
    name: 'Effectif',
    placeholder: '10',
    hint: 'Dirigeants et salariés',
  },
  tags: ['MRPW'],
} as const)

export const mrpwAssetsValueDimension = new AmountDimension({
  name: 'mrpwAssetsValue',
  displayValues: {
    name: 'Valeur du contenu du local',
    hint: 'Ce qui se trouve dans votre local : votre matériel professionnel, votre mobilier et vos stocks.',
    placeholder: amountToString(newAmount('20 000'), { addCurrency: true, displayDecimals: false }),
  },
  tags: ['MRPW'],
} as const)

export const mrpwAssetsClaimsThreeYearsDimension = new StringsWithDataEnumDimension({
  name: 'mrpwAssetsClaimsThreeYears',
  displayValues: {
    name: 'Combien de sinistres avez-vous déclarés en responsabilité civile professionnelle ou incendie durant les 3 dernières années ?',
    hint: 'Sinistres en responsabilité civile professionnelle ou incendies uniquement',
  },
  entries: [
    ['0', { label: '0' }],
    ['1', { label: '1' }],
    ['2', { label: '2' }],
    ['3+', { label: '3+' }],
  ],
  tags: ['MRPW'],
} as const)

export const mrpwOccupationStatusDimension = new StringsWithDataEnumDimension({
  name: 'mrpwOccupationStatus',
  displayValues: { name: "Statut d'occupation des locaux", placeholder: 'LOCATAIRE' },
  entries: [
    ['Locataire', { label: 'Locataire' }],
    ['Propriétaire Exploitant', { label: 'Propriétaire' }],
    ['Colocataire', { label: 'Colocataire' }],
    ['Copropriétaire', { label: 'Copropriétaire' }],
  ],
  tags: ['MRPW'],
} as const)

export const mrpwMonumentDimension = new BooleanDimension({
  name: 'mrpwMonument',
  displayValues: { name: 'Monument historique (manoir, château, classé)' },
  tags: ['MRPW'],
} as const)
export const mrpwHardBuildingDimension = new BooleanDimension({
  name: 'mrpwHardBuilding',
  displayValues: { name: 'Bâtiment construit et couvert en dur' },
  tags: ['MRPW'],
} as const)

export const mrpwDerogationRcDimension = new FinancialRateDimension({
  name: 'mrpwDerogationRc',
  displayValues: { name: 'Parametre Derogation_RC de wakam' },
  tags: ['MRPW'],
} as const)

export const mrpwDerogationDmgDimension = new FinancialRateDimension({
  name: 'mrpwDerogationDmg',
  displayValues: { name: 'Parametre Derogation_DMG de wakam' },
  tags: ['MRPW'],
} as const)

export const mrpwDerogationIncPePvvDimension = new FinancialRateDimension({
  name: 'mrpwDerogationIncPePvv',
  displayValues: { name: 'Parametre Derogation_INC_PE_PVV de wakam' },
  tags: ['MRPW'],
} as const)

export const mrpwMultisiteDimension = new BooleanDimension({
  name: 'mrpwMultisite',
  displayValues: {
    name: 'Multisite',
    hint: 'Seul le site principal peut accueillir du public. Prendre en compte tous les sites dans le calcul de la surface. Pas plus de trois sites en tout.',
  },
  tags: ['MRPW'],
} as const)

export const mrpwOptionPjDimension = new BooleanDimension({
  name: 'mrpwOptionPj',
  displayValues: { name: 'Option protection juridique' },
  tags: ['MRPW'],
} as const)
export const mrpwOptionPeDimension = new BooleanDimension({
  name: 'mrpwOptionPe',
  displayValues: { name: "Option perte d'exploitation" },
  tags: ['MRPW'],
} as const)
export const mrpwOptionPvvDimension = new BooleanDimension({
  name: 'mrpwOptionPvv',
  displayValues: { name: 'Option perte de valeur vénale' },
  tags: ['MRPW'],
} as const)
export const mrpwOptionBdgDimension = new BooleanDimension({
  name: 'mrpwOptionBdg',
  displayValues: { name: 'Option bris de glace' },
  tags: ['MRPW'],
} as const)
export const mrpwOptionRcTravauxDimension = new BooleanDimension({
  name: 'mrpwOptionRcTravaux',
  displayValues: { name: 'Option RC travaux' },
  tags: ['MRPW'],
} as const)

export const mrpwRcTravauxStartDimension = new CalendarDateDimension({
  name: 'mrpwRcTravauxStart',
  displayValues: { name: 'Date de démarrage des travaux' },
  tags: ['MRPW'],
} as const)
export const mrpwRcTravauxEndDimension = new CalendarDateDimension({
  name: 'mrpwRcTravauxEnd',
  displayValues: { name: 'Date de fin des travaux' },
  tags: ['MRPW'],
} as const)
export const mrpwOptionBoostDimension = new BooleanDimension({
  name: 'mrpwOptionBoost',
  displayValues: { name: 'Option boost garanties' },
  tags: ['MRPW'],
} as const)

export const mrpwPremiumOverchargesRcDimension = new FinancialRateDimension({
  name: 'mrpwPremiumOverchargesRC',
  displayValues: { name: 'Sur-prime RC' },
  configuration: {
    min: -0.1,
    max: 5,
    step: 0.01,
  },
  tags: ['MRPW'],
} as const)
export const mrpwPremiumOverchargesDmgDimension = new FinancialRateDimension({
  name: 'mrpwPremiumOverchargesDMG',
  displayValues: { name: 'Sur-prime DMG' },
  configuration: {
    min: -0.1,
    max: 5,
    step: 0.01,
  },
  tags: ['MRPW'],
} as const)
export const mrpwPremiumOverchargesIncPePvvDimension = new FinancialRateDimension({
  name: 'mrpwPremiumOverchargesIncPePvv',
  displayValues: { name: 'Sur-prime INC_PE_PVV' },
  configuration: {
    min: -0.1,
    max: 5,
    step: 0.01,
  },
  tags: ['MRPW'],
} as const)

export const mrpwQuoteDimension = new MrpwQuoteDimension({
  name: 'mrpwQuote',
  displayValues: { name: 'MRP Wakam quote' },
  tags: ['MRPW'],
} as const)

// returned by the wakam API
export const mrpwRequiredProtectionLevelDimension = new StringsWithDataEnumDimension({
  name: 'mrpwRequiredProtectionLevel',
  displayValues: {
    name: 'Niveau de protection nécessaire',
    hint: 'Calculé par Wakam, définit les mesures de sécurité exigées pour les locaux. De 1 à 4.',
  },
  entries: mrpwProtectionEntries,
  tags: ['MRPW'],
} as const)

export const mrpwNewlyCreatedCompanyDimension = new BooleanDimension({
  name: 'mrpwNewlyCreatedCompany',
  displayValues: { name: "Création d'entreprise" },
  tags: ['MRPW'],
} as const)

export const mrpwActivityCoffeeShopDimension = new BooleanDimension({
  name: 'mrpwActivityCoffeeShop',
  displayValues: { name: 'Active les clauses "CoffeeShop" dans les CP' },
  tags: ['MRPW'],
} as const)

export const mrpwActivityPharmacieDimension = new BooleanDimension({
  name: 'mrpwActivityPharmacie',
  displayValues: { name: 'Active les clauses "Pharmacie" dans les CP' },
  tags: ['MRPW'],
} as const)
export const mrpwMarchandiseConserveeDimension = new BooleanDimension({
  name: 'mrpwMarchandiseConservee',
  displayValues: { name: 'La garantie "MarchandiseConservee" est active' },
  tags: ['MRPW'],
} as const)
export const mrpwRcpDimension = new BooleanDimension({
  name: 'mrpwRcp',
  displayValues: { name: 'La garantie "RCP" est active' },
  tags: ['MRPW'],
} as const)

export const mrpwRevenueOneHundredAndFiftyPctDimension = new AmountDimension({
  name: 'mrpwRevenueOneHundredAndFiftyPct',
  displayValues: {
    name: 'Plafond 150%',
    hint: 'Plafond de la perte de valeur vénale',
  },
  tags: ['MRPW'],
} as const)
export const mrpwRevenueFifteenPctDimension = new AmountDimension({
  name: 'mrpwRevenueFifteenPct',
  displayValues: {
    name: 'Plafond 15%',
    hint: 'Plafond de la garantie "Marchandise Conservées"',
  },
  tags: ['MRPW'],
} as const)
export const mrpwAssetsValueTwentyPctDimension = new AmountDimension({
  name: 'mrpwAssetsValueTwentyPct',
  displayValues: { name: '20% du contenu assuré' },
  tags: ['MRPW'],
} as const)
export const mrpwAssetsValueFiftyPctDimension = new AmountDimension({
  name: 'mrpwAssetsValueFiftyPct',
  displayValues: { name: '50% du contenu assuré' },
  tags: ['MRPW'],
} as const)
export const mrpwYearlyBasePremiumDimension = new AmountDimension({
  name: 'mrpwYearlyBasePremium',
  displayValues: { name: 'Cotisation annuelle HT' },
  tags: ['MRPW'],
} as const)
export const mrpwYearlyTaxesDimension = new AmountDimension({
  name: 'mrpwYearlyTaxes',
  displayValues: { name: 'Taxes annuelles' },
  tags: ['MRPW'],
} as const)
export const mrpwYearlyTotalPremiumDimension = new AmountDimension({
  name: 'mrpwYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC' },
  tags: ['MRPW'],
} as const)
export const mrpwTerrorismTaxDimension = new AmountDimension({
  name: 'mrpwTerrorismTax',
  displayValues: { name: 'Contribution attentats' },
  tags: ['MRPW'],
} as const)

export const mrpwQuoteInputExclusionsDimension = new ExclusionDimension({
  name: 'mrpwQuoteInputExclusions',
  displayValues: { name: 'Exclusions spécifiques MPR Wakam, connues avant le calcul de la quote' },
  tags: ['MRPW'],
} as const)
export const mrpwExclusionsDimension = new ExclusionDimension({
  name: 'mrpwExclusions',
  displayValues: { name: 'Exclusions spécifiques MPR Wakam' },
  tags: ['MRPW'],
} as const)

export const mrpwPizzeriaStatusDimension = new BooleanDimension({
  name: 'mrpwPizzeriaStatus',
  displayValues: { name: 'Activité pizzeria ou équivalent' },
  tags: ['MRPW'],
} as const)

export const mrpwManagementExperienceInPizzeriaRequiredDimension = new BooleanDimension({
  name: 'mrpwManagementExperienceInPizzeriaRequired',
  displayValues: { name: "Nécessité d'avoir un an d'expérience en tant que gérant d'une pizzeria" },
  tags: ['MRPW'],
} as const)

export const mrpwHasManagementExperienceInPizzeriaDimension = new BooleanDimension({
  name: 'mrpwHasManagementExperienceInPizzeria',
  displayValues: { name: "Un an d'expérience en tant que gérant d'une pizzeria" },
  tags: ['MRPW'],
} as const)

export const mrpwNaturalDisasterPremiumDimension = new AmountDimension({
  name: 'mrpwNaturalDisasterPremium',
  displayValues: { name: 'Dont catasrophes naturelles' },
  tags: ['MRPW'],
} as const)

export const mrpwAssistancePremiumDimension = new AmountDimension({
  name: 'mrpwAssistancePremium',
  displayValues: { name: 'Dont assistance' },
  tags: ['MRPW'],
} as const)

export const mrpwHighRevenueDimension = new BooleanDimension({
  name: 'mrpwHighRevenue',
  displayValues: {
    name: 'Revenus supérieurs à 1500k',
    hint: 'Active des clauses supplémentaires limitant les options PE et PVV',
  },
  tags: ['MRPW'],
} as const)
export const mrpwHighRevenueThreshold = newAmount(1_500_000)
