import { createFileRoute } from '@tanstack/react-router'

import ReportClaimPage from '../../components/pages/report-claim/ReportClaimPage'
import CustomerPage from '../../components/templates/customer-page'

export const Route = createFileRoute('/report-claim')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <ReportClaimPage />
    </CustomerPage>
  ),
})
