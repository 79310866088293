import { createFileRoute } from '@tanstack/react-router'

import { PlatformNegativeInvoiceGroupsList } from '../../../../components/pages/backoffice/platform/platform-invoicing-negative-invoices-list'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/invoicing/negative-invoices-list')({
  component: () => (
    <PlatformPage requiredPermissions={['invoices.configuration']}>
      <PlatformNegativeInvoiceGroupsList />
    </PlatformPage>
  ),
})
