import { useBlocker } from '@tanstack/react-router'
import { memo } from 'react'
import { UnsavedChangesDialog } from './UnsavedChangesDialog'

export const SaveablePage = memo<{ children: React.ReactNode; hasUnsavedChanges: boolean }>(function SaveablePage({
  children,
  hasUnsavedChanges,
}) {
  const {
    proceed: blockerProceed,
    reset: blockerReset,
    status: blockerStatus,
  } = useBlocker({
    shouldBlockFn: ({ current, next }) => {
      return (
        hasUnsavedChanges &&
        // accept different hashes to avoid triggering on dialogs
        current.pathname !== next.pathname
      )
    },
    withResolver: true,
    enableBeforeUnload: hasUnsavedChanges,
  })

  return (
    <>
      {children}
      {blockerStatus === 'blocked' ? <UnsavedChangesDialog reset={blockerReset} proceed={blockerProceed} /> : null}
    </>
  )
})
