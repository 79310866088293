import { Editor, Element, Path, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import type { Link } from '../../types'
import { createParagraphNode } from '../../utils/create-paragraph-node'

export const createLinkNode = (url: string, label: string): Link => ({
  type: 'link',
  url: url,
  children: [{ text: label }],
})

export const insertLink = (editor: ReactEditor, url: string, label: string): void => {
  if (!url) return

  const { selection } = editor
  const link = createLinkNode(url, label)

  ReactEditor.focus(editor)

  if (selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path)

    if (Element.isElement(parentNode)) {
      if (parentNode.type === 'link') {
        removeLink(editor)
      }

      if (editor.isVoid(parentNode)) {
        Transforms.insertNodes(editor, createParagraphNode([link]), {
          at: Path.next(parentPath),
          select: true,
        })
      } else if (Range.isCollapsed(selection)) {
        Transforms.insertNodes(editor, link, { select: true })
      } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
      }
    }
  } else {
    Transforms.insertNodes(editor, createParagraphNode([link]))
  }
}

export const removeLink = (editor: ReactEditor, opts = {}): void => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  })
}

export function withLinks(editor: ReactEditor): ReactEditor {
  const { isInline } = editor

  editor.isInline = (element) => (element.type === 'link' ? true : isInline(element))

  return editor
}

export default withLinks
