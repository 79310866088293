import styled from '@emotion/styled'
import { getDifference } from '@orus.eu/sets'
import { memo } from 'react'
import { spacing } from '../../../foundation'
import { desktopMediaQuery } from '../../../hooks'
import { FlexContainer, Text } from '../../atoms/index.js'
import { Select, type SelectSize } from '../../inputs'

export const DropdownWithDescription = memo<{
  title: string
  description: string
  value: string | null
  values: string[]
  labels: { [key: string]: string }
  supportedValues?: string[]
  supportedLabels?: { [key: string]: string }
  onChange: (valueString?: string | null) => void
  ['aria-label']: string
  size: SelectSize
}>(function TextWithDropdown({
  title,
  description,
  value,
  values: currentValues,
  labels: currentLabels,
  supportedValues,
  supportedLabels,
  onChange,
  'aria-label': ariaLabel,
  size,
}) {
  const showSupportedValues =
    supportedValues != undefined &&
    supportedLabels != undefined &&
    value !== null &&
    getDifference(new Set(supportedValues), new Set(currentValues)).has(value)

  const values = showSupportedValues ? supportedValues : currentValues
  const labels = showSupportedValues ? supportedLabels : currentLabels

  return (
    <DropdownContainer>
      <LabelContainer>
        <Text variant="subtitle">{title}</Text>
        <Text variant="body2">{description}</Text>
      </LabelContainer>

      <FixedWidthDropdown
        value={value}
        values={values}
        labels={labels}
        onChange={onChange}
        aria-label={ariaLabel}
        size={size}
      />
    </DropdownContainer>
  )
})

const FixedWidthDropdown = styled(Select)`
  width: 200px;

  ${desktopMediaQuery} {
    width: 128px;
  }
`

const DropdownContainer = styled(FlexContainer)`
  gap: ${spacing[50]};
  margin: 0;
  align-items: center;
`

const LabelContainer = styled.div`
  gap: ${spacing[20]};
  flex-grow: 1;
`
