import { Text } from '@orus.eu/pharaoh'

type AddressModel = {
  streetAddress: string | null
  streetAddress2: string | null
  postalCode: string | null
  city: string | null
  country: string | null
}

type AddressDisplayProps = {
  address: AddressModel
}

const isEmptyAddress = (address: AddressModel): boolean =>
  Object.values(address).filter((value) => !!value).length === 0

export function AddressDisplay({ address }: AddressDisplayProps): React.ReactNode | undefined {
  if (!address || isEmptyAddress(address)) {
    return <Text variant="body2">Adresse : </Text>
  }

  return (
    <Text variant="body2">
      Adresse : {address.streetAddress ? <>{address.streetAddress}, </> : undefined}
      {address.streetAddress2 ? <>{address.streetAddress2}, </> : undefined}
      {address.postalCode || address.city ? (
        <>
          {address.postalCode} {address.city},{' '}
        </>
      ) : undefined}
      {address.country ? <>{address.country}</> : undefined}
    </Text>
  )
}
