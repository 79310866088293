import { css } from '@emotion/react'
import {
  Button,
  ButtonLink,
  ContentContainerAppClient,
  FlexColumn,
  PageTitle,
  ProductCard,
  Text,
  colorTokens,
  desktopMediaQuery,
  spacing,
  useLanguage,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, useCallback, useMemo } from 'react'
import { trpcReact } from '../../client'
import { dynamicFileLabels, type ContractDocumentDescription } from '../../lib/contract-util'
import { dynamicFileNames, staticFileNames } from '../../lib/download-filenames'
import {
  getMyCertificateDownloadLink,
  getMyContractDownloadLink,
  getMyGeneralTermsDocumentDownloadLink,
  getMyPaymentScheduleDownloadLink,
  getMySpecificCertificateTravelAgenciesDownloadLink,
} from '../../lib/download-links'
import { useSession } from '../../lib/session'

import styled from '@emotion/styled'
import { getAmountLabel, type CoverageInvoicingItemInputState } from '@orus.eu/dimensions'
import { CurrentProtectionStatusChip } from '@orus.eu/pharaoh/src/components/features/multi-contracts/contract-product'
import { RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { ContractDocumentDownloadRow } from '../organisms/contract-documents-download-row'
import { SmallScreenMargin } from '../templates/small-screen-margin'
import { onRequestContractUpdateTally } from './contracts/contrat-page.util'

const ContractPage = memo(function ContractPage(): JSX.Element {
  const language = useLanguage()
  const translate = useTranslate()

  const { subscriptionId } = useParams({ from: '/contract/$subscriptionId' })
  const session = useSession()
  const navigate = useNavigate()
  const screenType = useUiContext()

  const customerEmail = !session.user
    ? undefined
    : 'email' in session.user
      ? session.user.email
      : session.user.unverifiedEmail

  const [contract] = trpcReact.contracts.getMyContract.useSuspenseQuery(subscriptionId)

  const [organizationType] =
    trpcReact.organizations.getOrganizationTypeForSubscriptionId.useSuspenseQuery(subscriptionId)

  const relevantContract = contract[0]

  const certificateDocuments: Array<ContractDocumentDescription> = useMemo(() => {
    const docs: Array<ContractDocumentDescription> = [
      {
        method: 'get',
        fileName: dynamicFileNames['insurance-certificate'](relevantContract.contractNumber, language),
        url: getMyCertificateDownloadLink(subscriptionId),
        fileType: 'insurance-certificate',
      },
    ]

    if (
      relevantContract.selectedProductWithQuote.rcphSelected &&
      (relevantContract.activity.activity === '509334fe-6786-468a-92d8-052550f5d6fc' ||
        relevantContract.activity.activity === '278322e7-a0e8-45f0-89d9-cc02a2f524f6')
    ) {
      docs.push({
        method: 'get',
        fileName: dynamicFileNames['specific-insurance-certificate-travel-agencies'](
          relevantContract.contractNumber,
          language,
        ),
        url: getMySpecificCertificateTravelAgenciesDownloadLink(subscriptionId),
        fileType: 'specific-insurance-certificate-travel-agencies',
      })
    }

    return docs
  }, [
    relevantContract.contractNumber,
    relevantContract.selectedProductWithQuote.rcphSelected,
    relevantContract.activity.activity,
    language,
    subscriptionId,
  ])

  const documents: Array<ContractDocumentDescription> = useMemo(() => {
    const docs: Array<ContractDocumentDescription> = [
      {
        method: 'get',
        fileName: customerEmail
          ? dynamicFileNames['agreed-terms'](customerEmail, language)
          : staticFileNames['agreed-terms'](language),
        url: getMyContractDownloadLink(subscriptionId, null),
        fileType: 'agreed-terms',
      },
    ]

    if (relevantContract.paymentScheduleAvailable) {
      docs.push({
        method: 'get',
        fileName: dynamicFileLabels['payment-schedule'](language),
        url: getMyPaymentScheduleDownloadLink(subscriptionId),
        fileType: 'payment-schedule',
      })
    }

    docs.push(
      ...relevantContract.generalTerms.map(
        (file): ContractDocumentDescription => ({
          method: 'get',
          fileName: file.fileName,
          url: getMyGeneralTermsDocumentDownloadLink({
            subscriptionId,
            file,
          }),
          fileType: 'general-terms',
        }),
      ),
    )

    return docs
  }, [
    customerEmail,
    relevantContract.generalTerms,
    relevantContract.paymentScheduleAvailable,
    subscriptionId,
    language,
  ])

  const onSeeGuaranteesClick = useCallback(() => {
    void navigate({ to: '/contract-guarantees' })
  }, [navigate])

  const coverageInvoicingItemInputState: CoverageInvoicingItemInputState = {
    mrphQuoteV2: relevantContract.selectedProductWithQuote.mrphQuoteV2,
    rcphQuoteV2: relevantContract.selectedProductWithQuote.rcphQuoteV2,
    mrphSelected: relevantContract.selectedProductWithQuote.mrphSelected,
    rcphSelected: relevantContract.selectedProductWithQuote.rcphSelected,
    mrpwQuote: relevantContract.selectedProductWithQuote.mrpwQuote,
    rcdaQuote: relevantContract.selectedProductWithQuote.rcdaQuote,
    mutaQuote: relevantContract.selectedProductWithQuote.mutaQuote,
    mrpwSelected: relevantContract.selectedProductWithQuote.mrpwSelected,
    rcdaSelected: relevantContract.selectedProductWithQuote.rcdaSelected,
    mutaSelected: relevantContract.selectedProductWithQuote.mutaSelected,
    esRcphSelected: relevantContract.selectedProductWithQuote.esRcphSelected,
    esRcphQuote: relevantContract.selectedProductWithQuote.esRcphQuote,
    paymentRecurrence: relevantContract.quote.quote.paymentRecurrence,
  }

  const onRequestContractUpdate = useCallback(() => {
    onRequestContractUpdateTally(relevantContract, session.user)
  }, [relevantContract, session.user])

  return (
    <PaddedContainer>
      <PageTitle
        title={translate('contract_n', { contractNumber: relevantContract.contractNumber })}
        appendRightOnDesktop={
          <CurrentProtectionStatusChip
            currentProtectionStatus={relevantContract.currentProtectionStatus}
            screenType={screenType}
          />
        }
      />
      <ContentContainerAppClient>
        <SmallScreenMargin>
          <div
            css={css`
              display: flex;
              flex-direction: ${screenType === 'mobile' ? 'column' : 'row'};
              justify-content: space-between;

              gap: ${spacing[50]};
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing[20]};
              `}
            >
              <Text variant="subtitle">{relevantContract.activity.displayName}</Text>
              <Text variant="body2">{relevantContract.companyAddress}</Text>
            </div>
            <ButtonContainer>
              <Button
                variant="tertiary"
                onClick={onSeeGuaranteesClick}
                size={screenType === 'mobile' ? 'large' : 'small'}
              >
                {translate('see_guarantees')}
              </Button>
              <Button
                variant="secondary"
                onClick={onRequestContractUpdate}
                size={screenType === 'mobile' ? 'large' : 'small'}
              >
                {translate('request_update')}
              </Button>
            </ButtonContainer>
          </div>
        </SmallScreenMargin>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
            border-top: ${screenType === 'mobile' ? `1px solid ${colorTokens['color-stroke-base']}` : 'none'};
            border-bottom: ${screenType === 'mobile' ? `1px solid ${colorTokens['color-stroke-base']}` : 'none'};
            padding: ${screenType === 'mobile' ? spacing[60] : undefined};
          `}
        >
          {relevantContract.productsInformation.map((productInformation) => {
            return (
              <ProductCard
                productInformation={productInformation}
                key={productInformation.name}
                recurrence={coverageInvoicingItemInputState.paymentRecurrence}
                priceAndRecurrence={getAmountLabel(
                  {
                    productType: { type: 'productInformationId', productInformationId: productInformation.product },
                    state: coverageInvoicingItemInputState,
                    organizationType,
                  },
                  language,
                )}
              />
            )
          })}
        </div>
        <div css={css({ display: 'flex', flexDirection: 'column', gap: spacing[50] })}>
          <div
            css={css({ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' })}
          >
            <Text variant="body2Medium">{translate('certificates')}</Text>

            {relevantContract.activity.activity === 'a5cfbff0-8671-4555-b12f-acc4201dfe22' ? (
              <ButtonLink
                variant="secondary"
                size="medium"
                icon="plus-regular"
                avatarPosition="left"
                to="/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId"
                params={{ subscriptionId }}
              >
                {'Attestation CCI'}
              </ButtonLink>
            ) : null}
          </div>
          <ContractDocumentsBlock documents={certificateDocuments} />
        </div>
        <div css={css({ display: 'flex', flexDirection: 'column', gap: spacing[50] })}>
          <Text variant="body2Medium">{translate('documents')}</Text>
          <ContractDocumentsBlock documents={documents} />
        </div>

        <TerminateContractSection />
      </ContentContainerAppClient>
    </PaddedContainer>
  )
})

const TerminateContractSection = memo(function TerminateContractParagraph() {
  const translate = useTranslate()
  return (
    <FlexColumn margin="0" gap={spacing[30]}>
      <Text variant="h6">{translate('termination')}</Text>
      <Text variant="body2">{translate('termination_explanation')}</Text>
    </FlexColumn>
  )
})

const PaddedContainer = styled(FlexColumn)`
  padding-top: ${spacing[80]};
  padding-bottom: ${spacing[80]};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};

  ${desktopMediaQuery} {
    flex-direction: row;
    gap: ${spacing[20]};
  }
`

type ContractDocumentDownloadRowProps = {
  documents: Array<ContractDocumentDescription>
}

function ContractDocumentsBlock({ documents }: ContractDocumentDownloadRowProps): JSX.Element {
  return (
    <RowContainer
      variant="border"
      size="medium"
      css={css`
        padding: 0;
        flex-direction: column;
        display: inline-flex;
      `}
    >
      {documents.map((document) => (
        <ContractDocumentDownloadRow key={document.fileName} doc={document} />
      ))}
    </RowContainer>
  )
}

export default ContractPage
