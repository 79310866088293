import styled from '@emotion/styled'
import { Switch as MuiSwitch } from '@mui/material'
import { memo, type ChangeEvent, type InputHTMLAttributes } from 'react'
import { borderRadius, borderStroke, colorTokens, shadow } from '../../foundation/'

export type OrusSwitchSize = 'small' | 'large'

type SwitchProps = {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  disabled?: boolean
  className?: string
  size: 'small' | 'large'
}

export const Switch = memo<SwitchProps>(function Switch(props) {
  const { checked, onChange, inputProps, className, disabled, size } = props

  // Temporary workaround until 'color-bg-base-highlight' has a value in each embedded partner JSON
  const bgColor = hasCssVariableValue('--color-bg-base-highlight')
    ? colorTokens['color-bg-base-highlight']
    : colorTokens['color-bg-info-tertiary-active']

  return size === 'small' ? (
    <SmallMuiSwitch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      inputProps={inputProps}
      color="secondary"
      className={className}
      bgColor={bgColor}
    />
  ) : (
    <LargeMuiSwitch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      inputProps={inputProps}
      color="secondary"
      className={className}
    />
  )
})

function hasCssVariableValue(variableName: string) {
  const element = document.documentElement // Use the root element
  const value = getComputedStyle(element).getPropertyValue(variableName).trim()
  return value !== '' // Returns true if the variable has a value
}

const SmallMuiSwitch = styled(MuiSwitch)<{ bgColor: string }>`
  & .MuiSwitch-thumb {
    background-color: ${colorTokens['color-bg-base-normal']};
    border: ${borderStroke[10]} solid ${colorTokens['color-stroke-base']};
    box-shadow: ${shadow.bottom['05']};
  }

  & .MuiSwitch-track {
    background-color: ${colorTokens['color-bg-base-disable']};
    opacity: 1;
  }

  & .MuiSwitch-switchBase {
    &.Mui-checked {
      & + .MuiSwitch-track {
        background-color: ${(props) => props.bgColor};
        opacity: 1;
      }

      & .MuiSwitch-thumb {
        background-color: ${colorTokens['color-bg-base-active']};
        border: none;
        box-shadow: ${shadow.bottom['05']};
      }
    }
  }
`

const LargeMuiSwitch = styled(MuiSwitch)`
  width: 40px;
  height: 24px;
  padding: 0;
  border-radius: ${borderRadius['40']};

  & .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    background-color: ${colorTokens['color-bg-base-normal']};
    box-shadow: ${shadow.bottom['05']};
  }

  & .MuiSwitch-track {
    background-color: ${colorTokens['color-bg-base-disable']};
    opacity: 1;
  }

  & .MuiSwitch-switchBase {
    border: ${borderStroke[10]} solid ${colorTokens['color-stroke-base']};

    transform: translate(1.5px, 1.5px);
    margin: 0;
    padding: 0;

    &.Mui-checked {
      transform: translate(17.5px, 1.5px);

      & + .MuiSwitch-track {
        background-color: ${colorTokens['color-bg-base-active']};
        opacity: 1;
      }

      & .MuiSwitch-thumb {
        border: none;
      }
    }
  }
`
