import { css } from '@emotion/react'
import type { PersonalDataBannerSubscriptionUiElement } from '@orus.eu/dimensions'
import { AvatarDecorative, Text, borderRadius, colorTokens, colors, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { SubscriptionElementBlockProps } from '../subscription-v2-props'

export const PersonalDataBannerSubscriptionUiElementBlock = memo<
  SubscriptionElementBlockProps<PersonalDataBannerSubscriptionUiElement>
>(function PersonalDataBannerSubscriptionUiElementBlock() {
  return (
    <div
      css={css`
        padding: ${spacing[70]};
        border: 1px solid ${colorTokens['color-stroke-base']};
        border-radius: ${borderRadius[30]};
        background-color: ${colors.white};
      `}
    >
      <AvatarDecorative icon="hand-peace-light" backgroundColor="periwinkle" size="60" />
      <Text
        variant="h6"
        css={css`
          margin-top: ${spacing[40]};
        `}
      >
        Mieux vous accompagner
      </Text>
      <Text
        variant="body2"
        css={css`
          margin-top: ${spacing[30]};
        `}
      >
        Rassurez-vous, nous avons juste besoin de ces informations pour vous envoyer le devis personnalisé et vous
        rappeler si nécessaire.
      </Text>
    </div>
  )
})
