import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager.js'

export const getCurrentSiren = (
  before: SubscriptionDimensionnedState | undefined,
  changes: SubscriptionDimensionnedState,
): string => {
  const companyIdNumber = changes.companyIdNumber ?? before?.companyIdNumber
  if (
    companyIdNumber?.type === 'provided' ||
    companyIdNumber?.type === 'siren' ||
    companyIdNumber?.type === 'inferred'
  ) {
    return companyIdNumber.siren
  }
  return ''
}
