import { memo, type PropsWithChildren, type ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { colorTokens, shadow, spacing } from '@orus.eu/pharaoh'
import { CustomIconButton } from '../button/CustomIconButton'
import { ChevronDown } from '../icons'
import DropdownListItem from './DropdownListItem'

type Props = PropsWithChildren & {
  buttonContent: ReactNode
  options: {
    value: string
    icon: ReactNode
    label: string
  }[]
  availableOptions?: string[]
  onClick: (value: string) => void
}

export const DropdownButton = memo(function DropdownButton({
  buttonContent,
  options,
  availableOptions,
  onClick,
}: Props) {
  const [visible, setVisible] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent): void => {
      if (visible && menuRef.current && !menuRef.current.contains(ev.target as Node)) {
        setVisible(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [visible, setVisible, menuRef])

  const handleClick = useCallback(
    (value: string) => {
      setVisible(false)
      onClick(value)
    },
    [onClick],
  )

  const toggleDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setVisible(!visible)
  }

  return (
    <DropdownContainer ref={menuRef}>
      <CenteredCustomIconButton active onMouseDown={toggleDropdown}>
        {buttonContent}
        <ChevronDown width={16} height={16} />
      </CenteredCustomIconButton>
      {visible && (
        <DropdownMenuContainer>
          {options.map((choice) => (
            <DropdownListItem
              key={choice.value}
              onClick={handleClick}
              value={choice.value}
              icon={choice.icon}
              label={choice.label}
              disabled={availableOptions && !availableOptions.includes(choice.value)}
            />
          ))}
        </DropdownMenuContainer>
      )}
    </DropdownContainer>
  )
})

const DropdownContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
`

const CenteredCustomIconButton = styled(CustomIconButton)`
  justify-content: center;
  align-items: center;
  display: flex;
`

const DropdownMenuContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  background-color: ${colorTokens['color-bg-base-secondary']};
  border: ${`${spacing['5']} solid ${colorTokens['color-stroke-base']}`};
  border-radius: ${spacing['30']};
  box-shadow: ${shadow.bottom[20]};
  height: fit-content;
  z-index: 1;
`
