import { css } from '@emotion/react'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { Chip, Divider, RowContainerV2, spacing, TextFormField } from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { Fragment, memo } from 'react'
import {
  LABEL_AND_VARIANT_PER_FILE_STATUS,
  LABEL_AND_VARIANT_PER_GROUP_STATUS,
} from '../../../../subscription-v2/elements/documents-list-element/chip-config'
import { LABEL_PER_DOCUMENT_CATEGORY } from '../../../../subscription-v2/elements/documents-list-element/labels'
import type { DetailedSheetStatus } from '../../detailed-sheet.types'
import EmptyDocumentList from '../common/EmptyDocumentList'
import { GROUP_CATEGORIES_BY_PROFILE } from './document-categories-per-detailed-sheet-status'

const generateGridColumsTemplate = (count: number) => {
  return Array.from({ length: count }, () => '1fr').join(' ')
}

type OverviewDocumentsSectionProps = {
  files: File[] | undefined
  profile: DetailedSheetStatus
  groupStatusByCategory: Partial<Record<GroupCategory | 'none', GroupStatus>>
  onIdFileGroupStatusClick: (label: GroupCategory) => void
  onFileClick: (file: File) => void
  showAcag: boolean
}

const OverviewDocumentsSection = memo(function OverviewDocumentsSection({
  files,
  groupStatusByCategory,
  profile,
  onIdFileGroupStatusClick,
  onFileClick,
  showAcag,
}: OverviewDocumentsSectionProps) {
  const initialCategories = GROUP_CATEGORIES_BY_PROFILE[profile]
  const categoriesToDisplay = showAcag ? initialCategories : initialCategories.filter((category) => category !== 'acag')

  return (
    <RowContainerV2
      css={css`
        display: grid;
      `}
    >
      <RowContainerV2
        css={css`
          border: none;
          display: grid;
          grid-template-columns: ${generateGridColumsTemplate(4)};
        `}
      >
        {categoriesToDisplay?.map((groupCategory, index) => (
          <Fragment key={index}>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <Row
                key={index}
                variant={
                  !groupStatusByCategory[groupCategory] || groupStatusByCategory[groupCategory] === 'empty'
                    ? 'descriptive'
                    : undefined
                }
                title={LABEL_PER_DOCUMENT_CATEGORY[groupCategory]}
                titleVariant="body2Medium"
                rightAvatar={
                  <Chip
                    variant={
                      LABEL_AND_VARIANT_PER_GROUP_STATUS[groupStatusByCategory[groupCategory] ?? 'empty'].variant
                    }
                    dark={LABEL_AND_VARIANT_PER_GROUP_STATUS[groupStatusByCategory[groupCategory] ?? 'empty'].dark}
                    size="large"
                  >
                    {LABEL_AND_VARIANT_PER_GROUP_STATUS[groupStatusByCategory[groupCategory] ?? 'empty'].label}
                  </Chip>
                }
                onClick={() => {
                  if (groupStatusByCategory[groupCategory] !== 'empty') {
                    onIdFileGroupStatusClick(groupCategory)
                  }
                }}
                rightAvatarCssProps={{
                  alignSelf: 'start',
                }}
                css={css`
                  padding: ${spacing[40]};
                  align-items: start;
                  width: 100%;
                `}
              />
              {/* Add vertical dividers between columns */}
              {index % 4 !== 3 &&
              (index !== categoriesToDisplay.length - 1 ||
                (categoriesToDisplay.length - 1 && categoriesToDisplay.length % 4 !== 0)) ? (
                <Divider
                  key={`${index}-horizontal-divider`}
                  orientation="vertical"
                  css={css`
                    width: 4px;
                    height: 100%;
                  `}
                />
              ) : null}
            </div>
            {/* Add horizontal divider after every 4 blocks */}
            {index % 4 === 3 && index !== categoriesToDisplay.length - 1 ? (
              <div
                key={`${index}-divider`}
                css={css`
                  grid-column: 1 / -1;
                `}
              >
                <Divider
                  orientation="horizontal"
                  css={css`
                    width: 100%;
                  `}
                />
              </div>
            ) : null}
          </Fragment>
        ))}
      </RowContainerV2>
      <Divider orientation={'horizontal'} />
      <div
        css={css`
          padding: ${spacing[40]};
        `}
      >
        <TextFormField
          value="Justificatifs d'expérience activité"
          infoTooltip="Liste des documents permettant de justifier de l'expérience de l'entreprise dans l'activité"
          labelVariant="body2Medium"
          variant="backoffice"
        />
      </div>

      <Divider orientation={'horizontal'} />

      {files && files.length !== 0 ? (
        <RowContainerV2
          css={css`
            border: none;
            display: grid;
            grid-template-columns: ${generateGridColumsTemplate(4)};
          `}
        >
          {files?.map((file, index) => (
            <div
              key={index}
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <Row
                title={file.title}
                titleVariant="body2Medium"
                rightAvatar={
                  <Chip variant={LABEL_AND_VARIANT_PER_FILE_STATUS[file.status ?? 'empty'].variant} dark size="large">
                    {LABEL_AND_VARIANT_PER_FILE_STATUS[file.status ?? 'empty'].label}
                  </Chip>
                }
                rightAvatarCssProps={{
                  alignSelf: 'start',
                }}
                css={css`
                  padding: ${spacing[40]};
                  align-items: start;
                  width: 100%;
                `}
                onClick={() => {
                  onFileClick(file)
                }}
              />
              {/* Add vertical dividers between columns */}
              {index % 4 !== 3 && (index !== files.length - 1 || (files.length - 1 && files.length % 4 !== 0)) ? (
                <Divider
                  orientation="vertical"
                  css={css`
                    width: 4px;
                    height: 100%;
                  `}
                />
              ) : null}

              {/* Add horizontal divider after every 4 blocks */}
              {index % 4 === 3 && index !== files.length - 1 ? (
                <div
                  css={css`
                    grid-column: 1 / -1;
                  `}
                >
                  <Divider
                    orientation="horizontal"
                    css={css`
                      width: 100%;
                    `}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </RowContainerV2>
      ) : (
        <div
          css={css`
            padding: ${spacing[40]};
          `}
        >
          <EmptyDocumentList />
        </div>
      )}
    </RowContainerV2>
  )
})

export default OverviewDocumentsSection
