import { useCallback, useState } from 'react'

type UseLinkDialogReturn = {
  isDialogVisible: boolean
  url: string | undefined
  label: string | undefined
  openDialog: (initialUrl?: string, initialLabel?: string) => void
  closeDialog: () => void
  setUrl: (url: string | undefined) => void
  setLabel: (label: string | undefined) => void
  saveDialog: (onSaveCallback: (url: string, label: string) => void) => void
}

export function useLinkDialog(): UseLinkDialogReturn {
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [label, setLabel] = useState<string | undefined>(undefined)

  const openDialog = useCallback((initialUrl?: string, initialLabel?: string) => {
    setUrl(initialUrl || '')
    setLabel(initialLabel || '')
    setIsDialogVisible(true)
  }, [])

  const closeDialog = useCallback(() => {
    setIsDialogVisible(false)
    setUrl(undefined)
    setLabel(undefined)
  }, [])

  const saveDialog = useCallback(
    (onSaveCallback: (url: string, label: string) => void) => {
      if (url && label) {
        onSaveCallback(url, label)
        closeDialog()
      }
    },
    [url, label, closeDialog],
  )

  return {
    isDialogVisible,
    url,
    label,
    openDialog,
    closeDialog,
    setUrl,
    setLabel,
    saveDialog,
  }
}
