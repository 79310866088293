import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  colorTokens,
  Dialog,
  Divider,
  shadow,
  spacing,
  TextInputLabelWrapper,
  Tooltip,
  ValidatedTextField,
} from '@orus.eu/pharaoh'
import { memo, useCallback, useMemo } from 'react'
import { Editor, Transforms } from 'slate'
import { useFocused, useSelected, useSlateStatic, type RenderElementProps } from 'slate-react'
import type { ValidationUrlScheme } from '../../../../../../../../lib/validation'
import { urlMapper } from '../../../../../../subscription-v2/elements/string-element-mappers'
import { useLinkDialog } from '../../hooks/useLinkDialog'
import { removeLink } from '../../plugins/link/withLinks'
import type { Link } from '../../types'
import { CustomIconButton } from '../button/CustomIconButton'
import { Edit, LinkRemove } from '../icons'

const allowedSchemes: ValidationUrlScheme[] = ['http:', 'https:', 'mailto:', 'tel:']

type LinkComponentProps = RenderElementProps

const LinkComponent = memo(function LinkComponent({ attributes, children, element }: LinkComponentProps) {
  const { url } = element as Link

  const { isDialogVisible, setUrl, url: dialogUrl, label, openDialog, closeDialog } = useLinkDialog()
  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null
    try {
      parsedUrl = new URL(url)
    } catch {
      return 'about:blank'
    }
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol as ValidationUrlScheme)) {
      return parsedUrl.href
    }
    return 'about:blank'
  }, [url])

  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()

  const handleRemoveLink = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      removeLink(editor)
    },
    [editor],
  )

  const handleEditLink = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      openDialog(url, label)
    },
    [openDialog, url, label],
  )

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  const handleSaveDialog = useCallback(() => {
    if (!editor.selection) return

    const [parentNode, parentPath] = Editor.parent(editor, editor.selection.focus.path)

    if (parentNode) {
      Transforms.setNodes(editor, { url: dialogUrl }, { at: parentPath })
    }

    closeDialog()
  }, [editor, dialogUrl, closeDialog])

  return (
    <div
      css={css`
        display: inline;
      `}
    >
      {isDialogVisible ? (
        <Dialog
          title={"Veuillez saisir l'url du lien"}
          style="base"
          size="small"
          primaryActionLabel={'Enregistrer'}
          onPrimaryAction={handleSaveDialog}
          secondaryActionLabel={'Annuler'}
          onSecondaryAction={handleCloseDialog}
          primaryActionDisabled={!url}
          onClose={handleCloseDialog}
        >
          <ValidatedTextFieldWrapper label={'Url'}>
            <ValidatedTextField size="large" mapper={urlMapper} value={dialogUrl} onChange={(value) => setUrl(value)} />
          </ValidatedTextFieldWrapper>
        </Dialog>
      ) : null}
      <a
        {...attributes}
        target="_blank"
        rel="noreferrer"
        href={safeUrl}
        onClick={(e) => {
          if (e.metaKey) {
            window.open(safeUrl, '_blank')
          }
        }}
        css={css`
          cursor: pointer;
          line-height: 1;
        `}
      >
        {children}
      </a>
      {selected && focused && (
        <Container contentEditable={false}>
          <Tooltip title="Ouvrir le lien">
            <a href={safeUrl} rel="noreferrer" target="_blank">
              {safeUrl}
            </a>
          </Tooltip>
          <Divider
            orientation="vertical"
            css={css`
              height: 30px;
              margin-left: ${spacing['30']};
              margin-right: ${spacing['20']};
            `}
          />
          <Tooltip title="Modifier le lien">
            <CustomIconButton active title="Modifier le lien" onMouseDown={handleEditLink}>
              <Edit width={16} height={16} />
            </CustomIconButton>
          </Tooltip>
          <Tooltip title="Supprimer le lien">
            <CustomIconButton active title="Supprimer le lien" onMouseDown={handleRemoveLink}>
              <LinkRemove width={16} height={16} />
            </CustomIconButton>
          </Tooltip>
        </Container>
      )}
    </div>
  )
})

export default LinkComponent

const Container = styled.div`
  position: absolute;
  background: ${colorTokens['color-bg-base-secondary']};
  border: ${`${spacing['5']} solid ${colorTokens['color-stroke-base']}`};
  border-radius: ${spacing['30']};
  box-shadow: ${shadow.bottom[20]};
  height: fit-content;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: ${spacing['20']};
  font-size: 13px;
  padding: ${spacing['20']} ${spacing['30']};
`

const ValidatedTextFieldWrapper = styled(TextInputLabelWrapper)`
  width: 100%;
`
