import type { ClientTracingInfo } from '@orus.eu/backend/src/lib/tracing-info'
import type { OrusSession } from '@orus.eu/backend/src/services/session/session-types'
import { TechnicalError } from '@orus.eu/error'
import { failure, success, type Result } from '@orus.eu/result'
import { sleep } from '@orus.eu/sleep'
import React, { useContext } from 'react'
import { trpc } from '../client'

export type SessionResult = Result<OrusSession, null | 'client_outdated'>

class SessionManager {
  private currentSessionResult?: SessionResult
  private sessionResultPromiseListeners: Array<(sessionResult: Promise<SessionResult>) => void> = []
  private sessionResultListeners: Array<(sessionResult: SessionResult) => void> = []
  private currentSessionResultPromise: Promise<SessionResult> = this.loadSession()

  private loadSession(): Promise<SessionResult> {
    const sessionResultPromise = loadSession()
    this.currentSessionResultPromise = sessionResultPromise

    // notify session loading listeners
    this.sessionResultPromiseListeners.forEach((listener) => listener(this.currentSessionResultPromise))

    // notify session listeners (in time)
    sessionResultPromise.then(
      (result) => {
        // don't notify if another update is already pending
        this.currentSessionResult = result
        if (this.currentSessionResultPromise !== sessionResultPromise) return
        this.sessionResultListeners.forEach((listener) => listener(result))
      },
      () => {
        // Nothing to do. If it's a network error, we don't want to report it, and if it's a server error it's already on sentry
      },
    )

    return this.currentSessionResultPromise
  }

  getSessionResult(): Promise<SessionResult> {
    return this.currentSessionResultPromise
  }

  refreshSession(): Promise<SessionResult> {
    return this.loadSession()
  }

  onSessionRefreshing(listener: (sessionResultPromise: Promise<SessionResult>) => void): () => void {
    this.sessionResultPromiseListeners.push(listener)
    return () => {
      this.sessionResultPromiseListeners = this.sessionResultPromiseListeners.filter((l) => l !== listener)
    }
  }

  onSessionRefreshed(listener: (sessionResult: SessionResult) => void): () => void {
    this.sessionResultListeners.push(listener)

    // immediately notify them, so they can be informed if the session was loaded before they started listening
    if (this.currentSessionResult) listener(this.currentSessionResult)

    return () => {
      this.sessionResultListeners = this.sessionResultListeners.filter((l) => l !== listener)
    }
  }
}

export const sessionManager = new SessionManager()

async function loadSession(): Promise<SessionResult> {
  const trackingInfo = getTrackingInfo(new URL(window.location.href).searchParams)
  return getSessionWithRetries(trackingInfo)
}

async function getSessionWithRetries(trackingInfo: ClientTracingInfo): Promise<SessionResult> {
  for (let i = 0; i < 10; i++) {
    try {
      return success(await trpc.sessions.ensureOrusSession.mutate(trackingInfo))
    } catch (err) {
      if (err instanceof Error && 'data' in err && 'code' in (err as { data: { code: string } }).data) {
        const code = (err as { data: { code: string } }).data.code
        if (code === 'CLIENT_OUTDATED') {
          return failure('client_outdated')
        }
      }
      await sleep(5000)
    }
  }
  return failure(null)
}

function getTrackingInfo(searchParams: URLSearchParams) {
  /**
   * The first url seen is available in the app through the "fus" query param
   * See sg.js file for more context
   */
  const firstUrlSeenEncoded = searchParams.get('fus')

  return {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    screen: {
      width: window.screen.width,
      height: window.screen.height,
      density: window.devicePixelRatio,
    },
    utm: {
      source: searchParams.get('utm_source') ?? searchParams.get('otm_source') ?? undefined,
      medium: searchParams.get('utm_medium') ?? searchParams.get('otm_medium') ?? undefined,
      campaign: searchParams.get('utm_campaign') ?? searchParams.get('otm_campaign') ?? undefined,
      term: searchParams.get('utm_term') ?? searchParams.get('otm_term') ?? undefined,
      content: searchParams.get('utm_content') ?? searchParams.get('otm_content') ?? undefined,
    },
    firstUrlSeen: firstUrlSeenEncoded ? atob(firstUrlSeenEncoded) : undefined,
    app: { name: 'orus-web-app', version: __CODE_VERSION_ID__ },
    locale: navigator.languages[0],
    referrer: document.referrer,
  }
}

export const SessionContext = React.createContext<OrusSession | null>(null)

export function useSession(): OrusSession {
  const session = useContext(SessionContext)
  if (!session) {
    throw new TechnicalError('The session context has not been provided')
  }
  return session
}
