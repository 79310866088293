import { useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { NavigationButtonsWithSubmitUiElementBlock } from './NavigationButtonsWithSubmitUiElementBlock'
import { SubscriptionFooterElementGroupBlock } from './SubscriptionFooterElementGroupBlock'
import type { SubscriptionFooterProps } from './types'
import { useSubscriptionFooterUiElementProps } from './use-subscription-footer-ui-element-props'

export const SubscriptionFooterBlock = memo<SubscriptionFooterProps>(function SubscriptionFooterBlock(props) {
  const translate = useTranslate()
  const { stateProxy, footerElement, ...passedProps } = useSubscriptionFooterUiElementProps(props)

  if (footerElement?.type) {
    return <SubscriptionFooterElementGroupBlock {...passedProps} uiElement={footerElement} stateProxy={stateProxy} />
  }

  return (
    <NavigationButtonsWithSubmitUiElementBlock
      {...props}
      nextStepLabel={translate(props.step.nextStepLabelKey ?? 'continue')}
      hideInDesktop={false}
    />
  )
})
