import { getCummulatedMonthlyTotalPremiumPerYear, getYearlyTotalPremiumSplitPerMonth } from '@orus.eu/dimensions'
import type { FinalizeQuoteSubscriptionFooterUiElement } from '@orus.eu/dimensions/src/subscription-ui/subscription-footer-ui-element'
import { useTranslate } from '@orus.eu/pharaoh'

import { ifFooterStateProxy } from '../if-footer-state-proxy'
import { NavigationButtonsWithSubmitUiElementBlock } from '../subscription-footer/NavigationButtonsWithSubmitUiElementBlock'

export const FinalizeQuoteFooterUiElementBlock = ifFooterStateProxy<FinalizeQuoteSubscriptionFooterUiElement>(
  function FinalizeQuoteFooterUiElementBlock(props) {
    const translate = useTranslate()

    const { stateProxy, uiElement } = props

    const paymentRecurrence = stateProxy.readRequired(uiElement.dimensions.paymentRecurrence)
    const quote = stateProxy.readRequired(uiElement.dimensions.hiddenQuote)

    const monthlyPrice =
      paymentRecurrence === 'monthly' ? quote.subsequentMonthsTotalPremium : getYearlyTotalPremiumSplitPerMonth(quote)
    const yearlyPrice =
      paymentRecurrence === 'yearly' ? quote.yearlyTotalPremium : getCummulatedMonthlyTotalPremiumPerYear(quote)

    const currentRecurrencePrice = paymentRecurrence === 'monthly' ? monthlyPrice : yearlyPrice

    return (
      <NavigationButtonsWithSubmitUiElementBlock
        {...props}
        nextStepLabel={translate('subscription_funnel_finalize_for', { amount: currentRecurrencePrice })}
        hideInDesktop={true}
      />
    )
  },
)
