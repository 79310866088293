import { operatingZoneSchema } from '@orus.eu/operating-zone'
import { z } from 'zod'
import { activityZodSchema, type Activity } from './activities.js'
import { groupsCategoryZodSchema } from './groups-categories.js'

export const defaultActivityGroupWeight = 1

export type WeightedActivities = { weight: number; activities: Activity[]; description?: string; groupName: string }

export const changesGroupsSchema = z.record(
  z.string(),
  z.object({
    deleted: z.boolean(),
    weight: z.number(),
    aliases: z.array(z.string()),
    activities: z.array(activityZodSchema),
    categories: z.array(groupsCategoryZodSchema).optional(),
    description: z.string().optional().nullable(),
    forbiddenMonthlyPayment: z.boolean().optional().nullable(),
  }),
)

const groupDefinitionSchema = z.object({
  weight: z.number(),
  aliases: z.array(z.string()),
  activities: z.array(activityZodSchema),
  categories: z.array(groupsCategoryZodSchema),
  description: z.string().optional().nullable(),
  forbiddenMonthlyPayment: z.boolean(),
})
export type GroupDefinition = z.infer<typeof groupDefinitionSchema>

export const zoneGroupDefinitionsSchema = z.record(z.string(), groupDefinitionSchema)
export type ZoneGroupDefinitions = z.infer<typeof zoneGroupDefinitionsSchema>

export const groupDefinitionsSchema = z
  .record(operatingZoneSchema, zoneGroupDefinitionsSchema)
  // from https://stackoverflow.com/a/77958733
  .refine((obj): obj is Required<typeof obj> => operatingZoneSchema.options.every((key) => obj[key] != null))
export type GroupDefinitions = z.infer<typeof groupDefinitionsSchema>

const groupDefinitionUpdateV1Schema = z.object({
  weight: z.number(),
  aliases: z.array(z.string()),
  activities: z.array(activityZodSchema),
  categories: z.array(groupsCategoryZodSchema).optional(),
  description: z.string().optional().nullable(),
  forbiddenMonthlyPayment: z.boolean().optional().nullable(),
  deleted: z.boolean().optional().nullable(),
})
export const groupDefinitionUpdateV2Schema = z.object({
  name: z.string(),
  operatingZone: operatingZoneSchema,
  weight: z.number(),
  aliases: z.array(z.string()),
  activities: z.array(activityZodSchema),
  categories: z.array(groupsCategoryZodSchema),
  description: z.string().optional().nullable(),
  forbiddenMonthlyPayment: z.boolean(),
  deleted: z.boolean(),
})

/**
 * Activity groups group together a number of activites that are very similar and that can be considered the same
 * activity for the end user. This is because risk carriers and especially Hiscox tend to make ver fine-grained
 * activity categorization, but they still have the same characteristics. So we group them together to present
 * activity groups that make sense for the end user.
 */
export type GroupDefinitionUpdateV1 = z.infer<typeof groupDefinitionUpdateV1Schema>
export type GroupDefinitionUpdateV2 = z.infer<typeof groupDefinitionUpdateV2Schema>

export const groupDefinitionsUpdateV1Schema = z.record(z.string(), groupDefinitionUpdateV1Schema)
export type GroupDefinitionsUpdateV1 = z.infer<typeof groupDefinitionsUpdateV1Schema>
export const groupDefinitionsUpdateV2Schema = z.array(groupDefinitionUpdateV2Schema)
export type GroupDefinitionsUpdateV2 = z.infer<typeof groupDefinitionsUpdateV2Schema>

export function normalizeGroupDefinitionsUpdate(
  groupDefinitionsUpdate: GroupDefinitionsUpdateV1 | GroupDefinitionsUpdateV2,
): GroupDefinitionsUpdateV2 {
  if (Array.isArray(groupDefinitionsUpdate)) return groupDefinitionsUpdate
  return Object.entries(groupDefinitionsUpdate).map(([name, group]) => ({
    name,
    operatingZone: 'fr',
    ...group,
    categories: group.categories ?? [],
    deleted: group.deleted ?? false,
    forbiddenMonthlyPayment: group.forbiddenMonthlyPayment ?? false,
  }))
}
