import { isSuccess, type Result } from '@orus.eu/result'
import type { Language } from '@orus.eu/translations'
import { useCallback, useState, type ChangeEvent, type FocusEvent, type InputHTMLAttributes } from 'react'
import { useLanguage } from '../../localization/language-context.js'
import { typedMemo } from '../../util.js'
import { TextField, type TextFieldProps } from '../inputs/text-field/text-field.js'

export type ValidatedTextFieldProps<TYPE> = {
  placeholder?: string
  value?: TYPE | null
  onChange?: (value: TYPE | undefined) => void
  mapper: ValidatedTypeMapper<TYPE>
  highlight?: boolean
  slotProps?: TextFieldProps['slotProps']
} & Pick<
  TextFieldProps,
  | 'onBlur'
  | 'autoFocus'
  | 'multiline'
  | 'disabled'
  | 'fullWidth'
  | 'className'
  | 'InputProps'
  | 'size'
  | 'variant'
  | 'aria-label'
>

type InputType = 'tel' | 'email' | 'text' | 'number' | 'url' | 'amount' | 'financial-rate'

export type ValidatedTypeMapper<TYPE> = {
  inputType: InputType
  format: (value: TYPE, language: Language) => string
  formatPlaceholder: (value: TYPE, language: Language) => string
  parse: (value: string, language: Language) => Result<TYPE | undefined, string>
  formatFailingText?: (value: string, language: Language) => string
}

export const ValidatedTextField = typedMemo(function ValidatedTextField<TYPE>(props: ValidatedTextFieldProps<TYPE>) {
  const language = useLanguage()
  const [failingText, setFailingText] = useState<string | undefined>()
  const [helperText, setHelperText] = useState<string | undefined>()
  const [validationActive, setValidationActive] = useState(false)

  const { placeholder, value, onChange, onBlur, mapper, highlight, slotProps, ...passedProps } = props

  const valueString = failingText || (value != undefined ? mapper.format(value, language) : undefined)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value
      const parsingResult = mapper.parse(text, language) || undefined
      if (isSuccess(parsingResult)) {
        setFailingText(undefined)
        setHelperText(undefined)
        if (onChange) onChange(parsingResult.output)
      } else {
        const formattedFailingText = mapper.formatFailingText?.(event.target.value, language) ?? event.target.value
        setFailingText(formattedFailingText)
        setHelperText(parsingResult.problem)
        if (onChange) onChange(undefined)
      }
    },
    [language, mapper, onChange],
  )
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setValidationActive(true)
      if (onBlur) onBlur(event)
    },
    [onBlur],
  )

  const effectiveHelperText = validationActive && helperText ? helperText : undefined

  const shouldDisplayNumericKeyboard =
    mapper.inputType === 'number' || mapper.inputType === 'amount' || mapper.inputType === 'financial-rate'

  return (
    <TextField
      helperText={effectiveHelperText}
      error={!!effectiveHelperText || highlight}
      placeholder={placeholder}
      value={valueString}
      onChange={handleChange}
      slotProps={slotProps}
      onBlur={handleBlur}
      type={getTextFieldType(mapper.inputType)}
      inputProps={{ inputMode: shouldDisplayNumericKeyboard ? 'numeric' : undefined }}
      {...passedProps}
    />
  )
})

function getTextFieldType(inputType: InputType): InputHTMLAttributes<unknown>['type'] {
  if (inputType === 'amount' || inputType === 'financial-rate') return 'text'
  return inputType
}
