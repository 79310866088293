import { sendMessage } from './tracking/tracking'

type TallyPopupOptions = {
  width?: number
  layout?: 'default' | 'modal'
  hiddenFields?: {
    [key: string]: string | number | boolean | undefined | null
  }
  onSubmit?: (payload: { [key: string]: string | undefined | null }) => void
}

declare const Tally: { openPopup: (formId: string, options?: TallyPopupOptions) => void }

export const openTallyPopup = (formId: string, options?: TallyPopupOptions): void => {
  if (typeof Tally !== 'undefined' && Tally.openPopup) {
    Tally.openPopup(formId, {
      width: 500,
      ...options,
    })
    sendMessage({
      event: 'viewed_tally_form',
      form_id: formId,
    })
  } else {
    throw new Error('Tally is not available')
  }
}
