import styled from '@emotion/styled'
import { Avatar, Button, colorTokens, desktopMediaQuery, emoji, spacing, Text, useUiContext } from '@orus.eu/pharaoh'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'

export const RenewalFailState = function RenewalFailState(): JSX.Element {
  const uiContext = useUiContext()
  const navigateToLoginPage = useNavigateTo({ to: '/login' })

  return (
    <RenewalFailLayout>
      <Avatar src={emoji.circleExclamation} size="60" />
      <Text variant="h4" color={colorTokens['color-text-base-main']}>
        Votre situation est notre priorité
      </Text>
      <Text variant="body2" color={colorTokens['color-text-base-basic']}>
        Nous vous informons qu&apos;à la suite d&apos;une réévaluation, votre entreprise ne remplit plus les critères de
        notre cadre de souscription. Nous comprenons que cette nouvelle puisse être frustrante, et nous nous engageons à
        vous accompagner au mieux durant cette transition.
        <br />
        Un de nos conseillers vous contactera très prochainement pour vous expliquer les étapes à suivre et répondre à
        toutes vos questions.
      </Text>
      <Button
        variant="secondary"
        size={uiContext === 'mobile' ? 'large' : 'medium'}
        fullWidth={uiContext === 'mobile'}
        onClick={navigateToLoginPage}
      >
        Accéder à mon espace assuré
      </Button>
    </RenewalFailLayout>
  )
}

const RenewalFailLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[70]};
  margin: 0 auto;
  padding: ${spacing[60]} ${spacing[60]} ${spacing[100]};
  width: 100%;
  max-width: 760px;

  ${desktopMediaQuery} {
    padding: ${spacing[80]} 0;
  }
`
