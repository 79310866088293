import styled from '@emotion/styled'
import { Box } from '@mui/material'
import type { ContractClaimsContactInformations } from '@orus.eu/backend/src/services/claim-contact-service'
import {
  Button,
  ContentContainerAppClient,
  PageTitle,
  spacing,
  Text,
  useAsyncCallback,
  useDialogVisibility,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { ClaimCard } from '@orus.eu/pharaoh/src/components/features/claim-card'
import { memo } from 'react'
import { trpcReact } from '../../../client'
import { useSession } from '../../../lib/session'
import { SmallScreenMargin } from '../../templates/small-screen-margin'
import { ReportClaimsDialog } from './components/ReportClaimsDialog'
import { avatars, onOpenReportClaimTally } from './report-claim-util'

const ReportClaimPage = memo(function ReportClaimPage() {
  const translate = useTranslate()
  const [contractsClaimsContactInformations] = trpcReact.claims.getClaimsContactInfo.useSuspenseQuery()
  const screenType = useUiContext()
  const { user } = useSession()

  const {
    show: showReportClaimsDialog,
    hide: hideReportClaimsDialog,
    visible: isReportClaimsDialogVisible,
  } = useDialogVisibility('report-claims')

  const onReportClaim = useAsyncCallback(async () => {
    const hasMultipleClaimsContactInformations = contractsClaimsContactInformations.length > 1

    const hasMultipleProducts = contractsClaimsContactInformations.some(
      (contractClaimsContactInformations) =>
        contractClaimsContactInformations?.claimsContactInformations &&
        contractClaimsContactInformations?.claimsContactInformations?.length > 1,
    )

    if (hasMultipleClaimsContactInformations || hasMultipleProducts) {
      showReportClaimsDialog()
    } else {
      const firstClaimContactInformation = contractsClaimsContactInformations[0]?.claimsContactInformations?.[0]
      if (firstClaimContactInformation) {
        await onOpenReportClaimTally(
          firstClaimContactInformation.type,
          contractsClaimsContactInformations[0].subscriptionId,
          user,
        )
      }
    }
  }, [contractsClaimsContactInformations, showReportClaimsDialog, user])

  return (
    <>
      {isReportClaimsDialogVisible && (
        <ReportClaimsDialog
          contractsClaimsContactInformations={contractsClaimsContactInformations}
          onClose={hideReportClaimsDialog}
        />
      )}
      <PageTitle
        title={translate('claim')}
        appendRightOnDesktop={
          <Button
            variant="primary"
            size={screenType === 'mobile' ? 'large' : 'medium'}
            aria-label={'report-claim'}
            onClick={onReportClaim}
          >
            {translate('report_claim')}
          </Button>
        }
      />
      <ContentContainerAppClient>
        {screenType === 'desktop' ? (
          <ContractsClaims contractsClaimsContactInformations={contractsClaimsContactInformations} />
        ) : (
          <SmallScreenMargin>
            <ContractsClaims contractsClaimsContactInformations={contractsClaimsContactInformations} />
          </SmallScreenMargin>
        )}
      </ContentContainerAppClient>
      {screenType === 'mobile' ? (
        <OnlyVisibleOnMobileCTA>
          <SmallScreenMargin>
            <Button variant="primary" onClick={onReportClaim} size="large" fullWidth>
              {translate('report_claim')}
            </Button>
          </SmallScreenMargin>
        </OnlyVisibleOnMobileCTA>
      ) : null}
    </>
  )
})

export const ContractsClaims = memo<{
  contractsClaimsContactInformations: ContractClaimsContactInformations[]
}>(function ContractsClaims({ contractsClaimsContactInformations }) {
  const screenType = useUiContext()

  return (
    <>
      {contractsClaimsContactInformations.map((contractClaimsContactInformations, index) => (
        <Box key={index}>
          <Text variant={'subtitle'}>{contractClaimsContactInformations.activity?.displayName}</Text>
          <Text variant={'body2'}>{contractClaimsContactInformations.address}</Text>

          <CardsContainer isLargeScreen={screenType === 'desktop'}>
            {contractClaimsContactInformations.claimsContactInformations?.map((claimContact, index) => (
              <ClaimCard
                key={claimContact.type + index}
                title={claimContact.title}
                phoneNumber={claimContact.phoneNumber}
                email={claimContact.email}
                description={claimContact.description}
                avatar={avatars[claimContact.type].avatar}
                avatarBackgroundColor={avatars[claimContact.type].avatarBackgroundColor}
              />
            ))}
          </CardsContainer>
        </Box>
      ))}
    </>
  )
})

const CardsContainer = styled(Box)<{ isLargeScreen: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.isLargeScreen ? 'repeat(2, 1fr)' : '1fr')};
  gap: ${(props) => (props.isLargeScreen ? spacing[50] : spacing[60])};
  padding: ${spacing[50]} 0;
`

const OnlyVisibleOnMobileCTA = styled.div`
  position: sticky;
  bottom: ${spacing[60]};
`

export default ReportClaimPage
