import { createFileRoute } from '@tanstack/react-router'
import BackofficeNewSubscriptionPage from '../../../../components/pages/backoffice/common/backoffice-new-subscription-page'
import PartnerPage from '../../../../components/templates/partner-page'

export const Route = createFileRoute('/partner/$organization/new-subscription')({
  component: () => (
    <PartnerPage requiredPermissions={['subscription.create']}>
      <BackofficeNewSubscriptionPage />
    </PartnerPage>
  ),
})
