import { amountToString } from '@orus.eu/amount'
import type { OrusSessionUser } from '@orus.eu/backend/src/services/session/session-types'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import { formatDdMmYyyy } from '@orus.eu/calendar-date'
import { TechnicalError } from '@orus.eu/error'
import { isProductionEnvironment } from '../../../lib/env-utils'
import { openTallyPopup } from '../../../lib/tally-util'

const requestFrContractUpdateTallyId = 'nP2DDB'
const requestEsContractUpdateTallyId = 'waj4B2'

export const onRequestContractUpdateTally = (
  contract: CustomerContractDescription,
  user: OrusSessionUser | null,
): void => {
  if (!user) {
    throw new TechnicalError('UserId is required to open the Tally popup')
  }

  let formId = undefined
  switch (contract.operatingZone) {
    case 'es':
      formId = requestEsContractUpdateTallyId
      break
    case 'fr':
      formId = requestFrContractUpdateTallyId
  }

  if (!formId) {
    throw new TechnicalError('FormId is required to open the Tally popup')
  }

  openTallyPopup(formId, {
    layout: 'modal',
    hiddenFields: {
      isProductionEnvironment: isProductionEnvironment(),
      emailAddress: encodeURIComponent('email' in user ? user.email : user.unverifiedEmail),
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      subscriptionId: contract.subscriptionId,
      sirenNumber:
        contract.operatingZone === 'fr' && 'siren' in contract.companyIdNumber
          ? contract.companyIdNumber.siren
          : undefined,
      nifNumber:
        contract.operatingZone === 'es' && 'nif' in contract.companyIdNumber ? contract.companyIdNumber.nif : undefined,
      cifNumber:
        contract.operatingZone === 'es' && 'cif' in contract.companyIdNumber ? contract.companyIdNumber.cif : undefined,
      companyName: encodeURIComponent(contract.companyName),
      activityName: encodeURIComponent(contract.activity.displayName),
      activitiesList: encodeURIComponent(
        contract.activitiesList.map((activity) => `- ${activity.displayName}`).join('\n'),
      ),
      businessAddress: encodeURIComponent(contract.companyAddress),
      options: encodeURIComponent(contract.productsInformation.map((product) => product.product).join('\n')),
      declaredRevenue: amountToString(contract.estimatedRevenue, { displayDecimals: false }),
      declaredEtp: contract.workforce ?? contract.headcount,
      goodsValue: amountToString(contract.mrpwAssetsValue ?? contract.mrphAssetsValue, { displayDecimals: false }),
      localSize: contract.mrpwSurface ?? contract.mrphSurface,
      startDate: formatDdMmYyyy(contract.startDate),
      endDate: formatDdMmYyyy(contract.endDate),
      contractNumber: contract.contractNumber,
    },
  })
}
