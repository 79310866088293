import type {
  GroupDefinition,
  GroupDefinitions,
  GroupDefinitionsUpdateV2,
  GroupDefinitionUpdateV2,
} from '@orus.eu/activity'
import { TechnicalError } from '@orus.eu/error'
import { operatingZones, type OperatingZone } from '@orus.eu/operating-zone'
import deepEqual from 'deep-equal'

export type GroupDefinitionsUpdateDiff = {
  /**
   * Technical definition of the update
   */
  update: GroupDefinitionsUpdateV2
  /**
   * UI-friendly description of groups that were added in the process
   */
  addedGroups: GroupDescription[]
  /**
   * UI-friendly description of groups that were removed in the process
   */
  removedGroups: GroupDescription[]
  /**
   * UI-friendly description of groups that were updated in the process
   */
  updatedGroups: GroupDescription[]
}

export type GroupDescription = {
  name: string
  operatingZone: OperatingZone
} & Omit<GroupDefinition, 'deleted'>

export function isDiffEmpty(diff: GroupDefinitionsUpdateDiff): boolean {
  return Object.keys(diff.update).length === 0
}

export function computeDiff(a: GroupDefinitions, b: GroupDefinitions): GroupDefinitionsUpdateDiff {
  const updatesByNameByZone: Record<OperatingZone, Record<string, GroupDefinitionUpdateV2>> = { fr: {}, es: {} }
  const addedGroups: GroupDescription[] = []
  const removedGroups: GroupDescription[] = []
  const updatedGroups: GroupDescription[] = []

  const unseenOldGroupNames: Record<OperatingZone, Set<string>> = {
    fr: new Set(Object.keys(a['fr'])),
    es: new Set(Object.keys(a['es'])),
  }

  for (const operatingZone of operatingZones) {
    for (const entry of Object.entries(b[operatingZone])) {
      const name = entry[0]
      const definitionA = a[operatingZone][name] ? cleanGroupDefinition(a[operatingZone][name]) : undefined
      const definitionB = cleanGroupDefinition(entry[1])

      unseenOldGroupNames[operatingZone].delete(name)

      const { activities, aliases, weight, categories, description, forbiddenMonthlyPayment } = definitionB

      if (!definitionA) {
        updatesByNameByZone[operatingZone][name] = { ...definitionB, name, operatingZone, deleted: false }
        addedGroups.push({
          name,
          operatingZone,
          activities,
          aliases,
          weight,
          categories,
          description,
          forbiddenMonthlyPayment,
        })
        continue
      }

      if (
        !definitionA ||
        definitionA.weight !== definitionB.weight ||
        !deepEqual(definitionA.aliases, definitionB.aliases) ||
        !deepEqual(definitionA.activities, definitionB.activities) ||
        !deepEqual(definitionA.categories, definitionB.categories) ||
        definitionA.description != definitionB.description ||
        definitionA.forbiddenMonthlyPayment != definitionB.forbiddenMonthlyPayment
      ) {
        updatesByNameByZone[operatingZone][name] = { ...definitionB, name, operatingZone, deleted: false }
        updatedGroups.push({
          name,
          operatingZone,
          activities,
          aliases,
          weight,
          categories,
          description,
          forbiddenMonthlyPayment,
        })
        continue
      }

      if (!deepEqual(definitionA, definitionB)) {
        throw new TechnicalError('Unhandled difference between group definitions', {
          context: { definitionA, definitionB },
        })
      }
    }
  }

  for (const operatingZone of operatingZones) {
    for (const name of unseenOldGroupNames[operatingZone]) {
      const definitionA = a[operatingZone][name]
      const { activities, aliases, weight, categories, description, forbiddenMonthlyPayment } = definitionA

      updatesByNameByZone[operatingZone][name] = { ...definitionA, name, operatingZone, deleted: true }
      removedGroups.push({
        name,
        operatingZone,
        activities,
        aliases,
        weight,
        categories,
        description,
        forbiddenMonthlyPayment,
      })
    }
  }

  const update: GroupDefinitionsUpdateV2 = Object.values(updatesByNameByZone).flatMap((updatesByName) =>
    Object.values(updatesByName),
  )

  return { update, addedGroups, removedGroups, updatedGroups }
}

function cleanGroupDefinition(definition: GroupDefinition): GroupDefinition {
  return {
    weight: definition.weight,
    aliases: definition.aliases,
    activities: definition.activities,
    categories: definition.categories,
    description: definition.description,
    forbiddenMonthlyPayment: definition.forbiddenMonthlyPayment,
  }
}
