import { css } from '@emotion/react'
import {
  exclusionsDimension,
  hasQuoteEditorWritableInputs,
  type LooselyTypedData,
  type QuoteEditorTabsTitle,
} from '@orus.eu/dimensions'
import {
  Divider,
  LayoutTabbed,
  SaveablePage,
  Section,
  Tab,
  spacing,
  useAsyncCallback,
  useUiContext,
} from '@orus.eu/pharaoh'
import { LanguageProvider } from '@orus.eu/pharaoh/src/localization/language-context'
import { orusOrganization } from '@orus.eu/right-access-management'
import { useParams } from '@tanstack/react-router'
import { Fragment, memo, useCallback, useRef, useState, type FormEvent, type ReactNode } from 'react'
import { trpc, trpcReact } from '../../../../../client'
import { noop } from '../../../../../lib/noop'
import { usePermissions } from '../../../../../lib/use-permissions'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { PreventUpdatingContractDialog } from '../../../../organisms/prevent-updating-contract-dialog'
import { useModularNextEnabled } from '../../../subscription-v2/modular-next-enabled'
import { SubscriptionBackofficeElementGroupBlock } from '../../../subscription-v2/subscription-v2-element-group-block'
import { ExclusionsDimensionField } from '../../common/backoffice-quote-editor-v2/fields/exclusions-dimension-field'
import { getQuoteEditorUiTabs } from '../../common/backoffice-quote-editor-v2/sections/quote-editor-sections.util'
import { useRequiredDimensionsHighlights } from '../../common/use-required-dimensions-highlights'
import { useEndorsementQuoteEditorState } from './platform-endorsement-quote-editor-state'
import { PlatformPendingEndorsementPageHeader } from './PlatformPendingEndorsementPageHeader'
import { PlatformPendingEndorsementSidePanel } from './PlatformPendingEndorsementSidePanel'

const PlatformPendingEndorsementPage = memo(function PlatformPendingEndorsementPage(): ReactNode {
  const uiContext = useUiContext()
  const { subscriptionId, endorsementId } = useParams({
    from: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/',
  })
  const { permissions } = usePermissions()

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true)

  const { data: currentSubscriptionOrganization, isLoading: isOrganizationTechnicalNameLoading } =
    trpcReact.organizations.getOrganizationInfoBySubscriptionId.useQuery(subscriptionId)

  const {
    changes,
    setChanges,
    before,
    after,
    nonDimensionalDataAfter,
    reset,
    commit,
    commiting,
    stateChangeNotifications,
    creationDate,
  } = useEndorsementQuoteEditorState({ subscriptionId, endorsementId })

  const { nextEnabledByAllContributions, registerNextEnabledContribution } = useModularNextEnabled()

  /**
   * Compute the displayed state by displaying the freshest value available
   */
  const state = Object.assign(
    {},
    // Start with the complete state computed on the server
    after,
    // Overriden by uncommited changes to the inputs
    changes,
  )

  const quoteEditorUiTabs = getQuoteEditorUiTabs({
    state,
    permissions,
    isPartner: false,
    subscriptionOrganizationType: orusOrganization,
    currentOrganizationTechnicalName: currentSubscriptionOrganization?.technicalName,
    isContractSigned: true,
    currentOrganizationCanEditPartnerFees: currentSubscriptionOrganization?.canEditPartnerFees,
  })
  const tabs = [...quoteEditorUiTabs.entries()].map(([name, _]) => name)

  const [tabsMissingDimensions, setTabsMissingDimensions] = useState(tabs.map(() => new Set<string>()))
  const cleanTabsMissingDimensions = useCallback(() => {
    setTabsMissingDimensions(tabs.map(() => new Set<string>()))
  }, [tabs])

  const handleAddMissingDimension = useCallback(
    (tab: QuoteEditorTabsTitle, tabIndex: number, dimensionName: string) => {
      if (!hasQuoteEditorWritableInputs[tab]) return
      if (tabsMissingDimensions[tabIndex]?.has(dimensionName)) return
      setTabsMissingDimensions((prev) => {
        const newTabsRequiringFields = [...prev]
        newTabsRequiringFields[tabIndex].add(dimensionName)
        return newTabsRequiringFields
      })
    },
    [tabsMissingDimensions, setTabsMissingDimensions],
  )

  const handleRemoveMissingDimension = useCallback(
    (tab: QuoteEditorTabsTitle, tabIndex: number, dimensionName: string) => {
      if (!hasQuoteEditorWritableInputs[tab]) return
      if (!tabsMissingDimensions[tabIndex]?.has(dimensionName)) return

      setTabsMissingDimensions((prev) => {
        const newTabsRequiringFields = [...prev]
        newTabsRequiringFields[tabIndex].delete(dimensionName)
        return newTabsRequiringFields
      })
    },
    [tabsMissingDimensions, setTabsMissingDimensions],
  )

  const { toggleHighlightDimensions, highlightDimensionsNames, isHighlightDimensionsActive, requiredDimensionsNames } =
    useRequiredDimensionsHighlights({
      state,
      subscriptionId,
      cleanTabsMissingDimensions,
      nonDimensionalDataAfter,
    })

  const handleSubmit = useCallback(
    (_event?: FormEvent, changes?: LooselyTypedData) => {
      if (changes) setChanges(changes)
    },
    [setChanges],
  )

  const tabsBreadcrumbsLength = tabs.reduce(
    (previous, curr) => {
      previous[curr] = quoteEditorUiTabs.get(curr)!.size
      return previous
    },
    {} as Record<string, number>,
  )

  const setSubscriptionOwner = useAsyncCallback(
    async (newCustomerId: string) => {
      await trpc.subscriptions.setSubscriptionOwner.mutate({ subscriptionId, customerId: newCustomerId })
      setChanges({})
    },
    [setChanges, subscriptionId],
  )

  const shouldBypassBrowserConfirmationDialog = useRef(false)

  const bypassBrowserConfirmationDialog = useCallback(() => (shouldBypassBrowserConfirmationDialog.current = true), [])

  const hasUnsavedChanges = Object.keys(changes).length > 0

  const getTabVariant = useCallback(
    (tabIndex: number) => (tabsMissingDimensions[tabIndex]?.size > 0 ? 'danger' : 'neutral'),
    [tabsMissingDimensions],
  )

  if (!before || !nonDimensionalDataAfter || !creationDate || isOrganizationTechnicalNameLoading)
    return <GlobalLoadingState />

  const currentEmail = changes.email || before.email || undefined
  const currentPhone = changes.phone || before.phone || undefined

  const wrapperCss = css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: filter 0.3s;
    display: flex;
    flex-direction: column;
  `

  return (
    <div
      className="nodash"
      css={
        commiting
          ? css`
              filter: opacity(0.5);
              pointer-events: none;
              user-select: none;
              ${wrapperCss}
            `
          : wrapperCss
      }
    >
      <LanguageProvider value={state.operatingZone ?? undefined}>
        <SaveablePage hasUnsavedChanges={hasUnsavedChanges}>
          <PreventUpdatingContractDialog stateChangeNotifications={stateChangeNotifications} />
          <LayoutTabbed
            header={
              <>
                <PlatformPendingEndorsementPageHeader
                  subscriptionId={subscriptionId}
                  changes={changes}
                  onReset={reset}
                  onValidate={commit}
                  hasQuote={!!after?.quote}
                  currentEmail={currentEmail}
                  currentPhone={currentPhone}
                  nextEnabledByAllContributions={nextEnabledByAllContributions}
                  bypassBrowserConfirmationDialog={bypassBrowserConfirmationDialog}
                  creationDate={creationDate}
                />
                <ExclusionsDimensionField valueDimension={exclusionsDimension} state={state} />
              </>
            }
            sidePanel={
              <PlatformPendingEndorsementSidePanel
                state={state}
                stateBefore={before}
                stateAfter={after}
                setChanges={setChanges}
                toggleHighlightDimensions={toggleHighlightDimensions}
                isHighlightDimensionsActive={isHighlightDimensionsActive}
                toggleIsSidePanelOpen={() => setIsSidePanelOpen(!isSidePanelOpen)}
                isSidePanelOpen={isSidePanelOpen}
                changes={changes}
                hasQuote={!!after?.quote}
                currentEmail={currentEmail}
                currentPhone={currentPhone}
                subscriptionId={subscriptionId}
                nonDimensionalDataAfter={nonDimensionalDataAfter}
                endorsementId={endorsementId}
              />
            }
            isSidePanelOpen={isSidePanelOpen}
            activeTabHash={window.location.hash}
            tabs={tabs.map((tab, tabIndex) => {
              return {
                tab: (
                  <Tab key={tab} variant={getTabVariant(tabIndex)}>
                    {tab}
                  </Tab>
                ),
                content: (
                  <>
                    {[...quoteEditorUiTabs.get(tab)!.entries()].map(([breadcrumb, sections], index) => {
                      return (
                        <Fragment key={breadcrumb}>
                          <Section title={{ title: breadcrumb }}>
                            {sections.map((section) => (
                              <SubscriptionBackofficeElementGroupBlock
                                key={section.id}
                                context="backoffice"
                                customerId={nonDimensionalDataAfter.customerId}
                                contract={nonDimensionalDataAfter.contract}
                                group={section.bodyElements}
                                subscriptionId={subscriptionId}
                                stepId={undefined}
                                versionedSubscriptionId={`${subscriptionId}/0`}
                                localState={state}
                                updateLocalChanges={setChanges}
                                updateAndPersistLocalChanges={setChanges}
                                handleSubmit={handleSubmit}
                                registerNextEnabledContribution={registerNextEnabledContribution}
                                nextEnabled={false}
                                serverValidationIssue={null}
                                goBackToPreviousStep={noop}
                                goBackToStepRoot={noop}
                                goBackToBreadcrumbRootStep={noop}
                                synchronizing={false}
                                setSubscriptionOwner={setSubscriptionOwner}
                                changes={changes}
                                backofficeStepTitle={section.backofficeUi.title}
                                backofficeStepSubtitle={section.backofficeUi.subtitle}
                                requiredDimensionsNames={requiredDimensionsNames}
                                highlightDimensionsNames={highlightDimensionsNames}
                                addMissingDimension={(dimensionName: string) =>
                                  handleAddMissingDimension(tab, tabIndex, dimensionName)
                                }
                                removeMissingDimension={(dimensionName: string) =>
                                  handleRemoveMissingDimension(tab, tabIndex, dimensionName)
                                }
                                uiContext={uiContext}
                                missingSubscriptionDimensionsNames={
                                  nonDimensionalDataAfter.missingSubscriptionDimensionsNames
                                }
                              />
                            ))}
                          </Section>
                          {index !== tabsBreadcrumbsLength[tab] - 1 && (
                            <Divider
                              orientation="horizontal"
                              css={css`
                                margin-top: ${spacing[60]};
                                margin-bottom: ${spacing[60]};
                              `}
                            />
                          )}
                        </Fragment>
                      )
                    })}
                  </>
                ),
              }
            })}
          />
        </SaveablePage>
      </LanguageProvider>
    </div>
  )
})
export default PlatformPendingEndorsementPage
