import { css, Global } from '@emotion/react'
import {
  canNavigate,
  getStepProgress,
  isLastStep,
  subscriptionBreadcrumbsTranslationKeys,
  subscriptionUiSteps,
  subscriptionUiStepsById,
  type SubscriptionUiStep,
} from '@orus.eu/dimensions'
import { borderRadius, shadow, spacing, Text, useScreenType, useTranslate, useUiContext } from '@orus.eu/pharaoh'
import { memo, useMemo } from 'react'
import { useEmbeddingPartnerConfiguration } from '../../../lib/embedding-partner'
import { useNavigateBack } from '../../../lib/hooks/use-navigate-back'
import { GoBackButton, GoBackButtonWithContracts } from './components/go-back-button'

import { useSearch } from '@tanstack/react-router'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { useSession } from '../../../lib/session'
import { GlobalLoadingState } from '../../molecules/global-loading-state'
import { SubscriptionStepLayout } from './components/subscription-step-layout'
import {
  subscriptionStepOuterElementCss,
  subscriptionStepOuterElementCssMobile,
} from './components/subscription-step-outer-element-style'
import { SubscriptionV2Breadcrumbs } from './components/subscription-v2-breadcrumbs'
import { SubscriptionV2Header } from './components/subscription-v2-header'
import { SubscriptionFooterBlock } from './subscription-footer/SubscriptionFooterBlock'
import { SubscriptionElementGroupBlock, SubscriptionElementWrapper } from './subscription-v2-element-group-block'
import type { CommonSubscriptionStepProps } from './subscription-v2-props'
import { SubscriptionValidationIssueBlock } from './subscription-v2-validation-issue'

type SubscriptionV2StepPageContentProps = CommonSubscriptionStepProps & {
  step: SubscriptionUiStep
}

/**
 * This component is responsible for rendenring the content of a subscriptions step page.
 * Also see SubscriptionV2StepPageLoader for state management
 */
export const SubscriptionV2StepPageContent = memo<SubscriptionV2StepPageContentProps>(
  function SubscriptionV2StepPageContent(props) {
    const uiContext = useUiContext()
    const translate = useTranslate()
    const urlSearchParams = useSearch({ strict: false })
    const embeddingPartnerConfiguration = useEmbeddingPartnerConfiguration()
    const screenType = useScreenType()
    const navigateToContracts = useNavigateTo({ to: '/contracts' })
    const navigateBack = useNavigateBack()
    const { step, ...passedProps } = props
    const progress = getStepProgress(step.id)
    const isFirstStep = useMemo(() => subscriptionUiSteps[0].id === step.id, [step.id])
    const { user } = useSession()
    const canGoBack = useMemo(() => canNavigate(step.id), [step.id])
    const detailElements = props.detail && step.details ? step.details[props.detail.type] : undefined

    const compact = progress >= 1

    if (props.synchronizing) {
      return <GlobalLoadingState />
    }

    if (urlSearchParams.embedInIframe != null) {
      return props.localState ? (
        <>
          <Global
            styles={css`
              :root {
                /* stylelint-disable-next-line selector-id-pattern */
                #axeptio_overlay {
                  display: none;
                }
              }
            `}
          />
          <SubscriptionElementGroupBlock group={step.bodyElements} uiContext={uiContext} {...passedProps} />
        </>
      ) : undefined
    }

    let pageTitleText: string | undefined = undefined
    if (progress >= 1 && screenType === 'mobile') {
      const breadcrumb = subscriptionUiStepsById[step.id].breadcrumbs[1]
      if (breadcrumb) {
        pageTitleText = translate(subscriptionBreadcrumbsTranslationKeys[breadcrumb])
      }
    }

    const header = <SubscriptionV2Header progress={progress} compact={compact} title={pageTitleText} />

    if (detailElements) {
      return (
        <div css={screenType === 'mobile' ? subscriptionStepOuterElementCssMobile : subscriptionStepOuterElementCss}>
          {header}
          <SubscriptionStepLayout
            left={canGoBack ? <GoBackButton goBackToPreviousStep={navigateBack} /> : null}
            center={<SubscriptionElementGroupBlock group={detailElements} uiContext={uiContext} {...passedProps} />}
            right={<></>}
            compact={compact}
          />
        </div>
      )
    }

    const sideElements =
      step.sideElements && (screenType === 'desktop' || step.sideElementsMobileVariant !== 'hidden')
        ? step.sideElements
        : undefined

    const stepSubtitle = step.subtitleElement

    const embeddingPartnerStepSubtitle = embeddingPartnerConfiguration?.partnerStepSubtitles?.[step.id]

    const title = step.title ?? (step.titleKey ? translate(step.titleKey) : undefined)
    const stepContent = (
      <>
        {header}
        <SubscriptionStepLayout
          noDesktopMarginTop={step.id === 'checkout' || step.id === 'locked'}
          left={
            <>
              {user ? (
                <GoBackButtonWithContracts
                  isBackButtonHidden={isFirstStep}
                  goBackToPreviousStep={props.goBackToPreviousStep}
                  goBackContracts={navigateToContracts}
                />
              ) : isFirstStep || !canGoBack ? (
                <div
                  css={css`
                    height: 40px;
                  `}
                />
              ) : (
                <GoBackButton goBackToPreviousStep={props.goBackToPreviousStep} />
              )}
              <SubscriptionV2Breadcrumbs
                css={css`
                  margin-top: ${spacing[90]};
                `}
                stepId={step.id}
                goBackToBreadcrumbRootStep={props.goBackToBreadcrumbRootStep}
              />
            </>
          }
          center={
            <>
              {title ? (
                <div
                  css={css`
                    margin-bottom: ${spacing[70]};
                    width: 100%;
                    max-width: 760px;
                  `}
                >
                  <Text variant="h4">{title}</Text>

                  {stepSubtitle ? (
                    <SubscriptionElementWrapper verticalMargins={0} uiElement={stepSubtitle} {...passedProps} />
                  ) : (
                    <></>
                  )}

                  {embeddingPartnerStepSubtitle ? (
                    <Text
                      css={css`
                        margin-top: ${spacing[50]};
                      `}
                      variant="body1"
                    >
                      {embeddingPartnerStepSubtitle}
                    </Text>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              <SubscriptionValidationIssueBlock issue={props.serverValidationIssue} />
              {props.localState ? (
                <SubscriptionElementGroupBlock group={step.bodyElements} uiContext={uiContext} {...passedProps} />
              ) : (
                <></>
              )}
            </>
          }
          right={
            sideElements ? (
              <SubscriptionElementGroupBlock
                css={
                  screenType === 'mobile' && step.sideElementsMobileVariant === 'card'
                    ? css`
                        border-radius: ${borderRadius[30]};
                        box-shadow: ${shadow.top[30]};
                        margin: 0 -${spacing[60]};
                        padding: ${spacing[60]};
                      `
                    : screenType === 'desktop'
                      ? css`
                          margin-top: ${spacing[80]};
                        `
                      : css`
                          margin-bottom: ${spacing[60]};
                        `
                }
                group={sideElements}
                uiContext={uiContext}
                {...passedProps}
              />
            ) : undefined
          }
          footer={
            props.localState ? (
              <SubscriptionFooterBlock {...props} isFirstStep={isFirstStep} footerElement={step.footerElement} />
            ) : (
              <></>
            )
          }
          compact={compact}
        />
      </>
    )

    return isLastStep(step.id) ? (
      <div css={subscriptionStepOuterElementCss}>{stepContent}</div>
    ) : (
      <form
        id={`all-subscriptions-${step.id}`}
        onSubmit={props.isLoadingWhileTryCompleteStep ? undefined : props.handleSubmit}
        css={screenType === 'desktop' ? subscriptionStepOuterElementCss : subscriptionStepOuterElementCssMobile}
      >
        {stepContent}
      </form>
    )
  },
)
