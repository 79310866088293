import styled from '@emotion/styled'

import type { GuaranteesDescription } from '@orus.eu/backend/src/routers/contracts'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { CustomerInformation } from '@orus.eu/backend/src/views/user-account-view'
import { ContentContainerBackoffice, mobileMediaQuery, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../../../../client'
import { usePermissions } from '../../../../../lib/use-permissions'
import { BackofficeSubsectionTitle } from '../../../../atoms/backoffice-subsection-title'
import { BackofficeDataCard, DataList, DataRow } from '../../../../molecules/backoffice-data-card'
import { BackofficeContractCard } from '../../../../organisms/backoffice-contract-card'
import { BackofficeCustomerCard } from '../../../../organisms/backoffice-customer-card'
import { EditableChipOrganizations } from '../../../../organisms/editable-chip-organizations'
import { ContractGuaranteesList } from './contract-guarantees-list/ContractGuaranteesList'
import { ContractInvoicesBlock } from './contract-invoices-block/ContractInvoicesBlock'
import { ContractEndorsementsRenewalBlock } from './contract-renewal-endorsement/ContractEndorsementsRenewalBlock'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing[70]};
  ${mobileMediaQuery} {
    margin: ${spacing[50]} ${spacing[70]} 0 ${spacing[70]};
  }
`

export type BackofficeUsage = 'platform' | 'partner'

type ContractPageContentProps = {
  type: BackofficeUsage
  contract: BackofficeContractDescription
  customer: CustomerInformation
  guaranteesDescription: GuaranteesDescription
}

export const ContractPageContent = memo<ContractPageContentProps>(function ContractPageContent({
  type,
  contract,
  customer,
  guaranteesDescription,
}) {
  const { permissions } = usePermissions()
  const { data: createdByOperator, isLoading } = trpcReact.contracts.getContractCreatedByOperator.useQuery(
    contract.subscriptionId,
  )

  return (
    <ContentContainerBackoffice>
      <Header>
        <Text variant="h3">{contract.displayName}</Text>
      </Header>

      <BackofficeCustomerCard customer={customer} showUserLink />

      <BackofficeDataCard title="Apporteur d’affaires" icon="briefcase-regular">
        <DataList>
          <DataRow>
            <Text variant="body2">Courtier</Text>
            <EditableChipOrganizations subscriptionId={contract.subscriptionId} />
          </DataRow>
          <DataRow>
            <Text variant="body2">Responsable du contrat</Text>
            <Text variant="body2Medium">
              {!isLoading && !createdByOperator ? 'N/A' : !createdByOperator ? ' ' : createdByOperator.email}
            </Text>
          </DataRow>
        </DataList>
      </BackofficeDataCard>

      <BackofficeContractCard contract={contract} customerEmail={customer.email} type={type} />
      <ContractEndorsementsRenewalBlock contract={contract} customerEmail={customer.email} type={type} />

      {permissions.includes('invoices.read') ? (
        <ContractInvoicesBlock
          contract={contract}
          canCreateNewInvoice={type === 'platform' && permissions.includes('invoices.create')}
          // Only tech admins can see theoretical invoices for now, to check new invoicing system.
          canSeeInvoiceDebugInformation={type === 'platform' && permissions.includes('invoices.read')}
        />
      ) : null}

      <BackofficeSubsectionTitle>Tableau des garanties</BackofficeSubsectionTitle>
      <ContractGuaranteesList guaranteesDescription={guaranteesDescription} />
    </ContentContainerBackoffice>
  )
})
