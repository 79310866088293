import { activityZodSchema } from '@orus.eu/activity'
import { z } from 'zod'
import { BaseDimension } from '../../abstract-dimension'
import { rcpaGuarantees } from './rcpa-insurance.js'

export const RCPA_DEDUCTIBLE_SCHEMA = z.record(
  z.enum(rcpaGuarantees),
  z.object({
    loi: z.string(),
    deductible: z.string(),
  }),
)

export type RcpaDeductible = z.infer<typeof RCPA_DEDUCTIBLE_SCHEMA>

export const RCPA_DEDUCTIBLE_PER_ACTIVITY_TYPE_SCHEMA = z.record(activityZodSchema, RCPA_DEDUCTIBLE_SCHEMA)

export type RcpaDeductiblePerActivity = z.infer<typeof RCPA_DEDUCTIBLE_PER_ACTIVITY_TYPE_SCHEMA>

export const rcpaDeductiblePerActivityDimension = new BaseDimension<
  'rcpaDeductiblePerActivity',
  RcpaDeductiblePerActivity
>({
  name: 'rcpaDeductiblePerActivity',
  displayValues: { name: 'Franchise par activité' },
  tags: ['RCPA'],
} as const)
