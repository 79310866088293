import type { GroupsCategory } from '@orus.eu/activity'
import type { Amount } from '@orus.eu/amount'
import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import { type DiscountCode, type EmbeddingPartner } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { useAsyncCallback } from '@orus.eu/pharaoh'
import type { JsonableSiretData } from '@orus.eu/siret'
import { useState } from 'react'
import { v4 } from 'uuid'
import { trpc } from '../../../../client'
import { useNavigateToBackofficeSubscriptionPage } from '../../../../lib/hooks/use-navigate-to-backoffice-subscription'

export type NewSubscriptionParams = {
  discountCode?: DiscountCode
  category?: GroupsCategory
  activity?: string
  embeddingPartner?: EmbeddingPartner
  firstName?: string
  lastName?: string
  companyIdNumber?: CompanyIdNumber
  siretData?: JsonableSiretData
  email?: string
  estimatedRevenue?: Amount
  phone?: string
  operatingZone?: OperatingZone
  organizationTechnicalName?: string

  mrphSelected?: boolean
  mrpwSelected?: boolean
  rcphSelected?: boolean
  mutaSelected?: boolean
  rcdaSelected?: boolean
  esRcphSelected?: boolean
}

export function useCreateNewSubscription(): (newSubscriptionParams?: NewSubscriptionParams) => void {
  const navigateToBackofficeSubscriptionPage = useNavigateToBackofficeSubscriptionPage()
  const [createNewSubscriptionIdempotencyKey] = useState(v4())

  return useAsyncCallback(
    async (newSubscriptionParams?: NewSubscriptionParams) => {
      const subscriptionId = await trpc.subscriptions.createSubscriptionWithInitialValues.mutate({
        ...newSubscriptionParams,
        idempotencyKey: createNewSubscriptionIdempotencyKey,
      })

      void navigateToBackofficeSubscriptionPage(subscriptionId)
    },
    [navigateToBackofficeSubscriptionPage, createNewSubscriptionIdempotencyKey],
  )
}
