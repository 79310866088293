import { createFileRoute } from '@tanstack/react-router'

import ExclusionsDocumentationPage from '../../../../components/pages/backoffice/platform/platform-documentation/exclusions-documentation-page'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/documentation/exclusions')({
  component: () => (
    <PlatformPage requiredPermissions={[]}>
      <ExclusionsDocumentationPage />
    </PlatformPage>
  ),
})
