import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { getCalendarDateFromTimestamp, PARIS, type CalendarDate } from '@orus.eu/calendar-date'
import type {
  InsuranceCertificateRealEstateSalesAgentsData,
  LegalRepresentative,
} from '@orus.eu/documents/src/documents/insurance-certificate/insurance-certificate-props'
import {
  Button,
  ContentContainerAppClient,
  DatepickerFormField,
  FlexColumn,
  Form,
  FormRow,
  PageTitle,
  Select,
  spacing,
  Text,
  TextFieldFormField,
  TextInputLabelWrapper,
  useAsyncCallback,
  useDownloadFile,
  useLanguage,
  ValidatedTextFieldFormField,
  type ValidatedTypeMapper,
} from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { useParams } from '@tanstack/react-router'
import { memo, useMemo, useState } from 'react'
import { dynamicFileNames } from '../../lib/download-filenames'
import { getMySpecificCertificateRealEstateSalesAgentsDownloadLink } from '../../lib/download-links'
import { sendMessage } from '../../lib/tracking/tracking'
import CustomerPage from '../templates/customer-page'

const cpiNumberMapper: ValidatedTypeMapper<string> = {
  inputType: 'number',
  format: (value) => value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (number) => {
    return number.replace(' ', '').length === 17 ? success(number) : failure('Numéro de CPI invalide')
  },
}

const InsuranceCertificateGeneratorRealEstateSalesAgentsPage = memo(
  function InsuranceCertificateGeneratorRealEstateSalesAgentsPage(): JSX.Element {
    const { subscriptionId } = useParams({
      from: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId',
    })
    const language = useLanguage()

    const [companyName, setCompanyName] = useState('')
    const [cpiNumber, setCpiNumber] = useState<string | undefined>(undefined)
    const [cciName, setCciName] = useState('')
    const [dateOfIssue, setDateOfIssue] = useState<CalendarDate | null>(null)
    const [dateOfValidity, setDateOfValidity] = useState<CalendarDate | null>(null)
    const [legalRepresentatives, setLegalRepresentatives] = useState<LegalRepresentative[]>([])

    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

    const currentDate = getCalendarDateFromTimestamp(Date.now(), PARIS)

    const fields: InsuranceCertificateRealEstateSalesAgentsData = useMemo(
      () => ({
        companyName,
        cpiNumber: cpiNumber ?? '',
        cciName,
        dateOfIssue,
        dateOfValidity,
        legalRepresentatives: legalRepresentatives.filter(({ name }) => name),
      }),
      [companyName, legalRepresentatives, cpiNumber, cciName, dateOfIssue, dateOfValidity],
    )

    const { downloadFile } = useDownloadFile(
      getMySpecificCertificateRealEstateSalesAgentsDownloadLink(subscriptionId, fields),
      dynamicFileNames['specific-insurance-certificate-real-estate-sales-agents'](undefined, language),
    )

    const handleDownload = useAsyncCallback(async () => {
      setDownloadInProgress(true)
      try {
        sendMessage({
          event: 'download_contract_certificate_document',
          document_type: 'specific-insurance-certificate-real-estate-sales-agents',
        })
        await downloadFile()
      } finally {
        setDownloadInProgress(false)
      }
    }, [downloadFile, setDownloadInProgress])

    const isFormValid =
      fields.companyName &&
      fields.cpiNumber &&
      fields.cciName &&
      (fields.dateOfIssue || fields.dateOfValidity) &&
      fields.legalRepresentatives.length > 0

    return (
      <CustomerPage requiredPermissions={['contracts.read']}>
        <PaddedContainer>
          <PageTitle title="Nouvelle attestation CCI" />
          <ContentContainerAppClient>
            <Text variant="body2">
              Remplissez le formulaire pour télécharger une attestation CCI.
              <br />
              <br />
              Attestation d’assurance de la RC Professionnelle des agents commerciaux habilités par le titulaire de la
              carte professionnelle exerçant les activités prévues et régies par la loi n° 70-9 du 2 janvier 1970 et du
              décret n°72-678 du 20 juillet 1972.
            </Text>
            <Form>
              <FormRow>
                <TextFieldFormField
                  size="large"
                  variant="desktop"
                  label="Nom de la société"
                  placeholder="Société"
                  value={companyName}
                  onChange={(event) => setCompanyName(event.target.value)}
                />
                <ValidatedTextFieldFormField
                  size="large"
                  variant="desktop"
                  label="Numéro de CPI"
                  placeholder="Numéro à 17 chiffres"
                  value={cpiNumber}
                  onChange={setCpiNumber}
                  mapper={cpiNumberMapper}
                />
              </FormRow>

              <FormRow>
                <TextFieldFormField
                  size="large"
                  variant="desktop"
                  infoTooltip="Ex: CCI : Jura"
                  label="Nom de la CCI"
                  placeholder="Société"
                  value={cciName}
                  onChange={(event) => setCciName(event.target.value)}
                />
                <DatepickerFormField
                  size="large"
                  variant="desktop"
                  label="Date de délivrance"
                  value={dateOfIssue}
                  onChange={setDateOfIssue}
                  maxDate={currentDate}
                />
                <DatepickerFormField
                  size="large"
                  variant="desktop"
                  label="Date de validité"
                  value={dateOfValidity}
                  onChange={setDateOfValidity}
                />
              </FormRow>
            </Form>
            <div
              css={css({ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: spacing[30] })}
            >
              <Text variant="subtitle">Représentants</Text>
              <Button
                icon="plus-regular"
                size="small"
                variant="secondary"
                onClick={() =>
                  setLegalRepresentatives([...legalRepresentatives, { title: 'Monsieur', quality: 'Gérant', name: '' }])
                }
              />
            </div>

            <Form>
              {legalRepresentatives.map((legalRepresentative, index) => (
                <FormRow key={index}>
                  <TextInputLabelWrapper label="Civilité">
                    <Select
                      size="large"
                      variant="desktop"
                      labels={{
                        Monsieur: 'Monsieur',
                        Madame: 'Madame',
                      }}
                      value={legalRepresentative.title}
                      values={['Monsieur', 'Madame']}
                      onChange={(event) => {
                        setLegalRepresentatives(
                          legalRepresentatives.map((lr, i) =>
                            i === index ? { ...lr, title: event as 'Monsieur' | 'Madame' } : lr,
                          ),
                        )
                      }}
                    />
                  </TextInputLabelWrapper>
                  <TextFieldFormField
                    size="large"
                    variant="desktop"
                    label="Représentant légal"
                    value={legalRepresentative.name}
                    placeholder="Nom et prénom"
                    onChange={(event) => {
                      setLegalRepresentatives((previous) =>
                        previous.map((lr, i) => (i === index ? { ...lr, name: event.target.value } : lr)),
                      )
                    }}
                  />
                  <TextInputLabelWrapper label="Qualité du représentant">
                    <Select
                      size="large"
                      variant="desktop"
                      labels={{
                        Gérant: 'Gérant',
                        Président: 'Président',
                      }}
                      value={legalRepresentative.quality}
                      values={['Gérant', 'Président']}
                      onChange={(event) => {
                        setLegalRepresentatives((previous) =>
                          previous.map((lr, i) =>
                            i === index ? { ...lr, quality: event as 'Gérant' | 'Président' } : lr,
                          ),
                        )
                      }}
                    />
                  </TextInputLabelWrapper>{' '}
                </FormRow>
              ))}
            </Form>

            <div css={css({ display: 'flex', justifyContent: 'flex-end' })}>
              <Button
                icon="arrow-down-to-line-regular"
                size="medium"
                variant="primary"
                avatarPosition="left"
                disabled={!isFormValid}
                onClick={handleDownload}
                isLoading={downloadInProgress}
              >
                Télécharger
              </Button>
            </div>
          </ContentContainerAppClient>
        </PaddedContainer>
      </CustomerPage>
    )
  },
)

const PaddedContainer = styled(FlexColumn)`
  padding-top: ${spacing[80]};
  padding-bottom: ${spacing[80]};
`

export default InsuranceCertificateGeneratorRealEstateSalesAgentsPage
