import { css } from '@emotion/react'
import { Button, sectionDelimitersBorder, spacing, Text, useCrash, useEnqueueToast } from '@orus.eu/pharaoh'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { useCopyToClipboard } from '../../../../../lib/clipboard-util'
import { logger } from '../../../../../lib/logger'
import { ensureMonacoWorkerInitialized } from '../platform-editor/monaco-worker'

export const JobEditor = memo(function JobEditor() {
  const crash = useCrash()
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)
  const monacoEl = useRef<HTMLDivElement | null>(null)
  const { enqueueToast } = useEnqueueToast()
  const { copyToClipboard } = useCopyToClipboard()
  const runEphemeralJobMutation = trpcReact.jobs.runEphemeralJob.useMutation()
  const getEphemeralJobEditorConfigQuery = trpcReact.jobs.getEphemeralJobEditorConfig.useQuery()
  const [errors, setErrors] = useState<string[]>([])
  const [examplesVisible, setExamplesVisible] = useState(false)

  const run = useCallback(() => {
    if (!editorRef.current) {
      enqueueToast('Unexpected error: editor not initialized', { variant: 'danger' })
      return
    }

    if (errors.length > 0) {
      enqueueToast(`The job configuration is invalid: ${errors.join(', ')}`, { variant: 'danger' })
      return
    }

    const config = JSON.parse(editorRef.current.getValue())
    runEphemeralJobMutation.mutate({ config })
  }, [runEphemeralJobMutation, errors, enqueueToast])

  useEffect(() => {
    if (!getEphemeralJobEditorConfigQuery.data || !monacoEl.current) return
    if (editorRef.current) return

    ensureMonacoWorkerInitialized()

    const modelUri = monaco.Uri.parse('internal://model')
    const model = monaco.editor.createModel(
      JSON.stringify(getEphemeralJobEditorConfigQuery.data.defaultValue, null, 2),
      'json',
      modelUri,
    )

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'internal://schema.json', // id of the second schema
          fileMatch: [modelUri.toString()],
          schema: getEphemeralJobEditorConfigQuery.data.jsonSchema,
        },
      ],
    })

    const newEditor = monaco.editor.create(monacoEl.current, {
      model,
      automaticLayout: true,
    })

    editorRef.current = newEditor
    monaco.editor.onDidChangeMarkers((uris) => {
      if (uris.includes(modelUri)) {
        setErrors(monaco.editor.getModelMarkers({ resource: modelUri }).map((m) => m.message))
      }
    })

    return () => {
      model.dispose()
      newEditor.dispose()
      editorRef.current = null
    }
  }, [crash, getEphemeralJobEditorConfigQuery.data])

  useEffect(() => {
    if (runEphemeralJobMutation.status === 'error') {
      logger.warning('Error while launching the job')
      enqueueToast('Failed to launch the job', { variant: 'danger' })
    }

    if (runEphemeralJobMutation.status === 'success') {
      copyToClipboard(runEphemeralJobMutation.data.jobId)
      enqueueToast(
        `Job launched successfully, ID ${runEphemeralJobMutation.data.jobId} has been copied to your clipboard`,
        {
          variant: 'success',
        },
      )
    }
  }, [runEphemeralJobMutation, enqueueToast, copyToClipboard])

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 ${spacing[60]} ${spacing[40]} ${spacing[60]};
            display: flex;
            gap: ${spacing[40]};
          `}
        >
          <Button
            icon="book-open-regular"
            avatarPosition="left"
            size="small"
            variant="secondary"
            onClick={() => {
              setExamplesVisible(!examplesVisible)
            }}
          >
            {examplesVisible ? 'Cacher les exemples' : 'Voir les exemples'}
          </Button>
          <Button
            icon="play-solid"
            size="small"
            avatarPosition="left"
            variant="primary"
            style={errors.length > 0 ? 'danger' : 'base'}
            onClick={run}
            isLoading={runEphemeralJobMutation.isPending}
          >
            Exécuter
          </Button>
        </div>
        {examplesVisible && getEphemeralJobEditorConfigQuery.data ? (
          <div
            css={css`
              padding: 0 ${spacing[60]} ${spacing[40]} ${spacing[60]};
              display: flex;
              gap: ${spacing[50]};
              flex-flow: row wrap;
            `}
          >
            {getEphemeralJobEditorConfigQuery.data.jobTypeExamples.map((example) => (
              <div
                css={css`
                  flex: 25%;
                  margin-bottom: 10px;
                `}
                key={example.type}
              >
                <Text variant="h5">{example.type.toUpperCase()}</Text>
                <pre>
                  <code>
                    <div
                      css={css`
                        background: #ffeff0;
                        word-wrap: break-word;
                        box-decoration-break: clone;
                        padding: 0.1rem 0.3rem 0.2rem;
                        border-radius: 0.2rem;
                      `}
                    >
                      {JSON.stringify(example, null, 2)}
                    </div>
                  </code>
                </pre>
              </div>
            ))}
          </div>
        ) : undefined}
        <div
          css={css`
            flex-grow: 1;
            flex-shrink: 1;
            border-top: ${sectionDelimitersBorder};
          `}
          ref={monacoEl}
        />
      </div>
    </>
  )
})
