import styled from '@emotion/styled'
import { colorTokens, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { RenderElementProps } from 'slate-react'

type TableProps = RenderElementProps & {
  readonly?: boolean
}

const Table = memo(function Table({ attributes, children, readonly }: TableProps) {
  return (
    <StyledTable readonly={readonly} {...attributes}>
      {children}
    </StyledTable>
  )
})

export default Table

const StyledTable = styled.table<{ readonly?: boolean }>`
  border: ${spacing['5']} solid ${colorTokens['color-stroke-base']};
  border-radius: ${({ readonly }) => (readonly ? '' : `${spacing['30']}`)};
  border-collapse: collapse;
  border-spacing: 0;
  border-style: ${({ readonly }) => (readonly ? 'hidden' : 'visible')};
  width: 100%;
`
