import { Box } from '@mui/material'
import { Button, spacing, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const GoBackButton = memo<{ goBackToPreviousStep: () => void }>(function GoBackButton({ goBackToPreviousStep }) {
  const translate = useTranslate()
  return (
    <Button
      variant="secondary"
      size="medium"
      onClick={goBackToPreviousStep}
      icon="arrow-left-regular"
      avatarPosition="left"
    >
      {translate('back')}
    </Button>
  )
})

export const GoBackButtonWithContracts = memo<{
  isBackButtonHidden: boolean
  goBackToPreviousStep: () => void
  goBackContracts: () => void
}>(function GoBackButtonWithContracts({ isBackButtonHidden = false, goBackToPreviousStep, goBackContracts }) {
  const translate = useTranslate()
  return (
    <Box display="flex" gap={spacing[30]}>
      {isBackButtonHidden ? null : (
        <Button
          variant="secondary"
          size="medium"
          onClick={goBackToPreviousStep}
          icon="arrow-left-regular"
          avatarPosition="left"
        />
      )}

      <Button variant="tertiary" size="medium" onClick={goBackContracts} icon="folder-regular" avatarPosition="left">
        {translate('my_contracts')}
      </Button>
    </Box>
  )
})
