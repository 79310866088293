import { createFileRoute } from '@tanstack/react-router'

import ProductGuaranteesPage from '../../../../components/pages/product-guarantees-page'
import CustomerPage from '../../../../components/templates/customer-page'

export const Route = createFileRoute('/product-guarantees/$subscriptionId/$productName')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <ProductGuaranteesPage />
    </CustomerPage>
  ),
})
