import type {
  ComplyAdvantageCaseDTO,
  LystaCompanyDTO,
  PappersCompanyDTO,
} from '@orus.eu/backend/src/compliance/routers/compliance.dto'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'

export type DetailedSheetStatus = 'other' | 'pioupiou' | 'poussin' | 'coq' | 'aigle'

export type DetailedSheetStatusEntry = {
  label: string
  description: string
  emoji: string
}

export const detailedSheetStatusEntries: Record<DetailedSheetStatus, DetailedSheetStatusEntry> = {
  other: {
    label: 'Autre',
    description: 'Autre',
    emoji: '🦉',
  },
  pioupiou: {
    label: 'Piou-Piou',
    description: 'Création de -6 mois SANS antécédent d‘assurance',
    emoji: '🐣',
  },
  poussin: {
    label: 'Poussin',
    description: 'Création de +6 mois SANS antécédent d‘assurance',
    emoji: '🐥',
  },
  coq: {
    label: 'Coq',
    description: 'Création de -3 ans AVEC antécédent d‘assurance',
    emoji: '🐓',
  },
  aigle: {
    label: 'Aigle',
    description: 'Création de +3 ans AVEC antécédent d‘assurance',
    emoji: '🦅',
  },
}

export type DocumentGroupStatus = {
  label: string
  category: GroupCategory
  status: GroupStatus
  files: File[]
}

export type DetailedSheetSectionType =
  | 'representative'
  | 'company'
  | 'experience'
  | 'activity'
  | 'insurance-history'
  | 'other'

export type ComplianceData = {
  pappersCompany: PappersCompanyDTO | undefined
  lystaCompany: LystaCompanyDTO
  complyAdvantageCustomersCase: ComplyAdvantageCaseDTO[]
}
