import { useStateProxy, type StateProxy } from '../subscription-v2-props'
import type { SubscriptionFooterProps } from './types'

export function useSubscriptionFooterUiElementProps(
  props: SubscriptionFooterProps,
): SubscriptionFooterProps & { stateProxy: StateProxy | undefined } {
  const { localState, updateLocalChanges, updateAndPersistLocalChanges, footerElement } = props
  const allowedDimensions = footerElement ? [...Object.values(footerElement.dimensions)] : []
  const stateProxy = useStateProxy(localState, updateLocalChanges, updateAndPersistLocalChanges, allowedDimensions)
  return { ...props, stateProxy }
}
