import type {
  CollectedFileStatus,
  GroupCategory,
} from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { SubscriptionDocumentType } from '@orus.eu/dimensions'
import { DocumentViewerDialog, DocumentViewerMenu } from '@orus.eu/pharaoh/src/components/features/document-collection'
import { memo, type ComponentProps } from 'react'
import { trpcReact } from '../../../../client'
import { LABEL_PER_DOCUMENT_CATEGORY } from '../../subscription-v2/elements/documents-list-element/labels'
import type { File, Group } from '../../subscription-v2/elements/documents-list-element/types'

export type ViewerFile = ComponentProps<typeof DocumentViewerDialog>['documents'][number] & {
  metadata: { file: File; group: Group | null; fileIndexForCategory: number }
}

type FileViewerDialogProps = {
  selectedFile: ViewerFile
  onSelectedFileChange: (file: ViewerFile) => void
  allFiles: ViewerFile[]
  totalForCategory: number
  allowedCategories: SubscriptionDocumentType[]
  close: () => void
  onReviewFinished: (() => void) | null
  invalidate: () => void
}

export const FileViewerDialog = memo<FileViewerDialogProps>(function FileViewerDialog({
  selectedFile,
  allFiles,
  totalForCategory,
  allowedCategories,
  onSelectedFileChange,
  close,
  onReviewFinished,
  invalidate,
}: FileViewerDialogProps) {
  const updateFileTitle = trpcReact.collectedFile.updateFileTitle.useMutation({
    onSuccess: invalidate,
  })
  const updateFileStatus = trpcReact.collectedFile.updateFileStatus.useMutation({
    onSuccess: invalidate,
  })
  const updateFileCategory = trpcReact.collectedFile.updateFileCategory.useMutation({
    onSuccess: invalidate,
  })
  const updateFileNote = trpcReact.collectedFile.updateFileNote.useMutation({
    onSuccess: invalidate,
  })
  const updateGroupNote = trpcReact.collectedFile.updateGroupNote.useMutation({
    onSuccess: invalidate,
  })
  const reviewAiSuggestion = trpcReact.collectedFile.reviewFileAiSuggestion.useMutation({
    onSuccess: invalidate,
  })

  return (
    <DocumentViewerDialog
      title={
        selectedFile?.metadata.group?.category
          ? LABEL_PER_DOCUMENT_CATEGORY[selectedFile.metadata.group.category]
          : 'Non catégorisé'
      }
      activeDocument={selectedFile}
      onActiveDocumentChange={(document) => onSelectedFileChange(document as ViewerFile)}
      documents={allFiles}
      onClose={close}
      onReviewFinished={onReviewFinished}
    >
      <DocumentViewerMenu
        documentType={selectedFile.metadata.group?.category ?? null}
        filesLength={totalForCategory}
        fileIndex={selectedFile.metadata.fileIndexForCategory}
        title={selectedFile.metadata.file.title}
        documentStatus={selectedFile.metadata.file.status}
        allowedCategories={allowedCategories}
        allowedCategoriesLabels={LABEL_PER_DOCUMENT_CATEGORY}
        onCategoryChange={(newCategory) => {
          if (!newCategory) return

          updateFileCategory.mutate({
            etag: selectedFile.metadata.file.etag,
            fileId: selectedFile.metadata.file.id,
            category: newCategory as GroupCategory,
          })
        }}
        onStatusChange={(newStatus) => {
          if (!newStatus) return

          updateFileStatus.mutate({
            etag: selectedFile.metadata.file.etag,
            fileId: selectedFile.metadata.file.id,
            status: newStatus as CollectedFileStatus,
          })
        }}
        onTitleChange={(title) => {
          if (title === selectedFile.metadata.file.title) return

          updateFileTitle.mutate({
            etag: selectedFile.metadata.file.etag,
            fileId: selectedFile.metadata.file.id,
            title,
          })
        }}
        onFileNotesChange={(note) => {
          if (note === selectedFile.metadata.file.notes?.value) return

          updateFileNote.mutate({
            etag: selectedFile.metadata.file.etag,
            fileId: selectedFile.metadata.file.id,
            note,
          })
        }}
        onGroupNotesChange={
          selectedFile.metadata.group
            ? (note) => {
                if (!selectedFile.metadata.group || note === selectedFile.metadata.group.notes?.value) return

                updateGroupNote.mutate({
                  etag: selectedFile.metadata.group.etag,
                  groupId: selectedFile.metadata.group.id,
                  note,
                })
              }
            : null
        }
        fileNotes={selectedFile.metadata.file.notes}
        groupNotes={selectedFile.metadata.group?.notes ?? null}
        fileDate={selectedFile.metadata.file.collectionDate}
        aiSuggestion={
          selectedFile.metadata.file.aiSuggestion
            ? {
                title: selectedFile.metadata.file.aiSuggestion.title,
                category: selectedFile.metadata.file.aiSuggestion.category?.category ?? null,
                onReview: (accepted) =>
                  reviewAiSuggestion.mutate({
                    etag: selectedFile.metadata.file.etag,
                    fileId: selectedFile.metadata.file.id,
                    accepted,
                  }),
              }
            : null
        }
      />
    </DocumentViewerDialog>
  )
})
