import { createFileRoute } from '@tanstack/react-router'

import PlatformMigrationPage from '../../../components/pages/backoffice/platform/platform-migration-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/migration')({
  component: () => (
    <PlatformPage requiredPermissions={['contracts.update', 'users.update']}>
      <PlatformMigrationPage />
    </PlatformPage>
  ),
})
