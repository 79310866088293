import { createFileRoute } from '@tanstack/react-router'

import PendingSubscriptionPage from '../../../../components/pages/backoffice/common/PendingSubscriptionPage'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/v2-pending-subscriptions/$subscriptionId/')({
  component: () => (
    <PlatformPage requiredPermissions={['users.read']}>
      <PendingSubscriptionPage />
    </PlatformPage>
  ),
})
