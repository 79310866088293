import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { ContentContainerBackoffice, Text, colors } from '@orus.eu/pharaoh'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { memo } from 'react'
import { trpcReact } from '../../../../../client'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'

export default memo(function ExclusionsDocumentationPage() {
  const exclusions = trpcReact.exclusion.listExclusions.useQuery()
  if (!exclusions.data) return <GlobalLoadingState />

  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Exclusions</BackofficeSectionTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Text variant="h6">Nom technique</Text>
            </TableCell>
            <TableCell>
              <Text variant="h6">Texte exposé au client</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exclusions.data.map((exclusion) => (
            <TableRow key={exclusion.technicalName}>
              <TableCell>{exclusion.technicalName}</TableCell>
              <TableCell>
                <ExclusionText placeholders={exclusion.placeholders} template={exclusion.template} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ContentContainerBackoffice>
  )
})

const ExclusionText = memo<{
  placeholders: readonly string[]
  template: string
}>(function ExclusionText({ placeholders, template }) {
  const matches = match(template, placeholders.map((placeholder) => '${' + placeholder + '}').join(' '), {
    insideWords: true,
  })
  const parts = parse(template, matches)

  return (
    <Text variant="body1">
      {parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
            color: part.highlight ? colors.blue[500] : undefined,
          }}
        >
          {part.text}
        </span>
      ))}
    </Text>
  )
})
