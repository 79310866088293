import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { getCompanyIdNumberSirenValue } from '@orus.eu/company-id-number'
import type { RepresentativeSectionState } from '@orus.eu/dimensions'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { FormRow, TextFieldFormField, ValidatedTextFieldFormField, type ValidatedTypeMapper } from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { memo, useState, type ReactNode } from 'react'
import { validateInteger } from '../../../../../lib/validation'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { ComplianceData, DocumentGroupStatus } from '../detailed-sheet.types'
import CommonSection from './common/CommonSection'
import DocumentsList from './common/DocumentsList'
import { getExternalLink } from './widget/helper/get-external-link'
import { WidgetEntity } from './widget/widget-entity'

type RepresentativeSectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: RepresentativeSectionState
  stateBefore: RepresentativeSectionState
  setChanges: (changes: RepresentativeSectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (label: GroupCategory) => void
  complianceData: ComplianceData
  isComplianceDataLoading: boolean
  onFileClick: (selectedFile: File) => void
  isReadOnly: boolean
}

export const RepresentativeSection = memo(function RepresentativeSection(props: RepresentativeSectionProps): ReactNode {
  {
    const {
      state,
      setChanges,
      onIdFileGroupStatusClick,
      idsFileGroupStatus,
      complianceData,
      isComplianceDataLoading,
      onFileClick,
      isReadOnly,
    } = props

    const { firstName, lastName, detailedSheetCustomerSynthesis, workforce, companyIdNumber } = state

    const { pappersCompany, complyAdvantageCustomersCase } = complianceData

    const [firstNameValue, setFirstNameValue] = useState(firstName ?? '')
    const [lastNameValue, setLastNameValue] = useState(lastName ?? '')
    const [workforceValue, setWorkforceValue] = useState(workforce)
    const handleClick = (category: GroupCategory, status: GroupStatus) => {
      if (status !== 'empty') {
        onIdFileGroupStatusClick(category)
      }
    }

    const legalRepresentatives = pappersCompany?.legalRepresentatives.map((legalRepresentative) => {
      const check = complyAdvantageCustomersCase?.find((customer) => customer.entityId === legalRepresentative.entityId)
      return {
        ...legalRepresentative,
        isBeneficialOwner: false,
        check: check
          ? {
              ...check,
              href: getExternalLink(check?.customerIdentifier, 'complyadvantage'),
            }
          : undefined,
      }
    })

    const beneficialOwners = pappersCompany?.beneficialOwners.map((beneficialOwner) => {
      const check = complyAdvantageCustomersCase?.find((customer) => customer.entityId === beneficialOwner.entityId)

      return {
        ...beneficialOwner,
        isBeneficialOwner: true,
        check: check
          ? {
              ...check,
              href: getExternalLink(check?.customerIdentifier, 'complyadvantage'),
            }
          : undefined,
      }
    })

    const siren = getCompanyIdNumberSirenValue(companyIdNumber)

    const leftContent = (
      <>
        <FormRow>
          <TextFieldFormField
            required
            label="Prénom"
            size="small"
            placeholder="Jean"
            value={firstNameValue}
            onBlur={(value) => setChanges({ firstName: value.target.value })}
            onChange={(value) => setFirstNameValue(value.target.value)}
            disabled={isReadOnly}
            aria-label="Prénom"
          />
          <TextFieldFormField
            required
            label="Nom"
            size="small"
            placeholder="Dupond"
            value={lastNameValue}
            onBlur={(value) => setChanges({ lastName: value.target.value })}
            onChange={(value) => setLastNameValue(value.target.value)}
            disabled={isReadOnly}
          />
          <ValidatedTextFieldFormField
            size="small"
            label="Effectif"
            value={workforceValue}
            placeholder="1"
            onBlur={() => {
              setChanges({ workforce: workforceValue })
            }}
            onChange={(value) => {
              setWorkforceValue(value)
            }}
            mapper={integerMapper}
            infoTooltip="L’ensemble des salariés, les apprentis et le dirigeant. Ne sont pas compris les stagiaires, intérimaires et les conjoints non-employés"
            required
            disabled={isReadOnly}
          />
        </FormRow>
        <FormRow>
          <WidgetEntity
            title="Dirigeants"
            href={getExternalLink(siren, 'pappers') ?? undefined}
            entities={legalRepresentatives ?? null}
            isLoading={isComplianceDataLoading}
            isReadOnly={isReadOnly}
          />
          <WidgetEntity
            title="Bénéficiaires effectifs"
            href={getExternalLink(siren, 'pappers') ?? undefined}
            entities={beneficialOwners ?? null}
            isLoading={isComplianceDataLoading}
            isReadOnly={isReadOnly}
          />
        </FormRow>
      </>
    )

    const rightContent = (
      <DocumentsList
        idsFileGroupStatus={idsFileGroupStatus}
        onIdFileGroupStatusClick={handleClick}
        onFileClick={onFileClick}
      />
    )

    return (
      <CommonSection
        leftContent={leftContent}
        rightContent={rightContent}
        defaultTable="representative"
        value={detailedSheetCustomerSynthesis}
        onChange={(value) => {
          setChanges({ detailedSheetCustomerSynthesis: value })
        }}
      />
    )
  }
})

const integerMapper: ValidatedTypeMapper<number> = {
  inputType: 'number',
  format: (value) => value.toString(),
  formatPlaceholder: (value) => value.toString(),
  parse: (text, language) => {
    const result = validateInteger(text, language)
    return result.ok ? success(result.value) : failure(result.hint)
  },
}
