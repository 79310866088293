import { TechnicalError } from '@orus.eu/error'
import { m } from '@orus.eu/message'
import { failure, success, type Result } from '@orus.eu/result'
import { produce } from 'immer'
import type { ObjectId } from 'mongodb'
import { generateTypeIdFromString } from '../../../../../libs/utils/src/typeId.js'
import type {
  CollectedFileEvent,
  CollectedFileEventStore,
  CollectedFileStatus,
  GroupCategory,
  Source,
} from '../../events/collected-file/collected-file-event.js'
import type { Logger, LoggerFactory } from '../../lib/host/logger/types.js'
import { IdempotencyKeyViolationError } from '../../lib/store/index.js'
import type { SessionView } from '../../views/session-view.js'
import type { UserAccountView } from '../../views/user-account-view.js'
import type { FileService } from '../file-service/file-service.js'
import { fileHasSupportedExtension } from './supported-extensions.js'

export class CollectedFileService {
  private readonly logger: Logger
  constructor(
    private readonly collectedFileEventStore: CollectedFileEventStore,
    private readonly sessionView: SessionView,
    private readonly userAccountView: UserAccountView,
    private readonly fileService: FileService,
    loggerFactory: LoggerFactory,
  ) {
    this.logger = loggerFactory.build('collected_file_service')
  }

  async collectFile({
    subscriptionId,
    mimeType,
    fileName,
    buffer,
    category,
    sessionId,
    source,
  }: {
    mimeType: string
    fileName: string
    buffer: Buffer
    subscriptionId: string
    category: GroupCategory | null
    sessionId: string | null
    source: Source
  }): Promise<UpdateResult> {
    if (!fileHasSupportedExtension(fileName)) {
      return failure({ type: 'unsupported_file_type' })
    }

    const { storedFileId: storageFileId } = await this.fileService.save(buffer)

    const groupId = category ? await this.ensureFileGroup(subscriptionId, category) : null
    const fileId = generateTypeIdFromString('cffi', subscriptionId + storageFileId).toString()

    const doc = await this.collectedFileEventStore.appendAndReturn(
      { type: 'file_collected', payload: { fileId, mimeType, fileName, storageFileId, groupId, sessionId, source } },
      fileId,
    )

    const doc2 = await this.collectedFileEventStore.appendAndReturn(
      {
        type: 'file_associated_to_subscription',
        payload: { fileId, subscriptionId, source },
      },
      doc._id.toString(),
    )

    const etag = computeEtag(fileId, doc2)

    return success({ id: fileId, etag })
  }

  async downloadFile({ fileId }: { fileId: string }): Promise<Result<Buffer, 'not_found'>> {
    const file = await this.collectedFileEventStore.findOne({ type: 'file_collected', 'payload.fileId': fileId })
    if (!file) {
      return failure('not_found')
    }

    if (file.type !== 'file_collected') {
      throw new TechnicalError('Event should have been file_collected', {
        context: { eventId: file._id.toString() },
      })
    }

    const storageFileId = file.payload.storageFileId
    const buffer = await this.fileService.load({ type: 'dynamic', storedFileId: storageFileId })

    return success(buffer)
  }

  async getFile({ fileId }: { fileId: string }): Promise<Result<File, 'not_found'>> {
    const groups = await this.collectedFileEventStore
      .getSortedFindCursor({
        type: { $in: ['file_collected', 'file_associated_to_group', 'file_information_ai_suggested'] },
        'payload.fileId': fileId,
      })
      .toArray()

    const allFilesAndGroups = await this.reduceFileAndGroups({
      fileIds: [fileId],
      groupIds: groups.flatMap((group) => {
        if (
          group.type !== 'file_collected' &&
          group.type !== 'file_associated_to_group' &&
          group.type !== 'file_information_ai_suggested'
        ) {
          throw new TechnicalError(
            'Event should have been file_collected, file_associated_to_group or file_information_ai_suggested',
            {
              context: { eventId: group._id.toString() },
            },
          )
        }

        return group.payload.groupId ? [group.payload.groupId] : []
      }),
    })

    const file = allFilesAndGroups.files[fileId]
    if (!file) {
      return failure('not_found')
    }

    return success(file)
  }

  async getAllFilesAndGroupsForSubscription({
    subscriptionId,
    lastEventId,
  }: {
    subscriptionId: string
    lastEventId?: ObjectId
  }): Promise<FilesAndGroups> {
    const associations = await this.collectedFileEventStore
      .getSortedFindCursor({
        'payload.subscriptionId': subscriptionId,
        ...(lastEventId ? { _id: { $lte: lastEventId } } : {}),
      })
      .toArray()

    const allGroupIds = new Set<string>()
    const allFileIds = new Set<string>()

    for (const association of associations) {
      if (association.type === 'file_associated_to_subscription') {
        allFileIds.add(association.payload.fileId)
      } else if (association.type === 'group_associated_to_subscription') {
        allGroupIds.add(association.payload.groupId)
      }
    }

    return this.reduceFileAndGroups({ fileIds: Array.from(allFileIds), groupIds: Array.from(allGroupIds), lastEventId })
  }

  private async reduceFileAndGroups({
    fileIds,
    groupIds,
    lastEventId,
  }: {
    fileIds: string[]
    groupIds: string[]
    lastEventId?: ObjectId
  }): Promise<FilesAndGroups> {
    const filesAndGroups = await this.collectedFileEventStore
      .getSortedFindCursor({
        $and: [
          {
            $or: [{ 'payload.groupId': { $in: groupIds } }, { 'payload.fileId': { $in: fileIds } }],
          },
          ...(lastEventId ? [{ _id: { $lte: lastEventId } }] : []),
        ],
      })
      .toArray()

    return filesAndGroups.reduce<FilesAndGroups>(
      (acc, event) => {
        function getFile(fileId: string) {
          const file = acc.files[fileId]
          if (!file) {
            throw new TechnicalError('File should have been defined', { context: { fileId } })
          }
          return file
        }

        function getGroup(groupId: string) {
          const group = acc.groups[groupId]
          if (!group) {
            throw new TechnicalError('Group should have been defined', { context: { groupId } })
          }
          return group
        }

        switch (event.type) {
          case 'file_collected': {
            acc.files[event.payload.fileId] = {
              id: event.payload.fileId,
              deleted: false,
              collectionDate: new Date(event.timestamp),
              etag: computeEtag(event.payload.fileId, event),
              title: event.payload.fileName,
              fileName: event.payload.fileName,
              status: 'new',
              mimeType: event.payload.mimeType,
              storageFileId: event.payload.storageFileId,
              notes: null,
              groupId: event.payload.groupId ?? null,
              groupCategory: event.payload.groupId ? getGroup(event.payload.groupId).category : null,
              subscriptionId: null,
              aiSuggestion: null,
              sessionId: event.payload.sessionId,
            }

            const { groupId } = event.payload

            if (groupId) {
              const fileStatuses = Object.values(acc.files)
                .filter((file) => !file.deleted && file.groupId === groupId)
                .map((file) => file.status)

              const group = getGroup(groupId)
              acc.groups[groupId] = produce(group, (draft) => {
                draft.etag = computeEtag(groupId, event)
                draft.status = deduceGroupStatus({ fileStatuses, incomplete: group.incomplete })
              })
            }
            break
          }
          case 'file_title_updated': {
            const file = getFile(event.payload.fileId)
            acc.files[event.payload.fileId] = produce(file, (draft) => {
              draft.etag = computeEtag(event.payload.fileId, event)
              draft.title = event.payload.title
            })
            break
          }
          case 'file_status_updated': {
            const file = getFile(event.payload.fileId)
            acc.files[event.payload.fileId] = produce(file, (draft) => {
              draft.etag = computeEtag(event.payload.fileId, event)
              draft.status = event.payload.status
            })

            const { groupId } = file

            if (groupId) {
              const fileStatuses = Object.values(acc.files)
                .filter((file) => !file.deleted && file.groupId === groupId)
                .map((file) => file.status)

              const group = getGroup(groupId)
              acc.groups[groupId] = produce(group, (draft) => {
                draft.status = deduceGroupStatus({ fileStatuses, incomplete: group.incomplete })
              })
            }
            break
          }
          case 'file_note_updated': {
            const file = getFile(event.payload.fileId)
            acc.files[event.payload.fileId] = produce(file, (draft) => {
              draft.etag = computeEtag(event.payload.fileId, event)
              draft.notes = {
                value: event.payload.note,
                updateDate: new Date(event.timestamp),
              }
            })
            break
          }
          case 'file_associated_to_group': {
            const file = acc.files[event.payload.fileId]
            // We might be processing a single file event, in which case we might find all its group events
            // which can relate to a different file
            if (!file) {
              break
            }

            acc.files[event.payload.fileId] = produce(file, (draft) => {
              draft.etag = computeEtag(event.payload.fileId, event)
              draft.groupId = event.payload.groupId
              draft.groupCategory = event.payload.groupId ? getGroup(event.payload.groupId).category : null
            })

            const fileStatuses = Object.values(acc.files)
              .filter((file) => !file.deleted && file.groupId === event.payload.groupId)
              .map((file) => file.status)

            const group = getGroup(event.payload.groupId)
            acc.groups[event.payload.groupId] = produce(group, (draft) => {
              draft.etag = computeEtag(event.payload.groupId, event)
              draft.status = deduceGroupStatus({ fileStatuses, incomplete: draft.incomplete })
            })

            break
          }
          case 'file_information_ai_suggested': {
            const file = acc.files[event.payload.fileId]
            // We might be processing a single file event, in which case we might find all its group events
            // which can relate to a different file
            if (!file) {
              break
            }

            acc.files[event.payload.fileId] = produce(file, (draft) => {
              // Note that this event doen't contribute to the etag
              draft.aiSuggestion = {
                title: event.payload.title,
                category: event.payload.groupId
                  ? {
                      groupId: event.payload.groupId,
                      category: getGroup(event.payload.groupId).category,
                    }
                  : null,
              }
            })
            break
          }

          case 'file_information_ai_suggestion_reviewed': {
            const file = acc.files[event.payload.fileId]
            // We might be processing a single file event, in which case we might find all its group events
            // which can relate to a different file
            if (!file) {
              break
            }

            acc.files[event.payload.fileId] = produce(file, (draft) => {
              draft.etag = computeEtag(event.payload.fileId, event)

              if (!file.aiSuggestion) {
                this.logger.error('An unexisting AI suggestion was reviewed. This should not have happened', {
                  error: new TechnicalError('An unexisting AI suggestion was reviewed. This should not have happened', {
                    context: { eventId: event._id.toString(), fileId: event.payload.fileId },
                  }),
                })
                return
              }

              if (event.payload.accepted) {
                if (file.aiSuggestion.title) draft.title = file.aiSuggestion.title
                if (file.aiSuggestion.category) {
                  const group = getGroup(file.aiSuggestion.category.groupId)
                  draft.groupId = group.id
                  draft.groupCategory = group.category
                }
              }

              draft.aiSuggestion = null
            })

            if (event.payload.groupId) {
              const fileStatuses = Object.values(acc.files)
                .filter((file) => !file.deleted && file.groupId === event.payload.groupId)
                .map((file) => file.status)
              const group = getGroup(event.payload.groupId)
              acc.groups[group.id] = produce(group, (draft) => {
                draft.etag = computeEtag(group.id, event)
                draft.status = deduceGroupStatus({ fileStatuses, incomplete: draft.incomplete })
              })
            }

            break
          }
          case 'file_deleted': {
            const file = getFile(event.payload.fileId)
            acc.files[event.payload.fileId] = produce(file, (draft) => {
              draft.etag = computeEtag(event.payload.fileId, event)
              draft.deleted = true
            })

            const { groupId } = file

            if (groupId) {
              const fileStatuses = Object.values(acc.files)
                .filter((file) => !file.deleted && file.groupId === groupId)
                .map((file) => file.status)

              const group = getGroup(groupId)
              acc.groups[groupId] = produce(group, (draft) => {
                draft.status = deduceGroupStatus({ fileStatuses, incomplete: group.incomplete })
              })
            }
            break
          }
          case 'group_created':
            acc.groups[event.payload.groupId] = {
              id: event.payload.groupId,
              etag: computeEtag(event.payload.groupId, event),
              category: event.payload.category.type,
              incomplete: event.payload.incomplete,
              status: deduceGroupStatus({ fileStatuses: [], incomplete: event.payload.incomplete }),
              notes: null,
            }
            break
          case 'group_note_updated': {
            const group = getGroup(event.payload.groupId)
            acc.groups[event.payload.groupId] = produce(group, (draft) => {
              draft.etag = computeEtag(event.payload.groupId, event)
              draft.notes = {
                value: event.payload.note,
                updateDate: new Date(event.timestamp),
              }
            })
            break
          }
          case 'group_incomplete_updated': {
            const fileStatuses = Object.values(acc.files)
              .filter((file) => !file.deleted && file.groupId === event.payload.groupId)
              .map((file) => file.status)

            const group = getGroup(event.payload.groupId)
            acc.groups[event.payload.groupId] = produce(group, (draft) => {
              draft.etag = computeEtag(event.payload.groupId, event)
              draft.incomplete = event.payload.incomplete
              draft.status = deduceGroupStatus({ fileStatuses, incomplete: event.payload.incomplete })
            })
            break
          }
          case 'file_associated_to_subscription': {
            const file = getFile(event.payload.fileId)
            acc.files[event.payload.fileId] = produce(file, (draft) => {
              draft.etag = computeEtag(event.payload.fileId, event)
              draft.subscriptionId = event.payload.subscriptionId
            })
            break
          }
          case 'group_associated_to_subscription': {
            const group = getGroup(event.payload.groupId)
            acc.groups[event.payload.groupId] = produce(group, (draft) => {
              draft.etag = computeEtag(event.payload.groupId, event)
            })
            break
          }
        }

        return acc
      },
      { files: {}, groups: {} },
    )
  }

  async updateFileTitle({
    etag,
    fileId,
    title,
    sessionId,
    source,
  }: {
    etag: string
    fileId: string
    title: string
    sessionId: string | null
    source: Source
  }): Promise<UpdateResult> {
    return this.appendConsideringEtag(
      { type: 'file_title_updated', payload: { fileId, title, sessionId, source } },
      { type: 'file', id: fileId },
      etag,
    )
  }

  async updateFileStatus({
    etag,
    fileId,
    status,
    sessionId,
    source,
  }: {
    etag: string
    fileId: string
    status: CollectedFileStatus
    sessionId: string | null
    source: Source
  }): Promise<UpdateResult> {
    return this.appendConsideringEtag(
      { type: 'file_status_updated', payload: { fileId, status, sessionId, source } },
      { type: 'file', id: fileId },
      etag,
    )
  }

  async updateFileNote({
    etag,
    fileId,
    note,
    sessionId,
  }: {
    etag: string
    fileId: string
    note: string
    sessionId: string | null
  }): Promise<UpdateResult> {
    return this.appendConsideringEtag(
      { type: 'file_note_updated', payload: { fileId, note, sessionId } },
      { type: 'file', id: fileId },
      etag,
    )
  }

  private async ensureFileGroup(subscriptionId: string, category: GroupCategory): Promise<string> {
    const allSubscriptionGroupAssociations = await this.collectedFileEventStore
      .getSortedFindCursor({ type: 'group_associated_to_subscription', 'payload.subscriptionId': subscriptionId })
      .toArray()

    const allSubscriptionGroupIds = allSubscriptionGroupAssociations.map((association) => {
      if (association.type !== 'group_associated_to_subscription') {
        throw new TechnicalError('Event should have been group_associated_to_subscription', {
          context: { eventId: association._id.toString() },
        })
      }

      return association.payload.groupId
    })

    let group = await this.collectedFileEventStore.findOne(
      { type: 'group_created', 'payload.groupId': { $in: allSubscriptionGroupIds }, 'payload.category.type': category },
      { sort: 'desc' },
    )

    if (!group) {
      const groupId = generateTypeIdFromString('cfgr', subscriptionId + category).toString()
      group = await this.collectedFileEventStore.appendAndReturn(
        {
          type: 'group_created',
          payload: {
            category: { type: category },
            groupId,
            incomplete: true,
          },
        },
        `group_created-${subscriptionId}-${category}`,
      )

      await this.collectedFileEventStore.append(
        {
          type: 'group_associated_to_subscription',
          payload: { groupId, subscriptionId },
        },
        `group_associated_to_subscription-${subscriptionId}-${category}`,
      )
    }

    if (group.type !== 'group_created') {
      throw new TechnicalError('Event should have been group_created', {
        context: { eventId: group._id.toString() },
      })
    }

    return group.payload.groupId
  }

  async updateFileCategory({
    etag,
    fileId,
    category,
    sessionId,
    source,
  }: {
    etag: string
    fileId: string
    category: GroupCategory
    sessionId: string | null
    source: Source
  }): Promise<UpdateResult> {
    const fileAssociation = await this.collectedFileEventStore.findOne(
      { type: 'file_associated_to_subscription', 'payload.fileId': fileId },
      { sort: 'desc' },
    )

    if (!fileAssociation) {
      return failure({ type: 'not_found' })
    }

    if (fileAssociation.type !== 'file_associated_to_subscription') {
      throw new TechnicalError('Event should have been file_associated_to_subscription', {
        context: { eventId: fileAssociation._id.toString() },
      })
    }

    const { subscriptionId } = fileAssociation.payload

    const groupId = await this.ensureFileGroup(subscriptionId, category)

    return this.appendConsideringEtag(
      {
        type: 'file_associated_to_group',
        payload: { fileId, groupId, sessionId, source },
      },
      { type: 'file', id: fileId },
      etag,
    )
  }

  async registerFileAiSuggestion({
    fileId,
    title,
    category,
  }: {
    fileId: string
    title: string | null
    category: GroupCategory | null
  }): Promise<Result<{ id: string }, NotFound>> {
    const fileAssociation = await this.collectedFileEventStore.findOne(
      { type: 'file_associated_to_subscription', 'payload.fileId': fileId },
      { sort: 'desc' },
    )

    if (!fileAssociation) {
      return failure({ type: 'not_found' })
    }

    if (fileAssociation.type !== 'file_associated_to_subscription') {
      throw new TechnicalError('Event should have been file_associated_to_subscription', {
        context: { eventId: fileAssociation._id.toString() },
      })
    }

    const { subscriptionId } = fileAssociation.payload

    let groupId: string | null = null
    if (category) {
      groupId = await this.ensureFileGroup(subscriptionId, category)
    }

    // We don't consider the etag here, as we only allow one AI suggestion per file (with the idempotency key)
    // And we don't want to block the user from doing actions if the AI suggestion comes during his interactions
    await this.collectedFileEventStore.append(
      {
        type: 'file_information_ai_suggested',
        payload: { fileId, groupId, title },
      },
      `file_information_ai_suggested-${fileId}`,
    )

    return success({ id: fileId })
  }

  async reviewFileAiSuggestion({
    etag,
    fileId,
    accepted,
    sessionId,
  }: {
    etag: string
    fileId: string
    accepted: boolean
    sessionId: string | null
  }): Promise<UpdateResult> {
    const fileAiSuggestion = await this.collectedFileEventStore.findOne(
      { type: 'file_information_ai_suggested', 'payload.fileId': fileId },
      { sort: 'desc' },
    )

    if (!fileAiSuggestion) {
      return failure({ type: 'not_found' })
    }

    if (fileAiSuggestion.type !== 'file_information_ai_suggested') {
      throw new TechnicalError('Event should have been file_information_ai_suggested', {
        context: { eventId: fileAiSuggestion._id.toString() },
      })
    }

    return this.appendConsideringEtag(
      {
        type: 'file_information_ai_suggestion_reviewed',
        payload: { fileId, groupId: fileAiSuggestion.payload.groupId, accepted, sessionId },
      },
      { type: 'file', id: fileId },
      etag,
    )
  }

  async deleteFile({
    etag,
    fileId,
    sessionId,
  }: {
    etag: string
    fileId: string
    sessionId: string | null
  }): Promise<UpdateResult> {
    return this.appendConsideringEtag(
      { type: 'file_deleted', payload: { fileId, sessionId } },
      { type: 'file', id: fileId },
      etag,
    )
  }

  async updateGroupIncomplete({
    etag,
    groupId,
    incomplete,
    sessionId,
  }: {
    etag: string
    groupId: string
    incomplete: boolean
    sessionId: string | null
  }): Promise<UpdateResult> {
    return this.appendConsideringEtag(
      { type: 'group_incomplete_updated', payload: { groupId, incomplete, sessionId } },
      { type: 'group', id: groupId },
      etag,
    )
  }

  async updateGroupNote({
    etag,
    groupId,
    note,
    sessionId,
  }: {
    etag: string
    groupId: string
    note: string
    sessionId: string | null
  }): Promise<UpdateResult> {
    return this.appendConsideringEtag(
      { type: 'group_note_updated', payload: { groupId, note, sessionId } },
      { type: 'group', id: groupId },
      etag,
    )
  }

  private async getEntityEtag(entity: Entity): Promise<{ etag: string; sessionId: string | null }> {
    const doc = await this.collectedFileEventStore.findOne(
      {
        type: { $in: EVENT_TYPES_CONTRIBUTING_TO_ETAG },
        ...(entity.type === 'file' ? { 'payload.fileId': entity.id } : { 'payload.groupId': entity.id }),
      },
      { sort: 'desc' },
    )

    if (!doc) {
      throw new TechnicalError('There should have been a document for this entity', { context: { entity } })
    }

    return {
      etag: computeEtag(entity.id, doc),
      sessionId: 'sessionId' in doc.payload ? doc.payload.sessionId : null,
    }
  }

  private async appendConsideringEtag(
    event: CollectedFileEvent,
    entity: Entity,
    etag: string,
  ): Promise<Result<{ id: string; etag: string }, CollectedFileEtagConflict>> {
    try {
      const latestEtag = await this.getEntityEtag(entity)

      if (latestEtag.etag !== etag) {
        const session = latestEtag.sessionId ? await this.sessionView.getSession(latestEtag.sessionId) : null
        const user = session?.userId ? await this.userAccountView.getUserAccountByIdIfAny(session.userId) : null
        return failure({
          type: 'etag_conflict',
          latestEventEmail: user
            ? 'email' in user
              ? user.email
              : 'unverifiedEmail' in user
                ? user.unverifiedEmail
                : null
            : null,
        })
      }

      const doc = await this.collectedFileEventStore.appendAndReturn(event, etag)

      return success({ id: entity.id, etag: computeEtag(entity.id, doc) })
    } catch (err) {
      if (err instanceof IdempotencyKeyViolationError) {
        return failure({ type: 'etag_conflict', latestEventEmail: null })
      }

      throw err
    }
  }
}

function computeEtag(entityId: string, event: { _id: ObjectId }): string {
  const etag = generateTypeIdFromString('etag', entityId + '-' + event._id.toString()).toString()

  return etag
}

function deduceGroupStatus({
  fileStatuses,
  incomplete,
}: {
  fileStatuses: CollectedFileStatus[]
  incomplete: boolean
}): GroupStatus {
  if (fileStatuses.length === 0) return 'empty'
  if (fileStatuses.includes('rejected')) return 'rejected'
  if (fileStatuses.includes('new') || fileStatuses.includes('new-revised')) return 'to_validate'
  if (incomplete) return 'incomplete'
  return 'validated'
}

type Entity = { type: 'file' | 'group'; id: string }

type CollectedFileEtagConflict = {
  type: 'etag_conflict'
  latestEventEmail: string | null
}
type UnsupportedFileType = { type: 'unsupported_file_type' }
type NotFound = { type: 'not_found' }
type UpdateResult = Result<{ id: string; etag: string }, NotFound | CollectedFileEtagConflict | UnsupportedFileType>
export type File = {
  id: string
  deleted: boolean
  collectionDate: Date
  etag: string
  title: string
  status: CollectedFileStatus
  mimeType: string
  fileName: string
  storageFileId: string
  notes: { value: string; updateDate: Date } | null
  groupId: string | null
  groupCategory: GroupCategory | null
  subscriptionId: string | null
  aiSuggestion: {
    title: string | null
    category: {
      category: GroupCategory
      groupId: string
    } | null
  } | null
  sessionId: string | null
}
type FilesAndGroups = {
  files: Record<string, File>
  groups: Record<string, Group>
}

export const ALL_GROUP_STATUSES = ['empty', 'to_validate', 'incomplete', 'validated', 'rejected'] as const
export type GroupStatus = (typeof ALL_GROUP_STATUSES)[number]
export type Group = {
  id: string
  etag: string
  category: GroupCategory
  status: GroupStatus
  incomplete: boolean
  notes: { value: string; updateDate: Date } | null
}

const CONTRIBUTES_TO_ETAG_PER_EVENT_TYPE: Record<CollectedFileEvent['type'], boolean> = {
  file_collected: true,
  file_title_updated: true,
  file_associated_to_subscription: true,
  file_deleted: true,
  file_status_updated: true,
  file_note_updated: true,
  file_associated_to_group: true,
  file_information_ai_suggested: false,
  file_information_ai_suggestion_reviewed: true,
  group_created: true,
  group_note_updated: true,
  group_associated_to_subscription: true,
  group_incomplete_updated: true,
}

const EVENT_TYPES_CONTRIBUTING_TO_ETAG = Object.entries(CONTRIBUTES_TO_ETAG_PER_EVENT_TYPE).flatMap(
  ([eventName, contributes]) => (contributes ? [eventName as keyof typeof CONTRIBUTES_TO_ETAG_PER_EVENT_TYPE] : []),
)

export const fileGroupStatusEnumValueSpecification = m.nullable(
  m.enum<GroupStatus>(
    {
      title: 'File group status',
    },
    {
      empty: 'Vide',
      to_validate: 'À valider',
      incomplete: 'Incomplet',
      validated: 'Validé',
      rejected: 'Rejeté',
    },
  ),
)

export type SubscriptionReviewFinishedFileGroupStatusSpec = Record<
  GroupCategory,
  typeof fileGroupStatusEnumValueSpecification
>
