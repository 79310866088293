import { ContentContainerAppClient, PageTitle, Text, useTranslate } from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import { memo, useCallback } from 'react'
import { trpcReact } from '../../client'
import { SmallScreenMargin } from '../templates/small-screen-margin'
import { getUserContractsByActivitiesAndAddresses } from './contracts-by-activity-and-address'
import { ContractsByActivitiesAndAddresses } from './contracts/components/ContractsByActivitiesAndAddresses'

const GuaranteesPage = memo(function GuaranteesPage(): JSX.Element {
  const translate = useTranslate()
  const navigate = useNavigate()
  const [contracts] = trpcReact.contracts.listMyContracts.useSuspenseQuery()

  const onContractClick = useCallback(
    (subscriptionId: string, productName: string) => {
      void navigate({
        to: '/product-guarantees/$subscriptionId/$productName',
        params: { subscriptionId, productName },
      })
      return
    },
    [navigate],
  )

  if (contracts.length === 0) {
    return (
      <SmallScreenMargin>
        <Text variant="body1">{translate('no_active_contract')}</Text>
      </SmallScreenMargin>
    )
  }

  const userContractsByActivitiesAndAddresses = getUserContractsByActivitiesAndAddresses(contracts)

  return (
    <>
      <PageTitle title={translate('my_guarantees')} />
      <ContentContainerAppClient>
        <ContractsByActivitiesAndAddresses
          userContractsByActivitiesAndAddresses={userContractsByActivitiesAndAddresses}
          displayContractNumber={false}
          onContractClick={onContractClick}
          activityVariant="subtitle"
        />
      </ContentContainerAppClient>
    </>
  )
})

export default GuaranteesPage
