import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { RadioGroup } from '@mui/material'
import { amountToString, type Amount } from '@orus.eu/amount'
import { memo, useCallback, useRef, type ChangeEvent } from 'react'
import {
  Avatar,
  Button,
  Chip,
  Text,
  borderRadius,
  colorTokens,
  colors,
  secondaryColor,
  spacing,
  useScreenType,
  useTranslate,
  type ScreenType,
} from '../../../../index.js'
import { Radio } from '../../../atoms/radio.js'
import face from '../emoji/face-with-thermometer.png'
import glasses from '../emoji/glasses.png'
import tooth from '../emoji/tooth.png'

export type HealthCoverProduct = {
  formula: string
  avatar: string
  description: string
  monthlyPrice: Amount
  yearlyPrice: Amount
  specRate: string
  dentalRate: string
  opticalRate: string
  isRecommended: boolean
}

export type SectionProductProps = {
  products: HealthCoverProduct[]
  selectedValue: string
  paymentRecurrence: 'monthly' | 'yearly'
  isAvatar: boolean
  isSimplifiedVersion: boolean
  isGuaranteesTable: boolean
  handleChange?: ((event: ChangeEvent<HTMLInputElement>, value: string) => void) | undefined
  value: string
}

export const SectionProduct = memo<SectionProductProps>(function SectionProduct(props) {
  const {
    products,
    selectedValue,
    paymentRecurrence,
    isAvatar,
    isSimplifiedVersion,
    isGuaranteesTable,
    handleChange,
    value,
  } = props

  const screenType = useScreenType()
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[30]};
      `}
    >
      <RadioGroup
        onChange={handleChange}
        value={value}
        css={css`
          gap: ${spacing[30]};
        `}
      >
        {products.map((product) => (
          <HealthRadioButtonCard
            key={product.formula}
            value={product.formula}
            isSelected={product.formula === selectedValue}
            paymentRecurrence={paymentRecurrence}
            monthlyPrice={product.monthlyPrice}
            yearlyPrice={product.yearlyPrice}
            specRate={product.specRate}
            dentalRate={product.dentalRate}
            opticalRate={product.opticalRate}
            avatar={product.avatar}
            isAvatar={isAvatar}
            isSimplifiedVersion={isSimplifiedVersion}
            description={product.description}
            screenType={screenType}
            isRecommended={product.isRecommended}
            isGuaranteesTable={isGuaranteesTable}
          />
        ))}
      </RadioGroup>
    </div>
  )
})

type HealthRadioButtonCardProps = {
  value: string
  isSelected: boolean
  description: string
  paymentRecurrence: 'monthly' | 'yearly'
  monthlyPrice: Amount
  yearlyPrice: Amount
  specRate: string
  dentalRate: string
  opticalRate: string
  avatar: string
  isAvatar: boolean
  isSimplifiedVersion: boolean
  screenType: ScreenType
  isRecommended: boolean
  isGuaranteesTable: boolean
}
const HealthRadioButtonCard = memo<HealthRadioButtonCardProps>(function HealthRadioButtonCard(props) {
  const translate = useTranslate()
  const {
    value,
    isSelected,
    description,
    paymentRecurrence,
    monthlyPrice,
    yearlyPrice,
    specRate,
    dentalRate,
    opticalRate,
    avatar,
    isAvatar,
    isSimplifiedVersion,
    screenType,
    isRecommended,
    isGuaranteesTable,
  } = props

  const inputRef = useRef<HTMLButtonElement | null>(null)

  const handleClick = useCallback(() => {
    inputRef.current?.click()
  }, [inputRef])

  const formattedCurrentRecurrencePrice = amountToString(paymentRecurrence === 'monthly' ? monthlyPrice : yearlyPrice, {
    addCurrency: true,
    displayDecimals: false,
  })

  const otherRecurrence = paymentRecurrence === 'monthly' ? 'yearly' : 'monthly'
  const otherRecurrencePrice = paymentRecurrence === 'monthly' ? yearlyPrice : monthlyPrice

  return (
    <>
      <div
        onClick={handleClick}
        css={css`
          display: flex;
          gap: ${spacing[60]};
          border-radius: ${borderRadius[20]};
          padding: ${spacing[60]};
          border: 1px solid;
          border-color: ${isSelected ? colors.blue[500] : colorTokens['color-stroke-base']};
          background-color: ${isSelected ? colors.blue[100] : colors.white};
          cursor: pointer;
          box-shadow: ${isSelected ? 'inset 0 0 0 0.125rem' : 'none'} ${secondaryColor};
          transition: all 0.3s ease-in-out;

          &:hover {
            ${TitleText} {
              color: ${secondaryColor};
            }
          }
          width: 100%;
        `}
      >
        {isAvatar && screenType === 'desktop' ? <Avatar size="60" src={avatar} /> : <></>}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: ${screenType === 'desktop' ? spacing[40] : spacing[50]};
            width: 100%;
          `}
        >
          {isAvatar && screenType === 'mobile' ? (
            <div
              css={css`
                display: flex;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  gap: ${spacing[50]};
                `}
              >
                <Avatar size="60" src={avatar} />
                <TitleText
                  variant="subtitle"
                  css={css`
                    padding-bottom: ${spacing[40]};
                  `}
                >
                  {value}
                </TitleText>
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              />

              <div
                css={css`
                  text-align: end;
                `}
              >
                <Radio value={value} size="medium" ref={inputRef} />

                <Text
                  variant="h5"
                  css={css`
                    margin-top: ${spacing[40]};
                  `}
                >
                  {formattedCurrentRecurrencePrice}
                </Text>
                <Text variant="caption">{translate(`per_time_unit_${paymentRecurrence}`)}</Text>
                <Text variant="caption">
                  {translate(`or_amount_per_time_unit_${otherRecurrence}`, { amount: otherRecurrencePrice })}
                </Text>
              </div>
            </div>
          ) : (
            <></>
          )}
          {screenType === 'desktop' || (!isAvatar && screenType === 'mobile') ? (
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
                height: 24px;
                align-items: center;
                gap: ${spacing[50]};
              `}
            >
              <TitleText variant="subtitle">{value}</TitleText>
              <div
                css={css`
                  flex: 1;
                `}
              />
              {screenType === 'desktop' && isRecommended ? (
                <Chip
                  size="small"
                  css={css`
                    background-color: ${colors.orange[200]};
                  `}
                >
                  Le plus conseillé
                </Chip>
              ) : (
                <></>
              )}

              <Radio value={value} size="medium" ref={inputRef} />
            </div>
          ) : (
            <></>
          )}

          {!isSimplifiedVersion ? (
            <>
              {screenType === 'mobile' && !isAvatar ? (
                <div
                  css={css`
                    text-align: end;
                  `}
                >
                  <Text variant="h5">{formattedCurrentRecurrencePrice}</Text>
                  <Text variant="caption">{translate(`per_time_unit_${paymentRecurrence}`)}</Text>
                  <Text variant="caption">
                    {translate(`or_amount_per_time_unit_${otherRecurrence}`, { amount: otherRecurrencePrice })}
                  </Text>
                </div>
              ) : (
                <></>
              )}
              <div
                css={css`
                  display: flex;
                  gap: ${spacing[60]};
                `}
              >
                <div
                  css={css`
                    flex: 1;
                    min-width: 0;
                  `}
                >
                  <ShortDescriptionText
                    avatar={face}
                    text={`${specRate} sur les consultations spécialistes`}
                    screenType={screenType}
                  />

                  <ShortDescriptionText
                    avatar={tooth}
                    text={`${dentalRate} sur les consultations dentaires`}
                    screenType={screenType}
                  />

                  <ShortDescriptionText
                    avatar={glasses}
                    text={`${opticalRate} pour les
                    montures et verres`}
                    screenType={screenType}
                  />
                </div>
                {screenType === 'desktop' ? (
                  <div
                    css={css`
                      text-align: end;
                    `}
                  >
                    <Text variant="h5">{formattedCurrentRecurrencePrice}</Text>
                    <Text variant="caption">{translate(`per_time_unit_${paymentRecurrence}`)}</Text>
                    <Text variant="caption">
                      {translate(`or_amount_per_time_unit_${otherRecurrence}`, { amount: otherRecurrencePrice })}
                    </Text>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {screenType === 'mobile' && isRecommended ? (
                <Chip
                  size="small"
                  css={css`
                    background-color: ${colors.orange[200]};
                    margin: auto;
                  `}
                >
                  Le plus équilibré
                </Chip>
              ) : (
                <></>
              )}
              {isGuaranteesTable ? (
                <Button
                  variant="text"
                  size="small"
                  css={css`
                    align-self: flex-start;
                  `}
                >
                  Tableau des garanties
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Text variant="body2">{description}</Text>
              {isGuaranteesTable ? (
                <Button
                  variant="text"
                  size="small"
                  css={css`
                    align-self: flex-start;
                  `}
                >
                  Tableau des garanties
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
})

type ShortDescriptionTextProps = {
  screenType: ScreenType
  text: string
  avatar: string
}

const ShortDescriptionText = memo<ShortDescriptionTextProps>(function ShortDescriptionText(props) {
  const { screenType, text, avatar } = props

  return (
    <div
      css={css`
        display: flex;
        gap: ${spacing[50]};
        align-items: center;
        padding: ${spacing[10]} 0 ${spacing[10]} 0;
      `}
    >
      <Avatar size="30" src={avatar} />
      <Text
        variant="body2"
        css={css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: ${screenType === 'desktop' ? '380px' : '300px'};
        `}
      >
        {text}
      </Text>
    </div>
  )
})

const TitleText = styled(Text)()
