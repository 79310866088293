import { createFileRoute } from '@tanstack/react-router'

import PlatformOrganizationsPage from '../../../components/pages/backoffice/platform/platform-organization-page/platform-organizations-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/organizations')({
  component: () => (
    <PlatformPage requiredPermissions={['organization.read']}>
      <PlatformOrganizationsPage />
    </PlatformPage>
  ),
})
