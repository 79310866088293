import { css } from '@emotion/react'
import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import type { EmailAvailabilityCheckerUiElement } from '@orus.eu/dimensions'
import { ensureError } from '@orus.eu/error'
import { PersistentNotification, Spinner, Text, useCrash } from '@orus.eu/pharaoh'
import type { ActionButton } from '@orus.eu/pharaoh/src/components/callout-notification/common/callout-notification-template'
import { isFailure } from '@orus.eu/result'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trpc } from '../../../../client'
import { usePermissions } from '../../../../lib/use-permissions'
import { ifStateProxy } from '../if-state-proxy'

export const EmailAvailabilityCheckerSubscriptionUiElementBlock = ifStateProxy<EmailAvailabilityCheckerUiElement>(
  function EmailAvailabilityCheckerSubscriptionUiElementBlock({
    uiElement,
    stateProxy,
    registerNextEnabledContribution,
    setSubscriptionOwner,
    customerId,
  }) {
    const [existingUser, setExistingUser] = useState<UserAccount | null>(null)
    const [isBackOfficeUser, setIsBackOfficeUser] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState(false)
    const dimension = uiElement.dimension
    const email = stateProxy.read(dimension)
    const crash = useCrash()
    const { type: userType } = usePermissions()
    const isPartner = userType === 'partner'

    useEffect(() => {
      if (customerId || !email) {
        registerNextEnabledContribution('email-availability-checker', true)
        return
      }

      let cancelled = false

      registerNextEnabledContribution('email-availability-checker', false)

      setIsBackOfficeUser(false)
      trpc.users.getUserByEmailV2.query(email).then(
        (userResult) => {
          if (isFailure(userResult)) {
            if (userResult.problem === 'invalid-email-for-subscription') {
              setIsBackOfficeUser(true)
            } else {
              crash(ensureError(userResult))
            }
            return
          }

          const user = userResult.output
          if (cancelled) return
          registerNextEnabledContribution('email-availability-checker', !user)
          setExistingUser(user ?? null)
        },
        (err) => {
          if (cancelled) return
          crash(ensureError(err))
        },
      )

      return () => {
        cancelled = true
      }
    }, [crash, email, customerId, registerNextEnabledContribution])

    const changeSubscriptionUser = useCallback(() => {
      if (!existingUser) return

      setSubmitting(true)

      setSubscriptionOwner(existingUser.id)
    }, [existingUser, setSubscriptionOwner])

    const actionButton = useMemo<ActionButton>(
      () => ({
        label: isPartner ? "Confirmer l'email" : 'Utiliser ce compte client',
        onClick: changeSubscriptionUser,
      }),
      [changeSubscriptionUser, isPartner],
    )

    return !customerId && existingUser && !isBackOfficeUser ? (
      <div
        css={css`
          position: relative;
        `}
      >
        <PersistentNotification
          css={css`
            opacity: ${submitting ? '0' : '1'};
          `}
          variant="warning"
          title={isPartner ? "Email d'un client existant - Bonne nouvelle" : 'Utiliser ce compte client'}
          actionButton={actionButton}
        >
          {isPartner ? (
            'Ce devis (et sa commission en cas de signature) vous est attribué, sans impact sur ses contrats existants. Votre prospect conserve tout dans son espace client.'
          ) : (
            <>
              Adresse email déjà utilisée par le client :{' '}
              <Text variant="body1Medium" element="span">
                {existingUser.firstName} {existingUser.lastName}
              </Text>
            </>
          )}
        </PersistentNotification>
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            visibility: ${submitting ? 'visible' : 'hidden'};
          `}
        >
          <Spinner size="40" />
        </div>
      </div>
    ) : isBackOfficeUser ? (
      <PersistentNotification variant="danger" title="Compte utilisateur existant">
        Il est impossible de souscrire avec un compte back-office.
      </PersistentNotification>
    ) : (
      <></>
    )
  },
)
