import { createFileRoute } from '@tanstack/react-router'

import GeneralTermsDocumentationPage from '../../../../components/pages/backoffice/platform/platform-documentation/general-terms-documentation-page'
import PlatformPage from '../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/documentation/general-terms')({
  component: () => (
    <PlatformPage requiredPermissions={[]}>
      <GeneralTermsDocumentationPage />
    </PlatformPage>
  ),
})
