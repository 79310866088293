import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { mobileMediaQuery, secondaryColor, spacing } from '../../../../index.js'
import { Avatar, Text } from '../../../atoms/index.js'
import { LegacyDialog } from '../../../templates/index.js'
import { ListWrapper } from '../../quote-v3/list-wrapper/index.js'

export type FamilyMember = {
  emoji: string
  title: string
  onClick: () => void
}

type FamilyMembersListModalProps = {
  onClose: () => void
  familyMembers: FamilyMember[]
}

export const FamilyMembersListModal = memo<FamilyMembersListModalProps>(function FamilyMembersListModal(props) {
  const { onClose, familyMembers } = props

  return (
    <LegacyDialog
      header={
        <div>
          <Text variant="h3">Ajouter un membre</Text>
        </div>
      }
      onClose={onClose}
    >
      <div
        css={css`
          min-width: 600px;
          ${mobileMediaQuery} {
            min-width: unset;
          }
        `}
      >
        <ListWrapper>
          <FamilyMember familyMembers={familyMembers} />
        </ListWrapper>
      </div>
    </LegacyDialog>
  )
})

type FamilyMembersProps = {
  familyMembers: FamilyMember[]
}

export const FamilyMember = memo<FamilyMembersProps>(function FamilyMember(props) {
  const { familyMembers } = props
  return (
    <>
      {familyMembers.map((member) => (
        <div
          key={member.title}
          css={css`
            display: grid;
            grid-template-columns: auto 1fr auto;
            grid-template-rows: auto auto auto;
            gap: ${spacing[50]};
            grid-template-areas:
              'auto auto auto'
              'emoji member arrow';
            align-items: center;
            cursor: pointer;

            &:hover {
              ${TitleText} {
                color: ${secondaryColor};
              }
            }
          `}
          onClick={member.onClick}
        >
          <div
            css={css`
              grid-area: emoji;
              display: flex;
              align-items: center;
            `}
          >
            <Avatar src={member.emoji} size="50" />
          </div>
          <div
            css={css`
              grid-area: member;
            `}
          >
            <TitleText variant="body2">{member.title}</TitleText>
          </div>
          <div
            css={css`
              grid-area: arrow;
              justify-content: flex-end;
            `}
          >
            <Avatar icon="angle-right-regular" size="30" />
          </div>
        </div>
      ))}
    </>
  )
})

const TitleText = styled(Text)()
