import { createFileRoute } from '@tanstack/react-router'
import { RenewalPage } from '../../../../components/pages/renewal/RenewalPage'
import CustomerPage from '../../../../components/templates/customer-page'

export const Route = createFileRoute('/s/r/$token')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.sign']} withoutLayout={true}>
      <RenewalPage />
    </CustomerPage>
  ),
})
