import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminPage from '../../../components/pages/backoffice/platform/platform-admin-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/admin/')({
  component: () => (
    <PlatformPage requiredPermissions={['tech.configurations']}>
      <PlatformAdminPage />
    </PlatformPage>
  ),
})
