import { css } from '@emotion/react'
import { borderRadius, spacing, Text } from '@orus.eu/pharaoh'
import { OrusHorizontalLogoImage } from '@orus.eu/pharaoh/src/skin/default-skin/orus-horizontal-logo-image'
import { memo } from 'react'

export const PoweredByOrus = memo(function PoweredByOrus() {
  return (
    <div
      css={css`
        display: flex;
        height: 54px;
        justify-content: center;
      `}
    >
      <div
        css={css`
          display: flex;
          padding: ${spacing[50]} ${spacing[60]};
          justify-content: center;
          align-items: center;
          gap: ${spacing[30]};
          text-align: center;
          font-family: Poppins;
          color: #e2dcfe;
          background-color: #151318;
          border-radius: ${borderRadius[30]};
        `}
      >
        <Text variant="body1Medium">powered by</Text>
        {/*  TODO : use new palette tokens */}
        <OrusHorizontalLogoImage width={54} height={16} color="#e2dcfe" />
      </div>
    </div>
  )
})
