import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import PlatformPage from '../../../../../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet')({
  component: () => (
    <PlatformPage requiredPermissions={['endorsement.create']}>
      <DetailedSheetPage />
    </PlatformPage>
  ),
})
