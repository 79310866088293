import { css } from '@emotion/react'
import { formatFrenchAddress1Line } from '@orus.eu/address'
import type { PlaceData, SiretSearchSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  PersistentNotification,
  RowButtonV2,
  RowContainerV2,
  SearchBar,
  spacing,
  Spinner,
  TextInputLabelWrapper,
  useDebounce,
} from '@orus.eu/pharaoh'
import type { ActionButton } from '@orus.eu/pharaoh/src/components/callout-notification/common/callout-notification-template'
import { isSuccess } from '@orus.eu/result'
import { memo, useCallback, useEffect, useMemo, useState, type KeyboardEvent } from 'react'
import type { SubscriptionElementBlockProps } from '../../subscription-v2-props'
import type { SiretSearchElementProps } from './siret-search-helper'
import { buildPlaceSelectionUpdate } from './siret-search-helper'

export function SiretSOSearchElement({
  stateProxy,
  uiElement,
  handleSubmit,
  synchronizing,
}: SiretSearchElementProps): JSX.Element {
  /**
   * The query as typed by the user
   */
  const [rawQuery, setRawQuery] = useState(stateProxy.readRequired(uiElement.dimensions.placeSearchQuery))
  const [debouncedRawQuery] = useDebounce(rawQuery, 1000)

  /**
   * The query that is stored in the state
   */
  const storedQuery = stateProxy.readRequired(uiElement.dimensions.placeSearchQuery)

  const [displayResult, setDisplayResult] = useState(true)

  const writePlaceSearchQuery = stateProxy.useWrite(uiElement.dimensions.placeSearchQuery)

  const placesResult = stateProxy.read(uiElement.dimensions.placeSearchResults)
  const places = placesResult && isSuccess(placesResult) ? placesResult.output : noPlaces

  useEffect(() => {
    writePlaceSearchQuery(debouncedRawQuery)
    if (!displayResult) {
      setDisplayResult(true)
    }
  }, [debouncedRawQuery, displayResult, writePlaceSearchQuery])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (Array.isArray(places) && places.length === 1) {
          handleSubmit(undefined, buildPlaceSelectionUpdate(places[0], uiElement))
        }
      }
    },
    [handleSubmit, places, uiElement],
  )

  return (
    <>
      <div>
        <ResultElement
          displayResult={displayResult}
          handleSubmit={handleSubmit}
          places={places}
          rawQuery={rawQuery}
          storedQuery={storedQuery}
          handleKeyDown={handleKeyDown}
          synchronizing={synchronizing}
          uiElement={uiElement}
          setRawQuery={setRawQuery}
        />
      </div>
      <RowContainerV2
        css={css`
          margin-top: ${spacing[70]};
        `}
      >
        <RowButtonV2 onClick={handleSubmit} primaryText={uiElement.cantFindText} />
      </RowContainerV2>
    </>
  )
}

type ResultElementProps = {
  uiElement: SiretSearchSubscriptionUiElement
  synchronizing: boolean
  storedQuery: string
  rawQuery: string
  places: PlaceData[]
  displayResult: boolean
  handleKeyDown: (event: KeyboardEvent) => void
  setRawQuery: (query: string) => void
  handleSubmit: SubscriptionElementBlockProps['handleSubmit']
}

const ResultElement = memo<ResultElementProps>(function ResultElement({
  uiElement,
  synchronizing,
  storedQuery,
  rawQuery,
  places,
  displayResult,
  handleKeyDown,
  handleSubmit,
  setRawQuery,
}) {
  return (
    <>
      <TextInputLabelWrapper label={uiElement.searchText} required={uiElement.required}>
        <SearchBar
          options={[]}
          size="large"
          inputValue={rawQuery}
          clearInputValue={() => setRawQuery('')}
          onInputChange={(_event, newValue) => setRawQuery(newValue)}
          onKeyDown={handleKeyDown}
          freeSolo={true}
          error={uiElement.highlight}
        />
      </TextInputLabelWrapper>
      {rawQuery.trim().length > 0 && displayResult ? (
        <ResultsArea
          loading={synchronizing || rawQuery !== storedQuery}
          places={places}
          handleSubmit={handleSubmit}
          uiElement={uiElement}
          hideActionButton={false}
        />
      ) : (
        <></>
      )}
    </>
  )
})

type ResultsAreaProps = {
  loading: boolean
  places: PlaceData[]
  handleSubmit: SubscriptionElementBlockProps['handleSubmit']
  uiElement: SiretSearchSubscriptionUiElement
  hideActionButton?: boolean
}

const ResultsArea = memo<ResultsAreaProps>(function ResultsArea({
  loading,
  places,
  handleSubmit,
  uiElement,
  hideActionButton,
}) {
  const cantFindActionButton: ActionButton = useMemo(
    () => ({ label: 'Ajouter mon établissement', onClick: handleSubmit }),
    [handleSubmit],
  )

  if (loading) return <PlaceLoadingPlaceholder />

  if (places.length === 0) {
    return (
      <PersistentNotification
        css={css`
          margin-top: ${spacing[70]};
        `}
        variant="info"
        title="Établissement introuvable ?"
        actionButton={hideActionButton ? undefined : cantFindActionButton}
      >
        Si vous venez de créer votre établissement, il est possible qu’il ne soit pas encore référencé. Dans ce cas,
        vous pouvez l’ajouter à la main.
      </PersistentNotification>
    )
  }

  return (
    <RowContainerV2
      css={css`
        margin-top: ${spacing[70]};
      `}
    >
      {places.map((place) => (
        <ResultButton key={place.siret} place={place} handleSubmit={handleSubmit} uiElement={uiElement} />
      ))}
    </RowContainerV2>
  )
})

type ResultButtonProps = {
  place: PlaceData
  handleSubmit: SubscriptionElementBlockProps['handleSubmit']
  uiElement: SiretSearchSubscriptionUiElement
}

const ResultButton = memo<ResultButtonProps>(function ResultButton({ place, handleSubmit, uiElement }) {
  const handleClick = useCallback(() => {
    const updateOnPlaceSelected = buildPlaceSelectionUpdate(place, uiElement)
    handleSubmit(undefined, updateOnPlaceSelected)
  }, [handleSubmit, place, uiElement])

  return (
    <RowButtonV2
      key={place.siret}
      onClick={handleClick}
      primaryText={place.name ?? place.companyName ?? place.siret}
      secondaryText={place.siret}
      tertiaryText={formatFrenchAddress1Line(place)}
    />
  )
})

const noPlaces: PlaceData[] = []

const PlaceLoadingPlaceholder = memo(function PlaceLoadingPlaceholder() {
  return (
    <div
      css={css`
        margin-top: ${spacing[70]};
        display: flex;
        justify-content: center;
      `}
    >
      <Spinner size="50" />
    </div>
  )
})
