import { css } from '@emotion/react'
import { memo, type ChangeEvent } from 'react'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import { Switch, Text } from '../../../atoms/index.js'
import { ButtonInputLabelWrapper, type ButtonInputLabelWrapperProps } from '../../input-label-wrapper/index.js'

export type SwitchFormFieldProps = {
  optionalText?: string
  checked: boolean
  onChange: (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
} & Omit<ButtonInputLabelWrapperProps, 'leftComponent' | 'rightComponent' | 'rowGap'>

export const SwitchFormField = memo<SwitchFormFieldProps>(function SwitchFormField(props) {
  const { checked, onChange, optionalText, variant, label, value, infoTooltip } = props
  const rightComponent = (
    <div
      css={css`
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto;
        align-items: center;
        grid-column-gap: ${spacing[50]};
      `}
    >
      {optionalText ? (
        <Text variant="body2" screenVariant={variant}>
          {optionalText}
        </Text>
      ) : undefined}
      <Switch checked={checked} onChange={onChange} size="small" />
    </div>
  )
  return (
    <ButtonInputLabelWrapper
      variant={variant}
      label={label}
      value={value}
      infoTooltip={infoTooltip}
      rightComponent={rightComponent}
      rowGap="6px"
    />
  )
})
