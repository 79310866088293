'use client'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  colorTokens,
  Dialog,
  Divider,
  FormRow,
  spacing,
  TextInputLabelWrapper,
  ValidatedTextField,
} from '@orus.eu/pharaoh'
import { memo, useCallback } from 'react'
import { Editor, Element, Transforms, type BaseEditor } from 'slate'
import { HistoryEditor } from 'slate-history'
import type { ReactEditor } from 'slate-react'
import { TableEditor } from 'slate-table'

import { mappers, urlMapper } from '../../../../../../subscription-v2/elements/string-element-mappers'
import ColorPicker from '../../color-picker/ColorPicker'
import { EXPERIENCE_TEMPLATE_TABLE, INSURANCE_TIMELINE_TEMPLATE_TABLE } from '../../constants'
import EmojiPickerButton from '../../emoji-picker/EmojiPicker'
import { useLinkDialog } from '../../hooks/useLinkDialog'
import { insertLink } from '../../plugins/link/withLinks'
import type { CustomElement } from '../../types'
import { CustomIconButton } from '../button/CustomIconButton'
import {
  ColumnInsertLeft,
  ColumnInsertRight,
  ColumnRemove,
  LinkAdd,
  MergeCell,
  Redo,
  RowInsertBottom,
  RowInsertTop,
  RowRemove,
  SplitCell,
  Table,
  TableMinus,
  TablePlus,
  Undo,
} from '../icons'
import { DropdownButton } from './Dropdown'
import { FormatButtons } from './FormatButtons'

const TABLE_LIST_ITEMS = [
  {
    value: 'new-table',
    icon: <TablePlus width={20} height={20} />,
    label: 'Insérer un tableau',
  },
  {
    value: 'merge-cells',
    icon: <MergeCell width={20} height={20} />,
    label: 'Fusionner les cellules',
  },
  {
    value: 'split-cells',
    icon: <SplitCell width={20} height={20} />,
    label: 'Séparer les cellules',
  },
  {
    value: 'delete-table',
    icon: <TableMinus width={20} height={20} />,
    label: 'Supprimer le tableau',
  },
  {
    value: 'insert-row-above',
    icon: <RowInsertTop width={20} height={20} />,
    label: 'Insérer une ligne au dessus',
  },
  {
    value: 'insert-row-below',
    icon: <RowInsertBottom width={20} height={20} />,
    label: 'Insérer une ligne en dessous',
  },
  {
    value: 'delete-row',
    icon: <RowRemove width={20} height={20} />,
    label: 'Supprimer la ligne',
  },
  {
    value: 'insert-column-left',
    icon: <ColumnInsertLeft width={20} height={20} />,
    label: 'Insérer une colonne à gauche',
  },
  {
    value: 'insert-column-right',
    icon: <ColumnInsertRight width={20} height={20} />,
    label: 'Insérer une colonne à droite',
  },
  {
    value: 'delete-column',
    icon: <ColumnRemove width={20} height={20} />,
    label: 'Supprimer la colonne',
  },
  {
    value: 'insert-template',
    icon: <TablePlus width={20} height={20} />,
    label: 'Insérer un template',
  },
]

export type TableTemplate = 'experience' | 'insurance-timeline'

type ToolbarProps = {
  editor: BaseEditor & ReactEditor & HistoryEditor
  canUndo: boolean
  canRedo: boolean
  canDelete: boolean
  canSplit: boolean
  canMerge: boolean
  canManipulateTable: boolean
  tableTemplate?: TableTemplate
  onToolbarAction: () => void
  isLinkActive: boolean
}

export const Toolbar = memo(function Toolbar({
  editor,
  canUndo,
  canRedo,
  canDelete,
  canSplit,
  canMerge,
  canManipulateTable,
  tableTemplate,
  onToolbarAction,
}: ToolbarProps) {
  const handleOnToolbarAction = useCallback(() => {
    onToolbarAction()
  }, [onToolbarAction])

  const handleOnTableAction = useCallback(
    (value: string) => {
      switch (value) {
        case 'new-table':
          TableEditor.insertTable(editor, { rows: 3, cols: 3 })
          break
        case 'merge-cells':
          TableEditor.merge(editor)
          break
        case 'split-cells':
          TableEditor.split(editor)
          break
        case 'delete-table':
          TableEditor.removeTable(editor)
          break
        case 'insert-row-above':
          TableEditor.insertRow(editor, { before: true })
          break
        case 'insert-row-below':
          TableEditor.insertRow(editor)
          break
        case 'delete-row':
          TableEditor.removeRow(editor)
          break
        case 'insert-column-left':
          TableEditor.insertColumn(editor, { before: true })
          break
        case 'insert-column-right':
          TableEditor.insertColumn(editor)
          break
        case 'insert-template':
          if (tableTemplate) {
            switch (tableTemplate) {
              case 'experience':
                Transforms.insertNodes(editor, EXPERIENCE_TEMPLATE_TABLE)
                break
              case 'insurance-timeline':
                Transforms.insertNodes(editor, INSURANCE_TIMELINE_TEMPLATE_TABLE)
                break
            }
          }
          break
        default:
          break
      }
      handleOnToolbarAction()
    },
    [editor, tableTemplate, handleOnToolbarAction],
  )

  const getAvailableTableOptions = useCallback(() => {
    const availableOptions = TABLE_LIST_ITEMS.map((item) => {
      if (item.value === 'new-table' && !canManipulateTable) {
        return item.value
      }
      if (item.value === 'insert-template' && !canManipulateTable) {
        return item.value
      }
      if (item.value === 'merge-cells' && !canMerge) {
        return undefined
      }
      if (item.value === 'split-cells' && !canSplit) {
        return undefined
      }
      if (item.value === 'delete-table' && !canDelete) {
        return undefined
      }
      if (!canManipulateTable) {
        return undefined
      }
      return item.value
    })

    return availableOptions.filter((option) => option !== undefined)
  }, [canDelete, canMerge, canSplit, canManipulateTable])

  const { isDialogVisible, label, url, openDialog, setLabel, setUrl, closeDialog } = useLinkDialog()

  const handleOnLinkAction = useCallback(
    (value: string) => {
      switch (value) {
        case 'insert-link':
          {
            if (editor.selection) {
              const [parentNode] = Editor.parent(editor, editor.selection.focus?.path)

              if (Element.isElement(parentNode)) {
                if (parentNode.type === 'paragraph') {
                  const text = parentNode.children[0]
                  if (typeof text == 'object' && 'text' in text) {
                    setLabel(text.text)
                  }
                }
                if (parentNode.type === 'link') {
                  const text = parentNode.children[0]
                  if (typeof text == 'object' && 'text' in text) {
                    setLabel(text.text)
                  }

                  setUrl(parentNode.url)
                }
              }
            }

            openDialog()
          }
          break
        case 'remove-link':
          Transforms.removeNodes(editor, { match: (n) => (n as CustomElement).type === 'link' })
          break
      }
    },
    [editor, openDialog, setLabel, setUrl],
  )

  const onSave = useCallback(() => {
    if (!url || !label) return

    insertLink(editor, url, label)
    closeDialog()
  }, [editor, url, label, closeDialog])

  const onClose = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  return (
    <>
      {isDialogVisible ? (
        <Dialog
          title={"Veuillez saisir l'url du lien"}
          style="base"
          size="small"
          primaryActionLabel={'Enregistrer'}
          onPrimaryAction={onSave}
          secondaryActionLabel={'Annuler'}
          onSecondaryAction={onClose}
          primaryActionDisabled={!url}
          onClose={onClose}
        >
          <FormRow>
            <ValidatedTextFieldWrapper label={'Libellé'}>
              <ValidatedTextField
                size="large"
                mapper={mappers['string']}
                value={label}
                onChange={(value) => setLabel(value)}
              />
            </ValidatedTextFieldWrapper>
            <ValidatedTextFieldWrapper label={'Url'}>
              <ValidatedTextField size="large" mapper={urlMapper} value={url} onChange={(value) => setUrl(value)} />
            </ValidatedTextFieldWrapper>
          </FormRow>
        </Dialog>
      ) : null}
      <ToolbarContainer>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: ${spacing['30']};
          `}
        >
          <FormatButtons />
          <Divider
            orientation="vertical"
            css={css`
              margin: ${spacing['20']} 0;
            `}
          />
          <div
            css={css`
              display: flex;
              gap: ${spacing['30']};
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <ColorPicker onSelectColor={handleOnToolbarAction} format={'color'} editor={editor} />
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <ColorPicker onSelectColor={handleOnToolbarAction} format={'backgroundColor'} editor={editor} />
            </div>
          </div>
          <Divider
            orientation="vertical"
            css={css`
              margin: ${spacing['20']} 0;
            `}
          />
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <EmojiPickerButton onSelectEmoji={handleOnToolbarAction} editor={editor} format={'emoji'} />
          </div>
          <Divider
            orientation="vertical"
            css={css`
              margin: ${spacing['20']} 0;
            `}
          />
          <DropdownButton
            buttonContent={<Table width={20} height={20} />}
            options={TABLE_LIST_ITEMS}
            availableOptions={getAvailableTableOptions()}
            onClick={handleOnTableAction}
          />
          <Divider
            orientation="vertical"
            css={css`
              margin: ${spacing['20']} 0;
            `}
          />
          <CustomIconButton
            active
            onMouseDown={(event) => {
              event.preventDefault()
              handleOnLinkAction('insert-link')
              handleOnToolbarAction()
            }}
          >
            <LinkAdd width={20} height={20} />
          </CustomIconButton>
        </div>

        <div
          css={css`
            display: flex;
            gap: ${spacing['10']};
          `}
          role="group"
        >
          <Divider
            orientation="vertical"
            css={css`
              margin: ${spacing['20']} 0;
            `}
          />
          <CustomIconButton
            title="Undo"
            active={canUndo}
            onMouseDown={(event) => {
              event.preventDefault()
              HistoryEditor.undo(editor)
              handleOnToolbarAction()
            }}
          >
            <Undo />
          </CustomIconButton>
          <CustomIconButton
            title="Redo"
            active={canRedo}
            onMouseDown={(event) => {
              event.preventDefault()
              HistoryEditor.redo(editor)
              handleOnToolbarAction()
            }}
          >
            <Redo />
          </CustomIconButton>
        </div>
      </ToolbarContainer>
    </>
  )
})

const ToolbarContainer = styled.div`
  box-shadow: 0 0 0 1px ${colorTokens['color-stroke-base']};
  border-radius: ${spacing['30']} ${spacing['30']} 0 0;
  padding: ${spacing['10']} ${spacing['10']};
  gap: ${spacing['20']};
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`

const ValidatedTextFieldWrapper = styled(TextInputLabelWrapper)`
  width: 100%;
`
