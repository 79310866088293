import { createFileRoute } from '@tanstack/react-router'

import { CustomerHomePage } from '../../components/pages/customer-home-page'
import CustomerPage from '../../components/templates/customer-page'

export const Route = createFileRoute('/home')({
  component: () => (
    <CustomerPage requiredPermissions={[]}>
      <CustomerHomePage />
    </CustomerPage>
  ),
})
