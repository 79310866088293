import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import { isPharaohSkin, usePharaohSkin, useSetSkin, type Skin } from '@orus.eu/pharaoh'
import { memo, useEffect, useMemo, useState, type ReactNode } from 'react'
import { trpcReact } from '../../client'
import { EmbeddingPartnerProvider } from '../../lib/embedding-partner'
import { logger } from '../../lib/logger'
import { useSession } from '../../lib/session'

export const EmbeddingPartnerWrapper = memo<{ children: ReactNode }>(function EmbeddingPartnerWrapper({ children }) {
  const session = useSession()

  const [explicitEmbeddingPartnerId, setExplicitEmbeddingPartnerId] = useState<string | undefined>(
    getInitialEmbeddingPartnerId(),
  )
  const setSkin = useSetSkin()
  const { setSkin: setPharaohSkin } = usePharaohSkin()

  const hasPermissions =
    session?.permissions.type === 'client' &&
    session.permissions.rolesPermissions.flatMap((r) => r.permissions).includes('contracts.read')

  const { data: contracts } = trpcReact.contracts.listMyContracts.useQuery(undefined, {
    enabled: hasPermissions,
  })

  const embeddingPartnerId = explicitEmbeddingPartnerId ?? getDefaultEmbeddingPartnerId(contracts)

  // This effect reacts to embeddingPartner changes to update the skin accordingly
  useEffect(() => {
    if (!embeddingPartnerId) {
      setSkin(null)
      setPharaohSkin('light')
      return
    }

    if (isPharaohSkin(embeddingPartnerId)) setPharaohSkin(embeddingPartnerId)

    let cancelled = false

    skinFactories[embeddingPartnerId]()
      .then((skin) => {
        if (cancelled) {
          return
        }

        setSkin(skin)
      })
      .catch((err) => {
        if (cancelled) {
          return
        }

        logger.warning('Error while loading skin', err)
      })

    return () => {
      cancelled = true
    }
  }, [embeddingPartnerId, setSkin, setPharaohSkin])

  const embeddingPartnerContextValue = useMemo(
    () => ({ embeddingPartnerId, setEmbeddingPartnerId: setExplicitEmbeddingPartnerId }),
    [embeddingPartnerId, setExplicitEmbeddingPartnerId],
  )

  return <EmbeddingPartnerProvider value={embeddingPartnerContextValue}>{children}</EmbeddingPartnerProvider>
})

/**
 * Gets the default embedding partner id, based on the presence or absence of a contract, to use until
 * a different embedding partner id is explicitly set.
 */
function getDefaultEmbeddingPartnerId(contracts: CustomerContractDescription[] | undefined): string | undefined {
  if (!contracts) {
    // loading, or failed and waiting for redirect
    return getInitialEmbeddingPartnerId()
  }

  if (contracts.length < 1) {
    // no contract => no embedding partner
    return undefined
  }

  const firstContract = contracts[0]
  const embeddingPartner = firstContract.embeddingPartner
  return embeddingPartner === 'none' ? undefined : embeddingPartner.id
}

/**
 * Gets the initial embedding partner id from the URL parameters, to avoid a flash while loading
 */
function getInitialEmbeddingPartnerId(): string | undefined {
  const url = new URL(document.location.href)

  const embeddingPartnerParameter = url.searchParams.get('embeddingPartner')
  if (embeddingPartnerParameter && embeddingPartnerParameter in skinFactories) {
    return embeddingPartnerParameter
  }

  return undefined
}

const skinFactories: Record<string, () => Promise<Skin>> = {
  sidecare: () => import('../../lib/skins/sidecare').then((m) => m.sidecareSkin),
  superindep: () => import('../../lib/skins/superindep').then((m) => m.superindepSkin),
  propulse: () => import('../../lib/skins/propulse').then((m) => m.propulseSkin),
  jump: () => import('../../lib/skins/jump').then((m) => m.jumpSkin),
  shine: () => import('../../lib/skins/shine').then((m) => m.shineSkin),
  legalstart: () => import('../../lib/skins/legalstart').then((m) => m.legalstartSkin),
  legalplace: () => import('../../lib/skins/legalplace').then((m) => m.legalPlaceSkin),
  dougs: () => import('../../lib/skins/dougs').then((m) => m.dougsSkin),
  previi: () => import('../../lib/skins/previi').then((m) => m.previiSkin),
}
