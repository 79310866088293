import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { memo, useMemo, type CSSProperties } from 'react'
import { borderStroke, colorTokens, spacing } from '../../foundation'
import { shadow } from '../../foundation/shadow-tokens.js'
import { Avatar, AvatarContext, Text, type CompoundIconName } from '../atoms'
import { Radio } from '../atoms/radio'
import type { TextVariant } from '../atoms/text'
import type { BadgeProps } from '../badge'
import type { ButtonProps } from '../button'
import { Checkbox } from '../checkbox'
import type { ChipProps } from '../chip'
import { Spinner } from '../spinner'
import { Tooltip } from '../tooltip'

export type RowVariant = 'descriptive' | 'basic' | 'standalone'

type RowState = 'enable' | 'hover' | 'focus' | 'disabled' | 'active' | 'activeFocus'

export type OrusRowSize = 'xsmall' | 'small' | 'medium' | 'large'

export type RowProps = {
  title?: React.ReactNode
  titleVariant?: TextVariant
  subtitle?: React.ReactNode
  titleColor?: string
  subtitleColor?: string
  subtitleVariant?: TextVariant
  variant?: RowVariant
  size?: OrusRowSize
  checkbox?: boolean
  radio?: boolean
  selected?: boolean
  leftAvatar?: React.ReactNode
  leftIcon?: CompoundIconName
  chip?: React.ReactElement<ChipProps>
  badge?: React.ReactElement<BadgeProps>
  rightAvatar?: React.ReactNode
  rightIcon?: CompoundIconName
  tooltip?: string
  button?: React.ReactElement<ButtonProps>
  buttonGroup?: React.ReactNode
  disabled?: boolean
  isLoading?: boolean
  href?: string
  openInNewTab?: boolean
  className?: string
  onClick?: () => void
  ariaLabel?: string

  rightAvatarCssProps?: CSSProperties
}

export const Row = memo<RowProps>(function Row(props) {
  const {
    title,
    titleVariant = 'body1Medium',
    subtitle,
    variant = 'basic',
    subtitleVariant = 'body2',
    size = 'xsmall',
    checkbox = false,
    selected = false,
    radio = false,
    leftAvatar,
    leftIcon,
    chip,
    badge,
    rightAvatar,
    rightIcon,
    tooltip,
    button,
    buttonGroup,
    disabled = false,
    isLoading = false,
    href,
    className,
    onClick,
    ariaLabel,
    openInNewTab = false,
    rightAvatarCssProps,
  } = props

  const padding = useMemo(() => paddingPerRowVariantAndSize[variant][size], [variant, size])
  const rowStyle = useMemo(() => rowStylePerVariantAndState[variant], [variant])
  const isChoice = checkbox || radio

  const setupLeftAvatar = useMemo(
    () =>
      leftAvatar || leftIcon ? (
        <>
          {leftAvatar || (
            <AvatarContext.Provider value={avatarDefaultContext}>
              <Avatar
                icon={leftIcon}
                color={disabled ? colorTokens['color-text-base-disable'] : colorTokens['color-text-base-main']}
              />
            </AvatarContext.Provider>
          )}
        </>
      ) : (
        <></>
      ),
    [leftAvatar, leftIcon, disabled],
  )

  const setupRightAvatar = useMemo(
    () =>
      rightAvatar || rightIcon ? (
        <>
          {rightAvatar || (
            <AvatarContext.Provider value={avatarDefaultContext}>
              <Avatar
                icon={rightIcon}
                color={disabled ? colorTokens['color-text-base-disable'] : colorTokens['color-text-base-main']}
              />
            </AvatarContext.Provider>
          )}
        </>
      ) : (
        <></>
      ),
    [rightAvatar, rightIcon, disabled],
  )

  const forwardedProps = {
    padding,
    rowStyle,
    disabled,
    roundedBorder: variant === 'standalone',
    isChoice,
    onClick,
    checked: selected,
    className,
  }

  const content = (
    <>
      {checkbox ? (
        <Checkbox size="small" checked={selected} onChange={() => !disabled && onClick} aria-label={title} />
      ) : radio ? (
        <Radio size="small" value="" checked={selected} onChange={() => !disabled && onClick} aria-label={title} />
      ) : (
        <></>
      )}
      {isLoading ? (
        <Spinner
          size="40"
          css={css`
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          `}
        />
      ) : null}

      <HideOnLoadContainer isLoading={isLoading}>
        {setupLeftAvatar}
        {title || subtitle ? (
          <TitleAndSubtitleContainer>
            <Text
              color={
                disabled
                  ? colorTokens['color-text-base-disable']
                  : (props.titleColor ?? colorTokens['color-text-base-main'])
              }
              variant={titleVariant}
            >
              {title}
            </Text>
            <Text
              color={
                disabled
                  ? colorTokens['color-text-base-disable']
                  : (props.subtitleColor ?? colorTokens['color-text-base-basic'])
              }
              variant={subtitleVariant}
            >
              {subtitle}
            </Text>
          </TitleAndSubtitleContainer>
        ) : null}

        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: auto;
            gap: ${spacing[50]};
          `}
          style={rightAvatarCssProps}
        >
          {chip}
          {badge}
          {setupRightAvatar}
          {tooltip ? (
            <AvatarContext.Provider value={avatarDefaultContext}>
              <Tooltip title={tooltip}>
                <Avatar icon={'circle-info-regular'} />
              </Tooltip>
            </AvatarContext.Provider>
          ) : (
            <></>
          )}
          {buttonGroup}
          {button}
        </div>
      </HideOnLoadContainer>
    </>
  )

  if (href) {
    return (
      <RowLink
        {...forwardedProps}
        href={disabled ? undefined : props.href}
        onClick={() => !disabled && onClick}
        aria-label={ariaLabel}
        variant={variant}
        {...(openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        {content}
      </RowLink>
    )
  }

  if (isChoice) {
    return (
      <RowChoice {...forwardedProps} active={selected} aria-label={ariaLabel} variant={variant}>
        {content}
      </RowChoice>
    )
  }

  if (onClick) {
    return (
      <RowButton
        {...forwardedProps}
        tabIndex={disabled ? -1 : 0}
        onClick={() => !disabled && onClick()}
        aria-label={ariaLabel}
        variant={variant}
      >
        {content}
      </RowButton>
    )
  }

  return (
    <RowBase {...forwardedProps} variant={variant}>
      {content}
    </RowBase>
  )
})

const avatarDefaultContext = { size: '30' } as const

const HideOnLoadContainer = styled.div<{ isLoading: boolean }>`
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${spacing[50]};
`

type RowStylingProps = {
  padding: string
  rowStyle: { [key in RowState]?: RowStyle }
  disabled: boolean
  roundedBorder: boolean
  variant: RowVariant
}

const rowStyleMixin = ({ padding, rowStyle, disabled, roundedBorder }: RowStylingProps) => css`
  position: relative;
  display: flex;
  align-items: center;

  gap: ${spacing[50]};
  padding: ${padding};
  border-radius: ${roundedBorder ? spacing[30] : 0};

  &:hover {
    background-color: ${disabled ? '' : rowStyle.hover?.backgroundColor};
    outline: ${disabled ? '' : rowStyle.hover?.outline};
    outline-offset: ${disabled ? '' : rowStyle.hover?.outlineOffset};
  }

  &:focus {
    background-color: ${disabled ? '' : rowStyle.focus?.backgroundColor};
    outline: ${disabled ? '' : rowStyle.focus?.outline};
    outline-offset: ${disabled ? '' : rowStyle.focus?.outlineOffset};
  }

  background-color: ${disabled ? rowStyle.disabled?.backgroundColor : rowStyle.enable?.backgroundColor};
  outline: ${disabled ? rowStyle.disabled?.outline : rowStyle.enable?.outline};
  outline-offset: ${disabled ? rowStyle.disabled?.outlineOffset : rowStyle.enable?.outlineOffset};
`

const RowBase = styled.div<RowStylingProps>`
  ${(props) => rowStyleMixin(props)}
`

const RowButton = styled.button<RowStylingProps>`
  ${(props) => rowStyleMixin(props)}
  cursor: ${({ disabled, variant }) => (disabled || variant === 'descriptive' ? 'default' : 'pointer')};

  text-align: inherit;
  border: none;
`

const RowLink = styled.a<RowStylingProps>`
  ${(props) => rowStyleMixin(props)}
  color: inherit;
  text-decoration: inherit;
  cursor: ${({ disabled, variant }) => (disabled || variant === 'descriptive' ? 'default' : 'pointer')};
`

const RowChoice = styled.div<RowStylingProps & { active: boolean }>`
  ${(props) => rowStyleMixin(props)}
  cursor: ${({ disabled, variant }) => (disabled || variant === 'descriptive' ? 'default' : 'pointer')};

  ${({ rowStyle, disabled }) =>
    disabled
      ? ''
      : css`
          &:hover {
            box-shadow: ${rowStyle.hover?.boxShadow};
          }
        `}

  ${({ rowStyle, active }) =>
    active
      ? css`
          outline: ${rowStyle.active?.outline} !important;
          outline-offset: ${rowStyle.active?.outlineOffset} !important;
          border-color: transparent;

          &:hover {
            border-color: transparent;
          }

          &:focus-within {
            outline: ${rowStyle.activeFocus?.outline};
            outline-offset: ${rowStyle.activeFocus?.outlineOffset};
            box-shadow: ${rowStyle.activeFocus?.boxShadow};
            border-color: transparent;
          }
        `
      : ''}
`
const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  & > * {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const paddingPerRowVariantAndSize: Record<RowVariant, Record<OrusRowSize, string>> = {
  descriptive: {
    xsmall: `${spacing[20]} 0`,
    small: `${spacing[30]} 0`,
    medium: `${spacing[40]} 0`,
    large: `${spacing[50]} 0`,
  },
  basic: {
    xsmall: `${spacing[20]} 0`,
    small: `${spacing[30]} 0`,
    medium: `${spacing[40]} 0`,
    large: `${spacing[50]} 0`,
  },
  standalone: {
    xsmall: `${spacing[20]} ${spacing[50]}`,
    small: `${spacing[30]} ${spacing[50]}`,
    medium: `${spacing[40]} ${spacing[50]}`,
    large: `${spacing[50]} ${spacing[50]}`,
  },
}

type RowStyle = {
  backgroundColor?: string
  outline?: string
  outlineOffset?: string
  boxShadow?: string
}
const rowStylePerVariantAndState: Record<RowVariant, { [key in RowState]?: RowStyle }> = {
  descriptive: {
    enable: { backgroundColor: colorTokens['color-bg-base-normal'] },
    hover: { backgroundColor: colorTokens['color-bg-base-normal'] },
    focus: { backgroundColor: colorTokens['color-bg-base-normal'] },
    disabled: { backgroundColor: colorTokens['color-bg-base-normal'] },
  },
  basic: {
    enable: { backgroundColor: colorTokens['color-bg-base-normal'] },
    hover: { backgroundColor: colorTokens['color-bg-base-hover'] },
    focus: { backgroundColor: colorTokens['color-bg-base-focus'] },
    disabled: { backgroundColor: colorTokens['color-bg-base-normal'] },
  },
  standalone: {
    enable: {
      backgroundColor: colorTokens['color-bg-base-normal'],
      outline: `${borderStroke[20]} solid ${colorTokens['color-stroke-base']}`,
      outlineOffset: `-${borderStroke[20]}`,
    },
    hover: {
      backgroundColor: colorTokens['color-bg-base-hover'],
      outline: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-hover']}`,
      outlineOffset: `-${borderStroke[20]}`,
      boxShadow: shadow.bottom['05'],
    },
    focus: {
      backgroundColor: colorTokens['color-bg-base-focus'],
      outline: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-focus']}`,
      outlineOffset: `-${borderStroke[20]}`,
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-normal'],
      outline: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-disable']}`,
      outlineOffset: `-${borderStroke[20]}`,
    },
    active: {
      backgroundColor: colorTokens['color-bg-base-normal'],
      outline: `${borderStroke[30]} solid ${colorTokens['color-stroke-base-selected']}`,
      outlineOffset: `-${borderStroke[30]}`,
    },
    activeFocus: {
      backgroundColor: colorTokens['color-bg-base-focus'],
      outline: `${borderStroke[30]} solid ${colorTokens['color-stroke-base-selected']}`,
      outlineOffset: `-${borderStroke[30]}`,
      boxShadow: shadow.bottom['05'],
    },
  },
}
