import type { AllComplianceDTO } from '@orus.eu/backend/src/compliance/routers/compliance.dto'
import type { ORUS_TRPC_ERROR_CODE } from '@orus.eu/backend/src/types/orus-custom-error'
import { isSuccess } from '@orus.eu/result'
import type { TRPCClientErrorLike } from '@trpc/client'
import type { UseTRPCQueryResult } from '@trpc/react-query/dist/shared'
import type { TRPC_ERROR_CODE_NUMBER } from '@trpc/server/unstable-core-do-not-import'
import type { ComplianceData } from '../detailed-sheet.types'

type ComplianceDataQuery =
  | UseTRPCQueryResult<
      AllComplianceDTO,
      TRPCClientErrorLike<{
        input: {
          siren: string
        }
        output: AllComplianceDTO
        transformer: true
        errorShape: {
          data: {
            code: ORUS_TRPC_ERROR_CODE
            httpStatus: number
            path?: string
            stack?: string
          }
          message: string
          code: TRPC_ERROR_CODE_NUMBER
        }
      }>
    >
  | undefined

export const getComplianceData = (query: ComplianceDataQuery): ComplianceData => {
  if (query && query.data) {
    const { complyAdvantageCustomersCase, pappersCompany, lystaCompany } = query.data

    const complyAdvantageCustomersCaseSuccessOutput = complyAdvantageCustomersCase
      .filter((customerCase) => isSuccess(customerCase))
      .map((customerCase) => customerCase.output)

    return {
      pappersCompany: isSuccess(pappersCompany) ? pappersCompany.output : undefined,
      lystaCompany: isSuccess(lystaCompany) ? lystaCompany.output : undefined,
      complyAdvantageCustomersCase:
        complyAdvantageCustomersCaseSuccessOutput.length > 0 ? complyAdvantageCustomersCaseSuccessOutput : [],
    }
  }

  return {
    pappersCompany: undefined,
    lystaCompany: undefined,
    complyAdvantageCustomersCase: [],
  }
}
