import styled from '@emotion/styled'
import { Avatar, Button, colorTokens, useOpenChat } from '@orus.eu/pharaoh'

export function CompactHeaderContactButton(): JSX.Element {
  const openChat = useOpenChat()
  return (
    <NoPaddingButton
      avatar={<Avatar icon="comment-regular" color={colorTokens['color-fg-base-active-inverse']} />}
      variant="text"
      avatarSize="40"
      onClick={openChat}
    />
  )
}

const NoPaddingButton = styled(Button)({
  padding: 0,
})
