import { css } from '@emotion/react'

import { memo, type ReactNode } from 'react'
import { spacing } from '../../foundation/spacing-tokens.js'
import { mobileMediaQuery } from '../../hooks/use-screen-type.js'
import { Text } from '../atoms/index.js'

type Variant = 'mobile' | 'desktop'

export type Banner = {
  variant: Variant
  className?: string
  title: ReactNode
  text: ReactNode
  image: string
  button: ReactNode
  backgroundColor: string
}

export const Banner = memo<Banner>(function Banner(props) {
  const { variant, className, title, text, image, button, backgroundColor } = props

  return (
    <div
      className={className}
      css={css`
        background-color: ${backgroundColor};
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        align-items: center;

        /* desktop defaults */
        gap: ${spacing[50]};
        padding: ${spacing[60]} ${spacing[70]} ${spacing[60]} ${spacing[70]};
        border-radius: 12px;
        grid-template-areas:
          'text image'
          'button image';
        ${mobileMediaQuery} {
          gap: ${spacing[70]};
          padding: ${spacing[60]};
          border-radius: 0;
          grid-template-areas:
            'text image'
            'button button';
        }
      `}
    >
      <div
        css={css`
          grid-area: text;
          align-self: flex-end;
        `}
      >
        <Text variant="h6"> {title}</Text>
        <Text
          variant="body2"
          css={css`
            margin-top: ${variant === 'mobile' ? spacing[30] : spacing[40]};
            width: 100%;
          `}
        >
          {text}
        </Text>
      </div>

      <div
        css={css`
          grid-area: image;
          align-self: flex-start;
          width: ${variant === 'mobile' ? '96px' : '156px'};
          height: ${variant === 'mobile' ? '96px' : '156px'};
        `}
      >
        <img
          src={image}
          alt=""
          css={css`
            width: ${variant === 'mobile' ? '96px' : '156px'};
          `}
        />
      </div>

      <div
        css={css`
          grid-area: button;
          align-self: flex-start;
        `}
      >
        {button}
      </div>
    </div>
  )
})
