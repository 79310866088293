import { friendlyFormatIBAN } from 'ibantools'
import { forwardRef } from 'react'
import { NumberFormatBase } from 'react-number-format'

export const IbanFormatter = forwardRef<
  unknown,
  {
    onChange: (event: { target: { value: string | null } }) => void
  }
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props
  const formatIban = (value: string) => friendlyFormatIBAN(value) ?? value.trim()

  return (
    <NumberFormatBase
      inputMode="text"
      {...other}
      format={formatIban}
      getInputRef={ref}
      removeFormatting={(value) => value.replace(/\s+/gi, '')}
      isValidInputCharacter={(char) => /^[a-z0-9]$/i.test(char)}
      getCaretBoundary={(value) =>
        Array(value.length + 1)
          .fill(0)
          .map(() => true)
      }
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value === undefined ? null : values.value,
          },
        })
      }}
    />
  )
})
