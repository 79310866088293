import { css } from '@emotion/react'
import type { OrganizationMembershipsUpdate } from '@orus.eu/backend/src/access/membership-store'
import { Dialog, Text, spacing } from '@orus.eu/pharaoh'
import type { OrganizationMembership } from '@orus.eu/right-access-management'
import { memo, useCallback } from 'react'
import type { OrganizationMembershipsDiff } from './memberships-diff'

export type MembershipsReviewDialogProps = {
  onSubmit: (changes: OrganizationMembershipsUpdate) => void
  onClose: () => void
  diff: OrganizationMembershipsDiff
}

export const MembershipsReviewDialog = memo<MembershipsReviewDialogProps>(function ChangesReviewDialog({
  onClose,
  diff,
  onSubmit,
}) {
  const handleSubmit = useCallback(() => onSubmit(diff.update), [diff.update, onSubmit])

  return (
    <Dialog
      size="large"
      onClose={onClose}
      title={'Revue des changements'}
      style="base"
      primaryActionLabel="Valider les changements"
      onPrimaryAction={handleSubmit}
    >
      <div
        css={css`
          margin-top: ${spacing[70]};
          display: flex;
          flex-direction: column;
          gap: ${spacing[50]};
        `}
      >
        <OrganizationMembershipsList memberships={diff.removedOrganizationMemberships} title="Adhésions supprimées" />
        <OrganizationMembershipsList memberships={diff.addedOrganizationMemberships} title="Adhésions ajoutées" />
        <OrganizationMembershipsList
          memberships={diff.updatedOrganizationMemberships}
          title="Adhésions mises à jours"
        />
      </div>
    </Dialog>
  )
})

type OrganizationMembershipsListProps = { title: string; memberships: OrganizationMembership[] }

const OrganizationMembershipsList = memo<OrganizationMembershipsListProps>(function GroupDescriptionList({
  memberships,
  title,
}) {
  return memberships.length > 0 ? (
    <div>
      <Text variant="h6">{title}</Text>
      <ul>
        {memberships.map((membership) => (
          <li key={membership.email}>
            <Text>{membership.email}</Text>
            <Text>
              {membership.organizations
                .map(
                  (organization) =>
                    'Organisation : ' + organization.technicalName + ' | Roles : ' + organization.roles.join(', '),
                )
                .join(', ')}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <></>
  )
})
