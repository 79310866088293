import { css } from '@emotion/react'
import { nbsp } from '@orus.eu/char'
import type { Product } from '@orus.eu/product'
import { memo } from 'react'
import { Text, colorTokens, colors, spacing } from '../../../../index.js'
import { ButtonGroup } from '../../../button/button-group.js'
import { CalloutNotificationTemplate } from '../../../callout-notification/common/callout-notification-template.js'
import { Avatar, Button } from '../../../index.js'
import { RowContainer } from '../../../rows/row-container.js'
import { Row } from '../../../rows/row.js'
import { ProductBadge } from '../../backoffice-quote-editor/badges/index.js'

type Activity = { id: string; name: string; products?: Product[]; hasMoreDetails?: boolean }

type ActivitiesSelectorProps = {
  mainActivity: Activity
  complementaryActivities: Activity[]
  isSelfOnboarding: boolean
  maxActivitiesCount?: number
  isJustifyExperienceTextVisible?: boolean
  ActivityDetailButton?: React.ReactNode

  onRemoveMainActivity: () => void
  onAddActivity?: () => void
  onRemoveActivity?: (activity: Activity) => void
  onMoreDetailsClick?: (activity: Activity) => void

  className?: string
}

export const ActivitiesSelector = memo<ActivitiesSelectorProps>(function ActivitiesSelector(props) {
  const {
    isSelfOnboarding,
    complementaryActivities,
    maxActivitiesCount,
    mainActivity,
    onAddActivity,
    onRemoveActivity,
    onRemoveMainActivity,
    onMoreDetailsClick,
    isJustifyExperienceTextVisible,
    ActivityDetailButton,
    className,
  } = props

  const activitiesCount = 1 + complementaryActivities.length
  const isMaxActivitiesReached = maxActivitiesCount && activitiesCount >= maxActivitiesCount
  const shouldShowAddButton = isSelfOnboarding && !isMaxActivitiesReached

  return (
    <div className={className}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing['30']};
        `}
      >
        <RowContainer size={'medium'} variant="spacing">
          <Row
            title={mainActivity.name}
            titleVariant={'body2'}
            badge={
              !isSelfOnboarding && mainActivity.products ? (
                <div
                  css={css`
                    display: flex;
                    gap: ${spacing[20]};
                  `}
                >
                  {mainActivity.products.map((product, index) => (
                    <ProductBadge product={product} key={index} />
                  ))}
                </div>
              ) : undefined
            }
            buttonGroup={
              <ButtonGroup>
                {mainActivity.hasMoreDetails && onMoreDetailsClick ? (
                  <Button
                    variant="secondary"
                    size={isSelfOnboarding ? 'medium' : 'small'}
                    onClick={() => onMoreDetailsClick!(mainActivity)}
                  >
                    Plus de détails
                  </Button>
                ) : ActivityDetailButton ? (
                  ActivityDetailButton
                ) : undefined}
                {isSelfOnboarding ? undefined : (
                  <Button variant="secondary" icon="trash-can-regular" size="small" onClick={onRemoveMainActivity} />
                )}
              </ButtonGroup>
            }
          />

          {complementaryActivities.map((activity, index) => (
            <Row
              title={activity.name}
              titleVariant={'body2'}
              key={index}
              badge={
                !isSelfOnboarding && activity.products ? (
                  <div
                    css={css`
                      display: flex;
                      gap: ${spacing[20]};
                    `}
                  >
                    {activity.products.map((product, idx) => (
                      <ProductBadge product={product} key={idx} />
                    ))}
                  </div>
                ) : undefined
              }
              buttonGroup={
                <ButtonGroup>
                  {activity.hasMoreDetails && onMoreDetailsClick ? (
                    <Button
                      variant="secondary"
                      size={isSelfOnboarding ? 'medium' : 'small'}
                      onClick={() => onMoreDetailsClick!(activity)}
                    >
                      Plus de détails
                    </Button>
                  ) : null}
                  <Button
                    icon="xmark-regular"
                    variant="text"
                    ariaLabel="Retirer cette activité"
                    size="small"
                    onClick={() => onRemoveActivity?.(activity)}
                  />
                </ButtonGroup>
              }
            />
          ))}
        </RowContainer>
      </div>

      {shouldShowAddButton && (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin-top: ${spacing['30']};
          `}
        >
          <Button
            icon="plus-solid"
            size="small"
            variant="secondary"
            avatarPosition="left"
            onClick={onAddActivity}
            ariaLabel="ajouter-activité"
            css={css`
              margin-left: auto;
            `}
          >
            Ajouter une autre activité
          </Button>
        </div>
      )}
      {isMaxActivitiesReached && (
        <div
          css={css`
            margin-top: ${spacing[50]};
          `}
        >
          <CalloutNotificationTemplate
            leftAvatar={<Avatar icon={'ban-solid'} />}
            leftAvatarSize="30"
            backgroundColor={colorTokens['color-bg-danger-inverse']}
            textColor={colorTokens['color-text-base-main']}
            accentColor={colorTokens['color-fg-danger']}
          >
            Vous avez atteint le nombre maximum d’activités.
          </CalloutNotificationTemplate>
        </div>
      )}
      {isJustifyExperienceTextVisible ? (
        <Text
          variant="body2"
          color={colors.gray[700]}
          css={css`
            margin-top: ${shouldShowAddButton ? spacing['30'] : spacing['70']};
          `}
        >
          Votre expérience devra être justifiée{nbsp}: fiches de paie, factures, certificats de travail, etc.
        </Text>
      ) : null}
    </div>
  )
})
