import { createFileRoute } from '@tanstack/react-router'

import ContractPage from '../../../components/pages/contract-page'
import CustomerPage from '../../../components/templates/customer-page'

export const Route = createFileRoute('/contract/$subscriptionId')({
  component: () => (
    <CustomerPage requiredPermissions={['contracts.read']}>
      <ContractPage />
    </CustomerPage>
  ),
})
