import type { CalendarDate } from '@orus.eu/calendar-date'
import { ensureError } from '@orus.eu/error'
import { failure, success } from '@orus.eu/result'
import SSN from 'french-ssn'
import type { ValidatedTypeMapper } from '../../../index.js'

export function validateSocialSecurityNumber(
  text: string,
  birthDate?: CalendarDate,
  referenceSocialSecurityNumbers?: string[],
):
  | {
      ok: true
      value: string
    }
  | {
      ok: false
      hint: string
    } {
  text = text.replace(' ', '')

  try {
    const parsedText = SSN.parse(text)

    if (referenceSocialSecurityNumbers) {
      if (referenceSocialSecurityNumbers.includes(text)) {
        return { ok: true, value: text }
      }
    }
    if (birthDate) {
      if (
        Number(parsedText.birth.year.toString().slice(-2)) !== Number(birthDate.year.toString().slice(-2)) ||
        ('index' in parsedText.birth.month && parsedText.birth.month.index !== birthDate.oneBasedMonth)
      ) {
        return { ok: false, hint: 'Numéro de sécurité sociale incohérent avec les informations fournies' }
      }
    }
  } catch (e) {
    const error = ensureError(e)
    if (text.length < 15 || error.message === 'Input must be 15 characters long') {
      return { ok: false, hint: 'Numéro de sécurité sociale invalide' }
    }

    return { ok: false, hint: 'Numéro de sécurité sociale invalide' }
  }

  if (!SSN.validate(text)) {
    return { ok: false, hint: 'Numéro de sécurité sociale invalide' }
  }
  return { ok: true, value: text }
}
export const socialSecurityNumberMapper: ValidatedTypeMapper<string> = {
  inputType: 'text',
  format: (value) => value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (text) => {
    return text.replace(' ', '').length === 15 ? success(text) : failure('Numéro de sécurité sociale invalide')
  },
}

export type SocialSecurityNumberValidationParams = {
  birthDate?: CalendarDate
  referenceSocialSecurityNumbers?: string[]
}

export function getSocialSecurityNumberMapper({
  birthDate,
  referenceSocialSecurityNumbers,
}: SocialSecurityNumberValidationParams): ValidatedTypeMapper<string> {
  return {
    inputType: 'text',
    format: (value) => value.trim(),
    formatPlaceholder: (value) => value.trim(),
    parse: (text) => {
      const socialSecurityNumber = validateSocialSecurityNumber(text, birthDate, referenceSocialSecurityNumbers)
      return socialSecurityNumber.ok ? success(socialSecurityNumber.value) : failure(socialSecurityNumber.hint)
    },
  }
}
