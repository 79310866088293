import {
  Avatar,
  ChatProvider,
  Dialog,
  FlexSpacedColumn,
  RowButtonV2,
  RowContainerV2,
  Text,
  useDialogVisibility,
  useLanguage,
  useTranslate,
} from '@orus.eu/pharaoh'
import { createContext, memo, useContext, type ReactNode } from 'react'
import { helpContactDetails } from '../../lib/help-contact-details'

const ChatWrapperProvider = createContext<boolean>(false)

export const ChatWrapper = memo<{ children: ReactNode }>(function ChatWrapper({ children }) {
  const { visible, show: openChat, hide } = useDialogVisibility('chat')

  const isAlreadyWrapped = useContext(ChatWrapperProvider)

  // The `ChatWrapper` is already added to the root route, but if the root route itself crashes (e.g. wrong search parameters)
  // it's not wrapped. Therefore, we also wrap it in the root error boundary, so it's possible that it's wrapped twice.
  // Therefore, we add this safety layer
  if (isAlreadyWrapped) {
    return children
  }

  return (
    <ChatWrapperProvider.Provider value={true}>
      <ChatProvider value={openChat}>{children}</ChatProvider>
      {visible ? <ChatFallbackDialog onClose={hide} /> : null}
    </ChatWrapperProvider.Provider>
  )
})

/**
 * Opens as a fallback when the chat is not available in the current language.
 *
 * Note : the "fallback" is currently the only option, because we used to have an hubspot chat widget
 * in the app, but we removed it because :
 *  - it was not working well
 *  - it was have to manage by the care team
 *
 * We might add the Front chat later, so i'm not renaming things now.
 */
const ChatFallbackDialog = memo<{ onClose: () => void }>(function ChatFallbackDialog({ onClose }) {
  const translate = useTranslate()
  const language = useLanguage()
  const { email, whatsappLink } = helpContactDetails[language]
  return (
    <Dialog
      secondaryActionLabel={translate('close')}
      onSecondaryAction={onClose}
      onClose={onClose}
      size="medium"
      title={translate('chat_fallback_title')}
    >
      <FlexSpacedColumn padding="0">
        <Text>{translate('chat_fallback_explanation')}</Text>
        <RowContainerV2>
          <RowButtonV2
            href={whatsappLink}
            avatarLeft={<Avatar icon="whatsapp-brands" size="30" />}
            primaryText="WhatsApp"
          />
          <RowButtonV2
            href={`mailto:${email}`}
            avatarLeft={<Avatar icon="envelope-regular" size="30" />}
            primaryText={email}
          />
        </RowContainerV2>
      </FlexSpacedColumn>
    </Dialog>
  )
})
