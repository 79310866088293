import { createFileRoute } from '@tanstack/react-router'

import PlatformUsersPage from '../../../components/pages/backoffice/platform/platform-users-page'
import PlatformPage from '../../../components/templates/platform-page'

export const Route = createFileRoute('/bak/users/')({
  component: () => (
    <PlatformPage requiredPermissions={['users.read']}>
      <PlatformUsersPage />
    </PlatformPage>
  ),
})
