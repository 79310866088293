import styled from '@emotion/styled'
import { amountToString } from '@orus.eu/amount'
import type { Invoice } from '@orus.eu/backend/src/invoicing/views/invoice-model'
import type { InvoicingItemWithMedStatus } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import { TechnicalError } from '@orus.eu/error'
import { Dialog, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../../client'
import { formatDateTime } from '../../../lib/format'
import { PaymentStatusTag } from './payment-status-tag'

export const InvoiceDetailDialog = memo<{ invoicingItem: InvoicingItemWithMedStatus; onClose: () => void }>(
  function InvoiceDetailDialog({ invoicingItem, onClose }) {
    const { invoice } = invoicingItem
    const { periodStartTimestamp, periodEndTimestamp } = 'items' in invoice ? invoice.items[0] : invoice

    return (
      <Dialog title="Détails de la facture" size="large" onClose={onClose}>
        <DetailsLayout>
          <Text variant="body1Medium">Modèle</Text>
          <Text variant="body1">{'items' in invoice ? 'Facture V1' : 'Facture V2'}</Text>

          <Text variant="body1Medium">Génération</Text>
          <GenerationExplanation invoice={invoice} />

          {invoicingItem.type === 'emitted' ? (
            <>
              <Text variant="body1Medium">Statut du paiement</Text>
              <PaymentStatusTag paymentStatus={invoicingItem.paymentStatus} />
            </>
          ) : null}

          <Text variant="body1Medium">Date d’émission</Text>
          <Text variant="body1">{formatDateTime(invoice.issueTimestamp)}</Text>

          <Text variant="body1Medium">Date d’échéance</Text>
          <Text variant="body1">{formatDateTime(invoice.dueTimestamp)}</Text>

          <Text variant="body1Medium">Période</Text>
          <Text variant="body1">
            {formatDateTime(periodStartTimestamp)} - {formatDateTime(periodEndTimestamp)}
          </Text>

          <Text variant="body1Medium">Total</Text>
          <Text variant="body1">{amountToString(invoice.totalPremium, { addCurrency: true })}</Text>
        </DetailsLayout>
      </Dialog>
    )
  },
)

const DetailsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing[50]};
`

const GenerationExplanation = memo<{ invoice: Invoice }>(function GenerationExplanation({ invoice }) {
  const { generator } = invoice

  switch (generator.type) {
    case 'manual':
      return <ManualGenerationExplanation operatorId={generator.operatorId} />
    case 'simplest':
    case 'automatic':
    case 'automatic-v2':
      return <Text variant="body1">🤖 Automatique</Text>
  }
})

const ManualGenerationExplanation = memo<{ operatorId: string }>(function ManualGenerationExplanation({ operatorId }) {
  const operatorData = trpcReact.users.getUser.useQuery({ id: operatorId })

  const email = operatorData.data ? getOperatorEmail(operatorData.data) : undefined
  return <Text variant="body1">🧑 Manuelle - {email}</Text>
})

function getOperatorEmail(operatorData: UserAccount) {
  if (!('email' in operatorData)) {
    throw new TechnicalError('Operator is expected to have a verified email')
  }
  return operatorData.email
}
