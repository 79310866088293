export function getExternalLink(
  id: string | undefined,
  provider: 'pappers' | 'lysta' | 'creditsafe' | 'complyadvantage',
): string | undefined {
  if (!id) {
    return undefined
  }

  switch (provider) {
    case 'pappers':
      return `https://www.pappers.fr/entreprise/${id}`
    case 'lysta':
      return `https://orus.lysta.fr/companies/${id}`
    case 'creditsafe':
      return `https://app.creditsafe.com/companies/FR-X-${id}`
    case 'complyadvantage':
      return `https://eu.mesh.complyadvantage.com/customers/${id}`
  }
}
